import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../../api';
import './PreviewUtilities.scss';

export class PreviewBookPush extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      users: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;
    if (show && prevProps.show !== show) {
      this.loadUsersList();
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true, empty: false });
    if (validated === false || !obj.users) {
      event.stopPropagation();
    } else {
      this.createData(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  loadUsersList = () => {
    this.setState({
      loading: true,
    });
    api.get('/v1/users')
      .then((res) => {
        this.setState({
          users: res.data.users,
          loading: false,
        });
      })
      .catch((error) => {
        this.errorAlert(error.response.data.error);
        this.setState({
          loading: false,
        });
      });
  };

  createData = (data) => {
    const { obj, onHide } = this.props;

    this.setState({
      loading: true,
    });
    api.post(`/v1/books/${obj.story.bookId}/push`, data)
      .then(() => {
        this.setState({
          loading: false,
        }, () => {
          onHide();
        });
      })
      .catch((error) => {
        this.errorAlert(error.response.data.error);
        this.setState({
          loading: false,
        });
      });
  };

  errorAlert = (error) => {
    this.setState({
      error,
    });
    setTimeout(() => {
      this.setState({
        error: null,
      });
    }, 1500);
  };

  render() {
    const { show, onHide } = this.props;
    const {
      validated, error, loading, users,
    } = this.state;

    if (!show) {
      return null;
    }

    return (
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => this.handleSubmit(e)}
      >
        <div className="PreviewEditScene-box">
          <div className="PreviewEditScene-wrap">
            <div className="PreviewEditScene-header">
              <h2>Book Push</h2>
            </div>

            <div className="PreviewEditScene-content">
              {loading
                ? (
                  <div className="text-center loadingSpinner">
                    <Spinner
                      variant="primary"
                      animation="border"
                      className="justify-content-center"
                    />
                  </div>
                )
                : null}
              <Row className={error === null ? 'd-none' : 'd-block pt-2'}>
                <Col md={12}>
                  <Alert variant="danger">
                    {error}
                  </Alert>
                </Col>
              </Row>
              {users && users.map((obj, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Row key={i} className="py-2 border-bottom d-flex align-items-center">
                  <Col md={2} className="text-center">
                    <Form.Check
                      type="checkbox"
                      id={`checkbox_${i}`}
                      name={`users[${i}]`}
                      value={obj.id}
                    />
                  </Col>
                  <Col md={10} className="text-left">
                    <label htmlFor={`checkbox_${i}`}>{obj.username || obj.fullname}</label>
                  </Col>
                </Row>
              ))}
            </div>

            <div className="PreviewEditScene-footer">
              <Button
                size="sm"
                type="reset"
                variant="secondary"
                disabled={loading}
                onClick={onHide}
              >
                Cancel
              </Button>

              <Button
                size="sm"
                type="submit"
                variant="primary"
                className="mx-1"
                disabled={loading}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}
