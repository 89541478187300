import React from 'react';
import {
  Button, Col, Form, InputGroup, Modal, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../../api';
import { BranchesWidget } from '../../../ui/widgets/BranchesWidget';
import { AnswerPrice } from '../../StoryBranches/BranchEdit/Steps/AnswerPrice';
import { AnswerType } from '../../StoryBranches/BranchEdit/Steps/AnswerType';
import { StoryPreviewContext } from '../context';
import './style.scss';

function ChoiceEditingModal(props) {
  const { loadData } = props;
  const {
    limits,
    storyId,
    setShowEditChoice,
    branches,
    choiceProps,
    answersTypes,
    modifiersTypes,
    setModifiersTypes,
  } = React.useContext(StoryPreviewContext);
  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [goesObj, setGoesObj] = React.useState('');
  const [modifiers, setModifiers] = React.useState(null);
  const [bonusContent, setBonusContent] = React.useState(false);
  const [price, setPrice] = React.useState(0);
  const [answersType, setAnswersType] = React.useState('');
  const [answersText, setAnswersText] = React.useState('');
  const [EROTIC, setEROTIC] = React.useState('');
  const [FREE, setFREE] = React.useState('');
  const [PAID, setPAID] = React.useState('');
  const [ROMANTIC, setROMANTIC] = React.useState('');

  React.useEffect(() => {
    if (answersTypes && answersTypes.length > 0) {
      setEROTIC(answersTypes?.find((x) => x.value === 'erotic').id);
      setFREE(answersTypes?.find((x) => x.value === 'passive').id);
      setPAID(answersTypes?.find((x) => x.value === 'paid').id);
      setROMANTIC(answersTypes?.find((x) => x.value === 'romantic').id);
    }
  }, [answersTypes]);

  React.useEffect(() => {
    if (!choiceProps?.arr?.id) {
      setAnswersText('');
    }
  }, [choiceProps]);

  React.useEffect(() => {
    if (choiceProps?.arr?.gotoBranchId && branches) {
      const obj = branches.find((o) => String(o.id) === String(choiceProps.arr.gotoBranchId));
      setGoesObj(obj);
      setModifiers(('modifiers' in choiceProps.arr) && choiceProps.arr.modifiers);
      setBonusContent(!!(choiceProps.arr.modifiers && choiceProps.arr.modifiers.find((e) => e.value === 'bonus_content')));
      setPrice(choiceProps.arr?.answerPrice);
      setAnswersType(choiceProps.arr?.answerTypeId);
    }
  }, [branches, choiceProps]);

  React.useEffect(() => {
    if (!modifiersTypes) {
      setModifiersTypes();
    }
  }, [modifiersTypes, setModifiersTypes]);

  React.useEffect(() => {
    if (!choiceProps?.arr?.id) {
      setDisabled(answersText?.length > Number(limits?.answer_text_max?.value));
    }
  }, [choiceProps, answersText, limits]);

  const handleChangeBonusContent = (e) => {
    const newModifiers = [];
    if (modifiers && modifiers.length > 0) {
      modifiers.forEach((obj) => {
        if (obj.value !== 'bonus_content') {
          newModifiers.push(obj);
        }
      });
    }
    if (e.target.checked) {
      const bonusContentVal = modifiersTypes.find((modifiersType) => modifiersType.value === 'bonus_content');
      newModifiers.push({
        answerModifierTypeId: String(bonusContentVal.id),
      });
    }
    setModifiers(newModifiers);
    setBonusContent(Boolean(e.target.checked));
  };

  const updateData = (data) => {
    api.put(`/v1/stories/${storyId}/branches/${choiceProps.branchId}`, data)
      .then(() => {
        loadData();
        setLoading(false);
        setShowEditChoice(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const loadStep = (arr) => api.get(`/v1/stories/${storyId}/branches/${choiceProps.branchId}`)
    .then((res) => {
      const step = res.data.branch.steps[0];
      // eslint-disable-next-line no-param-reassign
      arr.text = answersText;
      const answers = JSON.parse(JSON.stringify(step.answers));
      if (!arr.answerTypeId) {
        // eslint-disable-next-line no-param-reassign
        arr.answerTypeId = String(FREE);
      }
      answers.push(arr);
      return updateData({
        steps: [{
          text: step.text,
          answers,
          characterExpressionId: step.characterExpressionId,
          characterId: step.characterId,
          stepTypeId: step.stepTypeId,
        }],
      });
    })
    .catch(() => {
      setLoading(false);
    });

  const handleSubmit = () => {
    setLoading(true);
    const arr = {
      answerPrice: String(price),
      answerTypeId: String(answersType),
      gotoBranchId: goesObj.id,
      modifiers,
    };

    if (choiceProps?.arr?.id) {
      return api.put(`/v1/stories/${storyId}/branches/${choiceProps.branchId}/answers/${choiceProps.arr.id}`, arr)
        .then(() => {
          loadData();
          setLoading(false);
          setShowEditChoice(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    return loadStep(arr);
  };

  const handleClose = () => {
    setGoesObj('');
    setAnswersText('');
    setBonusContent(false);
    setPrice(0);
    setAnswersType(FREE);
    setShowEditChoice(false);
  };

  const answerTextMaxLength = Number(limits.answer_text_max.value);

  return (
    <Modal.Dialog
      className="ChoiceEditingModal"
    >
      <Modal.Header
        closeButton
        onHide={handleClose}
      >
        <Modal.Title>What does this choice do?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {
            !choiceProps?.arr?.id
            && (
            <Row>
              <Col xs={12} className="pb-3">

                <InputGroup>
                  <Form.Control
                    disabled={loading}
                    isInvalid={(answersText?.length > answerTextMaxLength)}
                    size="sm"
                    type="text"
                    placeholder="Answer Text"
                    name="answers_text"
                    value={answersText}
                    onChange={(e) => {
                      setAnswersText(e.target.value);
                    }}
                    className={answersText?.length > answerTextMaxLength ? 'text-limit' : ''}
                  />
                  <InputGroup.Text
                    className="char-limit-info-box"
                  >
                    {answerTextMaxLength - (answersText ? answersText.length : 0)}
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
            )
          }
        <Row>
          <AnswerPrice
            xs={6}
            sm={6}
            lg={6}
            xl={6}
            disabled={loading}
            answerPrice={price}
            handleChange={(e) => {
              // If type is paid, romantic, or erotic and price is set to blank,
              // the type becomes free.
              if (Number(e) === 0 && Number(answersType) !== FREE) {
                setAnswersType(FREE); // free
              }
              // If type is free and price is set to not blank, type becomes paid
              if (Number(e) > 0 && Number(answersType) === FREE) {
                setAnswersType(PAID);
              }
              setPrice(Number(e));
            }}
          />

          <AnswerType
            xs={6}
            sm={6}
            lg={6}
            xl={6}
            disabled={loading}
            answerTypeId={answersType}
            answerTypes={answersTypes}
            handleChange={(e) => {
              // if price is blank and type is set to paid, price becomes 2 hearts
              if (e === '') {
                if (price > 0) {
                  // eslint-disable-next-line no-param-reassign
                  e = PAID;
                } else if (price === 0) {
                  // eslint-disable-next-line no-param-reassign
                  e = FREE;
                }
              }
              if (Number(e) === PAID && price === 0) {
                setPrice(2);
              }
              // if price is blank and type is set to romantic or erotic, price becomes 6 hearts
              if ((Number(e) === ROMANTIC || Number(e) === EROTIC) && price === 0) {
                setPrice(6);
              }
              // If price is not blank and type becomes set to "free", price becomes blank.
              if (Number(e) === FREE && price > 0) {
                setPrice(0);
              }
              setAnswersType(e);
            }}
          />

          <Col sm={12} className="py-2">
            <Form.Check
              custom
              disabled={loading}
              checked={bonusContent}
              id="bonus_content"
              name="bonus_content"
              type="checkbox"
              label="Bonus content"
              onChange={handleChangeBonusContent}
            />
          </Col>

          <Col sm={12}>
            <p className="boxGoesNode">
              <span style={{
                flex: 1,
                paddingRight: '1em',
                whiteSpace: 'nowrap',
              }}
              >
                Goes to
              </span>
              <b style={{
                flex: 3,
              }}
              >
                {goesObj.title}
              </b>
            </p>
          </Col>
        </Row>

        {
            storyId
            && (
            <BranchesWidget
              disabled={loading}
              branchesList={branches}
              storiesID={storyId}
              onSelected={(e) => {
                setGoesObj(e);
              }}
              currentBranchId={choiceProps.branchId}
              isSelected={goesObj}
              select={null}
            />
            )
          }

        {
            loading && (
            <div
              style={{
                position: 'absolute',
                zIndex: 999,
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                background: 'rgba(0,0,0,.2)',
              }}
            >
              <div style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%,-50%)',
              }}
              >
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
            )
          }
      </Modal.Body>

      <Modal.Footer>
        <Button
          disabled={loading}
          variant="outline-secondary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disabled={loading || disabled}
          onClick={handleSubmit}
          variant="outline-secondary"
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal.Dialog>
  );
}

export function ChoiceEditing(props) {
  const {
    showEditChoice,
  } = React.useContext(StoryPreviewContext);

  if (!showEditChoice) {
    return null;
  }

  return (
    <ChoiceEditingModal {...props} />
  );
}
