import React, { useReducer } from 'react';
import { api } from '../../../../api';
import { branchesWidgetReducer } from './BranchesWidgetReducer';
import {
  BRANCHES, FILTERED_DATA, LOADING, SEARCH, STORY, VALUE,
} from './types';
import { BranchesWidgetContext } from './index';

export function BranchesWidgetData(props) {
  const {
    children, currentBranchId, isSelected, onSelected, storiesID,
  } = props;

  const initialState = {
    value: null,
    loading: false,
    search: '',
    story: [],
    branches: [],
    filteredData: null,
  };

  const [state, dispatch] = useReducer(branchesWidgetReducer, initialState);

  const getBranchs = async () => {
    dispatch({ type: LOADING, payload: true });

    return api.get(`/v1/stories/${storiesID}/branches?order=updatedAt`)
      .then((res) => {
        const branches = currentBranchId
          ? res.data.branches.filter((x) => x.id !== currentBranchId)
          : res.data.branches;
        dispatch({ type: LOADING, payload: false });
        dispatch({ type: BRANCHES, payload: branches });
        dispatch({ type: FILTERED_DATA, payload: branches });
      })
      .catch(() => {
        dispatch({ type: LOADING, payload: false });
      });
  };

  const getStories = async () => {
    dispatch({ type: LOADING, payload: true });
    return api.get(`/v1/stories/${storiesID}`)
      .then((res) => {
        dispatch({ type: LOADING, payload: false });
        dispatch({ type: STORY, payload: res.data.story });
      })
      .catch(() => {
        dispatch({ type: LOADING, payload: false });
      });
  };

  React.useEffect(() => {
    (async () => getBranchs())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!state.search) {
      return dispatch({ type: FILTERED_DATA, payload: state.branches });
    }
    const newData = state.branches.filter((el) => {
      const t = el?.title.toLowerCase();
      return t.includes(state.search.toLowerCase());
    });
    return dispatch({ type: FILTERED_DATA, payload: newData });
  }, [state.search, state.branches]);

  const setSearch = (val) => {
    dispatch({ type: SEARCH, payload: val });
  };

  const setValue = (val) => {
    onSelected(val);
    // console.log(val)
    dispatch({ type: VALUE, payload: val });
  };

  React.useEffect(() => {
    dispatch({ type: VALUE, payload: isSelected });
  }, [isSelected]);

  return (
    <BranchesWidgetContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        value: state.value,
        search: state.search,
        loading: state.loading,
        story: state.story,
        branches: state.branches,
        storiesID,
        filteredData: state.filteredData,

        getStories,
        getBranchs,
        setSearch,
        setValue,
      }}
    >
      {children}
    </BranchesWidgetContext.Provider>
  );
}
