import classNames from 'classnames/bind';
import React, { PureComponent } from 'react';
import {
  Button, Card, Dropdown, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';
import { BranchContent } from './BranchContent';
import { ChangeColor } from './ChangeColor';
import { ConnectionButton } from './ConnectionButton';
import { DeleteBranch } from './DeleteBranch';
import { EditName } from './EditName';
import styles from './StoryBranches.scss';

const cs = classNames.bind(styles);

export class BranchItem extends PureComponent {
  constructor(props, ...args) {
    super(props, ...args);
    const { branchNode, newBranchId } = this.props;

    this.brancheNameForm = React.createRef();
    this.refFormControl = React.createRef();
    const editName = !!(newBranchId && newBranchId === branchNode.id);
    this.state = {
      modalDeleteBranchShow: false,
      editName,
      loading: false,
    };

    if (editName) {
      this.startEditName();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { editName } = this.state;
    const { onEdit } = this.props;

    if (prevState.editName !== editName) {
      if (editName) {
        this.startEditName();
      } else {
        this.stopEditName();
      }

      if (onEdit) {
        onEdit(editName);
      }
    }
  }

  handleClickOutside = (event) => {
    const { editName } = this.state;
    const area = this.refFormControl.current;
    if (area && !area.contains(event.target) && editName) {
      this.brancheNameForm.current.click();
    }
  };

  escFunction = (event) => {
    const { editName } = this.state;

    if (event.keyCode === 27 && editName) {
      this.setState({
        editName: false,
      });
    }
  };

  stopEditName = () => {
    document.removeEventListener('click', this.handleClickOutside, false);
    document.removeEventListener('keydown', this.escFunction, false);
  };

  startEditName = () => {
    document.addEventListener('click', this.handleClickOutside, { passive: true });
    document.addEventListener('keydown', this.escFunction, { passive: true });
  };

  updateBranch = (arr) => {
    const { branchNode, storyId, onError } = this.props;

    this.setState({
      loading: true,
    });

    api.patch(`/v1/stories/${storyId}/branches/${branchNode.id}`, arr)
      .then(() => {
        this.update();
        this.setState({
          loading: false,
          editName: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (onError) {
          onError(error);
        }
      });
  };

  editName = () => {
    const { branchNode } = this.props;
    const { editName, loading } = this.state;

    if (!loading && editName) {
      return (
        <EditName
          {...this.props}
          refFormControl={this.refFormControl}
          brancheNameForm={this.brancheNameForm}
          handleSubmit={this.updateBranch}
        />
      );
    }
    if (loading) {
      return (
        <div>
          <Spinner animation="border" variant="primary" />
          <div className="editNameBg" />
        </div>
      );
    }

    return (
      <span>{branchNode.title}</span>
    );
  };

  isIntro = () => {
    const { branchNode } = this.props;

    return branchNode.title === 'intro';
  };

  clearSelection = () => {
    const { clearSelection } = this.props;

    if (clearSelection) {
      clearSelection();
    }
  };

  update = (branchNode) => {
    const { update } = this.props;

    if (update) {
      update(branchNode);
    }
  };

  render() {
    const {
      disabled,
      lastEdit,
      duplicate,
      branchNode,
      selectCascade,
      storyId,
      storyGroup,
      actionEditBranch,
      onDeleteSuccess,
      isAnalyticsVisible,
      isCheckStep,
      memorySlotData,
    } = this.props;
    const { editName, modalDeleteBranchShow, modalChangeColorBranchShow } = this.state;

    const modalDeleteBranchClose = () => {
      this.setState({ modalDeleteBranchShow: false });
      this.update(branchNode);
    };

    const deleteBranch = () => {
      if (modalDeleteBranchShow !== true) {
        return null;
      }

      return (
        <DeleteBranch
          storyId={storyId}
          show={modalDeleteBranchShow}
          onHide={modalDeleteBranchClose}
          obj={branchNode}
          onDeleteSuccess={onDeleteSuccess}
        />
      );
    };

    const decisionPoint = branchNode.links && branchNode.links.length > 0;

    const modalChangeColorBranchClose = () => {
      this.setState({ modalChangeColorBranchShow: false });
      this.update(branchNode);
    };

    const changeColorBranch = () => {
      if (modalChangeColorBranchShow !== true) {
        return null;
      }

      return (
        <ChangeColor
          storyId={storyId}
          show={modalChangeColorBranchShow}
          onHide={modalChangeColorBranchClose}
          obj={branchNode}
        />
      );
    };

    const cardBodyStyle = branchNode.bgColor ? { backgroundColor: branchNode.bgColor } : {};

    return (
      <Card
        className={cs(
          'text-center m-1 branchesItems branchesItemsLong',
          this.isIntro() ? 'branch_intro' : `branch_${branchNode.type}`,
          branchNode.bonus_content ? 'branch_gold' : null,
          lastEdit === branchNode.id ? 'lastEdit' : null,
          editName ? 'branchEdit' : null,
          decisionPoint || isCheckStep ? 'branchesDecsionPoint' : null,
          isCheckStep ? 'branch_check' : null,
        )}
      >
        <Card.Body style={cardBodyStyle}>
          <Card.Title
            className="mb-0"
            onDoubleClick={() => {
              if (!disabled && !this.isIntro()) {
                this.setState({
                  editName: true,
                });
              }
            }}
          >
            {this.editName()}
            {decisionPoint ? 'Decision Point' : null}
          </Card.Title>

          <Card.Text
            as="div"
            className={cs('cardBranchContent', isAnalyticsVisible ? 'showAnalytic' : null)}
          >
            <BranchContent
              storyGroup={storyGroup}
              branchNode={branchNode}
              isAnalyticsVisible={isAnalyticsVisible}
              memorySlotData={memorySlotData}
            />
          </Card.Text>

        </Card.Body>
        <Card.Footer>
          {(!isAnalyticsVisible || !disabled) && (
            <ConnectionButton
              {...this.props}
              disabled={disabled}
            />
          )}

          <Button
            type="reset"
            variant="outline-dark"
            size="sm"
            className="mx-1"
            onClick={() => {
              this.clearSelection();
              if (actionEditBranch) {
                actionEditBranch(branchNode);
              }
            }}
          >
            Edit
          </Button>

          {(!isAnalyticsVisible || !disabled) && (
            <Dropdown className="mx-1 d-inline-block">
              <Dropdown.Toggle
                variant="outline-dark"
                id="ellipsis"
                className="mx-1"
                size="sm"
              >
                ...
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  disabled={disabled}
                  onClick={() => {
                    this.clearSelection();
                    this.setState({ modalChangeColorBranchShow: true });
                  }}
                >
                  Color
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={disabled}
                  onClick={() => {
                    this.clearSelection();
                    duplicate([branchNode.id]);
                  }}
                >
                  Duplicate
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={disabled}
                  onClick={() => {
                    this.clearSelection();
                    selectCascade(branchNode.id);
                  }}
                >
                  Select cascade
                </Dropdown.Item>

                <Dropdown.Divider />
                <Dropdown.Item
                  disabled={disabled || this.isIntro()}
                  onClick={() => {
                    this.clearSelection();
                    this.setState({ modalDeleteBranchShow: true });
                  }}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          {deleteBranch()}
          {changeColorBranch()}
        </Card.Footer>
      </Card>
    );
  }
}
