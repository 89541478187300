import { FormikErrors } from 'formik';
import React, { ChangeEvent, useEffect } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { Character } from 'dorian-shared/types/character/Character';
import classes from './MemoryBank.module.scss';
import { MemorySlotsConverted } from './memoryBankTypes';
import { MemoryBankTypesList, MemorySlotsTypesList } from './MemoryBankTypesList';

export const stringOrNumberValuePattern = '^([A-Za-z]|[0-9]|_|-| |[.])+$';

type MemoryBankSlotsFormProps = {
  slotIndex: number,
  memoryBankSlot: MemorySlotsConverted,
  errors: string | FormikErrors<MemorySlotsConverted>,
  handleChange: (event: ChangeEvent<HTMLInputElement>, slotIndex: number) => void,
  handleRemove: (slotIndex: number) => void,
  characters: Character[] | null,
  getCharacters: () => void,
}

export function MemoryBankSlotsForm(props: MemoryBankSlotsFormProps) {
  const {
    handleChange, handleRemove, memoryBankSlot, slotIndex, errors,
    characters, getCharacters,
  } = props;

  const {
    type, value, defaultValue, name,
  } = memoryBankSlot;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(event, slotIndex);
  };
  const onRemove = () => {
    handleRemove(slotIndex);
  };

  useEffect(() => {
    if (type === MemorySlotsTypesList.character) {
      getCharacters();
    }
  }, [type, getCharacters]);

  const isInvalidName = !!(errors && Object.prototype.hasOwnProperty.call(errors, 'name'));
  const isInvalidValue = !!(errors && Object.prototype.hasOwnProperty.call(errors, 'value'));

  return (
    <Row>
      <Form.Row
        className={classes.memoryBankFormRow}
      >
        <Form.Group
          className={classes.memoryBankFormGroup}
        >
          <Form.Control
            required
            type="text"
            placeholder="name"
            pattern={stringOrNumberValuePattern}
            name={`memoryBankSlots[${slotIndex}].name`}
            value={name ?? ''}
            onChange={onChange}
            isInvalid={isInvalidName}
            className={classes.memoryBankInput}
          />
        </Form.Group>
        <Form.Group
          className={classes.memoryBankFormGroup}
        >
          <MemoryBankTypesList
            value={type}
            slotIndex={slotIndex}
            handleChange={onChange}
          />
        </Form.Group>
        <Form.Group
          className={classes.memoryBankFormGroup}
        >
          {(type === MemorySlotsTypesList.string || type === MemorySlotsTypesList.number)
          && (
          <Form.Control
            required
            type={type === MemorySlotsTypesList.number ? 'number' : 'text'}
            placeholder="default"
            pattern={stringOrNumberValuePattern}
            name={`memoryBankSlots[${slotIndex}].value`}
            value={value ?? defaultValue}
            onChange={onChange}
            isInvalid={isInvalidValue}
            className={classes.memoryBankInput}
          />
          )}
          {type === MemorySlotsTypesList.boolean
          && (
          <Form.Control
            as="select"
            required
            name={`memoryBankSlots[${slotIndex}].value`}
            value={value ?? defaultValue}
            onChange={onChange}
            isInvalid={isInvalidValue}
            className={classes.memoryBankInput}
          >
            <option value="" disabled>[select default]</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Form.Control>
          )}
          {type === MemorySlotsTypesList.character
          && (
          <Form.Control
            as="select"
            required
            name={`memoryBankSlots[${slotIndex}].value`}
            value={value ?? defaultValue}
            onChange={onChange}
            isInvalid={isInvalidValue}
            className={classes.memoryBankInput}
          >
            {!characters
              ? <option value="loading">loading...</option>
              : (
                <>
                  <option value="" disabled>[select default]</option>
                  {characters.map((character) => (
                    <option value={character.id} key={character.id}>
                      {character.name}
                    </option>
                  ))}
                </>
              )}
          </Form.Control>
          )}
        </Form.Group>
        <Form.Group
          className={classes.memoryBankFormGroupShort}
        >
          <Button
            onClick={onRemove}
            variant="secondary"
            className={classes.memoryBankRemoveButton}
          >
            X
          </Button>
        </Form.Group>
      </Form.Row>
    </Row>
  );
}
