import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, FormControl, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../../api';
import { StoryPreviewContext } from '../context';
import './PreviewUtilities.scss';

export class PreviewAddStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: null,
      loading: false,
      error: null,
      form: {
        stepTypeId: 1,
        characterExpressionId: null,
        characterId: null,
      },
      ending: false,
      result: false,
      stepsTypeFields: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { obj } = this.props;

    if (prevProps.obj.previewAddStep !== obj.previewAddStep && obj.previewAddStep) {
      this.setState({
        validated: null,
        loading: false,
        error: null,
        form: {
          stepTypeId: 1,
          characterExpressionId: null,
          characterId: null,
        },
        ending: false,
        result: false,
        stepsTypeFields: [],
      }, () => {
        this.checkChoice();
        this.checkFields();
      });
    }
  }

  errorAlert = (error) => {
    this.setState({
      error,
    });
    setTimeout(() => {
      this.setState({
        error: null,
      });
    }, 1500);
  };

  saveData = (obj, validated) => {
    const { obj: _obj, onHide, updateStep } = this.props;

    if (!validated) {
      return;
    }
    this.setState({
      loading: true,
    });

    const step = Number(_obj.current_step) + 1;

    api.post(`/v1/stories/${_obj.story.id}/branches/${_obj.current_branch.id}/steps`, obj)
      .then(() => {
        this.setState({
          loading: false,
        }, () => {
          updateStep(_obj.current_branch.id, step);
          onHide();
        });
      })
      .catch((error) => {
        this.errorAlert(error.response.data.error);
        this.setState({
          loading: false,
        });
      });
  };

  handleChange = (key) => {
    const { form } = this.state;
    const { name } = key.target;
    form[name] = key.target.value;
    this.setState({
      form,
    }, () => {
      if (name === 'stepTypeId') {
        this.checkFields();
      }
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true });
    if (validated === false) {
      event.stopPropagation();
    } else {
      this.saveData(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  };

  checkChoice() {
    const { obj } = this.props;

    if (!obj.current_branch || !obj.current_branch.steps || obj.current_branch.steps.length < 1) {
      return;
    }
    const { steps } = obj.current_branch;
    const { form } = this.state;
    form.stepTypeId = 1;
    const ending = steps.find((a) => a && (Number(a.stepTypeId) === 5)) !== undefined;
    const result = steps.find((a) => a && (Number(a.stepTypeId) === 6)) !== undefined;
    if (result) {
      form.stepTypeId = 5;
    }
    if (ending) {
      form.stepTypeId = 5;
    }
    this.setState({
      ending,
      result,
      form,
    });
  }

  checkFields = () => {
    const { form } = this.state;
    const { obj } = this.props;

    const step = obj.stepTypes.find((a) => a && (Number(a.id) === Number(form.stepTypeId)));
    const newFields = [];
    step.fields.forEach((object) => {
      newFields[object] = true;
    });
    this.setState({
      stepsTypeFields: newFields,
    });
  };

  render() {
    const { obj, onHide } = this.props;
    const {
      validated, form, error, result, loading, stepsTypeFields, ending,
    } = this.state;

    if (!obj.previewAddStep) {
      return null;
    }

    const { current_branch: currentBranch } = obj;
    const { steps } = currentBranch;
    const limitName = `step_${form.stepTypeId}_text_max`;
    const { limits } = this.context;
    const limit = limits && limits[limitName] ? limits[limitName].value : 0;

    return (
      <div className="PreviewEditScene-box">
        <div className="PreviewEditScene-wrap">
          <Form
            noValidate
            validated={validated}
            onSubmit={this.handleSubmit}
          >

            <div className="PreviewEditScene-header">
              <h2>Add Step</h2>
            </div>

            <div className="PreviewEditScene-content">
              {
                  loading
                  && (
                  <div className="text-center loadingSpinner">
                    <Spinner
                      variant="primary"
                      animation="border"
                      className="justify-content-center"
                    />
                  </div>
                  )
                }
              <Row className={error === null ? 'd-none' : 'd-block pt-2'}>
                <Col md={12}>
                  <Alert variant="danger">
                    {error}
                  </Alert>
                </Col>
              </Row>

              <Form.Group
                md={12}
                controlId="addStepType"
              >
                <Form.Label className="prev-addStepLabel p-2">Step Type</Form.Label>
                <Form.Control
                  size="sm"
                  as="select"
                  name="stepTypeId"
                  value={form.stepTypeId}
                  onChange={this.handleChange}
                >
                  {obj.stepTypes.filter((el) => ![6, 7].includes(el.id)).map((stepType) => {
                    const dis = steps && (
                      (steps.length > 0 && stepType.id === 3)
                        || (steps.length > 0 && result && stepType.id !== 5)
                        || (steps.length > 0 && ending && stepType.id !== 5 && stepType.id !== 6)
                        || (
                          steps.length > 0 && !ending && !result
                          && (stepType.id === 5 || stepType.id === 6)
                        )
                    );

                    return !dis && (
                      <option value={stepType.id} key={stepType.id}>
                        {stepType.title}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>

              {
                  (stepsTypeFields.character === true)
                  && (
                  <Form.Group
                    md={12}
                    controlId="addСharacterId"
                  >
                    <Form.Label className="prev-addStepLabel p-2">Character</Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      name="characterId"
                      defaultValue=""
                      onChange={this.handleChange}
                    >
                      <option value="" disabled>Select character</option>
                      {obj.characters.map((character) => (
                        <option value={character.id} key={character.id}>{character.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  )
                }

              {
                  (stepsTypeFields.characterExpression === true)
                  && (
                  <Form.Group
                    md={12}
                    controlId="addCharacterExpressionId"
                  >
                    <Form.Label className="prev-addStepLabel p-2">Character Expression</Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      name="characterExpressionId"
                      defaultValue={23}
                      onChange={this.handleChange}
                    >
                      {
                        obj.expressions.map((expression, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <option value={expression.id} key={i}>{expression.title}</option>
                        ))
                      }
                    </Form.Control>
                  </Form.Group>
                  )
                }

              {
                  (stepsTypeFields.subType === true) && (
                  <Form.Group
                    md={12}
                    controlId="addSubType"
                  >
                    <Form.Label className="prev-addStepLabel p-2">Sub Type</Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      name="subType"
                      onChange={this.handleChange}
                    >
                      <option value="choices">choices</option>
                      <option value="charts">charts</option>
                      <option value="traits">traits</option>
                    </Form.Control>
                  </Form.Group>
                  )
                }

              {
                  (stepsTypeFields.text === true)
                  && (
                  <Form.Group
                    md={12}
                    controlId="Text"
                  >
                    <Form.Label className="prev-addStepLabel p-2">Text</Form.Label>
                    <span className="inpBox inpBoxEdit">
                      <FormControl
                        value={form.text || ''}
                        name="text"
                        as="textarea"
                        onChange={this.handleChange}
                        className={form.text && form.text.length > Number(limit) ? 'text-limit' : null}
                      />
                      <Form.Text className="char-limit-info-box">
                        {Number(limit) - (form.text ? form.text.length : 0)}
                      </Form.Text>
                    </span>
                  </Form.Group>
                  )
                }

            </div>

            <div className="PreviewEditScene-footer">
              <Button
                size="sm"
                type="reset"
                variant="secondary"
                onClick={onHide}
              >
                Cancel
              </Button>

              <Button
                size="sm"
                type="submit"
                variant="primary"
                className="mx-1"
                disabled={(form.text && form.text.length > Number(limit))}
              >
                Save
              </Button>
            </div>

          </Form>
        </div>
      </div>
    );
  }
}

PreviewAddStep.contextType = StoryPreviewContext;
