import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import {
  Button, Card, Col, Form, FormControl, ListGroup, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { Pagination } from '../../ui/Pagination/Pagination';
import { ChangeUser } from '../Backgrounds/ChangeUser';
import { Delete } from '../Backgrounds/Delete';
import { EditAlias } from '../Backgrounds/EditAlias';
import { UploadImage } from '../Backgrounds/UploadImage';
import { getUserDisplayName } from '../Users';
import './index.scss';

const debounceEvent = (callback, time) => {
  let interval;
  return (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      callback(...args);
    }, time);
  };
};

function RenderImageItems(props) {
  const {
    show,
    userRole,
    selected,
    imageObject,
    loadImages,
  } = props;

  const { user } = imageObject;
  return (
    <ListGroup.Item
      as="div"
      action
      active={selected && selected.id === imageObject.id}
      onClick={show}
    >
      <Row className="align-items-center">
        <Col sm={2} className="pr-0">
          <img
            style={{
              height: 50,
              width: 50,
              objectFit: 'cover',
            }}
            src={imageObject.imageUrl}
            alt={imageObject.alias}
          />
        </Col>
        <Col>
          <b>{imageObject.title}</b>
          <br />
          {imageObject.alias}
        </Col>
        {
          (userRole === 'admin' && user) && (
          <>
            <Col>
              {getUserDisplayName(user)}
            </Col>
            <Col sm={3} className="text-right px-0">
              <ChangeUser
                customCharacter={selected}
                update={loadImages}
              />
              <EditAlias
                type="character"
                obj={selected}
                update={loadImages}
                user={user}
              />
              <Delete
                type="character"
                obj={selected}
                update={loadImages}
              />
            </Col>
          </>
          )
        }
      </Row>
    </ListGroup.Item>
  );
}

function ShowSpinner({ show }) {
  if (!show) return null;
  return (
    <>
      <div className="spinner">
        <Spinner
          variant="primary"
          animation="border"
          className="ml-2"
          size="lg"
        />
      </div>
      <div className="overlay" />
    </>
  );
}

function SearchBar({ initialValue, onSearchSubmit }) {
  const [searchString, setSearchString] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearchSubmit(searchString);
  };

  const onChangeSearchString = (event) => {
    const newSearchString = event.target.value;
    setSearchString(newSearchString);
    onSearchSubmit(newSearchString);
  };

  useEffect(() => {
    setSearchString(initialValue);
  }, [initialValue]);

  return (
    <Form onSubmit={handleSubmit} className="search-form">
      <FormControl
        type="search"
        className="search-form_input"
        size="sm"
        placeholder="Type search substring"
        aria-label="Search"
        value={searchString}
        onChange={onChangeSearchString}
      />
      <Button
        type="button"
        size="sm"
        variant="secondary"
        onClick={handleSubmit}
      >
        Search
      </Button>
    </Form>
  );
}

export function CharacterArtLibrary(props) {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);
  const [images, setImages] = useState([]);
  const [uploadImage, setUploadImage] = useState(false);
  const [editImage, setEditImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCountImages, setTotalCountImages] = useState(0);
  const [searchImageString, setSearchImageString] = React.useState('');
  const {
    history,
    auth,
  } = props;

  const user = auth.getUser();
  const pageSize = 10;

  const initUpload = () => {
    setUploadImage(true);
    setEditImage(null);
  };

  const page = {
    header: {
      title: 'Character Art Library',
      settings: 'admin',
    },
    sidebar: {
      nav: [
        {
          title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
          href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
          action: history.length > 1 && document.referrer !== window.location.href
            ? () => {
              window.history.back();
            } : null,
          variant: 'secondary',
          disabled: false,
        },
        {
          title: 'Upload Character Art',
          action: () => initUpload(),
          variant: 'primary',
          disabled: false,
        },
      ],
    },
  };

  const loadImages = useCallback(() => {
    setLoading(true);

    const params = {
      order: 'createdAt:desc',
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
      search: searchImageString || undefined,
      authorId: user.role !== 'admin' ? user.id : undefined,
    };
    api.get('/v1/customcharacters', { params })
      .then((response) => {
        const { images: loadedImages, totalCount } = response.data;
        setImages(loadedImages);
        setTotalCountImages(totalCount);
      }).finally(() => setLoading(false));
  }, [currentPage, searchImageString, user.id, user.role]);

  useEffect(() => {
    if (!uploadImage) loadImages();
  }, [loadImages, uploadImage]);

  const cancelUpload = () => {
    setUploadImage(false);
  };

  const confirmUpload = () => {
    setUploadImage(false);
    setCurrentPage(1);
  };

  const editUpload = () => {
    setUploadImage(true);
    setEditImage(selected);
  };

  const handleSearchBar = debounceEvent((searchString) => {
    setCurrentPage(1);
    setSearchImageString(searchString);
  }, 1000);

  const paginationProps = {
    pageSize,
    currentPage,
    totalCount: totalCountImages,
    onPageChange: (newPage) => setCurrentPage(newPage),
  };
  return (
    <>
      <PageWrapper
        {...props}
        page={page}
      >
        <Card className="d-block">
          <Card.Body>
            <Card.Title className="text-center">
              Character Art Library
              <ShowSpinner show={loading} />
            </Card.Title>
            {
              (!loading || images.length > 0)
              && (
              <Row>
                <Col sm={6}>
                  <SearchBar
                    initialValue={searchImageString}
                    onSearchSubmit={handleSearchBar}
                  />
                  <Pagination {...paginationProps} />
                  <ListGroup>
                    {images.map((image) => (
                      <RenderImageItems
                        show={() => {
                          setSelected(image);
                        }}
                        loadImages={loadImages}
                        selected={selected}
                        imageObject={image}
                        userRole={user.role}
                        key={image.id}
                      />
                    ))}
                  </ListGroup>
                  <Pagination {...paginationProps} />
                </Col>
                <Col sm={6} className="text-center">
                  {!!selected && (
                  <div className="stickyImgBox">
                    <h5>
                      {selected.title}
                    </h5>
                    <Row float="center">
                      <Col>
                        <img
                          style={{ maxHeight: 500, maxWidth: '100%' }}
                          src={selected?.imageUrl}
                          alt="Preview"
                        />
                      </Col>
                    </Row>
                    <Row float="center">
                      <Col style={{ padding: '10px' }}>
                        <Button
                          onClick={editUpload}
                          variant="secondary"
                          className="mx-1"
                          hidden={user?.role !== 'admin'}
                        >
                          Replace image
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  )}
                </Col>
              </Row>
              )
            }
          </Card.Body>
        </Card>
      </PageWrapper>

      {
        uploadImage
        && (
        <UploadImage
          type="character"
          show={uploadImage}
          onHide={cancelUpload}
          update={confirmUpload}
          obj={editImage}
          user={user}
        />
        )
      }
    </>
  );
}
