import React from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { StoryPreviewData } from './context/data';
// eslint-disable-next-line import/no-cycle
import { Preview } from './Preview';

const winProps = 'width=480,height=900,scrollbars=no,status=no,titlebar=no,location=no,toolbar=no,personalbar=no';

export function HideUi(props) {
  const { onChange, value } = props;

  return (
    <div
      style={{
        position: 'relative',
        marginTop: '-2em',
      }}
    >
      <Form.Group>
        <Form.Check
          custom
          id="hideUiCheck"
          checked={value}
          onChange={onChange}
          type="checkbox"
          label="Hide UI"
        />
      </Form.Group>
    </div>
  );
}

export class DetachablePreview extends React.PureComponent {
  constructor(props) {
    super(props);
    this.externalWindow = null;
    this.state = {
      hideUi: false,
      portal: false,
      emSize: 10,
    };
  }

  closeWindow = () => {
    this.setState({ portal: false });
    if (this.externalWindow) this.externalWindow.close();
    this.externalWindow = null;
  };

  onExternalClose = () => {
    const { portal } = this.state;

    if (portal && this.externalWindow) {
      this.setState({ portal: false });
      this.externalWindow = null;
    }
  };

  openWindow = () => {
    const { storyUuid } = this.props;
    this.externalWindow = window.open(`/play/${storyUuid}`, '_blank', winProps);

    this.externalWindow.document.title = document.title;

    // NOTE: Different browser and also different versions of same browser
    // have strange behaviour for this events.
    // Dirty fix.
    this.externalWindow.addEventListener('beforeunload', this.onExternalClose, { capture: true });
    this.externalWindow.addEventListener('close', this.onExternalClose, { capture: true });
    this.externalWindow.addEventListener('unload', this.onExternalClose, { capture: true });

    this.externalWindow.addEventListener('load', () => {
      const { portal } = this.state;

      if (!portal) {
        this.setState({ portal: true });
      }
    });
  };

  beforeUnload = () => {
    const { storyUuid } = this.props;

    Preview.clearState(storyUuid);
    this.closeWindow();
  };

  componentDidUpdate(prevProps, prevState) {
    const { update, storyUuid, show } = this.props;
    const { portal } = this.state;

    if (prevState.portal !== portal) {
      update(!!(show && !portal));
    }

    if (prevProps.show !== show) {
      Preview.clearState(storyUuid);
      this.closeWindow();
      update(!!(show && !portal));
      if (show) {
        window.addEventListener('beforeunload', this.beforeUnload, { passive: true });
      } else {
        window.removeEventListener('beforeunload', this.beforeUnload);
      }
    }
  }

  componentWillUnmount() {
    const { storyUuid } = this.props;

    window.removeEventListener('beforeunload', this.beforeUnload);
    Preview.clearState(storyUuid);
    this.closeWindow();
  }

  renderPanel() {
    const { hideDetach, onClose } = this.props;

    return (
      <div className="navi">
        <Row>
          {!hideDetach
          && (
          <Col>
            <Button
              block
              onClick={this.openWindow}
              variant="primary"
              size="sm"
            >
              Detach
            </Button>
          </Col>
          )}
          <Col>
            <Button
              disabled={!onClose}
              block
              onClick={onClose}
              variant="secondary"
              size="sm"
            >
              Close
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  refCallback = (el) => {
    if (el) {
      const newHeight = Math.min(el.getBoundingClientRect().height, 987);
      const emSize = 10 * (newHeight / 987);
      this.setState({ emSize });
    }
  };

  render() {
    const { hideUi, emSize, portal } = this.state;
    const {
      show, setLastEdit, lastEdit, storyUuid,
    } = this.props;

    if (portal || !show || !storyUuid) {
      return (
        <div className=" previewDock " ref={this.refCallback} />
      );
    }

    return (
      <StoryPreviewData>
        <div className=" previewDock " ref={this.refCallback}>
          {this.renderPanel()}
          <div>
            <div className="emulatorWindowDock" style={{ fontSize: `${emSize}px` }}>
              <Preview
                storyUuid={storyUuid}
                setLastEdit={setLastEdit}
                lastEdit={lastEdit}
                hideUi={hideUi}
              />
            </div>
          </div>
          <HideUi
            value={hideUi}
            onChange={() => {
              this.setState({
                hideUi: !hideUi,
              });
            }}
          />
        </div>
      </StoryPreviewData>
    );
  }
}
