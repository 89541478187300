import React from 'react';
import { Button } from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import { PremiumIpDisabledApprovedEdit } from '../../../utils/premiumIpDisabledApprovedEdit';
import { PremiumIpDisabledEdit } from '../../../utils/premiumIpDisabledEdit';
import { StoryPreviewContext } from '../context';
import './style.scss';

export function AddAnswer(props) {
  const {
    story, hideUi, auth, step,
  } = props;
  const user = auth.getUser();
  const {
    setShowEditChoiceProps,
    setShowEditChoice,
    showEditChoice,
  } = React.useContext(StoryPreviewContext);

  if (
    !step.answers
    || hideUi
    || step.stepTypeId !== 3
    || step.answers.length > 2
    || PremiumIpDisabledEdit(user.role, story.book, story?.group)
    || PremiumIpDisabledApprovedEdit(user.role, story.book)
    || (story?.group === 'live' || story?.group === 'liveprompt')
  ) {
    return null;
  }

  return (
    <div className="preview-stepTextBox">
      <Button
        block
        className="btnAddAnswer"
        variant="outline-secondary"
        onClick={() => {
          setShowEditChoiceProps(props);
          setShowEditChoice(!showEditChoice);
        }}
      >
        Add Answer
      </Button>
    </div>
  );
}
