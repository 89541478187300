import React, { Component } from 'react';
import {
  Button, Card, Col, Form, Row,
} from 'react-bootstrap';

export class AvatarPicker extends Component {
  showForced() {
    const {
      obj: itemTypeWithParameters, tab, isPlayer, tabs, avatar,
    } = this.props;

    if (!isPlayer) return false;
    const tabWithItems = tabs.find((e) => e.id === tab);
    if (!tabWithItems || !tabWithItems.items || tabWithItems.items.length < 1) return false;
    const items = tabWithItems.items.find((e) => e.id === itemTypeWithParameters.obj.id);
    if (!items || !items.forced || items.forced.length < 1) return false;
    return !!items.forced.find((e) => e === avatar.type);
  }

  render() {
    const {
      avatarTypes,
      forced,
      obj: itemTypeWithParameters,
      tabs,
      path,
      onHide,
      update,
      forceChecked,
      avatar,
      tab,
      isPlayer,
    } = this.props;

    if (!itemTypeWithParameters) {
      return null;
    }

    const disabled = tab === 'outfit' && (forced.topinnerstyle || forced.bottomstyle);
    const items = itemTypeWithParameters.id === 'type' ? avatarTypes : itemTypeWithParameters.obj.values[avatar.type];
    const back = tabs.find((e) => e.id === tab).items.length > 1;
    const showForced = this.showForced();
    const forcedConverted = !!forced[itemTypeWithParameters.obj.id];

    if (!items || items.length < 1) {
      return null;
    }

    const noneItem = () => {
      if (itemTypeWithParameters.obj.isRequired) {
        return null;
      }
      const active = !avatar[itemTypeWithParameters.obj.id]
      || (forcedConverted && avatar[itemTypeWithParameters.obj.id] === 'removed')
      || avatar[itemTypeWithParameters.obj.id] === 'none'
        ? 'active-item-picker-item'
        : '';
      const url = `url(${path}img/avatar/icon/none.png)`;
      return (
        <div
          className={`item-picker-item-border ${active}`}
        >
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            style={{ backgroundImage: url }}
            className="item-picker-item"
            onClick={() => {
              update(itemTypeWithParameters.obj.id, 'none');
              if (forcedConverted) {
                update(itemTypeWithParameters.obj.id, 'removed');
              }
            }}
          >
            <div
              className="badge badge-light text-break text-wrap"
            >
              {forcedConverted ? '(removed)' : '(none)'}
            </div>
          </div>
        </div>
      );
    };

    return (
      <Card className="view" id="itemPicker-view">
        <Row className="itemPicker-header pt-3">
          {back && (
          <Col sm={2} className="col text-left">
            <Button
              className="ml-1"
              onClick={onHide}
              variant="primary"
              size="sm"
            >
              Back
            </Button>
          </Col>
          )}
          {showForced && (
          <Col sm={2} className="col">
            <Form.Check
              custom
              disabled={disabled}
              id="checkboxForce"
              type="checkbox"
              checked={forcedConverted}
              className="checkboxForce"
              onChange={() => {
                forceChecked(itemTypeWithParameters.obj, !forcedConverted);
              }}
              label="Force"
            />
          </Col>
          )}
          <Col className="col">
            <h3 className="my-0">
              {itemTypeWithParameters.obj.title}
            </h3>
          </Col>
          {back && <Col sm={2} className="col" />}
          {showForced && <Col sm={2} className="col" />}
        </Row>

        <div id="itemPicker-container">
          <div className={`item-picker ${(forcedConverted && isPlayer) && 'item-picker-forced'}`}>
            {noneItem()}
            {items.map((item) => {
              if (item.disabled) {
                return null;
              }
              if (itemTypeWithParameters.id === 'type') {
                return (
                  <Button
                    key={item.id}
                    variant="primary"
                    onClick={() => {
                      update(itemTypeWithParameters.id, item);
                    }}
                  >
                    {item}
                  </Button>
                );
              }
              const active = avatar[itemTypeWithParameters.obj.id] === item.id ? 'active-item-picker-item' : '';
              const url = `url(${path}img/avatar/icon/${avatar.type}/${itemTypeWithParameters.obj.id}/${item.id}.jpg)`;
              return (
                <div
                  key={item.id}
                  className={`item-picker-item-border ${active}`}
                >
                  <Button
                    block
                    variant="outline-light"
                    style={{ backgroundImage: url }}
                    className="item-picker-item"
                    disabled={disabled}
                    onClick={() => {
                      update(itemTypeWithParameters.obj.id, item.id);
                    }}
                  >
                    <div
                      className="badge badge-light text-break text-wrap"
                    >
                      {item.label}
                    </div>
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      </Card>
    );
  }
}
