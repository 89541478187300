import React, { Component } from 'react';
import FrontImg003 from '../../../assets/images/02_clearglass_flip_reducedheight.png';
import FrontImg004 from '../../../assets/images/04_flip_opensmile.png';

export class HomeRightImages extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      img001: false,
      // eslint-disable-next-line react/no-unused-state
      img002: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        img001: true,
        // eslint-disable-next-line react/no-unused-state
        img002: true,
      });
    }, 500);
  }

  render() {
    const { rightBox } = this.props;

    return (
      <div className="mainPage-img-right-box">
        <div
          className={`frontImgBox FrontImg003 ${rightBox === 2 || rightBox === 3 || rightBox === 4 || rightBox === 7 ? 'active' : ''}`}
        >
          <img src={FrontImg003} alt="" />
        </div>
        <div
          className={`frontImgBox FrontImg004 ${rightBox === 2 || rightBox === 3 || rightBox === 4 || rightBox === 7 ? 'active' : ''}`}
        >
          <img src={FrontImg004} alt="" />
        </div>
      </div>
    );
  }
}
