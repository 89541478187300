import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { MemorySlotsBase, MemorySlotsValue } from 'components/pages/Book/MemoryBank/memoryBankTypes';
import { Branch } from 'dorian-shared/types/branch/Branch';
import { Character } from 'dorian-shared/types/character/Character';
import { MemorySlotCompareForm, MemorySlotFormValue } from './MemorySlotCompareForm';
import { StepTypeCheckSwitchForm } from './StepTypeCheckSwitchForm';

export type StepCheck = {
  variableId: number,
  operator: string,
  value: MemorySlotsValue,
}
export type StepCheckSwitch = {
  value: boolean,
  gotoBranchId: number,
}

export type StepCheckOperator = {
  name: string,
  title: string,
  short: string,
}

export const SWITCH_INDEX_TYPE = {
  TRUE: 0,
  FALSE: 1,
};

export const CheckOperator: Record<string, StepCheckOperator> = {
  EQUAL: {
    name: 'equal',
    title: 'exactly',
    short: '==',
  },
  NOTEQUAL: {
    name: 'notEqual',
    title: 'not exactly',
    short: '!=',
  },
  GREATER: {
    name: 'greater',
    title: 'more than',
    short: '>',
  },
  ATLEAST: {
    name: 'atLeast',
    title: 'at least',
    short: '>=',
  },
  LESS: {
    name: 'less',
    title: 'less than',
    short: '<',
  },
  MOST: {
    name: 'atMost',
    title: 'at most',
    short: '<=',
  },
};

export function isCheckStep(branch: Branch) {
  return branch.switch && branch.switch.length > 0;
}

type StepTypeCheckProps = {
  stepIndex: number,
  memoryBankSlots: MemorySlotsBase[],
  branches: Branch[],
  currentBranchId: number,
  checkData: StepCheck,
  switchData: StepCheckSwitch[],
  addStepCheck: (
    stepIndex: number,
    variableId: number,
    operatorName: string,
    value: MemorySlotsValue,
  ) => void,
  handleChangeStepCheck: (
    values: MemorySlotFormValue[],
    stepIndex: number,
  ) => void,
  handleChangeStepSwitch: (
    gotoBranchId: number,
    stepIndex: number,
    switchIndex: number,
  ) => void,
  characters: Character[],
  isDisabled?: boolean,
}

export function StepTypeCheck(props: StepTypeCheckProps) {
  const {
    stepIndex, memoryBankSlots, handleChangeStepCheck, handleChangeStepSwitch,
    checkData, switchData, branches, currentBranchId, addStepCheck, characters,
    isDisabled,
  } = props;

  const variableInputValue = checkData && checkData.variableId ? Number(checkData.variableId) : 0;
  const operatorInputValue = checkData && checkData.operator ? checkData.operator : '';
  const checkInputValue = checkData && checkData.value ? checkData.value : '';

  const currentSlot = memoryBankSlots.find((slot) => slot.id === Number(checkData?.variableId));
  const switchTrueGotoBranchId = switchData
    && switchData[SWITCH_INDEX_TYPE.TRUE] ? switchData[SWITCH_INDEX_TYPE.TRUE].gotoBranchId : 0;
  const switchFalseGotoBranchId = switchData
    && switchData[SWITCH_INDEX_TYPE.FALSE] ? switchData[SWITCH_INDEX_TYPE.FALSE].gotoBranchId : 0;

  const isCheckStepPossible = memoryBankSlots && memoryBankSlots.length > 0;
  useEffect(() => {
    if (!isCheckStepPossible) {
      return;
    }

    // prevent re-adding check object if already exists
    if (checkData && checkData.variableId && checkData.operator) {
      return;
    }

    const variable = memoryBankSlots[0];
    const operatorName = CheckOperator.EQUAL.name;
    const value = '';
    addStepCheck(stepIndex, variable.id, operatorName, value);
  }, [checkData, addStepCheck, isCheckStepPossible, memoryBankSlots, stepIndex]);

  return (
    <Col>
      <MemorySlotCompareForm
        variableInputValue={variableInputValue}
        operatorInputValue={operatorInputValue}
        checkInputValue={checkInputValue}
        currentSlot={currentSlot}
        inputNamePrefix={`steps[${stepIndex}][check]`}
        memoryBankSlots={memoryBankSlots}
        stepIndex={stepIndex}
        characters={characters}
        onChangeValue={handleChangeStepCheck}
        isDisabled={isDisabled}
      />
      <Row>
        <StepTypeCheckSwitchForm
          stepIndex={stepIndex}
          switchIndex={SWITCH_INDEX_TYPE.TRUE}
          gotoBranchId={switchTrueGotoBranchId}
          currentBranchId={currentBranchId}
          handleChangeStepSwitch={handleChangeStepSwitch}
          branches={branches}
          isDisabled={isDisabled}
        />
        <StepTypeCheckSwitchForm
          stepIndex={stepIndex}
          switchIndex={SWITCH_INDEX_TYPE.FALSE}
          gotoBranchId={switchFalseGotoBranchId}
          currentBranchId={currentBranchId}
          handleChangeStepSwitch={handleChangeStepSwitch}
          branches={branches}
          isDisabled={isDisabled}
        />
      </Row>
    </Col>
  );
}
