import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Button, Form, FormControl, InputGroup, Spinner,
} from 'react-bootstrap';
import Delete from '../../../assets/images/delete_t.svg';
import Edit from '../../../assets/images/edit-branch.svg';
import Cancel from '../../../assets/images/no.svg';
import IconBubble from '../../../assets/images/ui/textbox/icon-bubble.svg';
import Wrench from '../../../assets/images/wrench.svg';
import Save from '../../../assets/images/yes.svg';
import { api } from '../../api';
import { PremiumIpDisabledApprovedEdit } from '../../utils/premiumIpDisabledApprovedEdit';
import { PremiumIpDisabledEdit } from '../../utils/premiumIpDisabledEdit';
// eslint-disable-next-line import/no-cycle
import { StoryPreviewContext } from './context';
import './Preview.scss';
// eslint-disable-next-line import/no-cycle
import { TextPreview } from './textPreview';

export class StepTextPreview extends Component {
  constructor(props) {
    super(props);
    const {
      arr, type, auth, step,
    } = this.props;

    this.state = {
      disabled: true,
      loading: false,
      text: type === 'answ' ? arr.text : step.text,
      defaultText: type === 'answ' ? arr.text : step.text,
      edit: false,
    };

    this.user = auth.getUser();
  }

  componentDidMount() {
    window.addEventListener('storage', this.updateStorage);
  }

  updateStorage = (e) => {
    const { story } = this.props;

    if (e.key === `modeEdit-${story.internal_uuid}`) {
      this.setState({
        disabled: JSON.parse(localStorage.getItem(`modeEdit-${story.internal_uuid}`)),
      });
    }
  };

  componentDidUpdate(prevProps) {
    const {
      arr, type, step, story,
    } = this.props;
    const { text, disabled } = this.state;

    const editMode = JSON.parse(localStorage.getItem(`modeEdit-${story.internal_uuid}`));
    if (editMode !== disabled) {
      this.setState({
        disabled: editMode,
      });
    }

    if (step && step.id && step.id !== prevProps.step.id) {
      if (type === 'answ' && arr.text !== text) {
        this.setState({
          text: arr.text,
          defaultText: arr.text,
        });
      }

      if (type !== 'answ' && step.text !== text) {
        this.setState({
          text: step.text,
          defaultText: step.text,
        });
      }
    }

    if (step.text !== prevProps.step.text) {
      this.setState({
        text: type === 'answ' ? arr.text : step.text,
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const value = serialize(form, { hash: true, disabled: false });
    if (validated === false) {
      event.stopPropagation();
    } else {
      this.updateData(value, validated);
    }
    event.stopPropagation();
  }

  updateData(obj, validated) {
    const {
      arr, action, branchId, step, story, editMode, type,
    } = this.props;

    if (validated === true) {
      this.setState({
        loading: true,
      });

      const data = {
        text: obj.text,
      };

      const url = type === 'answ'
        ? `/v1/stories/${story.id}/branches/${branchId}/answers/${arr.id}`
        : `/v1/stories/${story.id}/branches/${branchId}/steps/${step.id}`;
      api.put(url, data)
        .then((res) => {
          this.setState({
            defaultText: type === 'answ' ? res.data.answer.text : res.data.step.text,
            text: type === 'answ' ? res.data.answer.text : res.data.step.text,
            loading: false,
            edit: false,
          });
          editMode(false);
          if (action) {
            action();
          }
        })
        .catch(() => {
          const { defaultText } = this.state;

          this.setState({
            loading: false,
            text: defaultText,
            edit: false,
          });
          editMode(false);
        });
    }
  }

  editBtn = () => {
    const { story, hideUi } = this.props;
    const { edit, disabled } = this.state;

    if (hideUi) {
      return false;
    }
    if (edit) {
      return false;
    }
    if (!this.user) {
      return false;
    }
    if (story && !['active', 'completed', 'archived', 'prompt'].includes(story.group)) {
      return false;
    }
    if (this.user.role !== 'admin' && (story.story_role === 'none' || story.story_role === 'viewer')) {
      return false;
    }
    return !((this.user.role === 'admin' && story.story_role !== 'owner') && disabled);
  };

  render() {
    const {
      action,
      playername,
      step,
      story,
      nameBoxClass,
      name,
      user_props: userProps,
      editMode,
      type,
    } = this.props;
    const {
      text, defaultText, edit, loading, disabled,
    } = this.state;
    const {
      showDeleteAnswer,
      limits,
      setShowDeleteAnswer,
      showEditChoice,
      setShowEditChoiceProps,
      setShowEditChoice,
    } = this.context;

    let typeClass = '';
    let formattedText = text;

    if (type !== 'answ') {
      switch (step.stepTypeId) {
        case 1:
          typeClass = 'dialogue_text';
          break;
        case 2:
          typeClass = 'text';
          break;
        case 3:
          typeClass = 'choice_text';
          break;
        case 5:
          typeClass = 'text';
          break;
        case 6:
          typeClass = 'result';
          formattedText = step.subType;
          break;
        case 7:
          typeClass = 'result';
          formattedText = 'Player Select';
          break;
        case 8:
          typeClass = 'texting_text';
          break;
        case 9:
          typeClass = 'thinking_text';
          break;
        default:
          break;
      }
    }

    const limitName = `step_${step.stepTypeId}_text_max`;

    const limit = limits[limitName] ? limits[limitName].value : 0;

    if (formattedText) {
      formattedText = formattedText.replace(/<playername>/gi, `<b style="color:blue;">${playername}</b>`);
      formattedText = formattedText.replace(/<playernamefemale>/gi, `<b style="color:blue;">${playername}</b>`);
    }

    return (
      <div className={
        `preview-stepTextBox ${
          !typeClass && type === 'answ' && step.stepTypeId !== 3
            ? 'd-none'
            : typeClass
        } ${
          edit
            ? 'activeEdit'
            : ''
        } ${
          userProps
            ? 'isPlayer'
            : ''
        } ${
          this.editBtn()
            ? 'editBtnLeftPos'
            : ''
        }`
      }
      >
        <div className="choice-info-top">
          <img className="choice-text-bubbles" src={IconBubble} alt="" />
        </div>
        <div className={nameBoxClass}><b>{name}</b></div>
        <TextPreview
          {...this.props}
          disabled={disabled}
          text={formattedText}
        />
        {(this.editBtn() && typeClass !== 'result') && (
          <>
            {(type === 'answ') && (
              <>
                <Button
                  size="sm"
                  className="EditBtn EditBtn-left"
                  variant="outline-secondary"
                  disabled={
                    disabled
                    || PremiumIpDisabledEdit(this.user.role, story.book, story?.group)
                    || PremiumIpDisabledApprovedEdit(this.user.role, story.book)
                  }
                  onClick={() => {
                    setShowEditChoiceProps(this.props);
                    setShowEditChoice(!showEditChoice);
                  }}
                  onMouseOut={() => {
                    if (!edit) {
                      editMode(false);
                    }
                  }}
                  onMouseOver={() => {
                    editMode(true);
                  }}
                >
                  <img src={Wrench} alt="Wrench" />
                </Button>

                <Button
                  size="sm"
                  className="EditBtn EditBtn-left-bottom"
                  variant="outline-secondary"
                  disabled={
                    disabled
                    || PremiumIpDisabledEdit(this.user.role, story.book, story?.group)
                    || PremiumIpDisabledApprovedEdit(this.user.role, story.book)
                  }
                  onClick={() => {
                    setShowDeleteAnswer(!showDeleteAnswer, this.props);
                  }}
                  onMouseOut={() => {
                    if (!edit) {
                      editMode(false);
                    }
                  }}
                  onMouseOver={() => {
                    editMode(true);
                  }}
                >
                  <img src={Delete} alt="Wrench" />
                </Button>
              </>
            )}

            <Button
              size="sm"
              className="EditBtn"
              variant="outline-secondary"
              disabled={
                disabled
                || PremiumIpDisabledEdit(this.user.role, story.book, story?.group)
                || PremiumIpDisabledApprovedEdit(this.user.role, story.book)
              }
              onClick={() => {
                this.setState({
                  edit: true,
                }, () => {
                  editMode(true);
                  if (action) {
                    action();
                  }
                });
              }}
              onMouseOut={() => {
                if (!edit) {
                  editMode(false);
                }
              }}
              onMouseOver={() => {
                editMode(true);
              }}
            >
              <img src={Edit} alt="Edit" />
            </Button>
          </>
        )}

        {loading && (
          <div className="EditLoading">
            <i><Spinner variant="primary" animation="border" size="sm" /></i>
          </div>
        )}

        {edit && (
        <div className="EditBox">
          <form
            onSubmit={(e) => { this.handleSubmit(e); }}
          >
            <InputGroup size="sm">
              <span className="inpBox inpBoxEdit">
                <FormControl
                  value={text || ''}
                  name="text"
                  as="textarea"
                  autoFocus
                  onChange={(e) => {
                    this.setState({ text: e.target.value });
                  }}
                  className={text && text.length > Number(limit) ? 'text-limit' : null}
                />
                <Form.Text className="char-limit-info-box">
                  {Number(limit) - (text ? text.length : 0)}
                </Form.Text>
              </span>

              <InputGroup.Append>
                <Button
                  disabled={(text && text.length > Number(limit))}
                  variant="outline-secondary"
                  type="submit"
                >
                  <img src={Save} alt="Save" />
                </Button>
                <Button
                  onClick={() => {
                    this.setState({
                      text: defaultText,
                      edit: false,
                    });
                    editMode(false);
                    if (action) {
                      action();
                    }
                  }}
                  variant="outline-secondary"
                >
                  <img src={Cancel} alt="Cancel" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </form>
        </div>
        )}
      </div>
    );
  }
}

StepTextPreview.contextType = StoryPreviewContext;
