import { omit } from 'lodash-es';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory from 'react-bootstrap-table2-filter';
import { useAbortController } from '../../../../dorian-shared/hooks/useAbortController';
import { useAsyncOperationState } from '../../../../dorian-shared/hooks/useAsyncOperationState';
import { ExportAsyncCSV } from '../../../ui/ExportAsyncCSV/ExportAsyncCSV';
import { Pagination } from '../../../ui/Pagination/Pagination';
import { showToast } from '../../../ui/utils';
import '../style.scss';
import { usePerformanceTableChange } from '../usePerformanceTableChange';

function usePrepareCsvData(requestParams, getData, tabTitle, csvHeaders) {
  const [, {
    isLoading,
    setToLoading,
    setToError,
    setToSuccess,
  }] = useAsyncOperationState();

  const { getAbortSignal, newAbortSignal } = useAbortController();

  const prepareCSVData = async () => {
    if (isLoading) {
      newAbortSignal();
    }
    try {
      setToLoading();
      const params = omit(
        requestParams,
        ['offset', 'limit'],
      );
      const response = await getData(
        params,
        getAbortSignal(),
      );
      setToSuccess();
      return {
        data: response.data,
        fileName: `${tabTitle}-${moment()}.csv`,
        headers: csvHeaders,
      };
    } catch (exception) {
      if (!getAbortSignal().aborted) {
        setToError();
        showToast({ textMessage: 'An errors occurs while loading data.' });
      }
    }
    return null;
  };
  return { isLoading, prepareCSVData };
}

export function PerformanceTabContent(props) {
  const {
    tabTitle,
    csvHeaders,
    columns,
    getData,
    pageSize,
    handlePageChangeCallback,
  } = props;

  const [, {
    isError,
    isLoading,
    setToLoading,
    setToError,
    setToSuccess,
  }] = useAsyncOperationState();

  const { getAbortSignal, newAbortSignal } = useAbortController();

  const {
    currentPage,
    totalCount,
    setTotalCount,
    requestParams,
    handlePageChange,
    handleTableChange,
    tableData,
    setTableData,
  } = usePerformanceTableChange(pageSize);

  useEffect(() => {
    handlePageChangeCallback?.(handlePageChange);
  }, [handlePageChange, handlePageChangeCallback]);

  useEffect(() => {
    newAbortSignal();
    setToLoading();
    getData(requestParams, getAbortSignal()).then(
      (response) => {
        const newData = response.data.map((dataItem) => ({ ...dataItem, key: Math.random() }));
        setTotalCount(response.count);
        setTableData(newData);
        setToSuccess();
      },
    ).catch(() => {
      if (!getAbortSignal().aborted) {
        setToError();
      }
    });
  }, [
    getAbortSignal,
    getData,
    newAbortSignal,
    requestParams,
    setTableData,
    setToError,
    setToLoading,
    setToSuccess,
    setTotalCount,
  ]);

  const { isLoading: isLoadingCsvData, prepareCSVData } = usePrepareCsvData(
    requestParams,
    getData,
    tabTitle,
    csvHeaders,
  );

  return (
    <>
      {isLoading && (
        <Spinner
          variant="primary"
          animation="border"
          className="loadingSpinner justify-content-center"
        />
      )}
      <div className="text-right">
        <ExportAsyncCSV
          asyncExportMethod={prepareCSVData}
          disabled={isLoadingCsvData}
        >
          {isLoadingCsvData && (
            <Spinner
              title="Loading data for export to CSV"
              className="mr-2"
              animation="border"
              size="sm"
            />
          )}
          {isLoadingCsvData ? 'Downloading...' : 'Download CSV'}
        </ExportAsyncCSV>
      </div>
      {isError && (
        <div className="text-right">An error occurred while loading data</div>
      )}
      {!isError && (
        <>
          <Pagination
            onPageChange={handlePageChange}
            totalCount={totalCount}
            currentPage={currentPage}
            pageSize={pageSize}
          />
          <BootstrapTable
            remote={{ pagination: false, sort: true, filter: true }}
            onTableChange={handleTableChange}
            bootstrap4
            keyField="key"
            data={tableData}
            columns={columns}
            filter={filterFactory()}
            filterPosition="top"
          />
        </>
      )}
    </>
  );
}
