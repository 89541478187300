import classNames from 'classnames/bind';
import React, { PureComponent } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as ShareIcon } from '../../../../assets/images/cog_2.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/images/copy.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete_t.svg';
import { DeleteBranch } from '../DeleteBranch';
import { FaqPanel } from './FaqPanel';
import styles from './HelpSection.css';

const cs = classNames.bind(styles);

export class HelpSection extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      modalDeleteBranchShow: false,
    };
  }

  duplicateBranch = () => {
    const { selectionItems, duplicate } = this.props;

    duplicate(selectionItems);
  };

  render() {
    const {
      update,
      branch,
      storyId,
      user,
      disabled,
      HelpPanel,
      closeHelpPanel,
      selectionItems,
      sharedSettingsUpdate,
      onDeleteSuccess,
    } = this.props;
    const { modalDeleteBranchShow } = this.state;

    return (
      <>
        <div className="navBoxHelpSection">
          {
            (selectionItems.length > 0 && !disabled)
            && (
            <>
              <OverlayTrigger
                placement="left"
                trigger={['hover', 'hover']}
                overlay={<Tooltip id="TooltipDeleteSelected">Shared Node Settings</Tooltip>}
              >
                <Button
                  size="sm"
                  className={cs('btn-fab')}
                  onClick={sharedSettingsUpdate}
                >
                  <ShareIcon />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                placement="left"
                trigger={['hover', 'hover']}
                overlay={<Tooltip id="TooltipDeleteSelected">Delete selected</Tooltip>}
              >
                <Button
                  size="sm"
                  className={cs('btn-fab')}
                  onClick={() => {
                    this.setState({
                      modalDeleteBranchShow: true,
                    });
                  }}
                >
                  <DeleteIcon />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                placement="left"
                trigger={['hover', 'hover']}
                overlay={<Tooltip id="TooltipDuplicateSelected">Duplicate selected</Tooltip>}
              >
                <Button
                  size="sm"
                  className={cs('btn-fab')}
                  onClick={this.duplicateBranch}
                >
                  <CopyIcon />
                </Button>
              </OverlayTrigger>
            </>
            )
          }

        </div>
        <FaqPanel
          user={user}
          show={HelpPanel}
          pageTitle="faq"
          onHide={closeHelpPanel}
        />

        {modalDeleteBranchShow
            && (
            <DeleteBranch
              storyId={storyId}
              show={modalDeleteBranchShow}
              branch={branch}
              update={update}
              onHide={() => {
                this.setState({
                  modalDeleteBranchShow: false,
                });
              }}
              obj={selectionItems}
              onDeleteSuccess={onDeleteSuccess}
            />
            )}
      </>
    );
  }
}
