import React, { Component } from 'react';
import {
  Alert, Button, Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../../api';
import { PreviewImg } from '../../Characters/PreviewImg';
import { PreviewScenesForm } from './PreviewScenesForm';
import './PreviewUtilities.scss';

export class PreviewEditScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: null,
      loading: false,
      error: null,
      addScene: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { show, obj } = this.props;

    if (prevProps.show !== show && show) {
      this.setState({
        checked: obj.current_location,
      });
    }
  }

  errorAlert = (error) => {
    this.setState({
      error,
    });
    setTimeout(() => {
      this.setState({
        error: null,
      });
    }, 1500);
  };

  saveData = () => {
    const { checked } = this.state;
    const { obj, onHide, updateLocation } = this.props;

    this.setState({
      loading: true,
    });
    const value = { locationId: checked.id };
    api.patch(`/v1/stories/${obj.story.id}/branches/${obj.current_branch.id}`, value)
      .then(() => {
        this.setState({
          loading: false,
        }, () => {
          updateLocation(checked);
          onHide();
        });
      })
      .catch((error) => {
        this.errorAlert(error.response.data.error);
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const { show, obj, onHide } = this.props;
    const {
      addScene, loading, error, checked,
    } = this.state;

    if (!show) {
      return null;
    }

    return (
      <div className="PreviewEditScene-box">
        <div className="PreviewEditScene-wrap">

          <div className="PreviewEditScene-header">
            <h2>Edit Location</h2>
          </div>
          {!addScene ? (
            <Container>
              <div className="PreviewEditScene-content">
                {loading && (
                  <div className="text-center loadingSpinner">
                    <Spinner
                      variant="primary"
                      animation="border"
                      className="justify-content-center"
                    />
                  </div>
                )}
                <Row className={error === null ? 'd-none' : 'd-block pt-2'}>
                  <Col md={12}>
                    <Alert variant="danger">
                      {error}
                    </Alert>
                  </Col>
                </Row>
                {obj.locations.map((location, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Row key={i} className="py-2 border-bottom d-flex align-items-center">
                    <Col xs={2} className="text-center">
                      <Form.Check
                        type="radio"
                        id={`checkbox_${i}`}
                        checked={(checked && checked.id === location.id) || false}
                        onChange={() => this.setState({ checked: location })}
                      />
                    </Col>
                    <Col xs={2} className="text-center">
                      <PreviewImg
                        className="PreviewImg-36"
                        style={{ maxWidth: '36px', maxHeight: '36px', overflow: 'hidden' }}
                        character={location}
                      />
                    </Col>
                    <Col xs={8} className="text-left">
                      <label htmlFor={`checkbox_${i}`}>{location.title}</label>
                    </Col>
                  </Row>
                ))}
                <Row className="py-2 d-flex align-items-center">
                  <Col
                    md={12}
                    size="sm"
                    className="text-left"
                    onClick={() => this.setState({ addScene: true })}
                  >
                    CREATE NEW LOCATION
                  </Col>
                </Row>
              </div>

              <div className="PreviewEditScene-footer">
                <Button
                  size="sm"
                  type="reset"
                  variant="secondary"
                  onClick={onHide}
                >
                  Cancel
                </Button>

                <Button
                  size="sm"
                  type="reset"
                  variant="primary"
                  onClick={() => this.saveData()}
                  className="mx-1"
                  disabled={(!!loading || !checked)}
                >
                  {loading && (
                    <Spinner
                      size="sm"
                      animation="border"
                    />
                  )}
                  Save
                </Button>
              </div>
            </Container>
          ) : (
            <PreviewScenesForm
              onClose={() => this.setState({ addScene: false })}
              {...this.props}
            />
          )}
        </div>
      </div>
    );
  }
}
