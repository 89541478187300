import classNames from 'classnames/bind';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Image, Modal, Nav, Row, Spinner, Tab,
} from 'react-bootstrap';
import imgNoImage from '../../../assets/images/no_image.svg';
import { api } from '../../api';
import styles from './BookCover.css';
// import {SketchPicker} from 'react-color';
import { BookCoverUploader } from './BookCoverUploader';
// import {colors} from "../StoryBranches/ChangeColor";

classNames.bind(styles);

export class BookCover extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      validatedImage: false,
      // eslint-disable-next-line react/no-unused-state
      formError: null,
      file: null,
      fileUrl: null,
      // eslint-disable-next-line react/no-unused-state
      uploadForm: false,
      uploadFormLoading: false,
      uploadDone: false,
      uploader: false,
      uploaderComplete: false,
      portrait: true,
      color: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
    };
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.btnSaveForm = React.createRef();
    this.btnSaveImageForm = React.createRef();
    this.uploaderRef = React.createRef();
  }

  componentDidMount() {
    const { book } = this.props;

    if (
      book
      && book.imageAlt
      && book.imageAlt.color
    ) {
      const color = JSON.parse(book.imageAlt.color);
      if (color && color.r && color.g && color.b) {
        this.setState({
          color,
        });
      }
    }
  }

  handleImageSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData();
    const {
      color,
      portrait,
      file,
    } = this.state;

    const {
      update,
      book,
    } = this.props;

    formData.append('image', file, file.name);

    formData.append('book_id', book.id);
    if (portrait) {
      formData.append('img_type', 'alt');
      formData.append('color', JSON.stringify(color));
    }
    this.setState({
      uploadFormLoading: true,
    });
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      this.setState({
        loading: true,
      });
      api.post(`/v1/books/${book.id}/images/cover`, formData, config)
        .then((res) => {
          if (portrait) {
            update({ imageAltUrl: res.data.url });
          } else {
            update({ imageUrl: res.data.url });
          }
          this.setState({
            uploadFormLoading: false,
            // eslint-disable-next-line react/no-unused-state
            uploadForm: false,
            uploadDone: 'Upload Successful',
            loading: false,
            file: null,
            fileUrl: null,
          }, () => {
            setTimeout(() => {
              this.setState({
                uploadDone: false,
              });
            }, 1500);
          });
        })
        .catch(() => {
          this.setState({
            uploadFormLoading: false,
            // eslint-disable-next-line react/no-unused-state
            uploadForm: false,
            loading: false,
            file: null,
            fileUrl: null,
          });
        });
    }
    this.setState({
      validatedImage: true,
    });
    event.stopPropagation();
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleImageDelete(event) {
    event.preventDefault();
    this.setState({
      uploadFormLoading: true,
      loading: true,
    });
    const data = {};
    const { portrait } = this.state;
    const {
      update,
      book,
    } = this.props;

    if (portrait) {
      data.img_type = 'alt';
    }
    api.delete(`/v1/books/${book.id}/images/cover`, { data })
      .then(() => {
        update(true);
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          uploadForm: false,
          uploadFormLoading: false,
          uploadDone: 'Delete Successful',
          fileUrl: null,
          loading: false,
        }, () => {
          setTimeout(() => {
            this.setState({
              uploadDone: false,
            });
          }, 1500);
        });
      })
      .catch(() => {
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          uploadForm: false,
          uploadFormLoading: false,
          loading: false,
        });
      });
    event.stopPropagation();
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  onChangeFile(e) {
    const file = e.target.files[0];
    this.setState({
      file,
      fileUrl: file && file.type.startsWith('image') ? URL.createObjectURL(file) : null,
    });
  }

  onUploadDone = (image) => {
    this.setState({
      uploader: false,
      fileUrl: URL.createObjectURL(image),
      file: image,
    });
  };

  btnShowUploader = () => {
    this.setState({
      uploader: true,
      uploaderComplete: false,
    });
  };

  renderImgVariants = () => {
    const { book } = this.props;

    const {
      portrait,
      file,
    } = this.state;

    if (!book || file) {
      return null;
    }

    if (!portrait && (!book.image || !book.image.images)) {
      return null;
    }

    if (portrait && (!book.imageAlt || !book.imageAlt.images)) {
      return null;
    }

    const obj = portrait ? book.imageAlt.images : book.image.images;
    const res = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const prop in obj) {
      res.push({ key: prop, url: obj[prop] });
    }

    return res.map((el) => (
      <span key={el.key} style={{ marginRight: '1em' }}>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={el.url}
        >
          {el.key}
        </a>
      </span>
    ));
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleChange = (color) => {
    this.setState({ color: color.rgb });
  };

  render() {
    const { validatedImage } = this.state;
    const { book } = this.props;
    // eslint-disable-next-line max-len,no-nested-ternary,react/destructuring-assignment
    const imgTypeUrl = this.state.portrait ? book.imageAlt && book.imageAlt.imageUrl ? book.imageAlt.imageUrl : null : book.image && book.image.imageUrl ? book.image.imageUrl : null;
    // eslint-disable-next-line react/destructuring-assignment
    const imgUrl = this.state.fileUrl ? this.state.fileUrl : imgTypeUrl;
    const { update, ...props } = this.props;
    /*
    let color = (
      this.props.book
      && this.props.book.imageAlt
      && this.props.book.imageAlt.color
    ) ? this.props.book.imageAlt.color : '#000000'
     */

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onMouseDown={(e) => e.stopPropagation()}
        centered
        backdrop="static"
        keyboard={false}
      >

        <Form
          noValidate
          validated={validatedImage}
          onSubmit={(e) => this.handleImageSubmit(e)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Cover Art -
              {/* eslint-disable-next-line react/destructuring-assignment */}
              {this.props.book.title}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body
            onMouseDown={(e) => e.stopPropagation()}
          >

            {/* eslint-disable-next-line react/destructuring-assignment */}
            <Alert show={!!this.state.uploadDone} variant="success" className="my-1">
              {/* eslint-disable-next-line react/destructuring-assignment */}
              {this.state.uploadDone || null}
            </Alert>

            {
              // eslint-disable-next-line react/destructuring-assignment
                this.state.uploader
                && (
                <BookCoverUploader
                  onDone={this.onUploadDone}
                  ref={this.uploaderRef}
                  /* eslint-disable-next-line react/destructuring-assignment */
                  portrait={this.state.portrait}
                  onComplete={() => this.setState({ uploaderComplete: true })}
                  /* eslint-disable-next-line react/destructuring-assignment */
                  book={this.props.book}
                />
                )
              }

            {/* eslint-disable-next-line react/destructuring-assignment */}
            {!this.state.uploader
                && (
                <>
                  <Row style={{ display: 'none' }}>
                    <Col>
                      <Form.Check
                        custom
                        id="portraitId"
                        name="portrait"
                        type="checkbox"
                        /* eslint-disable-next-line react/destructuring-assignment */
                        checked={this.state.portrait}
                        onChange={() => {
                          // eslint-disable-next-line react/destructuring-assignment
                          this.setState((prevState) => ({ portrait: !prevState.portrait }));
                        }}
                        label="Portrait"
                      />
                    </Col>
                  </Row>

                  {/* eslint-disable-next-line react/destructuring-assignment */}
                  <Tab.Container defaultActiveKey={this.state.portrait}>
                    <Nav className="nav nav-tabs">
                      {/*
                    <Nav.Item>
                      <Nav.Link
                        disabled={true}
                        eventKey={false}
                        onClick={() => {
                          this.setState({portrait: false})
                        }}
                      >Landscape</Nav.Link>
                    </Nav.Item>
                    */}
                      <Nav.Item>
                        <Nav.Link
                          eventKey
                          onClick={() => {
                            this.setState({ portrait: true });
                          }}
                        >
                          Portrait
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Tab.Container>

                  <Row>
                    {/*
                    (this.state.portrait) &&
                    <Col md={4}>
                      <Form.Label className={'mt-3'}>
                        <small>Background color selection:</small>
                      </Form.Label>

                      <SketchPicker
                        width={"100%"}
                        colors={colors}
                        color={this.state.color}
                        onChange={this.handleChange}
                      />
                    </Col>
                  */}

                    <Col className="text-center">
                      <Image
                        onClick={() => {
                          this.setState({
                            // eslint-disable-next-line react/no-unused-state
                            uploadForm: true,
                          });
                        }}
                        className="imgCoverBook"
                        src={imgUrl || imgNoImage}
                        fluid
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {this.renderImgVariants()}
                    </Col>
                  </Row>
                </>
                )}

            <Spinner
              variant="primary"
              animation="border"
              /* eslint-disable-next-line react/destructuring-assignment */
              className={this.state.uploadFormLoading !== false ? 'loadingSpinner justify-content-center' : 'd-none '}
            />
          </Modal.Body>
          <Modal.Footer>
            {
              // eslint-disable-next-line react/destructuring-assignment
                this.state.uploader
                  ? (
                    <>
                      <Button
                        size="sm"
                        type="reset"
                        variant="secondary"
                        className="mx-2"
                        onClick={() => this.setState({ uploader: false })}
                      >
                        Cancel
                      </Button>

                      <Button
                        size="sm"
                        type="reset"
                        variant="secondary"
                        className="mx-2"
                        disabled={
                        // eslint-disable-next-line react/destructuring-assignment
                        !this.state.uploaderComplete
                        // eslint-disable-next-line react/destructuring-assignment
                        || this.props.disabled
                      }
                        onClick={() => this.uploaderRef.current.doCropImg()}
                      >
                        Done
                      </Button>
                    </>
                  )
                  : (
                    <>
                      <Button
                        variant="primary"
                        disabled={
                          // eslint-disable-next-line react/destructuring-assignment
                        this.state.loading
                        // eslint-disable-next-line react/destructuring-assignment
                        || this.props.disabled
                      }
                        size="sm"
                        onClick={this.btnShowUploader}
                      >
                        New Cover
                      </Button>
                      {/*
                  <Button
                    disabled={(!book.image || this.state.loading || this.props.disabled)}
                    size="sm"
                    type="reset"
                    variant="secondary"
                    className={'mx-2'}
                    onClick={(e) => this.handleImageDelete(e)}
                  >
                    Delete
                  </Button>
                  */}
                      <Button
                        variant="primary"
                        disabled={
                          // eslint-disable-next-line react/destructuring-assignment
                        !this.state.file
                        // eslint-disable-next-line react/destructuring-assignment
                        || !this.state.fileUrl
                        // eslint-disable-next-line react/destructuring-assignment
                        || this.state.loading
                        // eslint-disable-next-line react/destructuring-assignment
                        || this.props.disabled
                      }
                        size="sm"
                        type="submit"
                        ref={this.btnSaveImageForm}
                      >
                        Save
                      </Button>
                    </>
                  )
              }
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
