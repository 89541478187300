import { getAssetsImageURL } from './globalStaticData';

export const convertAssetsImageToURL = (path) => {
  const assetImageURL = getAssetsImageURL();
  if (typeof path === 'string') return path.replace('assets/img/', assetImageURL);
  return null;
};

export const convertURLToAssetsImage = (path) => {
  const assetImageURL = getAssetsImageURL();
  if (typeof path === 'string') return path.replace(assetImageURL, 'assets/img/');
  return null;
};
