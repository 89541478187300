import React from 'react';
import { isCheckStep } from '../BranchEdit/Steps/StepTypeCheck';
import { BranchNodeCardCheckInfo } from './BranchNodeCardCheckInfo';
import { TraitImages } from './TraitImages';

export function BranchNodeCard({
  storyGroup,
  branchNode,
  memorySlotData,
}) {
  const isLocationImg = branchNode.location?.image
    && (storyGroup === 'active' || storyGroup === 'prompt');

  const decisionPoint = branchNode.links?.length > 0;
  const isCheck = isCheckStep(branchNode);
  return (
    <>
      {isLocationImg
        && (
          <div
            className="locationImg"
            style={{
              backgroundImage: `url(${branchNode.location.image.imageUrl})`,
            }}
          />
        )}
      <p className="text-left m-0 py-2 description descriptionLocationImg">
        {branchNode.description}
      </p>
      <div className="text-left pt-2">
        {branchNode.type !== 'choice'
          && (
            <p className="m-0">
              Steps:
              <span className="ml-1">
                {branchNode.steps_count || 0}
              </span>
            </p>
          )}
        <p className="m-0 py-1">
          Words:
          <span className="ml-1">
            {branchNode.words_count || 0}
          </span>
        </p>
        {!isLocationImg
          && (
            <p className="m-0">
              Location:
              <span className="ml-1">
                {branchNode.location_title || '-'}
              </span>
            </p>
          )}
      </div>
      {decisionPoint && (
      <TraitImages
        answers={branchNode.answers}
      />
      )}
      {isCheck && (
        <BranchNodeCardCheckInfo
          memorySlotData={memorySlotData}
        />
      )}
    </>
  );
}
