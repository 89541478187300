import _ from 'lodash-es';
import React, {
  ReactNode, createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { api } from 'components/api';

type ClientVariablesData = Record<string, string | number | object>;
type ClientVariablesDataResponse = {
  constants: ClientVariablesData,
  experiments: ClientVariablesData,
}
interface IClientVariables {
  constants: ClientVariablesData,
  setConstants: (constants: ClientVariablesData) => void,
  experiments: ClientVariablesData,
  setExperiments: (experiments: ClientVariablesData) => void,
}

export const ClientVariablesContext = createContext<IClientVariables>({
  constants: {},
  setConstants(constants) {
    this.constants = constants;
  },
  experiments: {},
  setExperiments(experiments) {
    this.experiments = experiments;
  },
});

type ClientVariablesContextProviderProps = {
  children: ReactNode
}

export function ClientVariablesContextProvider(props: ClientVariablesContextProviderProps) {
  const { children } = props;
  const [constants, setConstants] = useState<ClientVariablesData>({});
  const [experiments, setExperiments] = useState<ClientVariablesData>({});

  const contextValue = useMemo(() => ({
    constants, setConstants, experiments, setExperiments,
  }), [constants, experiments]);

  return (
    <ClientVariablesContext.Provider value={contextValue}>
      {children}
    </ClientVariablesContext.Provider>
  );
}

export async function fetchPublicClientVariables() {
  const response = await api.get<ClientVariablesDataResponse>('/v1/getClientConfig');
  if (response && response.data) {
    return response.data;
  }
  return null;
}

function getSelectedVariables(
  clientVariables?: IClientVariables,
  constantName?: string,
  experimentName?: string,
) {
  if (!clientVariables) {
    return [null, null];
  }

  let selectedConstant = null;
  let selectedExperiment = null;

  if (constantName && clientVariables.constants) {
    selectedConstant = clientVariables.constants[constantName];
  }

  if (experimentName && clientVariables.experiments) {
    selectedExperiment = clientVariables.experiments[experimentName];
  }

  return [selectedConstant, selectedExperiment];
}

export function useClientVariables(constantName?: string, experimentName?: string) {
  const clientVariables = useContext(ClientVariablesContext);

  useEffect(() => {
    if (clientVariables
      && (!_.isEmpty(clientVariables.constants) || !_.isEmpty(clientVariables.experiments))) {
      return;
    }

    fetchPublicClientVariables()
      .then((loadedClientVariables) => {
        if (!loadedClientVariables) {
          return;
        }
        const { constants, experiments } = loadedClientVariables;
        clientVariables.setConstants(constants);
        clientVariables.setExperiments(experiments);
      });
  }, [clientVariables]);

  const [selectedConstant, selectedExperiment] = getSelectedVariables(
    clientVariables,
    constantName,
    experimentName,
  );

  return [selectedConstant, selectedExperiment, clientVariables];
}
