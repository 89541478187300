import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BranchesWidgetContext } from './context';

export function BranchesWidgetItem(props) {
  const { obj } = props;
  const BranchesWidgetFunc = React.useContext(BranchesWidgetContext);
  const { value } = React.useContext(BranchesWidgetContext);

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={`branchesWidgetItem ${value.id === obj.id ? 'isSelected' : ''}`}
      onClick={() => BranchesWidgetFunc.setValue(obj)}
    >
      <Row>
        {obj?.location?.image?.imageUrl && (
          <Col xs={3}>
            <figure className="locationImage">
              <img alt={obj?.location?.title || ''} src={obj.location.image.imageUrl} style={{ width: '100%' }} />
            </figure>
          </Col>
        )}
        <Col xs={obj?.location?.image?.imageUrl ? 9 : 12}>
          <div className="content">
            <h2>
              {obj.title}
              {' '}
              {obj.type !== 'story' ? `- ${obj.type}` : ''}
            </h2>
            <p>{obj.description}</p>
            {obj?.location?.title && (
              <p>
                <span className="label">location:</span>
                {' '}
                {obj?.location?.title}
              </p>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
