import moment from 'moment';
import { TableChangeState } from 'react-bootstrap-table-next';

export interface FetchParams {
  offset?: number,
  limit?: number,
  search?: string,
  order?: string,
}

// eslint-disable-next-line no-shadow
enum FilterComparator {
  LIKE = 'LIKE',
  EQ = '=',
  NE = '!=',
  GT = '>',
  GE = '>=',
  LT = '<',
  LE = '<=',
}

type FilterVal = {
  number: number,
  date: string,
}

type tableFilters = {
  [key: string]: {
    filterVal: FilterVal;
    filterType: 'TEXT';
    comparator: FilterComparator }
}

// eslint-disable-next-line no-shadow
enum TypeOfFilter {
  string,
  number,
  calculatedNumber,
  date,
}

// eslint-disable-next-line no-shadow
export enum PerformanceTabs {
  Chapters = 'chapters',
  Choices = 'choices',
  Nodes = 'nodes',
  Monetization = 'monetization',
  Completion = 'completion',
  MonetizationTopStories = 'MonetizationTopStories',
  HeartsTopStories = 'HeartsTopStories',
}

const getTypeOfFilterByKey = (key: string) => {
  switch (key) {
    case 'bookTitle':
    case 'title':
    case 'authors':
    case 'owner':
      return TypeOfFilter.string;
    case 'storyChapter':
    case 'chapter':
    case 'storyRevision':
    case 'revision':
    case 'free':
    case 'paid':
    case 'users':
    case 'active':
    case 'romantic':
    case 'hearts':
    case 'basic':
    case 'other':
    case 'bonus':
    case 'heartsPerUser':
    case 'started':
      return TypeOfFilter.number;
    case 'createdAt':
    case 'storyCreatedAt':
      return TypeOfFilter.date;
    case 'dropoff':
      return TypeOfFilter.calculatedNumber;
    default:
      return null;
  }
};

export const formatFilterParams = (filters: tableFilters) => {
  const searchParams: string[] = [];
  Object.keys(filters).forEach((key) => {
    const typeOfFilter = getTypeOfFilterByKey(key);
    switch (typeOfFilter) {
      case TypeOfFilter.string:
        searchParams.push(`${key}.name:${filters[key].filterVal}`);
        break;
      case TypeOfFilter.number: {
        const value = filters[key].filterVal.number;
        if (value) {
          searchParams.push(`${key}:${value}`);
        }
        break;
      }
      case TypeOfFilter.date: {
        const filterDate = filters[key].filterVal.date;
        if (filterDate) {
          const formattedDate = moment(filterDate).format('YYYY-MM-DD');
          searchParams.push(`${key}:${formattedDate}`);
        }
        break;
      }
      case TypeOfFilter.calculatedNumber: {
        const value = filters[key].filterVal.number;
        if (value) {
          searchParams.push(`${key}:${100 - value}`);
        }
        break;
      }
      default:
        throw new Error(`Filter key ${key} not found`);
    }
  });
  return searchParams;
};

export const formatOrderParams = (state: TableChangeState<[]>) => {
  switch (state.sortField) {
    case 'authors':
    case 'owner': {
      return `${state.sortField}.name:${state.sortOrder}`;
    }
    default:
      return `${state.sortField}:${state.sortOrder}`;
  }
};
