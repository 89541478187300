import axios from 'axios';
import React from 'react';
import { Alert } from 'react-bootstrap';
import ReactDOM from 'react-dom';

let url = process.env.REACT_APP_BASE_URL || 'http://localhost:3000/';

if (url.substr(-1) !== '/') {
  url += '/';
}

export const baseURL = url;

export const api = axios.create({
  baseURL,
});

const token = localStorage.getItem('token');
if (token) {
  api.defaults.headers.common.Authorization = token;
}

api.defaults.headers.post['Content-Type'] = 'application/json';

api.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status === 401) {
    if (error.response.config.url.replace(error.response.config.baseURL, '') !== 'v1/login') {
      window.location.assign('/logout');
    }
  }

  if (error.response) {
    let errorText = error.response.statusText;

    if (error.response.data) {
      if (error.response.data.error) {
        errorText = error.response.data.error;
      } else if (error.response.data.message) {
        errorText = error.response.data.message;
      }
    }

    const isDocumentsNeedSigningError = error.response.data?.documents;
    const isTeapotError = String(error.response.status) === '418';
    const isErrorHandled = isDocumentsNeedSigningError || isTeapotError;
    if (!isErrorHandled) {
      // TODO: https://dorian.atlassian.net/browse/DOR-3171
      ReactDOM.render(
        <Alert variant="danger" className="mx-3 my-3">
          {errorText}
        </Alert>,
        document.getElementById('errorsBox'),
      );
    }
  }

  setTimeout(() => {
    ReactDOM.render(null, document.getElementById('errorsBox'));
  }, 3000);

  return Promise.reject(error);
});
