// eslint-disable-next-line max-classes-per-file
import { EpisodeStorePublicInterface } from '@dorian/creation-tools-ui';
import async from 'async';
import classNames from 'classnames/bind';
import React, { Component, PureComponent, createRef } from 'react';
import {
  Alert, Badge, Button, Form, FormControl, InputGroup, Modal, Spinner,
} from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { DraggableCore } from 'react-draggable';
import { Redirect } from 'react-router-dom';
import IconCursor from '../../../assets/images/flag.png';
import { isCTAsPreviewEnabled } from '../../../helpers/runtimeEnv';
import { api } from '../../api';
import { ErrorAlert } from '../../ui/Errors/ErrorAlet';
// eslint-disable-next-line import/no-cycle
import { PageWrapper } from '../../ui/PageWrapper';
import { PremiumIpDisabledApprovedEdit } from '../../utils/premiumIpDisabledApprovedEdit';
import { PremiumIpDisabledEdit } from '../../utils/premiumIpDisabledEdit';
import { BookCover } from '../Book/BookCover';
import { ChapterRestoreModal } from '../Book/ChapterRestoreModal';
import { getMemoryBankSlotDataById } from '../Book/MemoryBank/memoryBankUtils';
import { fetchMemorySlotsData } from '../Book/MemoryBankModal';
import { Characters } from '../Characters';
import { Locations } from '../Locations';
import { Export } from '../Stories/Export';
// eslint-disable-next-line import/no-cycle
import { DetachablePreview as LegacyDetachablePreview } from '../StoryPreview/DetachablePreview';
import { BookTagsModal } from './BookTagsModal';
// eslint-disable-next-line import/no-cycle
import { fetchIsStoryAnalyticsVisible } from './BranchAnalytics/utils';
import { AddBranch } from './BranchEdit/AddBranch';
import { clearStepData } from './BranchEdit/Steps/CopyPastStep';
import { isCheckStep } from './BranchEdit/Steps/StepTypeCheck';
import { BranchItem } from './BranchItem';
import { CancelAddBranch } from './CancelAddBranch';
import { CoverEditor } from './CoverEditor';
import { DetachablePreview } from './DetachablePreview/DetachablePreview';
import { HelpSection } from './HelpSection';
import { InfoPanel } from './InfoPanel';
import { NodePositioningModal } from './NodePositioningModal';
import { NotFoundModal } from './NotFoundModal';
import { BranchesListPanel } from './Panel/BranchesListPanel';
import { SaveAsTemplate } from './SaveAsTemplate';
import { DeselectAll, SelectableGroup, createSelectable } from './Selectable';
import { SharedSettingsPanel } from './SharedSettingsPanel';
import styles from './StoryBranches.scss';
import { ValidatePanel } from './ValidatePanel';

export const BRANCH_LINES_TYPES = {
  Link: 'link',
  Branch: 'branch',
  SwitchTrue: 'switch_true',
  SwitchFalse: 'switch_false',
};

export const BRANCH_LINES_CLASSES = {
  link: 'line_dashed',
  switchTrue: 'line_dashed line_switch_true',
  switchFalse: 'line_dashed line_switch_false',
  branch: 'line_solid',
};

function getClassNameByLineType(lineType) {
  switch (lineType) {
    case BRANCH_LINES_TYPES.Link:
      return BRANCH_LINES_CLASSES.link;
    case BRANCH_LINES_TYPES.SwitchTrue:
      return BRANCH_LINES_CLASSES.switchTrue;
    case BRANCH_LINES_TYPES.SwitchFalse:
      return BRANCH_LINES_CLASSES.switchFalse;
    case BRANCH_LINES_TYPES.Branch:
    default:
      return BRANCH_LINES_CLASSES.branch;
  }
}

const cs = classNames.bind(styles);

function SComponent(props) {
  const {
    isSelected, children, selectableRef, isSelecting,
  } = props;

  return (
    <div
      ref={selectableRef}
      className={`
      ${isSelecting && 'selecting'}
      ${isSelected && 'selected'}
    `}
    >
      {children}
    </div>
  );
}

const SelectableComponent = createSelectable(SComponent);

class BranchesLine extends PureComponent {
  render() {
    const {
      angle, top, left, toID, length, fromID, type,
    } = this.props;

    const style = {
      position: 'absolute',
      left: `${left}px`,
      top: `${top}px`,
      width: `${length}px`,
      transform: `rotate(${angle}deg)`,
      transformOrigin: '0 0',
    };

    const className = getClassNameByLineType(type);
    return (
      <div style={style} className={`line_${fromID}_${toID} ${className}`} />
    );
  }
}

function LiveEditDisabled(role, group) {
  if (!role) return true;

  return ['live', 'liveprompt'].includes(group);
}

function showDangerAlert(text, timeBeforeDisappearing = 1000) {
  ReactDOM.render(
    <Alert variant="danger" className="mx-3 my-3">
      {text}
    </Alert>,
    document.getElementById('errorsBox'),
  );
  setTimeout(() => {
    ReactDOM.render(null, document.getElementById('errorsBox'));
  }, timeBeforeDisappearing);
}

const handlePreviewExit = (reason) => {
  switch (reason) {
    case 'pass-to-publish-dialog':
      // TODO: publish
      return;
    default:
      // TODO: https://dorian.atlassian.net/browse/DOR-3171
      showDangerAlert('Thanks for playing');
  }
};

export class StoryBranches extends Component {
  constructor(props) {
    super(props);
    const { auth } = this.props;

    this.state = {
      stepTypes: [],
      branch: [],
      story: {},
      editBranchData: null,
      addBranchActive: false,
      showLocations: false,
      branchesDragItems: [],
      branchesScale: localStorage.getItem('zoom') !== null ? JSON.parse(localStorage.getItem('zoom')) : 1,
      errorMsg: null,
      shiftDown: false,
      ctrlDown: false,
      snapToGrid: true,
      showGrid: true,
      lastEdit: null,
      notFound: false,
      modeEdit: false,
      modeAdminStyle: 'd-none',
      modeEditStyle: 'd-none',
      validatePanel: false,
      lastLocation: null,
      branchesWrapper: { x: 10000, y: 5000 },
      handleSize: { x: 200, y: 160 },
      boxSize: { x: 25, y: 25 },
      grid: 25,
      infoPanel: false,
      branchesListPanel: false,
      m: [],
      dragStart: false,
      limits: {},
      newBranchId: null,
      newStories: null,
      selectionItems: [],
      savePositionLoading: false,
      warning: 0,
      error: 0,
      sharedSettingsPanel: false,
      preview: true,
      showImagePath: false,
      authUser: auth.getUser(),
      branchOnNameEdit: false,
      NodePositioningModal: false,
      previewPanel: true,
      destinationNodeId: null,
      sourceNodeId: null,
      sourceNodeIdType: null,
      shareModal: false,
      copyText: null,
      GoLiveLoading: false,
      GoLiveModal: false,
      saveTemplateModal: false,
      editCoverActive: false,
      ConfirmationCancelAddBranch: false,
      AddBranchEdit: false,
      showEditBookTags: false,
      duplicateBranchs: [],
      showBookCover: false,
      showChapterRestore: false,
      HelpPanel: false,
      // eslint-disable-next-line react/no-unused-state
      dragTotal: 0,
      // eslint-disable-next-line react/no-unused-state
      groups: [],
      redirect: null,
      isAnalyticsVisible: false,
      memoryBank: null,
    };
    this.optionsRef = React.createRef();
    this.selectionRef = createRef();
    this.mainContent = React.createRef();
    this.mainContentWrapper = React.createRef();

    const { authUser } = this.state;
    this.user = authUser;
    this.storePublicInterface = new EpisodeStorePublicInterface();
  }

  static mGridMatrix(a, b) {
    const m = new Array(a).fill('');
    for (let i = 0; i < a; i++) m[i] = new Array(b);
    return m;
  }

  updatePreviewCurrentBranch = (branchId) => {
    // with disabled caching for engine this will trigger refetch of data on CT side
    this.storePublicInterface.setStackStartBranch(branchId);
  };

  updatePreviewAllBranches = () => {
    const { story } = this.state;
    const storyUuid = story.internal_uuid;
    api.get(`/v1/preview/${storyUuid}/branches`).then((response) => {
      this.storePublicInterface.updateEpisodeBranches(response.data.branches);
    });
  };

  handleBranchDelete = () => {
    const { branch } = this.state;
    const firstBranchId = branch[0].id;
    this.updatePreviewCurrentBranch(firstBranchId);
  };

  handleSelectionFinish = (newSelectedNodes) => {
    const { selectionItems: selectedNodeIds, branch: allNodes } = this.state;

    const isSelectedNodesEmpty = newSelectedNodes.length === 0;
    const isSelectionItemsEmpty = selectedNodeIds.length === 0;
    const isNeedDeselectItems = isSelectedNodesEmpty && !isSelectionItemsEmpty;

    if (isNeedDeselectItems) {
      this.setState({ selectionItems: [] });
    }

    if (isSelectedNodesEmpty) {
      return;
    }

    // FIXME: When deleting, "handleSelectionFinish" passes a node that was deleted and
    //  is not present in the allNodes
    const existingNewSelectedNodes = newSelectedNodes.filter(
      (existingNewSelectedNode) => allNodes.find(
        (node) => node.id === existingNewSelectedNode.id,
      ),
    );

    this.setState({
      selectionItems: existingNewSelectedNodes.map(
        (verifiedSelectedNode) => verifiedSelectedNode.id,
      ),
    });
  };

  handleSelectionClear = () => {
    this.setState({
      selectionItems: [],
    });
  };

  errorAlert = (error) => {
    this.setState({
      errorMsg: error,
    });
    setTimeout(() => {
      this.errorAlertClose();
    }, 3000);
  };

  errorAlertClose = () => {
    this.setState({
      errorMsg: null,
    });
  };

  wheel = (e) => {
    const { branchesScale, wheelLastTime } = this.state;

    const now = new Date().getTime();
    e.preventDefault();
    const last = wheelLastTime || 0;
    if (!(last + 500 < now)) {
      return false;
    }
    this.setState({ wheelLastTime: now });
    const delta = e.deltaY;
    let scale = branchesScale;
    if (delta > 0) {
      scale -= 0.25;
    } else {
      scale += 0.25;
    }
    if (scale < 0.25) {
      scale = 0.25;
    } else if (scale > 1) {
      scale = 1;
    }
    this.setState({ branchesScale: scale });
    return true;
  };

  keyFunctionDown = (e) => {
    if (e.keyCode === 17) {
      document.oncontextmenu = () => false;
      this.setState({
        ctrlDown: true,
      });
    }
    if (e.keyCode === 18) {
      this.mainContent.current.addEventListener('wheel', this.wheel, false);
    }
    if (e.keyCode === 32) {
      if (document.activeElement.nodeName !== 'INPUT' && document.activeElement.nodeName !== 'TEXTAREA') {
        e.preventDefault();
      }
      this.mainContent.current.setAttribute('style', 'cursor: grab');
      this.setState({
        shiftDown: true,
      });
    }
  };

  keyFunctionUp = (e) => {
    if (e.keyCode === 17) {
      document.oncontextmenu = () => true;
      this.setState({
        ctrlDown: false,
      });
    }
    if (e.keyCode === 18) {
      this.mainContent.current.removeEventListener('wheel', this.wheel, false);
    }
    if (e.keyCode === 32) {
      this.mainContent.current.setAttribute('style', 'cursor: default');
      this.setState({
        shiftDown: false,
      });
    }
  };

  errCount = (warning, error) => {
    this.setState({
      warning,
      error,
    });
  };

  scrollToBranch = (branchId) => {
    const { branchesScale } = this.state;

    const elm = document.getElementById(`branches_${branchId}`);
    if (elm) {
      const elmTop = (elm.offsetTop - 30) * branchesScale;
      const elmLeft = (elm.offsetLeft - 30) * branchesScale;
      this.mainContent.current.scrollTo(elmLeft, elmTop);
    }
  };

  validateAction = (branch, key) => {
    const { validatePanel, branch: branchFromState, infoPanel } = this.state;

    this.setState({
      lastEdit: branch || null,
    });

    if (branch) {
      this.scrollToBranch(branch);
    }

    if (key) {
      if (key === 'story') {
        this.setState({
          validatePanel: !validatePanel,
        }, () => {
          if (this.optionsRef) {
            this.optionsRef.current.show();
          }
        });
      }

      if (key === 'info') {
        this.setState({
          validatePanel: !validatePanel,
          infoPanel: !infoPanel,
        });
      }

      if (key === 'book_cover') {
        this.setState({
          validatePanel: !validatePanel,
        }, () => {
          this.actionBookCover();
        });
      }

      if (key === 'story_cover') {
        this.setState({
          validatePanel: !validatePanel,
        }, () => {
          this.actionEditCover();
        });
      }

      if (key === 'locations') {
        this.setState({
          validatePanel: !validatePanel,
        });
        this.initLocations();
        return;
      }

      if (key === 'branches') {
        this.setState({
          validatePanel: !validatePanel,
        });
        const b = branchFromState.find((e) => e.id === branch);
        this.initAddBranch(b);
      }
      if (key === 'characters') {
        this.actionEditCharacters();
      }
      if (key === 'book_tags') {
        this.setState({
          validatePanel: !validatePanel,
        }, () => {
          this.setState({
            showEditBookTags: true,
          });
        });
      }
    }
  };

  clearSelectionUsingRef = () => {
    const { ctrlDown } = this.state;

    if (this.selectionRef && !ctrlDown) {
      this.selectionRef.current.clearSelection();
    }
  };

  componentDidMount() {
    const { story } = this.state;

    clearStepData();
    this.loadSettings();
    this.loadBranchList();
    this.loadStory();
    document.addEventListener('keydown', this.keyFunctionDown, { passive: false });
    document.addEventListener('keyup', this.keyFunctionUp, { passive: false });
    window.addEventListener('storage', this.updateStorage, { passive: false });

    localStorage.removeItem(`lastEdit-${story.internal_uuid}`);
    localStorage.removeItem(`modeEdit-${story.internal_uuid}`);
    this.newStorySteps();
  }

  newStorySteps() {
    const { match } = this.props;
    const { branch } = this.state;

    const a = JSON.parse(sessionStorage.getItem('newStories'));
    if (a && String(a.id) === String(match.params.id)) {
      this.setState({
        newStories: String(a.id),
      });
      switch (a.step) {
        case 1:
          this.initLocations();
          break;
        case 2:
          break;
        case 3:

          if (branch.length === 1) {
            this.initAddBranch(branch[0]);
          } else {
            sessionStorage.removeItem('newStories');
            this.setState({
              editBranchData: null,
              addBranchActive: false,
              newStories: null,
            });
          }
          break;
        default:
          sessionStorage.removeItem('newStories');
          break;
      }
    }
  }

  updateStorage = (e) => {
    const { story } = this.state;

    if (e.key === 'newStories') {
      this.newStorySteps();
    }
    if (e.key === `lastEdit-${story.internal_uuid}`) {
      const lastEdit = localStorage.getItem(`lastEdit-${story.internal_uuid}`) !== null ? JSON.parse(localStorage.getItem(`lastEdit-${story.internal_uuid}`)) : null;

      this.setState({
        lastEdit,
      });
      this.scrollToBranch(lastEdit);
    }
  };

  mGridSize() {
    const { branchesWrapper, boxSize, grid } = this.state;

    const handleItems = document.getElementsByClassName('handle');
    const wrapBox = this.mainContentWrapper.current.getBoundingClientRect();

    const newM = StoryBranches.mGridMatrix(
      Math.ceil(branchesWrapper.y / grid),
      Math.ceil(branchesWrapper.x / grid),
    );

    if (handleItems && handleItems.length) {
      const wrapBoxLeft = wrapBox ? wrapBox.left : 0;
      const wrapBoxTop = wrapBox ? wrapBox.top : 0;
      for (let i = 0, l = handleItems.length; i < l; i++) {
        const o = handleItems[i].getBoundingClientRect();
        const x = Math.floor((o.x - wrapBoxLeft) / grid);
        const y = Math.floor((o.y - wrapBoxTop) / grid);
        if (newM.length > 0) {
          const r = Math.ceil(boxSize.y / grid);
          const c = Math.ceil(boxSize.x / grid);
          for (let j = 0; j < r; j++) {
            for (let ii = 0; ii < c; ii++) {
              const v = newM[y + j][x + ii];
              newM[y + j][x + ii] = v + v ? v + 1 : 1;
            }
          }
        }
      }
    }

    this.setState({
      m: newM,
    });
  }

  mGrid() {
    const { handleSize, branch } = this.state;

    branch.forEach((object, i) => {
      const { x } = object;
      const { y } = object;
      const { branchesWrapper } = this.state;
      if (branchesWrapper.x < (x + handleSize.x)) {
        branchesWrapper.x = x + handleSize.x;
        this.setState({
          branchesWrapper,
        });
      }

      if (branchesWrapper.y < (y + handleSize.y)) {
        branchesWrapper.y = y + handleSize.y;
        this.setState({
          branchesWrapper,
        });
      }

      if (i === branch.length - 1) {
        this.mGridSize();
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { auth } = this.props;
    const {
      lastEdit, branch, authUser, story,
    } = this.state;

    if (prevState.lastEdit !== lastEdit) {
      localStorage.setItem(`lastEdit-${story.internal_uuid}`, lastEdit);
    }

    if (
      prevProps.auth !== auth
        || prevProps.auth.getUser().id !== auth.getUser().id
        || prevProps.auth.getUser().role !== auth.getUser().role
    ) {
      this.setState({ authUser: auth.getUser() });
    }

    if (prevState.branch !== branch) {
      this.mGrid();
    }

    if (prevState.story.story_role !== story.story_role) {
      const user = authUser;
      let modeEdit = true;
      let modeEditStyle = 'd-none';

      if (user.role !== 'admin' && story.story_role === 'viewer') {
        modeEdit = false;
        modeEditStyle = 'd-none';
      }

      if (user.role === 'admin' && story.story_role !== 'owner') {
        modeEdit = localStorage.getItem('modeEdit') !== null
          ? !JSON.parse(localStorage.getItem('modeEdit'))
          : false;
        modeEditStyle = 'd-block';
      }

      this.setState({
        modeEdit,
        modeEditStyle,
        modeAdminStyle: user.role === 'admin' ? 'd-block' : 'd-none',
      });

      localStorage.setItem(`modeEdit-${story.internal_uuid}`, !modeEdit);

      fetchIsStoryAnalyticsVisible(this.user, story.book.id, story.group)
        .then((isStoryAnalyticsVisible) => {
          this.setState({ isAnalyticsVisible: isStoryAnalyticsVisible });
        });
    }
  }

  componentWillUnmount() {
    const { story } = this.state;

    document.removeEventListener('keydown', this.keyFunctionDown, false);
    document.removeEventListener('keyup', this.keyFunctionUp, false);
    window.removeEventListener('storage', this.updateStorage);
    localStorage.removeItem(`lastEdit-${story.internal_uuid}`);
    localStorage.removeItem(`modeEdit-${story.internal_uuid}`);
    sessionStorage.removeItem('newStories');
  }

  updateGoLive = () => {
    const { validatePanel, story } = this.state;
    this.setState({
      GoLiveLoading: true,
    });

    let url = `/v1/books/${story.book.id}/chapters/${story.id}/golive`;
    if (story.group === 'prompt') {
      url = `/v1/books/${story.book.id}/chapters/${story.id}/makepromptlive`;
    } else if (story.book.original) {
      url = `/v1/books/${story.book.id}/chapters/${story.id}/submit`;
    }

    api.post(url)
      .then(() => {
        this.setState({
          GoLiveModal: false,
          GoLiveLoading: false,
          redirect: `/book/${story.book.id}`,
        });
      })
      .catch((error) => {
        this.setState({
          GoLiveModal: false,
          GoLiveLoading: false,
          validatePanel: String(error.response.status) !== '418' ? validatePanel : true,
        }, () => {
          if (String(error.response.status) !== '418') {
            this.errorAlert(error);
          }
        });
      });
  };

  loadSettings() {
    async.parallel({
      settings: (callback) => {
        api.get('/v1/settings')
          .then((res) => {
            callback(null, res.data.settings);
          }).catch((error) => {
            callback(error, null);
          });
      },
      types: (callback) => {
        api.get('/v1/steps/types')
          .then((res) => {
            callback(null, res.data.types);
          }).catch((error) => {
            callback(error, null);
          });
      },
      groups: (callback) => {
        api.get('/v1/groups/chapters')
          .then((res) => {
            callback(null, res.data.groups);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (!err) {
          this.setState({
            stepTypes: res.types,
            limits: res.settings.limits,
            showImagePath: res.settings.preferences.show_character_image_path.value === 'on',
            // eslint-disable-next-line react/no-unused-state
            groups: res.groups,
          });
        }
      } catch (e) {
        // TODO: report to sentry
      }
    });
  }

  loadBranchList = (NewBranchId, val) => {
    const { match } = this.props;
    const { story } = this.state;

    api.get(`/v1/stories/${match.params.id}/branches`)
      .then((res) => {
        // eslint-disable-next-line max-len
        const newValID = val && val.id && val.arr && val.arr.number && val.arr.number === 1 ? val.id : null;
        const lastEdit = localStorage.getItem(`lastEdit-${story.internal_uuid}`);

        this.setState({
          newBranchId: newValID || null,
          addBranchActive: false,
          branch: res.data.branches,
          lastEdit: lastEdit !== null ? JSON.parse(lastEdit) : null,
          destinationNodeId: null,
          sourceNodeId: null,
          sourceNodeIdType: null,
        });
        if (val && val.arr && val.arr.number && val.arr.number !== 1) {
          const value = val.arr;
          value.number -= 1;
          this.initAddBranch(value);
        }
      })
      .catch((error) => {
        this.errorAlert(error);
      });
  };

  loadStory() {
    const { match } = this.props;

    api.get(`/v1/stories/${match.params.id}`)
      .then((res) => {
        this.setState({
          story: res.data.story,
        });
        this.fetchMemorySlots();
        this.mGrid();
      })
      .catch((error) => {
        this.errorAlert(error);
      });
  }

  async fetchMemorySlots() {
    const { story } = this.state;
    const { id: bookId } = story.book;
    const memoryBankResult = await fetchMemorySlotsData(bookId);

    this.setState({ memoryBank: memoryBankResult });
  }

  handleStart(data, ui, nodeToMove) {
    const {
      branchesScale, selectionItems: selectedNodeIds, branch: allNodes,
    } = this.state;

    const wrapBox = this.mainContentWrapper.current.getBoundingClientRect();
    const wrapBoxLeft = wrapBox ? wrapBox.left : 0;
    const wrapBoxTop = wrapBox ? wrapBox.top : 0;

    const hasAtLeastOneSelectedNode = selectedNodeIds.length > 0;

    const nodesToMove = hasAtLeastOneSelectedNode
      ? selectedNodeIds.map((selectedNodeId) => allNodes.find((node) => node.id === selectedNodeId))
      : [nodeToMove];

    this.setState({
      dX: ((data.clientX - wrapBoxLeft) / branchesScale) - nodeToMove.x,
      dY: ((data.clientY - wrapBoxTop) / branchesScale) - nodeToMove.y,
      branchesDragItems: nodesToMove,
    });
  }

  getSnapToGridPosition = (x, y) => {
    const { grid: cellSize } = this.state;

    const snapToGridX = Math.floor(x / cellSize) * cellSize;
    const snapToGridY = Math.floor(y / cellSize) * cellSize;
    return {
      x: snapToGridX,
      y: snapToGridY,
    };
  };

  calculateNodePosition(uiX, uiY, node, pickedNodeToMove) {
    const {
      branchesScale, dX, dY, snapToGrid,
    } = this.state;

    const dragNodeLeftPosition = (uiX / branchesScale) - dX;
    const dragNodeTopPosition = (uiY / branchesScale) - dY;
    const isCurrentDragNode = node.id === pickedNodeToMove.id;
    const offsetPositionX = isCurrentDragNode ? 0 : pickedNodeToMove.x - node.x;
    const offsetPositionY = isCurrentDragNode ? 0 : pickedNodeToMove.y - node.y;
    const preLeftPosition = dragNodeLeftPosition - offsetPositionX;
    const preTopPosition = dragNodeTopPosition - offsetPositionY;

    const x = preLeftPosition >= 0 ? preLeftPosition : 0;
    const y = preTopPosition >= 0 ? preTopPosition : 0;

    if (snapToGrid) {
      const snapToGridPosition = this.getSnapToGridPosition(x, y);
      return {
        x: snapToGridPosition.x,
        y: snapToGridPosition.y,
      };
    }

    return { x, y };
  }

  handleDrag(data, ui, nodeToMove) {
    const {
      selectionItems: selectionItemIds, branchesDragItems, dragStart,
    } = this.state;

    if (!dragStart) {
      this.setState({ dragStart: true });
    }

    if (!selectionItemIds.find((selectionItemId) => selectionItemId === nodeToMove.id)) {
      this.clearSelectionUsingRef();
    }

    branchesDragItems.forEach((movingNode) => {
      if (movingNode.id) {
        const newPosition = this.calculateNodePosition(ui.x, ui.y, movingNode, nodeToMove);

        const dragNodeElement = document.getElementById(`branchesDragItem-${movingNode.id}`);
        dragNodeElement.setAttribute('style', `display: block; left: ${newPosition.x}px; top: ${newPosition.y}px;z-index:99999;`);
        dragNodeElement.classList.add('opacity');
      }
    });
  }

  handleStop(data, ui, dragNode) {
    const {
      dragStart,
      branchesDragItems: movedNodes,
    } = this.state;

    if (!dragStart) {
      return;
    }

    const movedNodesWithNewPosition = movedNodes.map(
      (branchesDragItem) => {
        const newPosition = this.calculateNodePosition(ui.x, ui.y, branchesDragItem, dragNode);
        return { ...branchesDragItem, ...newPosition };
      },
    );
    this.setState({
      dragStart: false,
      lastEdit: dragNode.id,
    });
    this.savePosition(movedNodesWithNewPosition);
  }

  checkGrid(value, object) {
    const { branchesScale, m, grid } = this.state;

    if (!value) {
      return null;
    }
    if (value.id && !object) {
      return value;
    }

    const aaa = m[Math.floor((value.y / grid) * branchesScale)];
    const bbb = aaa[Math.floor((value.x / grid) * branchesScale)];
    if (bbb === undefined) {
      return value;
    }
    // eslint-disable-next-line no-param-reassign
    value.x += grid * 8;
    // eslint-disable-next-line no-param-reassign
    value.y += grid;
    return this.checkGrid(value, object);
  }

  moveDragNodesToSavedPosition(movedNodes) {
    const { branch: allNodes } = this.state;

    movedNodes.forEach(
      (movedNode) => {
        const nodeToMove = allNodes.find((node) => node.id === movedNode.id);
        // TODO: To think about refactoring
        nodeToMove.x = movedNode.x;
        nodeToMove.y = movedNode.y;

        const movedNodeElement = document.getElementById(`branches_${movedNode.id}`);
        if (movedNodeElement) {
          movedNodeElement.setAttribute('style', `position: absolute; left: ${movedNode.x}px; top: ${movedNode.y}px;`);
          movedNodeElement.classList.remove('opacity');
        }
      },
    );
  }

  savePosition(nodes) {
    const { match } = this.props;

    const prepareNodesForPayload = nodes.map(
      (node) => ({
        id: node.id,
        data: {
          x: node.x,
          y: node.y,
        },
      }),
    );

    const payload = {
      branches: prepareNodesForPayload,
    };

    api.patch(`/v1/stories/${match.params.id}/branches`, payload)
      .then(() => {
        this.moveDragNodesToSavedPosition(nodes);
      })
      .finally(() => this.setState({
        branchesDragItems: [],
      }));
  }

  addBranches = (val) => {
    const { branchesScale } = this.state;

    const value = {
      x: this.mainContent.current.scrollLeft / branchesScale,
      y: this.mainContent.current.scrollTop / branchesScale,
      number: val,
    };
    this.initAddBranch(value);
  };

  initAddBranch = (obj, value) => {
    const { branch } = this.state;

    let editBranchData = null;
    if (obj) {
      if (!obj.copy_from_id && branch.length > 0) {
        this.checkGrid(obj, value);
      }
      editBranchData = obj;
    }
    this.setState({
      editBranchData,
      addBranchActive: true,
    });
  };

  cancelAddBranch = () => {
    const { match } = this.props;
    let { newStories } = this.state;

    if (newStories === match.params.id) {
      newStories = null;
      sessionStorage.removeItem('newStories');
    }
    this.setState({
      editBranchData: null,
      addBranchActive: false,
      ConfirmationCancelAddBranch: false,
      AddBranchEdit: false,
      newStories,
    });
  };

  branchesLinesObj(fromID, toID, type, index) {
    const fromElm = document.getElementById(`branches_${fromID}`);
    const toElm = document.getElementById(`branches_${toID}`);

    if (!fromElm || !toElm) {
      return null;
    }

    const wf = fromElm.clientWidth / 2;
    const hf = fromElm.clientHeight / 2;
    const wt = toElm.clientWidth / 2;
    const ht = toElm.clientHeight / 2;

    const dy = (toElm.offsetTop + ht) - (fromElm.offsetTop + hf);
    const dx = (toElm.offsetLeft + wt) - (fromElm.offsetLeft + wf);

    const angle = (Math.atan2(dy, dx) * 180) / Math.PI;
    const length = Math.sqrt(dx * dx + dy * dy);

    const left = fromElm.offsetLeft + wf;
    const top = fromElm.offsetTop + hf;

    return (
      <BranchesLine
        key={index}
        type={type}
        fromID={fromID}
        toID={toID}
        left={left}
        top={top}
        length={length}
        angle={angle}
      />
    );
  }

  branchesLines = () => {
    const { branch } = this.state;

    return branch.map((object, i) => {
      const fromID = object.id;
      if (object.links !== undefined && object.links.length > 0) {
        return object.links.map((_link, j) => this.branchesLinesObj(
          fromID,
          _link,
          BRANCH_LINES_TYPES.Link,
          j,
        ));
      }
      if (object.switch && object.switch.length > 0) {
        return object.switch.map((_switch, j) => {
          const switchValue = _switch.value === 'true';
          const lineType = switchValue
            ? BRANCH_LINES_TYPES.SwitchTrue : BRANCH_LINES_TYPES.SwitchFalse;

          return this.branchesLinesObj(
            fromID,
            _switch.gotoBranchId,
            lineType,
            j,
          );
        });
      }
      if (object.gotoBranchId !== null) {
        return this.branchesLinesObj(fromID, object.gotoBranchId, BRANCH_LINES_TYPES.Branch, i);
      }
      return null;
    });
  };

  updateBranch = () => {
    this.loadBranchList();
  };

  findCascade = (data, res, node) => {
    if (node.gotoBranchId) {
      const nextNode = data.find((el) => el.id === node.gotoBranchId);
      if (!res.includes(nextNode.id)) {
        res.push(nextNode.id);
        this.findCascade(data, res, nextNode);
      }
    } else {
      node.links.forEach((link) => {
        const nextNode = data.find((el) => el.id === link);
        if (!res.includes(nextNode.id)) {
          res.push(nextNode.id);
          this.findCascade(data, res, nextNode);
        }
      });
    }
  };

  findCascadeNodes = (data, start) => {
    const nodes = [];
    const startNode = data.find((el) => el.id === start);
    nodes.push(startNode.id);
    this.findCascade(data, nodes, startNode);
    return nodes;
  };

  selectCascade = (val) => {
    const { branch } = this.state;

    if (this.selectionRef) {
      const ids = this.findCascadeNodes(branch, val);
      this.selectionRef.current.selectByIds(ids);
    }
  };

  duplicateBranchsLoading = (val) => {
    const { match } = this.props;
    const { duplicateBranchs: duplicateBranches } = this.state;

    this.setState({ savePositionLoading: true });
    const data = {};
    data.branches = duplicateBranches;
    data.position = val;
    api.post(`/v1/stories/${match.params.id}/branches/duplicate`, data)
      .then((res) => {
        // TODO: Need clarification. If redo to id - it breaks down duplicating node.
        const lastEditNode = res.data.branches[res.data.branches.length - 1];
        this.setState({
          savePositionLoading: false,
          lastEdit: lastEditNode,
          duplicateBranchs: [],
        }, () => {
          this.loadBranchList();
        });
      })
      .then(this.updatePreviewAllBranches)
      .catch(() => {
        this.setState({
          savePositionLoading: false,
          duplicateBranchs: [],
        });
      });
  };

  duplicateBranchs = (val) => {
    this.setState({
      duplicateBranchs: val,
    }, () => {
      this.handleSelectionClear();
      this.clearSelectionUsingRef();
    });
  };

  sharedSettingsUpdate = () => {
    const { sharedSettingsPanel } = this.state;

    this.setState({
      sharedSettingsPanel: !sharedSettingsPanel,
    });
  };

  onBranchNameEdit = (val) => {
    this.setState({ branchOnNameEdit: val });
  };

  branchesItems() {
    const { match } = this.props;
    const {
      branch,
      modeEdit,
      newBranchId,
      lastEdit,
      limits,
      shiftDown,
      handleSize,
      destinationNodeId,
      story,
      savePositionLoading,
      branchesScale,
      authUser,
      sourceNodeId,
      isAnalyticsVisible,
      memoryBank,
    } = this.state;

    const action = this.initAddBranch;
    const storyId = match.params.id;
    const disabled = shiftDown
        || !modeEdit
        || PremiumIpDisabledEdit(authUser.role, story.book, story.group)
        || PremiumIpDisabledApprovedEdit(authUser.role, story.book)
        || LiveEditDisabled(authUser.role, story.group);

    return branch.map((object, i) => {
      const { x } = object;
      const { y } = object;
      const handleStyle = x !== null ? {
        position: 'absolute',
        left: x < 0 ? 0 : x,
        top: y < 0 ? 0 : y,
        width: handleSize.x,
      } : {
        backgroundColor: 'rgba(0, 0, 0, .1)',
        width: handleSize.x,
      };

      const decisionPoint = object.links && object.links.length > 0 ? 'handle_branchesDecsionPoint' : '';
      const handleLastEdit = lastEdit === object.id ? 'handleLastEdit' : '';

      const dragObjClassName = `.handle_${object.id}`;
      const dragObjClass = `handle handle_${object.id} ${decisionPoint} ${handleLastEdit}`;

      const isCheck = isCheckStep(object);
      const memorySlotData = getMemoryBankSlotDataById(memoryBank, object.check);

      const handleBranchClick = () => {
        this.setState({ lastEdit: object.id });
        if (object.steps_count === 0) {
          showDangerAlert("Node without steps can't be previewed", 3000);
          return;
        }
        this.updatePreviewCurrentBranch(object.id);
      };

      return (
        <DraggableCore
            // eslint-disable-next-line react/no-array-index-key
          key={i}
          handle={dragObjClassName}
          disabled={disabled}
          position={null}
          grid={[1, 1]}
          onDrag={(e, ui) => this.handleDrag(e, ui, object)}
          onStart={(e, ui) => this.handleStart(e, ui, object)}
          onStop={(e, ui) => this.handleStop(e, ui, object)}
        >
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className={dragObjClass}
            style={handleStyle}
            id={`branches_${object.id}`}
            onClick={handleBranchClick}
          >
            <SelectableComponent {...this.props} selectableId={object.id}>
              <BranchItem
                onDeleteSuccess={this.handleBranchDelete}
                branchNode={object}
                actionEditBranch={action}
                update={this.updateBranch}
                duplicate={this.duplicateBranchs}
                selectCascade={this.selectCascade}
                storyId={storyId}
                storyGroup={story ? story.group : null}
                lastEdit={lastEdit}
                disabled={disabled}
                limits={limits}
                newBranchId={newBranchId || null}
                clearSelection={this.clearSelectionUsingRef}
                onError={this.errorAlert}
                onEdit={this.onBranchNameEdit}
                mainContent={this.mainContent}
                destinationNodeId={destinationNodeId}
                branchesScale={branchesScale}
                sourceNodeId={sourceNodeId}
                savePositionLoading={savePositionLoading}
                updateLoading={(val) => {
                  this.setState({
                    savePositionLoading: val,
                  });
                }}
                updateConnection={(id, type) => {
                  this.setState({
                    sourceNodeId: id,
                    sourceNodeIdType: type,
                    destinationNodeId: null,
                  });
                }}
                isAnalyticsVisible={isAnalyticsVisible}
                isCheckStep={isCheck}
                memorySlotData={memorySlotData}
              />
            </SelectableComponent>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className={`hoverConnection ${sourceNodeId ? 'd-block' : 'd-none'}`}
              onClick={() => {
                this.setState({
                  destinationNodeId: object.id,
                });
              }}
            />
          </div>
        </DraggableCore>
      );
    });
  }

  branchesDragItem() {
    const { authUser, branchesDragItems } = this.state;

    if (!branchesDragItems || branchesDragItems.length < 1) {
      return null;
    }
    return branchesDragItems.map((branchNode) => {
      const decisionPoint = branchNode.links && branchNode.links.length > 0 ? 'handle_branchesDecsionPoint' : '';
      const isCheck = isCheckStep(branchNode);
      return (
        <div
          key={branchNode.id}
          id={`branchesDragItem-${branchNode.id}`}
          className={`branchesDragItem ${decisionPoint}`}
        >
          <BranchItem
            onDeleteSuccess={this.handleBranchDelete}
            branchNode={branchNode}
            disabled
            user={authUser}
            isCheckStep={isCheck}
          />
        </div>
      );
    });
  }

  initLocations() {
    this.setState({
      showLocations: true,
    });
  }

  validatePanelHide = () => {
    this.setState({
      validatePanel: false,
    });
  };

  sharedSettingsPanelHide = () => {
    this.setState({
      sharedSettingsPanel: false,
    });
  };

  infoPanelHide = () => {
    this.setState({
      infoPanel: false,
    });
  };

  branchesListPanelHide = () => {
    this.setState({
      branchesListPanel: false,
    });
  };

  handleDoubleClickItem(e) {
    const {
      snapToGrid, branchesScale, duplicateBranchs: duplicateBranches, grid, story,
    } = this.state;

    if (['live', 'liveprompt'].includes(story.group)) {
      return;
    }
    const wrapBox = this.mainContentWrapper.current.getBoundingClientRect();
    const wrapBoxLeft = wrapBox ? wrapBox.left : 0;
    const wrapBoxTop = wrapBox ? wrapBox.top : 0;
    const snapToGridX = snapToGrid === true ? grid : 1;
    const snapToGridY = snapToGrid === true ? grid : 1;
    const obj = {
      // eslint-disable-next-line max-len
      x: Math.round(((e.clientX - wrapBoxLeft - (snapToGridX / 2)) / branchesScale) / snapToGridX) * snapToGridX,
      // eslint-disable-next-line max-len
      y: Math.round(((e.clientY - wrapBoxTop - (snapToGridY / 2)) / branchesScale) / snapToGridY) * snapToGridY,
      number: 1,
    };

    if (duplicateBranches && duplicateBranches.length > 0) {
      this.duplicateBranchsLoading(obj);
      return;
    }

    this.initAddBranch(obj);
  }

  onMouseMove = (e) => {
    const { shiftDown } = this.state;

    const elm = this.mainContent.current;
    if (shiftDown === true) {
      if (e.buttons === 1) {
        elm.setAttribute('style', 'cursor: grabbing');
        elm.scrollTo({
          left: elm.scrollLeft - e.movementX,
          top: elm.scrollTop - e.movementY,
        });
      } else {
        elm.setAttribute('style', 'cursor: grab;');
      }
    }
  };

  onLocationSelected = (locationId) => {
    this.setState({
      lastLocation: locationId,
    });
  };

  validateBadge = () => {
    const { error, warning } = this.state;

    return (
      <>
        Validate
        <span className="boxBadge">
          {(warning || warning > 0) && (
          <Badge pill variant="warning">
            {warning || 0}
          </Badge>
          )}
          {(error || error > 0) && (
          <Badge pill variant="danger">
            {error || 0}
          </Badge>
          )}
        </span>
      </>
    );
  };

  setLastEdit = (branchId) => {
    if (!branchId) {
      return;
    }

    this.setState({
      lastEdit: branchId,
    });

    this.scrollToBranch(branchId);
  };

  actionEditCharacters = () => {
    this.setState({
      editCharactersActive: true,
    });
  };

  actionEditCover = () => {
    const { editCoverActive } = this.state;

    this.setState({
      editCoverActive: !editCoverActive,
    });
  };

  actionBookCover = () => {
    const { showBookCover } = this.state;

    this.setState({
      showBookCover: !showBookCover,
    });
  };

  actionChapterRestore = () => {
    const { showChapterRestore } = this.state;

    this.setState({
      showChapterRestore: !showChapterRestore,
    });
  };

  editCover() {
    const {
      authUser, editCoverActive, modeEdit, story,
    } = this.state;

    if (editCoverActive) {
      return (
        <CoverEditor
          obj={this.state}
          onHide={this.actionEditCover}
          viewOnly={
            (
              (
                authUser.role !== 'admin'
                && story.story_role === 'viewer'
              )
              || !modeEdit
              || PremiumIpDisabledEdit(authUser.role, story.book, story.group)
              || PremiumIpDisabledApprovedEdit(authUser.role, story.book)
              || LiveEditDisabled(authUser.role, story.group)
            )
          }
          update={() => {
            this.loadStory();
          }}
        />
      );
    }
    return null;
  }

  characters() {
    const {
      modeEdit, editCharactersActive, limits, authUser, story, showImagePath,
    } = this.state;

    if (editCharactersActive) {
      const user = authUser;
      return (
        <Characters
          user={user}
          book={story.book}
          id={String(story.book.id)}
          title={story.book.title}
          show={editCharactersActive}
          onHide={() => {
            this.setState({
              editCharactersActive: false,
            });
          }}
          disabledEdit={
            !modeEdit
            || PremiumIpDisabledEdit(authUser.role, story.book, story.group)
            || PremiumIpDisabledApprovedEdit(authUser.role, story.book)
            || LiveEditDisabled(authUser.role, story.group)
          }
          disabled={
            (
              !modeEdit
              || (
                user.role !== 'admin'
                && story.book.book_role !== 'owner'
                && story.book.book_role !== 'editor'
                && story.book.book_role !== 'co-author'
              )
            )
          }
          limits={limits}
          showImagePath={showImagePath}
        />
      );
    }
    return null;
  }

  handleCopy = () => {
    const copyText = document.getElementById('linkShare');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    this.setState({
      copyText: copyText.value,
    }, () => {
      setTimeout(() => {
        this.setState({
          copyText: null,
        });
      }, 1000);
    });
  };

  render() {
    /* eslint-disable react/destructuring-assignment */
    /* eslint-disable max-len */

    const { isAnalyticsVisible } = this.state;

    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />);
    }
    const user = this.state.authUser;

    const handleAnyBranchUpdate = (maybeNewBranchInfo) => {
      if (this.state.newStories === this.props.match.params.id) {
        sessionStorage.removeItem('newStories');
        this.setState({
          newStories: null,
        });
      }
      this.loadBranchList(null, maybeNewBranchInfo);
    };

    const handleBranchUpdate = (branchId) => {
      this.updatePreviewCurrentBranch(branchId);
      handleAnyBranchUpdate();
    };

    const handleBranchCreate = (maybeNewBranchInfo) => {
      this.updatePreviewAllBranches();
      handleAnyBranchUpdate(maybeNewBranchInfo);
    };

    const addBranch = () => {
      if (this.state.addBranchActive === true) {
        return (
          <>
            <AddBranch
              branchesGroup={this.state.story.group}
              modeEdit={this.state.modeEdit}
              user={this.state.authUser}
              actionEditBranch={this.initAddBranch}
              branch={this.state.branch}
              story={this.state.story}
              storyId={this.props.match.params.id}
              data={this.state.editBranchData}
              stepTypes={this.state.stepTypes}
              show={this.state.addBranchActive}
              edit={(e) => {
                this.setState({
                  AddBranchEdit: e,
                });
              }}
              addBranchEdit={this.state.AddBranchEdit}
              onHide={() => {
                if (
                  (user.role === 'admin' && !this.state.modeEdit)
                  || (user.role !== 'admin' && this.state.story.book.book_role !== 'owner' && this.state.story.book.book_role !== 'editor' && this.state.story.book.book_role !== 'co-author')
                  || !this.state.AddBranchEdit
                  || (
                    this.state.story
                    && (this.state.story.book
                      && !!this.state.story.book.original
                      && ['live', 'liveprompt'].includes(this.state.story.group)
                    )
                  )
                ) {
                  this.cancelAddBranch();
                } else {
                  this.setState({
                    ConfirmationCancelAddBranch: true,
                  });
                }
              }}
              update={handleAnyBranchUpdate}
              onBranchUpdate={handleBranchUpdate}
              onBranchCreate={handleBranchCreate}
              disabled={
                !this.state.modeEdit
                // eslint-disable-next-line max-len
                || PremiumIpDisabledEdit(this.state.authUser.role, this.state.story.book, this.state.story.group)
                || PremiumIpDisabledApprovedEdit(this.state.authUser.role, this.state.story.book)
                || LiveEditDisabled(this.state.authUser.role, this.state.story.group)
              }
              onLocationSelected={this.onLocationSelected}
              lastLocation={this.state.lastLocation}
              limits={this.state.limits}
              onError={this.errorAlert}
              restrictedEdit={['live', 'liveprompt'].includes(this.state.story.group)}
            />

            <CancelAddBranch
              show={this.state.ConfirmationCancelAddBranch}
              onHide={() => {
                this.setState({
                  ConfirmationCancelAddBranch: false,
                });
              }}
              update={this.cancelAddBranch}
            />

          </>
        );
      }
      return false;
    };

    const closeLocations = () => {
      this.setState({
        showLocations: false,
      });
      if (this.state.newStories === this.props.match.params.id) {
        sessionStorage.setItem('newStories', JSON.stringify({
          id: this.state.newStories,
          step: 2,
        }));
        this.newStorySteps();
      }
    };

    const listLocations = () => {
      if (this.state.showLocations === true) {
        return (
          <Locations
            user={this.state.authUser}
            book={this.state.story.book}
            bookId={this.state.story.book.id}
            bookTitle={this.state.story.book.title}
            show={this.state.showLocations}
            onHide={closeLocations}
            disabled={!this.state.modeEdit}
            limits={this.state.limits}
            disabledEdit={
              !this.state.modeEdit
              // eslint-disable-next-line max-len
              || PremiumIpDisabledEdit(this.state.authUser.role, this.state.story.book, this.state.story.group)
              || PremiumIpDisabledApprovedEdit(this.state.authUser.role, this.state.story.book)
              || LiveEditDisabled(this.state.authUser.role, this.state.story.group)
            }
          />
        );
      }
      return false;
    };

    const closeStoryNotFound = () => {
      window.location.assign('/stories');
    };

    const storyNotFound = () => {
      if (this.state.notFound !== true) {
        return null;
      }
      return (
        <NotFoundModal
          show={this.state.notFound}
          onHide={closeStoryNotFound}
        />
      );
    };

    const previewClose = () => {
      this.setState({
        preview: false,
      });
    };

    const editBookTags = () => {
      if (this.state.showEditBookTags === true) {
        return (
          <BookTagsModal
            book={this.state.story.book}
            show={this.state.showEditBookTags}
            disabled={
              !this.state.modeEdit
              // eslint-disable-next-line max-len
              || PremiumIpDisabledEdit(this.state.authUser.role, this.state.story.book, this.state.story.group)
              || PremiumIpDisabledApprovedEdit(this.state.authUser.role, this.state.story.book)
              || LiveEditDisabled(this.state.authUser.role, this.state.story.group)
            }
            onHide={() => {
              this.setState({
                showEditBookTags: false,
              });
            }}
            update={() => {
              this.setState({
                showEditBookTags: false,
              });
            }}
          />
        );
      }
      return false;
    };

    const renderBookCover = () => {
      if (this.state.showBookCover) {
        return (
          <BookCover
            user={this.user}
            book={this.state.story.book}
            show={this.state.showBookCover}
            onHide={this.actionBookCover}
            update={() => {
              this.loadSettings();
              this.loadBranchList();
              this.loadStory();
              this.newStorySteps();
              this.actionBookCover();
            }}
            disabled={(
              !(this.state.story && this.state.story.book)
              || (
                this.user.role !== 'admin'
                && this.state.story.book.book_role !== 'owner'
                && this.state.story.book.book_role !== 'editor'
                && this.state.story.book.book_role !== 'co-author'
              )
            )}
          />
        );
      }
      return null;
    };

    const renderChapterRestore = () => {
      if (!this.state.showChapterRestore) {
        return null;
      }

      return (
        <ChapterRestoreModal
          book={this.state.story.book}
          obj={this.state.story}
          show
          update={this.loadBranchList}
          onHide={() => {
            this.setState({
              showChapterRestore: false,
            });
          }}
        />
      );
    };

    const validatePanel = this.state.infoPanel || this.state.branchesListPanel || this.state.validatePanel ? 'main-panel-sm' : null;
    const previewPanel = this.state.previewPanel ? 'preview-panel' : null;
    const REACT_APP_LIVE = process.env.REACT_APP_LIVE !== undefined && process.env.REACT_APP_LIVE && process.env.REACT_APP_LIVE === 'true';
    const limits = this.state.limits && Object.entries(this.state.limits).length > 0 ? this.state.limits : undefined;
    const goLiveTitle = () => {
      if (this.state.story && (this.state.story.book && !!this.state.story.book.original)) {
        return 'Submit';
      }
      if (this.state.story && this.state.story.live_count === 0) {
        return 'Release in the app';
      }
      if (this.state.story && this.state.story.live_count !== 0) {
        return 'Update in the app';
      }
      return '...';
    };
    const livePromptTitle = () => {
      if (this.state.story.group === 'liveprompt' || this.state.story.group === 'prompt') {
        return 'Publish Prompt';
      }
      return goLiveTitle();
    };

    const page = {
      header: {
        title: this.state.story.title ? `Episode: ${this.state.story.title}` : `Episode # ${this.props.match.params.id}`,
        type: 'branches',
        settings: (user.role === 'admin') && 'branches',
        settingsItems: (user.role === 'admin') && [
          {
            title: 'Node Positioning',
            disabled: !this.state.modeEdit,
            action: () => {
              this.setState({
                NodePositioningModal: true,
              });
            },
          },
        ],
        contentRef: this.mainContent,
        wrapperFunc: () => this.onMouseMove,
        mainContentStyle: this.state.shiftDown ? 'content-branches-shift content-branches' : 'content-branches',
        mainContentPanel: ` ${validatePanel} ${previewPanel} `,
      },
      sidebar: {
        nav: [
          {
            title: 'Back to book',
            href: `/book/${this.state.story.bookId}`,
            variant: 'secondary',
            disabled: !this.state.story.bookId,
          },
          {
            title: 'Share emulator link',
            variant: 'success',
            action: () => {
              this.setState({
                shareModal: true,
              });
            },
            disabled: this.state.story && this.state.story.book && this.state.story.book.original,
          },
          REACT_APP_LIVE
          && {
            title: livePromptTitle(),
            action: () => {
              this.setState({
                GoLiveModal: true,
              });
            },
            loading: this.state.GoLiveLoading,
            variant: this.state.story.group === 'active' || this.state.story.group === 'prompt' ? 'success' : 'secondary',
            disabled:
              !(this.state.story.group === 'active' || this.state.story.group === 'prompt')
              || this.state.GoLiveLoading
              || (user.role !== 'admin' && ['viewer', 'editor'].includes(this.state.story.story_role))
              || !this.state.modeEdit
              || PremiumIpDisabledEdit(this.state.authUser.role, this.state.story.book, this.state.story.group)
              || PremiumIpDisabledApprovedEdit(this.state.authUser.role, this.state.story.book)
              || LiveEditDisabled(this.state.authUser.role, this.state.story.group)
            ,
          },
          {
            title: 'Create Node',
            variant: 'CreateNodeButton',
            branchesScale: this.state.branchesScale,
            addBranchActive: this.state.addBranchActive,
            modeEdit: !this.state.modeEdit
              || PremiumIpDisabledEdit(this.state.authUser.role, this.state.story.book, this.state.story.group)
              || PremiumIpDisabledApprovedEdit(this.state.authUser.role, this.state.story.book)
              || LiveEditDisabled(this.state.authUser.role, this.state.story.group),
            action: this.addBranches,
          },
          {
            title: 'Edit Story Tags',
            action: () => this.setState({ showEditBookTags: true }),
            variant: 'primary',
            disabled:
              user.role !== 'admin'
              && !(
                this.state.story.book
                && ['owner', 'editor', 'co-author'].includes(this.state.story.book.book_role))
            ,
          },
          {
            title: 'Locations',
            action: () => this.initLocations(),
            variant: 'primary',
            disabled: false,
            activeWizardStep: 8,
          },
          {
            title: 'Options',
            variant: 'Options',
            ref: this.optionsRef,
            id: this.props.match.params.id,
            user: this.state.authUser,
            update: () => this.loadStory(),
            limits,
            modeEdit: this.state.modeEdit
              || PremiumIpDisabledEdit(this.state.authUser.role, this.state.story.book, this.state.story.group)
              || PremiumIpDisabledApprovedEdit(this.state.authUser.role, this.state.story.book)
              || LiveEditDisabled(this.state.authUser.role, this.state.story.group),
          },
          {
            title: 'Characters',
            action: () => this.actionEditCharacters(),
            variant: 'primary',
            disabled: false,
          },
          {
            title: 'Cover',
            action: () => this.actionEditCover(),
            variant: 'primary',
            disabled: false,
          },
          {
            variant: 'space',
          },
          {
            title: 'Info',
            action: () => {
              this.setState((prevState) => ({
                infoPanel: !prevState.infoPanel,
              }));
            },
            variant: 'primary',
            disabled: false,
          },
          {
            title: this.validateBadge(),
            action: () => {
              this.setState((prevState) => ({
                lastEdit: null,
                validatePanel: !prevState.validatePanel,
              }));
            },
            variant: 'primary',
            disabled: false,
          },
          {
            title: !this.state.preview ? 'Play' : 'Close Play',
            action: () => this.setState((state) => ({ preview: !state.preview })),
            variant: 'primary',
            disabled: this.state.branch.length < 1,
            activeWizardStep: 10,
          },
          {
            variant: 'space',
          },

          {
            title: 'Help',
            action: () => {
              this.setState((prevState) => ({
                HelpPanel: !prevState.HelpPanel,
              }));
            },
            variant: 'primary',
          },

          {
            title: 'Search',
            action: () => {
              this.setState((prevState) => ({
                branchesListPanel: !prevState.branchesListPanel,
              }));
            },
            variant: 'primary',
          },
          {
            title: 'Snap to Grid',
            action: () => {
              this.setState((state) => ({ snapToGrid: !state.snapToGrid }));
            },
            variant: 'snapToGridCheckbox',
            id: 'snapToGrid',
            disabled: !this.state.modeEdit,
            value: this.state.snapToGrid,
          },

          {
            title: 'Show grid',
            action: () => {
              this.setState((state) => ({ showGrid: !state.showGrid }));
            },
            variant: 'showGridCheckBox',
            id: 'showGrid',
            disabled: !this.state.modeEdit,
            value: this.state.showGrid,
          },

          {
            action: (val) => {
              const a = 1 + (Number(val) - Number(this.state.branchesScale));
              let b = 1;
              let c = a > 1 ? val / (1 - (Number(val) - Number(this.state.branchesScale))) : a;
              if (a < 1 && val - a !== 0) {
                b = 1 / (1 + (this.state.branchesScale - 1));
                c = val;
              }
              if (val !== 1 && a > 1 && val - a !== 0) {
                b = 1 / (1 + (this.state.branchesScale - 1));
                c = val;
              }
              this.setState({
                branchesScale: val,
              }, () => {
                localStorage.setItem('zoom', val);
              });
              const obj = this.mainContent.current;
              obj.scrollTo((obj.scrollLeft * b) * c, (obj.scrollTop * b) * c);
            },
            variant: 'zoom',
            branchesScale: this.state.branchesScale,
          },
          {
            title: 'See Whole Episode',
            action: () => {
              const branches = this.state.branch;
              const maxX = branches.reduce((prev, curr) => (prev > curr.x ? prev : curr.x));
              const minX = branches.reduce((prev, curr) => (prev < curr.x ? prev : curr.x));
              const maxY = branches.reduce((prev, curr) => (prev > curr.y ? prev : curr.y));
              const minY = branches.reduce((prev, curr) => (prev < curr.y ? prev : curr.y));
              const box = this.mainContent.current.getBoundingClientRect();
              const val = Math.min(1, box.width / (maxX + minX + 200), box.height / (maxY + minY + 200));

              this.setState({
                branchesScale: val,
              });
              const obj = this.mainContent.current;
              obj.scrollTo(0, 0);
            },
            variant: 'primary',
            disabled: user.role !== 'admin' && this.state.story.story_role !== 'owner',
          },
          {
            title: 'Restore this version',
            action: () => this.actionChapterRestore(),
            variant: 'primary',
            disabled:
              (
                user.role !== 'admin'
                && this.state.story.story_role !== 'owner'
              )
              || !this.state.modeEdit
              || PremiumIpDisabledEdit(this.state.authUser.role, this.state.story.book, this.state.story.group)
              || PremiumIpDisabledApprovedEdit(this.state.authUser.role, this.state.story.book)
              || LiveEditDisabled(this.state.authUser.role, this.state.story.group),
            NotAdminPermissions: !(this.state.story && ['submitted', 'rejected', 'approved', 'pending', 'live', 'liveprompt', 'prompt'].includes(this.state.story.group)),
          },
          {
            title: 'Export',
            action: () => Export.exportStory(this.props.match.params.id, this.state.story.title),
            variant: 'primary',
            disabled: user.role !== 'admin' && this.state.story.story_role !== 'owner',
            NotAdminPermissions: !this.state.authUser || this.state.authUser.role !== 'admin',
          },
        ],
      },
    };
    const linkUrl = document.location.origin.replace('www.', '');
    const bookStyle = this.state.story.book && this.state.story.book.bookStyle;
    const bookStyleAlias = bookStyle && bookStyle.alias === 'arcana_style' ? 'arcana' : undefined;

    return (
      <>
        <PageWrapper
          {...this.props}
          page={page}
        >
          <ErrorAlert
            error={this.state.errorMsg}
            close={this.errorAlertClose}
          />

          <div
            className={cs(`content-branches-wrapper branchesScale_${this.state.branchesScale * 100}`)}
            style={{
              transform: `scale(${this.state.branchesScale})`,
              width: `${this.state.branchesWrapper.x}px`,
              height: `${this.state.branchesWrapper.y}px`,
            }}
            ref={this.mainContentWrapper}
          >

            <div className={cs(`gridBg ${this.state.showGrid ? 'gridBgShow' : ''}`)} />

            <div
              className={cs([
                'wrapItems',
                this.state.sourceNodeId ? 'activeConnection' : '',
                this.state.sourceNodeIdType === 'choice' ? 'activeConnectionChoice' : '',
                this.state.dragStart || this.state.shiftDown || this.state.branchOnNameEdit ? 'SelectableGroupDisabled' : null])}
            >

              <SelectableGroup
                ref={this.selectionRef}
                className="wrapItems wrapItems-selection"
                enableDeselect
                mixedDeselect
                resetOnStart={false}
                globalMouse={false}
                allowClickWithoutSelected={false}
                onSelectionFinish={this.handleSelectionFinish}
                ignoreList={['.not-selectable', '.DeselectAll-button']}
                disabled={this.state.dragStart || this.state.shiftDown || this.state.branchOnNameEdit || ['live', 'liveprompt'].includes(this.state.story.group)}
                delta={this.state.branchesScale}
                allowCtrlClick={this.state.ctrlDown}
              >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  ref={this.scrollRef}
                  className={`content-dbClickArea ${this.state.duplicateBranchs && this.state.duplicateBranchs.length > 0 ? ' cursor-point' : ''}`}
                  onClick={(e) => {
                    if (
                      this.state.duplicateBranchs
                      && this.state.duplicateBranchs.length > 0
                      && this.state.modeEdit
                    ) {
                      this.handleDoubleClickItem(e);
                    }
                    this.setState({
                      sourceNodeId: null,
                      destinationNodeId: null,
                    });
                  }}
                  onDoubleClick={(e) => {
                    if (this.state.modeEdit) {
                      this.handleDoubleClickItem(e);
                    }
                  }}
                />
                {
                  (this.state.selectionItems.length > 0)
                  && <DeselectAll className="DeselectAll-button" />
                }
                {this.branchesItems()}
              </SelectableGroup>
            </div>

            {this.branchesDragItem()}

            <div className={cs('wrapLines')}>
              {this.branchesLines()}
            </div>

            <div id="boxConnection" />

          </div>

          {this.state.savePositionLoading && (
          <Spinner
            animation="border"
            variant="primary"
            className="story-branches-spinner justify-content-center"
          />
          )}

          <div
            className={`modeAlert ${this.state.modeAdminStyle}`}
          >
            <Alert
              className={`${this.state.modeEditStyle}`}
              variant={!this.state.modeEdit ? 'info' : 'danger'}
            >
              <Form.Check
                custom
                type="checkbox"
                id="NodeEditMode"
                label={
                  !this.state.modeEdit
                    ? 'Node Read-Only Mode'
                    : 'Node Edit Mode'
                }
                checked={this.state.modeEdit}
                onChange={() => {
                  this.setState((prevState) => {
                    const modeEdit = !prevState.modeEdit;
                    localStorage.setItem(`modeEdit-${prevState.story.internal_uuid}`, !modeEdit);
                    localStorage.setItem('modeEdit', !modeEdit);
                    return {
                      modeEdit,
                    };
                  });
                }}
              />
            </Alert>
          </div>

          {this.state.NodePositioningModal
          && (
          <NodePositioningModal
            show={this.state.NodePositioningModal}
            branch={this.state.branch}
            storyId={this.props.match.params.id}
            onHide={() => {
              this.setState({
                NodePositioningModal: false,
              });
            }}
            update={this.loadBranchList}
          />
          )}

          {isCTAsPreviewEnabled() ? (
            <DetachablePreview
              show={this.state.preview}
              update={(val) => {
                this.setState({
                  previewPanel: val,
                });
              }}
              storyUuid={this.state.story.internal_uuid}
              bookStyle={bookStyleAlias}
              onClose={previewClose}
              store={this.storePublicInterface.internalStore}
              onPreviewExit={handlePreviewExit}
              isBranchDetailsCacheDisabled
              displayAnalyticsOption={isAnalyticsVisible ? 'percentage' : 'hide'}
              showAnalyticsPanel={isAnalyticsVisible}
            />
          ) : (
            <LegacyDetachablePreview
              show={this.state.preview}
              update={(val) => {
                this.setState({
                  previewPanel: val,
                });
              }}
              storyUuid={this.state.story.internal_uuid}
              setLastEdit={this.setLastEdit}
              lastEdit={this.state.lastEdit}
              onClose={previewClose}
            />
          )}

          {
            (this.state.duplicateBranchs && this.state.duplicateBranchs.length > 0)
            && (
            <Alert
              className="duplicateBranchsAlert"
              variant="dark"
            >
              <img src={IconCursor} alt="" />
              click to paste
            </Alert>
            )
          }

          <HelpSection
            onDeleteSuccess={this.handleBranchDelete}
            HelpPanel={this.state.HelpPanel}
            closeHelpPanel={() => {
              this.setState({
                HelpPanel: false,
              });
            }}
            user={this.state.authUser}
            branch={this.state.branch}
            update={this.loadBranchList}
            duplicate={this.duplicateBranchs}
            storyId={this.props.match.params.id}
            selectionItems={this.state.selectionItems}
            sharedSettingsUpdate={this.sharedSettingsUpdate}
            disabled={(
                  this.state.ShareLoading
                  || (user.role !== 'admin' && this.state.story.story_role === 'viewer')
                  || !this.state.modeEdit
                  || PremiumIpDisabledEdit(this.state.authUser.role, this.state.story.book, this.state.story.group)
                  || PremiumIpDisabledApprovedEdit(this.state.authUser.role, this.state.story.book)
              )}
          />
        </PageWrapper>

        {addBranch()}
        {listLocations()}
        {storyNotFound()}
        {editBookTags()}

        <SharedSettingsPanel
          user={this.state.authUser}
          storyId={this.props.match.params.id}
          story={this.state.story}
          selectedItems={this.state.selectionItems}
          show={this.state.sharedSettingsPanel}
          onHide={this.sharedSettingsPanelHide}
          update={this.loadBranchList}
          limits={this.state.limits}
        />

        <ValidatePanel
          lastEdit={this.state.lastEdit}
          branch={this.state.branch}
          storyId={this.props.match.params.id}
          data={this.state.editBranchData}
          stepTypes={this.state.stepTypes}
          show={this.state.validatePanel}
          onHide={this.validatePanelHide}
          validateAction={this.validateAction}
          errCount={this.errCount}
        />

        <InfoPanel
          lastEdit={this.state.lastEdit}
          branch={this.state.branch}
          story={this.state.story}
          storyId={this.props.match.params.id}
          data={this.state.editBranchData}
          stepTypes={this.state.stepTypes}
          show={this.state.infoPanel}
          onHide={this.infoPanelHide}
          validateAction={this.validateAction}
          isAnalyticsVisible={this.state.isAnalyticsVisible}
        />

        <BranchesListPanel
          lastEdit={this.state.lastEdit}
          branch={this.state.branch}
          story={this.state.story}
          storyId={this.props.match.params.id}
          data={this.state.editBranchData}
          stepTypes={this.state.stepTypes}
          show={this.state.branchesListPanel}
          onHide={this.branchesListPanelHide}
          validateAction={this.validateAction}
          actionEditBranch={this.initAddBranch}
          showAnalytics={this.state.isAnalyticsVisible}
        />

        {this.characters()}

        {this.editCover()}

        <Modal
          show={this.state.GoLiveModal}
          onHide={() => {
            this.setState({
              GoLiveModal: false,
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{livePromptTitle()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              (
                this.state.story
                && !(this.state.story.book && !!this.state.story.book.original)
                && this.state.story.live_count === 0
                && !this.user.firstPublishData
              )
              && (
              <Alert variant="warning">
                Your story is going live on our mobile app! Make sure to register
                your email on the Dorian app so you can collect followers and
                patrons and interact with your fans directly! If you don’t have
                our app, download Dorian: the future of fiction! on
                {' '}
                <Alert.Link
                  href="https://apps.apple.com/us/app/dorian-the-future-of-fiction/id1529215455"
                  target="_blank"
                >
                  Apple App Store
                </Alert.Link>
                {' '}
                or
                {' '}
                <Alert.Link
                  href="https://play.google.com/store/apps/details?id=com.dorian.playtogether"
                  target="_blank"
                >
                  Google Play
                </Alert.Link>
              </Alert>
              )
            }
            Do you really want to
            {' '}
            {livePromptTitle()}
            {' '}
            this episode?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  GoLiveModal: false,
                });
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => this.updateGoLive()}
              disabled={this.state.GoLiveLoading}
            >
              {this.state.GoLiveLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {livePromptTitle()}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.shareModal}
          onHide={() => {
            this.setState({
              shareModal: false,
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Share emulator link</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {this.state.copyText
              ? (
                <Alert variant="success">
                  copy to clipboard
                </Alert>
              )
              : null}

            <InputGroup className="mb-3">
              <FormControl
                id="linkShare"
                aria-label="Share"
                defaultValue={`${linkUrl}/play/${this.state.story.internal_uuid}`}
              />
              <InputGroup.Append>
                <Button
                  variant="dark"
                  onClick={this.handleCopy}
                >
                  Copy
                </Button>
              </InputGroup.Append>
            </InputGroup>

          </Modal.Body>
        </Modal>

        <SaveAsTemplate
          limits={this.state.limits}
          show={this.state.saveTemplateModal}
          story={this.state.story}
          onSuccess={() => {
            this.loadStory();
          }}
          onValidationError={() => {
            this.setState({
              saveTemplateModal: false,
              validatePanel: true,
            });
          }}
          onHide={() => {
            this.setState({
              saveTemplateModal: false,
            });
            this.loadStory();
          }}
        />

        {renderBookCover()}
        {renderChapterRestore()}
      </>
    );
  }
}
