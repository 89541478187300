import React, { Component } from 'react';
import { NodeEditContext } from './NodeEditContext';

export class ContextProvider extends Component {
  constructor(...args) {
    super(...args);
    const {
      traitsTypes,
      stepsType,
      branches,
      answerType,
      modifiersTypes,
      storyRecommendations,
      tagsTypes,
      requiresTypes,
      currentBranchId,
      characterExpression,
      characters,
      currStatsInfo,
      memoryBankSlots,
    } = this.props;

    this.state = {
      branches,
      currentBranchId,
      characterExpression,
      tagsTypes,
      modifiersTypes,
      characters,
      stepsType,
      requiresTypes,
      traitsTypes,
      answerType,
      currStatsInfo,
      storyRecommendations,
      memoryBankSlots,
    };
  }

  componentDidUpdate(prevProps) {
    Object.keys(this.state).forEach((key) => {
      // eslint-disable-next-line react/destructuring-assignment,no-prototype-builtins
      if (this.props.hasOwnProperty(key) && (prevProps[key] !== this.props[key])) {
        const obj = {};
        // eslint-disable-next-line react/destructuring-assignment
        obj[key] = this.props[key];
        this.setState(obj);
      }
    });
  }

  render() {
    const { children } = this.props;

    return (
      <NodeEditContext.Provider value={this.state}>
        {children}
      </NodeEditContext.Provider>
    );
  }
}
