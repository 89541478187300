import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Spinner,
} from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { api } from '../../api';

export class LostPasswordForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      validated: null,
      email: '',
      error: '',
      loading: false,
      redirect: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location.hash !== location.hash) {
      this.setState({
        validated: null,
        email: '',
        error: '',
        loading: false,
      });
    }
  }

  handleSubmit = (event) => {
    this.setState({ loading: true });
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    if (validated === false) {
      this.setState({ loading: false });
      event.stopPropagation();
    } else {
      const { email } = this.state;
      const formattedEmail = email && email.trim().toLowerCase();
      const createTemplate = JSON.parse(localStorage.getItem('createTemplate'));
      if (createTemplate) {
        createTemplate.lostPassword = true;
      }

      api.post('/v1/users/resetpassword', { email: formattedEmail, createTemplate })
        .catch((error) => {
          this.setState({
            error: error.response?.data?.error ? error.response.data.error : error.message,
            loading: false,
          });
        })
        .then((res) => {
          if (res && res.status === 201) {
            localStorage.setItem('signUpEmail', formattedEmail);
            localStorage.setItem('createTemplate', JSON.stringify(createTemplate));
            this.setState({
              error: false,
              loading: false,
              redirect: '/reset',
            });
          }
        });
    }
    this.setState({ validated: true });
    event.stopPropagation();
  };

  handleChange = (event) => {
    this.setState({
      email: event.target.value,
      error: '',
    });
  };

  renderError() {
    const { error, loading } = this.state;

    if (error) {
      return (
        <Alert
          show
          variant="danger"
        >
          {error}
        </Alert>
      );
    } if (loading) {
      return (
        <Alert
          show
          variant="success"
        >
          Loading...
        </Alert>
      );
    }
    return null;
  }

  render() {
    const { redirect, loading, validated } = this.state;

    if (redirect) {
      return (<Redirect to={redirect} />);
    }

    return (
      <>
        <p className="text-center">
          Please enter your email address. You will receive an email
          message with instructions on how to reset your password.
        </p>

        {this.renderError()}

        <Form
          noValidate
          validated={validated}
          onSubmit={this.handleSubmit}
        >

          <Form.Row>
            <Form.Group
              as={Col}
              md={12}
              controlId="formUserName"
              className="mt-input"
            >
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                name="email"
                onChange={this.handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid Email.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Col md={12} className="text-center">
              <Button
                variant="outline-primary"
                type="submit"
                className="mt-3 btnMainPage"
              >
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Submit
              </Button>
            </Col>
          </Form.Row>

          <p className="text-center pt-2 mainPageLinks-nav">
            <Link
              className="mr-2 mainPageLinks"
              to="#sign-in"
            >
              Sign in
            </Link>
            <Link
              className="ml-2 mainPageLinks"
              to="/write-with-us-app"
            >
              Write with us
            </Link>
          </p>
        </Form>
      </>
    );
  }
}
