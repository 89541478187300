import React, { useState } from 'react';
import {
  Button, FormControl,
} from 'react-bootstrap';
import { useDebouncedCallback } from 'use-debounce';
import classNames from './DebouncedSearch.module.scss';

export function DebouncedSearch({ initialValue, onSearchSubmit }) {
  const [searchString, setSearchString] = useState(initialValue);

  const onSearchSubmitDebounced = useDebouncedCallback(
    (newSearchString) => onSearchSubmit(newSearchString),
    1000,
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearchSubmit(searchString);
  };

  const onChangeSearchString = (event) => {
    const newSearchString = event.target.value;
    setSearchString(newSearchString);
    onSearchSubmitDebounced(newSearchString);
  };

  return (
    <div className="search-form">
      <FormControl
        type="search"
        className="search-form_input"
        size="sm"
        placeholder="Type search substring"
        aria-label="Search"
        value={searchString}
        onChange={onChangeSearchString}
      />
      <Button
        className={classNames.button}
        type="submit"
        size="sm"
        variant="secondary"
        onClick={handleSubmit}
      >
        Search
      </Button>
    </div>
  );
}
