import React, { Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { isCTAsPreviewEnabled } from '../../helpers/runtimeEnv';
import { baseURL } from '../api';
import { FeaturedStories } from '../FeaturedStories';
import { AnswerModifiersTypes } from '../pages/AnswerModifiersTypes';
import { AnswerTypes } from '../pages/AnswerTypes';
import { Article } from '../pages/Article';
import { Articles } from '../pages/Articles';
import { AudioLibraryPage } from '../pages/AudioLibraryPage/AudioLibraryPage';
import { AvatarAssets } from '../pages/AvatarAssets';
import { BackgroundsPage } from '../pages/Backgrounds';
import { BackgroundTags } from '../pages/BackgroundTags';
import { Book } from '../pages/Book';
import { BookActivity } from '../pages/BookActivity';
import { BookCreateLink } from '../pages/BookCreateLink';
import { BookPerformance } from '../pages/BookPerformance';
import { DailyReads } from '../pages/BookPerformance/DailyReads';
import { EpisodeDailyReads } from '../pages/BookPerformance/EpisodeDailyReads';
import { BookPrompts } from '../pages/BookPrompts';
import { Books } from '../pages/Books';
import { CharacterArtLibrary } from '../pages/CharacterArtLibrary';
import { ClientVariablesPage } from '../pages/ClientVariablesPage/ClientVariablesPage';
import { Documents } from '../pages/Documents';
import { EventsRouter } from '../pages/Events/EventsRouter';
import { Expressions } from '../pages/Expressions';
import { FanSignups } from '../pages/FanSignups';
import { ApprovedFan } from '../pages/FanSignups/ApprovedFan';
import { Genre } from '../pages/Genre';
import { GlobalInvitationStatus } from '../pages/GlobalInvitationStatus';
import { Home } from '../pages/Home';
import { ConfirmTerm } from '../pages/Home/ConfirmTerm';
import { PrivacyPolicyPage } from '../pages/Home/PrivacyPolicy';
import { CopyrightPolicyPage } from '../pages/Home/PrivacyPolicy/CopyrightPolicyPage';
import { TermsOfUsePage } from '../pages/Home/PrivacyPolicy/TermsOfUsePage';
import { SignUpPage } from '../pages/Home/SignUp';
import { WriteWithUs } from '../pages/Home/WriteWithUs';
import { Logout } from '../pages/Logout';
import { MailTemplates } from '../pages/MailTemplates';
import { Maintenance } from '../pages/Maintenance';
import { News } from '../pages/News';
import { PageNotFound } from '../pages/PageNotFound';
import { Payout } from '../pages/Payout';
import { PlayPreview } from '../pages/PlayPreview';
import { Profile } from '../pages/Profile';
import { RecentlyUploaded } from '../pages/RecentlyUploaded';
import { Search } from '../pages/Search';
import { SettingsPreferences } from '../pages/SettingsPreferences';
import { SettingsTextLimits } from '../pages/SettingsTextLimits';
import { SignUpCodeRequests } from '../pages/SignUpCodeRequests';
import { SignUpCodes } from '../pages/SignUpCodes';
import { StatTypes } from '../pages/StatTypes';
import { StoryBranches } from '../pages/StoryBranches';
import { ChapterRequirements } from '../pages/StoryBranches/Options/ChapterRequirements';
import { StoryCreation } from '../pages/StoryCreation';
import { StoryCreationDate } from '../pages/StoryCreationDate';
import { StoryPreview } from '../pages/StoryPreview';
import { StoryTags } from '../pages/StoryTags';
import { TagTypes } from '../pages/TagTypes';
import { Users } from '../pages/Users';
import { UsersNotApproved } from '../pages/UsersNotApproved';
import { AvatarTool } from '../ui/AvatarTool';
import { MakeRouteWithSubRoutes } from './makeRouteWithSubRoutes';

function StoryRedirect(props) {
  const { match } = props;
  const to = `/stories/${match.params.id}/branches`;
  return (<Redirect to={to} />);
}

function GetRedirectConfig() {
  switch (baseURL) {
    case 'https://authoringtoolapi.4dorian.net/':
      return {
        enabled: true,
        url: 'https://home.dorian.live/',
      };
    case 'https://rev.4dorian.net/':
      return {
        enabled: false,
        url: 'https://dorian.squarespace.com/',
      };
    default:
      return {
        enabled: false,
        url: 'https://dorian.squarespace.com/',
      };
  }
}

function LandingPageRedirect(props) {
  const config = GetRedirectConfig();
  if (!config.enabled) {
    return <Home {...props} />;
  }
  const { history } = props;
  const tab = history.location.hash;
  if (
    tab === '#info-collect'
    || tab === '#info-use'
    || tab === '#info-shared'
    || tab === '#cookies-tracking'
    || tab === '#social-logins'
    || tab === '#third-party'
    || tab === '#info-time-kept'
    || tab === '#info-safety'
    || tab === '#info-minors'
    || tab === '#privacy-rights'
    || tab === '#do-not-track'
    || tab === '#do-not-track'
    || tab === '#privacy-updates'
    || tab === '#contact'
    || tab === '#write-with-us'
    || tab === '#sign-in'
    || tab === '#lost-password'
    || tab === '#privacy-policy'
    || tab === '#congratulations'
    || tab === '#terms-of-use'
    || tab === '#copyright-policy') {
    return <Home {...props} />;
  }
  window.location.href = config.url;
  return null;
}

window.$traits = process.env.REACT_APP_TRAITS && process.env.REACT_APP_TRAITS === 'true';

const playPreviewRoute = {
  path: '/play/:uuid',
  component: isCTAsPreviewEnabled() ? PlayPreview : StoryPreview,
};
const routesNotLoged = [
  {
    path: '/',
    exact: true,
    component: LandingPageRedirect,
  },
  {
    path: '/home',
    component: LandingPageRedirect,
  },
  playPreviewRoute,
  {
    path: '/invite',
    search: '?token=id',
    component: WriteWithUs,
  },
  {
    path: '/reset',
    search: '?token=token',
    component: Home,
  },
  {
    path: '/setpassword',
    search: '?token=token',
    component: Home,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicyPage,
  },
  {
    path: '/terms-of-use',
    component: TermsOfUsePage,
  },
  {
    path: '/copyright-policy',
    component: CopyrightPolicyPage,
  },
  {
    path: '/write-with-us',
    component: WriteWithUs,
  },
  {
    path: '/write-with-us-app',
    component: WriteWithUs,
  },
  {
    path: '/create/template/:id',
    component: BookCreateLink,
  },
  {
    path: '/signup',
    component: SignUpPage,
  },
  {
    path: '**',
    component: PageNotFound,
  },
];

const routesLoged = [
  {
    path: '/',
    exact: true,
    component: News,
  },
  {
    path: '/search',
    component: Search,
  },
  {
    path: '/home',
    component: News,
  },
  {
    path: '/mystories',
    component: News,
  },
  {
    path: '/create/template/:id',
    component: BookCreateLink,
  },
  {
    path: '/books',
    component: Books,
  },
  {
    path: '/verify',
    search: '?token=token',
    component: Books,
  },
  {
    path: '/articles/page/:id',
    component: Articles,
  },
  {
    path: '/articles',
    component: Articles,
  },
  {
    path: '/article/:id',
    component: Article,
  },
  {
    path: '/book/:id',
    component: Book,
  },
  {
    path: '/stories/:id/branches',
    component: StoryBranches,
  },
  {
    path: '/stories/:id',
    component: StoryRedirect,
  },
  {
    path: '/avatar-tool',
    component: AvatarTool,
  },
  {
    path: '/profile',
    component: Profile,
  },
  playPreviewRoute,
  {
    path: '/fan',
    component: FanSignups,
  },
  {
    path: '/performance',
    component: BookPerformance,
  },
  {
    path: '/documents/:id',
    component: Documents,
  },
  {
    path: '/documents/',
    component: Documents,
  },
  {
    path: '/payout',
    component: Payout,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicyPage,
  },
  {
    path: '/terms-of-use',
    component: TermsOfUsePage,
  },
  {
    path: '/copyright-policy',
    component: CopyrightPolicyPage,
  },
  {
    path: '/logout',
    component: Logout,
  },
  {
    path: '/setpassword',
    search: '?token=token',
    component: Home,
  },
];

const routesLogedAdmin = [
  {
    path: '/',
    exact: true,
    component: News,
  },
  {
    path: '/search',
    component: Search,
  },
  {
    path: '/home',
    component: News,
  },
  {
    path: '/mystories',
    component: News,
  },
  {
    path: '/create/template/:id',
    component: BookCreateLink,
  },
  {
    path: '/books',
    component: Books,
  },
  {
    path: '/activity',
    component: BookActivity,
  },
  {
    path: '/performance/dailyreads/:book_id',
    component: DailyReads,
  },
  {
    path: '/performance/episodedailyreads/:story_id',
    component: EpisodeDailyReads,
  },
  {
    path: '/performance',
    component: BookPerformance,
  },
  {
    path: '/creation/:date',
    component: StoryCreationDate,
  },
  {
    path: '/creation',
    component: StoryCreation,
  },
  {
    path: '/articles/page/:id',
    component: Articles,
  },
  {
    path: '/articles',
    component: Articles,
  },

  {
    path: '/mail-templates',
    component: MailTemplates,
  },
  {
    path: '/article/:id',
    component: Article,
  },
  {
    path: '/verify',
    search: '?token=token',
    component: Books,
  },
  {
    path: '/book/:id',
    component: Book,
  },
  {
    path: '/stories/:id/branches',
    component: StoryBranches,
  },
  {
    path: '/stories/:id',
    component: StoryRedirect,
  },
  {
    path: '/users',
    component: Users,
  },
  {
    path: '/usersnotapproved',
    component: UsersNotApproved,
  },
  {
    path: '/stats',
    component: !window.$traits ? StatTypes : PageNotFound,
  },
  {
    path: '/tags',
    component: TagTypes,
  },
  {
    path: '/modifiers',
    component: AnswerModifiersTypes,
  },
  {
    path: '/answertypes',
    component: AnswerTypes,
  },
  {
    path: '/prompts',
    component: BookPrompts,
  },
  {
    path: '/genres',
    component: Genre,
  },
  {
    path: '/expressions',
    component: Expressions,
  },
  {
    path: '/textlimits',
    component: SettingsTextLimits,
  },
  {
    path: '/preferences',
    component: SettingsPreferences,
  },
  {
    path: '/chapter-requirements',
    component: ChapterRequirements,
  },
  {
    path: '/storytags',
    component: StoryTags,
  },
  {
    path: '/backgroundtags',
    component: BackgroundTags,
  },
  {
    path: '/signupcodes',
    component: SignUpCodes,
  },
  {
    path: '/signupcodesrequests',
    component: SignUpCodeRequests,
  },
  {
    path: '/backgrounds',
    component: BackgroundsPage,
  },
  {
    path: '/character-art-library',
    component: CharacterArtLibrary,
  },
  {
    path: '/audio-library',
    component: AudioLibraryPage,
  },
  {
    path: '/maintenance',
    component: Maintenance,
  },
  {
    path: '/avatar-tool',
    component: AvatarTool,
  },
  {
    path: '/profile',
    component: Profile,
  },
  playPreviewRoute,
  {
    path: '/fan',
    component: FanSignups,
  },
  /*
  {
    path: '/beta-signup-limits',
    component: BetaSignupLimits,
  },
   */
  {
    path: '/approvedfan',
    component: ApprovedFan,
  },
  {
    path: '/avatarassets',
    component: AvatarAssets,
  },
  {
    path: '/global-invitation-status',
    component: GlobalInvitationStatus,
  },
  {
    path: '/featured-stories',
    component: FeaturedStories,
  },
  {
    path: '/documents/:id',
    component: Documents,
  },
  {
    path: '/documents/',
    component: Documents,
  },
  {
    path: '/payout',
    component: Payout,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicyPage,
  },
  {
    path: '/terms-of-use',
    component: TermsOfUsePage,
  },
  {
    path: '/copyright-policy',
    component: CopyrightPolicyPage,
  },
  {
    path: '/logout',
    component: Logout,
  },
  {
    path: '/recently-uploaded',
    component: RecentlyUploaded,
  },
  {
    path: '/client-variables',
    component: ClientVariablesPage,
  },
  {
    path: '/events',
    component: EventsRouter,
  },
  {
    path: '**',
    component: PageNotFound,
  },
];

const routesConfirmTerm = [
  {
    path: '/:id',
    component: ConfirmTerm,
  },
  {
    path: '/',
    exact: true,
    component: ConfirmTerm,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicyPage,
  },
  {
    path: '/terms-of-use',
    component: TermsOfUsePage,
  },
  {
    path: '/copyright-policy',
    component: CopyrightPolicyPage,
  },
  {
    path: '/write-with-us-app',
    component: WriteWithUs,
  },
  {
    path: '/create/template/:id',
    component: BookCreateLink,
  },
];

export function Routes(props) {
  const { auth } = props;
  const authenticated = auth.isAuthenticated();
  let documents = [];
  try {
    documents = JSON.parse(localStorage.getItem('documents'));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  let routes = routesNotLoged;
  if (authenticated === 'true') {
    const user = auth.getUser();
    if (user.actions?.includes('backgrounds')) {
      routesLoged.push({
        path: '/backgrounds',
        component: BackgroundsPage,
      });
    }
    if (user.actions?.includes('characters')) {
      routesLoged.push({
        path: '/character-art-library',
        component: CharacterArtLibrary,
      });
    }
    if (!user.email) {
      routesLoged.push({
        path: '/write-with-us-app',
        component: WriteWithUs,
      });
    }
    routes = user.role !== 'admin' ? routesLoged : routesLogedAdmin;
    routes.push({
      path: '**',
      redirectTo: '/',
      component: PageNotFound,
    });
    routes = (documents && documents.length > 0) || !user.accepted ? routesConfirmTerm : routes;
  }

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {
          routes.map(
            // eslint-disable-next-line react/no-array-index-key
            (route, index) => <MakeRouteWithSubRoutes key={index} {...route} {...props} />,
          )
        }
      </Switch>
    </Suspense>
  );
}
