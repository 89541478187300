import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import './AnalyticsData.css';

export class AnalyticsData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: 'hide',
    };
  }

  handleChange = (e) => {
    this.setState({
      checked: e.target.value,
    }, () => {
      const { checked } = this.state;
      const { update } = this.props;

      localStorage.setItem('AnalyticsData', checked);
      update();
    });
  };

  render() {
    const { checked } = this.state;

    return (
      <div className="formAnalyticsData">
        <Form.Label>Analytics Data:</Form.Label>
        <Form.Check
          inline
          name="AnalyticsData"
          label="Counts"
          type="radio"
          id="inline-Counts"
          value="counts"
          checked={(checked === 'counts')}
          onChange={this.handleChange}
        />
        <Form.Check
          inline
          name="AnalyticsData"
          label="Percentage"
          type="radio"
          value="percentage"
          id="inline-Percentage"
          checked={(checked === 'percentage')}
          onChange={this.handleChange}
        />
        <Form.Check
          inline
          name="AnalyticsData"
          label="Hide"
          type="radio"
          value="hide"
          id="inline-Hide"
          checked={(checked === 'hide')}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
