import axios from 'axios';
import queryString from 'query-string';
import React, { Component } from 'react';
import {
  Accordion, Col, Row, Spinner,
} from 'react-bootstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Arr from '../../../assets/images/arr.svg';
import { api } from '../../api';
import { Paginator } from '../../ui/Paginator';
import { BookItem } from './BookItem';
import './books.scss';

const { CancelToken } = axios;

const SortableItem = SortableElement(({
  object, i, itemIndex, showIndex, ...props
}) => (
  <BookItem
    {...props}
    disabled={props.disabledRow}
    obj={object}
    itemIndex={i}
    index={itemIndex}
  />
));

const SortableList = SortableContainer(({ items, ...props }) => {
  // eslint-disable-next-line no-param-reassign
  items = items.sort((a, b) => a.pos - b.pos);

  if (items.length < 1) {
    return (
      <div className="books-list">
        <Row className="books-list-item">
          <Col>
            <i
              className="indicator-disabled"
              style={{ backgroundColor: props.obj.color }}
            />
            No stories
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div className={`books-list ${props.dragBookItem.groupId === props.obj.id ? 'books-list-active' : ''}`}>
      {items.map((value, index) => (
        <SortableItem
          disabled={props.disabledRow}
          /* eslint-disable-next-line react/no-array-index-key */
          key={`item-${index}`}
          index={index}
          itemIndex={index}
          object={value}
          i={((props.current_page - 1) * props.limit) + index}
          {...props}
        />
      ))}
    </div>
  );
});

export class BookGroups extends Component {
  constructor(props) {
    super(props);
    const {
      obj,
      auth,
    } = this.props;

    this.state = {
      loading: true,
      // eslint-disable-next-line react/no-unused-state
      hover: false,
      // eslint-disable-next-line react/no-unused-state
      disabledRow: false,
      books: [],
      activeKey: obj.id,
      limit: 10,
      offset: 0,
      totalCount: 0,
      current_page: 1,
    };

    this.user = auth.getUser();
  }

  // eslint-disable-next-line react/sort-comp
  componentDidUpdate(prevProps, prevState) {
    const {
      loading,
      location,
      search,
    } = this.props;

    const { current_page: currentPage } = this.state;

    if (prevProps.loading !== loading
      || prevState.current_page !== currentPage
      || prevProps.search !== search
      || (
        location
        && location.search
        && (location.search !== prevProps.location.search)
      )
    ) {
      this.loadData();
    }
  }

  componentDidMount() {
    const { obj } = this.props;

    if (sessionStorage.getItem(`booksAccordion_${obj.id}`)) {
      const value = JSON.parse(sessionStorage.getItem(`booksAccordion_${obj.id}`));
      this.setState({
        activeKey: value.activeKey,
      });
    }

    if (sessionStorage.getItem(`books_group_${obj.id}`)) {
      const value = JSON.parse(sessionStorage.getItem(`books_group_${obj.id}`));
      this.setState(() => value, () => {
        this.loadData();
      });
    } else {
      this.loadData();
    }
  }

  loadData() {
    if (this.loadCancel) {
      this.loadCancel();
    }

    this.setState({
      loading: true,
    });

    const {
      obj,
      search,
      location,
    } = this.props;

    const {
      limit,
      offset,
      current_page: currentPage,
    } = this.state;

    const params = {
      groupId: obj.id,
      offset,
      limit,
    };
    if (search) {
      params.search = search;
    }
    const values = queryString.parse(location.search);

    if (values && values.story_list && values.story_list !== 'all') {
      params.story_list = values.story_list;
    }

    api.get('/v1/books/', {
      params,
      cancelToken: new CancelToken((c) => {
        this.loadCancel = c;
      }),
    }).then((res) => {
      if (currentPage !== 1 && (Math.ceil(res.data.totalCount / limit) - currentPage) < 0) {
        this.setState({
          offset: 0,
          current_page: 1,
          totalCount: res.data.totalCount,
        }, () => {
          this.loadData();
        });
      } else {
        this.setState({
          books: res.data.books,
          totalCount: res.data.totalCount,
          loading: false,
        });
      }
    }).catch((error) => {
      if (!axios.isCancel(error)) {
        this.setState({
          loading: false,
        });
      }
    });
  }

  render() {
    const {
      obj,
      editTitle,
      dragBookItem,
      dragBook,
      groupHover,
    } = this.props;

    const {
      activeKey,
      loading,
      limit,
      totalCount,
      books,
      current_page: currentPage,
    } = this.state;

    const hoverGroup = dragBookItem.groupId
      && (dragBookItem.groupId !== obj.id)
      ? 'd-block' : 'd-none';

    const disabledRow = editTitle || (this.user.role !== 'admin');

    return (
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <div
        className="books-list-box"
        onMouseOver={() => {
          groupHover('over', obj.id);
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            hover: true,
          });
        }}
        onMouseOut={() => {
          groupHover('out', null);
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            hover: false,
          });
        }}
      >
        <Accordion defaultActiveKey={obj.id}>
          <Row className="books-list-item books-list-header d-flex flex-nowrap justify-content-between">
            <Col
              sm={4}
              xl={5}
              className="pr-0"
            >
              <h3
                className="list-column-header"
                style={{ color: obj.color }}
              >
                <Accordion.Toggle
                  className="list-column-header-toggle"
                  eventKey={obj.id}
                  style={{ backgroundColor: obj.color }}
                  onClick={() => {
                    const value = { activeKey: Number(activeKey) > 0 ? 0 : obj.id };
                    sessionStorage.setItem(`booksAccordion_${obj.id}`, JSON.stringify(value));
                    this.setState({
                      activeKey: Number(activeKey) > 0 ? 0 : obj.id,
                    });
                  }}
                >
                  <img
                    className={`list-column-header-toggle-img ${activeKey ? 'active' : 'inactive'}`}
                    src={Arr}
                    alt=">"
                  />
                </Accordion.Toggle>
                {obj.title}
                {' '}
                (
                {totalCount || 0}
                )
              </h3>
            </Col>
            {(books && books.length > 0)
            && (
            <>
              <Col
                sm={4}
                xl={4}
                className={`px-0 text-center books-list-additionalBox long ${activeKey ? 'visible' : 'invisible'}`}
              >
                <h4 className="books-list-additionalBox-title">Writers</h4>
              </Col>
              <Col sm={4} xl={3} className="px-0 books-list-actionBox" />
            </>
            )}
          </Row>
          <Accordion.Collapse
            eventKey={activeKey}
            className={Number(activeKey) === 0 ? '' : 'show'}
          >
            <>
              <div className={`hoverGroup ${hoverGroup}`} />

              {loading
              && (
              <div className="text-center boxSpinner">
                <Spinner
                  variant="primary"
                  animation="border"
                  className="loadingSpinner justify-content-center"
                />
              </div>
              )}

              <SortableList
                {...this.props}
                currentItem={this}
                helperClass="sortItem"
                distance={1}
                lockAxis="y"
                disabledRow={disabledRow}
                items={books}
                limit={limit}
                current_page={currentPage}
                totalCount={totalCount}
                onSortStart={(e) => {
                  dragBook('start', books[e.index]);
                }}
                onSortEnd={(e) => {
                  dragBook('stop', books[e.oldIndex]);
                }}
                updateBooks={(index, value) => {
                  books[index].title = value;
                  this.setState({
                    books,
                  });
                }}
              />

              <Paginator
                limit={limit}
                totalCount={totalCount}
                current_page={currentPage}
                update={(val, number) => {
                  const value = { offset: val, current_page: number };
                  sessionStorage.setItem(`books_group_${obj.id}`, JSON.stringify(value));
                  this.setState(() => value);
                }}
              />

            </>
          </Accordion.Collapse>

        </Accordion>
      </div>
    );
  }
}
