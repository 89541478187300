import classNames from 'classnames/bind';
import React from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { MemorySlotsBase, MemorySlotsValue } from 'components/pages/Book/MemoryBank/memoryBankTypes';
import { Character } from 'dorian-shared/types/character/Character';
import { MemorySlotCompareForm, MemorySlotFormValue } from './MemorySlotCompareForm';
import styles from './Steps.scss';
import { StepCheck } from './StepTypeCheck';

const cs = classNames.bind(styles);

type Requirement = {
  check: StepCheck,
  visualTreatmentIfNotMet: string,
}

export const VisualTreatmentIfNotMet: Record<string, string> = {
  Disable: 'Disable',
  Hide: 'Hide',
};

const getRequirementsVariableValues = (requirement?: Requirement) => {
  const requirementValues = {
    variableId: 0,
    operator: '',
    check: '' as MemorySlotsValue,
    visualEffect: '',
  };

  if (requirement) {
    if (requirement.check) {
      requirementValues.variableId = requirement.check.variableId
        ? Number(requirement.check.variableId) : 0;
      requirementValues.operator = requirement.check.operator ? requirement.check.operator : '';
      requirementValues.check = requirement.check.value ? requirement.check.value : '';
    }
    requirementValues.visualEffect = requirement.visualTreatmentIfNotMet;
  }
  return requirementValues;
};

interface AnswerFormMemorySlotRequirementProps {
  requirement?: Requirement,
  isSlotRequirementActive: boolean,
  answerIndex: number,
  memoryBankSlots: MemorySlotsBase[],
  stepIndex: number,
  characters: Character[],
  onChangeCheckValue: (
    values: MemorySlotFormValue[],
    stepIndex: number,
  ) => void,
  onChangeVisualEffectValue: (
    value: string,
  ) => void,
  onDeleteRequirement: () => void,
}

export function AnswerFormMemorySlotRequirement(props: AnswerFormMemorySlotRequirementProps) {
  const {
    requirement,
    isSlotRequirementActive,
    answerIndex,
    memoryBankSlots,
    stepIndex,
    characters,
    onChangeCheckValue,
    onChangeVisualEffectValue,
    onDeleteRequirement,
  } = props;

  const {
    variableId,
    operator,
    check,
    visualEffect,
  } = getRequirementsVariableValues(requirement);

  const currentSlot = memoryBankSlots.find((slot) => slot.id === variableId);

  return (
    <Row className={cs('py-0 justify-content-between')}>
      <Col
        className={cs('mx-0 pb-0 pt-2 border-top', isSlotRequirementActive ? 'd-block' : 'd-none')}
      >
        <Row>
          <Col sm md>
            <MemorySlotCompareForm
              variableInputValue={variableId}
              operatorInputValue={operator}
              checkInputValue={check}
              currentSlot={currentSlot}
              inputNamePrefix={`steps[${stepIndex}][answers][${answerIndex}][requirement][check]`}
              memoryBankSlots={memoryBankSlots}
              stepIndex={stepIndex}
              characters={characters}
              onChangeValue={onChangeCheckValue}
            />
          </Col>
          <Col sm={2} md={1}>
            <Form.Label column>
              otherwise:
            </Form.Label>
          </Col>
          <Col sm={2} md={2}>
            <Form.Group
              as={Row}
            >
              <Form.Control
                size="sm"
                as="select"
                required
                name={`steps[${stepIndex}][answers][${answerIndex}][requirement][visualTreatmentIfNotMet]`}
                value={visualEffect}
                onChange={(event) => onChangeVisualEffectValue(event.target.value)}
              >
                <option value={VisualTreatmentIfNotMet.Disable}>
                  {VisualTreatmentIfNotMet.Disable}
                </option>
                <option value={VisualTreatmentIfNotMet.Hide}>{VisualTreatmentIfNotMet.Hide}</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm={2} xl={1} className="text-right">
            <Button
              className="ml-1 btnDeleteImgSm"
              size="sm"
              variant="secondary"
              onClick={() => onDeleteRequirement()}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
