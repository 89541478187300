import React, {
  PropsWithChildren, useCallback, useEffect, useState,
} from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import {
  RequestState,
  combineAsyncOperationStates,
  useAsyncOperationState,
} from '../../../../dorian-shared/hooks/useAsyncOperationState';
import { Event } from '../../../../dorian-shared/types/event/Event';
import { api } from '../../../api';
import { PageWrapper } from '../../../ui/PageWrapper';
import { showToast } from '../../../ui/utils';
import { DetailedPrize } from '../components/EventForm/DetailedPrizes/DetailedPrizeType';
import { EventForm, EventFormValues, eventToFormValues } from '../components/EventForm/EventForm';
import { syncWithServerDetailedEventPrizes } from '../prizesAPI';
import { useUpdateEvent } from './useUpdateEvent';

function toastSuccess() {
  showToast({
    textMessage: 'Event saved!',
    timeout: 20000,
    variant: 'success',
  });
}

function toastError(textMessage: string) {
  showToast({
    textMessage,
    timeout: 20000,
  });
}

function getPageLayout(history: History) {
  return {
    header: {
      title: 'Event Admin Panel',
      settings: 'admin',
    },
    sidebar: {
      nav: [
        {
          title: 'Back',
          href: '/home/',
          action: history.length > 1 && document.referrer !== window.location.href
            ? () => {
              window.history.back();
            } : null,
          variant: 'secondary',
        },
        {
          title: 'Events',
          href: '/events',
        },
      ],
    },
  };
}

function useDetailedEventPrizes(id: number) {
  const [requestState, {
    setToLoading,
    setToError,
    setToSuccess,
  }] = useAsyncOperationState();
  const [detailedEventPrizes, setDetailedEventPrizes] = useState<DetailedPrize[]>([]);

  const fetchDetailedEventPrizes = useCallback(() => {
    setToLoading();
    api.get<{ prizes: DetailedPrize[] }>(`/v1/events/${id}/prizes`)
      .then((response) => {
        setDetailedEventPrizes(response.data.prizes);
      })
      .then(setToSuccess)
      .catch(setToError);
  }, [id, setToError, setToLoading, setToSuccess]);

  useEffect(() => {
    fetchDetailedEventPrizes();
  }, [fetchDetailedEventPrizes]);

  return {
    requestState,
    detailedEventPrizes,
    fetchDetailedEventPrizes,
  };
}

function useEvent(id: number) {
  const [requestState, {
    setToLoading,
    setToError,
    setToSuccess,
  }] = useAsyncOperationState();
  const [event, setEvent] = useState<Event>();
  useEffect(() => {
    setToLoading();
    api.get<{ event: Event }>(`/v1/events/${id}`)
      .then((response) => {
        setEvent(response.data.event);
      })
      .then(setToSuccess)
      .catch(setToError);
  }, [id, setToError, setToLoading, setToSuccess]);

  return {
    requestState,
    event,
  };
}

interface EventLoadingWrapperProps extends PropsWithChildren {
  requestState: RequestState
}

function EventLoadingWrapper(props: EventLoadingWrapperProps) {
  const {
    requestState,
    children,
  } = props;

  switch (requestState) {
    case RequestState.Loading:
      return (
        <div className="text-center">
          <Spinner
            variant="primary"
            animation="border"
            className="loadingSpinner justify-content-center"
          />
        </div>
      );
    case RequestState.Success:
      return (
        <div>
          {children}
        </div>
      );
    case RequestState.Error:
      return (
        <div className="text-center">
          Error occurs during events loading. Please try again later or contact support.
        </div>
      );
    default:
      return null;
  }
}

export function EventItemPage(props: RouteComponentProps<{ id: string }>) {
  const {
    match,
    history,
  } = props;
  const pageLayout = getPageLayout(history);
  const eventId = Number(match.params.id);

  const {
    requestState: eventRequestState,
    event,
  } = useEvent(eventId);

  const {
    requestState: prizesEventState,
    detailedEventPrizes,
    fetchDetailedEventPrizes,
  } = useDetailedEventPrizes(eventId);

  const {
    isLoading,
    updateEvent,
  } = useUpdateEvent(eventId);

  const handleEventFormSubmit = (values: EventFormValues) => {
    const { detailedEventPrizes: newDetailedEventPrizes, ...eventValues } = values;
    Promise.all([
      updateEvent(eventValues),
      syncWithServerDetailedEventPrizes(newDetailedEventPrizes, detailedEventPrizes, eventId)
        .then(fetchDetailedEventPrizes),
    ]).then(toastSuccess)
      .catch((error) => toastError(error.message));
  };

  return (
    <PageWrapper
      {...props}
      page={pageLayout}
    >
      <Card>
        <Card.Body>
          <Card.Title>
            {event ? event.title : 'Loading...'}
          </Card.Title>
          <EventLoadingWrapper
            requestState={combineAsyncOperationStates([
              eventRequestState,
              prizesEventState,
            ])}
          >
            {event && (
              <EventForm
                eventFormValues={eventToFormValues(event, detailedEventPrizes)}
                onSubmit={handleEventFormSubmit}
                isLoading={isLoading}
                submitButtonText="Save"
              />
            )}
          </EventLoadingWrapper>
        </Card.Body>
      </Card>
    </PageWrapper>
  );
}
