import { omit } from 'lodash-es';
import React, { Component, forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import IconBrush from '../../../../assets/images/brush-1.svg';
// eslint-disable-next-line import/no-cycle
import { PremiumIpDisabledApprovedEdit } from '../../../utils/premiumIpDisabledApprovedEdit';
import { PremiumIpDisabledEdit } from '../../../utils/premiumIpDisabledEdit';
import { PreviewBookPush } from './PreviewBookPush';
import { PreviewChangeStepType } from './PreviewChangeStepType';
import { PreviewCreateChoice } from './PreviewCreateChoice';
import { PreviewEditCharacter } from './PreviewEditCharacter';
import { PreviewEditExpressions } from './PreviewEditExpressions';
import { PreviewEditScene } from './PreviewEditScene';
import './PreviewUtilities.scss';

const PreviewUtilitiesToggle = forwardRef(function PreviewUtilitiesToggle(props, ref) {
  const { children, onClick } = props;
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <span
      className="PreviewUtilitiesToggle"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </span>
  );
});

export class PreviewUtilities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      openEditScene: false,
      openEditCharacter: false,
      openEditExpressions: false,
      openEditStepType: false,
      openCreateChoice: false,
      openBookPush: false,
    };
  }

  componentDidMount() {
    window.addEventListener('storage', this.updateStorage);
  }

  updateStorage = (e) => {
    const { storyUuid } = this.props;

    if (e.key === `modeEdit-${storyUuid}`) {
      this.setState({
        disabled: JSON.parse(localStorage.getItem(`modeEdit-${storyUuid}`)),
      });
    }
  };

  componentDidUpdate() {
    const { storyUuid } = this.props;
    const { disabled } = this.state;
    const mode = JSON.parse(localStorage.getItem(`modeEdit-${storyUuid}`));
    if (mode !== disabled) {
      this.setState({
        disabled: mode,
      });
    }
  }

  checkDisabled = () => {
    const { obj } = this.props;

    if (
      !obj.current_branch
        || !obj.current_branch.steps
        || obj.current_branch.steps.length < 1
    ) {
      return true;
    }
    const stepType = obj.current_branch.steps[obj.current_step].stepTypeId;
    return stepType === 2 || stepType === 5 || stepType === 6 || stepType === 7;
  };

  render() {
    const {
      user, action, storyUuid, obj, story, hideUi,
    } = this.props;

    const {
      openEditStepType,
      openEditScene,
      openCreateChoice,
      openEditExpressions,
      openBookPush,
      openEditCharacter,
    } = this.state;

    if (!user) {
      return null;
    }
    if (obj.story && !['active', 'completed', 'archived', 'prompt'].includes(obj.story.group)) {
      return null;
    }
    if (user.role !== 'admin' && !['owner', 'editor', 'co-author'].includes(obj.story.story_role)) {
      return null;
    }

    const modeEdit = JSON.parse(localStorage.getItem(`modeEdit-${storyUuid}`));
    const disabled = modeEdit
        || PremiumIpDisabledEdit(user.role, obj.story.book, story?.group)
        || PremiumIpDisabledApprovedEdit(user.role, obj.story.book);

    if (modeEdit) {
      return null;
    }

    if (hideUi) {
      return null;
    }

    return (
      <>
        <Dropdown
          className="PreviewUtilitiesDropdown"
          onToggle={(e) => {
            if (!e && action) {
              action();
            }
          }}
        >
          <Dropdown.Toggle
            alignRight
            as={PreviewUtilitiesToggle}
          >
            <img src={IconBrush} alt="Utilities Menu" />
          </Dropdown.Toggle>

          <Dropdown.Menu alignRight>
            <Dropdown.Item
              disabled={disabled}
              onClick={() => {
                this.setState({
                  openEditScene: true,
                });
              }}
              eventKey="1"
            >
              Edit Location
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              disabled={(
                this.checkDisabled()
                || disabled
              )}
              onClick={() => {
                this.setState({
                  openEditCharacter: true,
                });
              }}
            >
              Edit Character
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="3"
              disabled={(
                this.checkDisabled()
                || disabled
              )}
              onClick={() => {
                this.setState({
                  openEditExpressions: true,
                });
              }}
            >
              Edit Expressions
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="4"
              disabled={(
                !obj.current_branch
                || !obj.current_branch.steps
                || obj.current_branch.steps.length < 1
                || disabled
              )}
              onClick={() => {
                this.setState({
                  openEditStepType: true,
                });
              }}
            >
              Change Step Type
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <PreviewEditScene
          {...this.props}
          show={openEditScene}
          onHide={
            () => {
              this.setState({
                openEditScene: false,
              });
            }
          }
        />
        <PreviewEditCharacter
          {...omit(this.props, ['story', 'user'])}
          show={openEditCharacter}
          onHide={
            () => {
              this.setState({
                openEditCharacter: false,
              });
            }
          }
        />
        <PreviewEditExpressions
          {...omit(this.props, ['story', 'user'])}
          show={openEditExpressions}
          onHide={
            () => {
              this.setState({
                openEditExpressions: false,
              });
            }
          }
        />
        <PreviewChangeStepType
          {...omit(this.props, ['story', 'user'])}
          show={openEditStepType}
          onHide={
            () => {
              this.setState({
                openEditStepType: false,
              });
            }
          }
        />
        <PreviewCreateChoice
          {...this.props}
          show={openCreateChoice}
          onHide={
            () => {
              this.setState({
                openCreateChoice: false,
              });
            }
          }
        />
        <PreviewBookPush
          {...this.props}
          show={openBookPush}
          onHide={
            () => {
              this.setState({
                openBookPush: false,
              });
            }
          }
        />
      </>
    );
  }
}
