import { isEqual } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import {
  Form,
} from 'react-bootstrap';
import { useShowModal } from '../../../../hooks/useShowModal';
import { ConfirmModal } from '../../../ui/ConfirmModal/ConfirmModal';
import classNames from './ClientVariable.module.scss';
import { ClientVariableRow } from './ClientVariableRow';

function useClientVariableChanged(initialClientVariable, clientVariable) {
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (!isEqual(initialClientVariable, clientVariable)) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [clientVariable, isChanged, initialClientVariable]);
  return { isChanged };
}

export function ClientVariable({
  initialClientVariable,
  onDelete,
  onSave,
}) {
  const [clientVariable, setClientVariable] = useState(initialClientVariable);

  const { isModalShown, showModal, hideModal } = useShowModal(false);
  const { isChanged } = useClientVariableChanged(initialClientVariable, clientVariable);

  const handleRestore = () => setClientVariable(initialClientVariable);

  const handleDeleteConfirm = () => {
    hideModal();
    onDelete(clientVariable.id);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      onSave(clientVariable);
    }
  };

  return (
    <Form
      noValidate
      validated={isChanged}
      onSubmit={handleSubmit}
    >
      <ClientVariableRow
        clientVariable={clientVariable}
        onClientVariableChange={setClientVariable}
        isChanged={isChanged}
        onRestore={handleRestore}
        onDelete={showModal}
      />
      <ConfirmModal
        show={isModalShown}
        size="md"
        title="Delete variable"
        onConfirm={handleDeleteConfirm}
        onCancel={hideModal}
      >
        <div className={classNames.textBodyConfirmModal}>
          <div className={classNames.textVariableBodyConfirmModal}>
            { `${clientVariable.name} (${clientVariable.type})`}
          </div>
          <div>
            Do you really want to delete this variable?
          </div>
        </div>
      </ConfirmModal>
    </Form>
  );
}
