import React from 'react';
import { Alert } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/types';
import ReactDOM from 'react-dom';

interface ShowToastParams {
  textMessage: string,
  timeout?: number,
  variant?: Variant
}

// TODO: https://dorian.atlassian.net/browse/DOR-3171
export const showToast = (params: ShowToastParams) => {
  const {
    textMessage,
    timeout = 4000,
    variant = 'danger',
  } = params;
  const alertElement = document.getElementById('errorsBox');
  ReactDOM.render(
    <Alert variant={variant} className="mx-3 my-3">
      {textMessage}
    </Alert>,
    alertElement,
  );
  setTimeout(() => {
    ReactDOM.render(
      <div />,
      alertElement,
    );
  }, timeout);
};
