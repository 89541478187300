import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

export function WizardFinalModal(props) {
  const { storyUuid } = props;

  const [isShown, setShown] = useState(true);

  const hide = () => {
    setShown(false);
    localStorage.setItem('wizardStep', 0);
    window.location.reload();
  };

  return (
    <Modal
      show={isShown}
      onHide={hide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body className="text-center">
        <h3>Now share your story with friends:</h3>
        <p>
          <Button
            onClick={hide}
            variant="link"
            target="_blank"
            href={`${window.location.origin}/play/${storyUuid}`}
          >
            {`${window.location.origin}/play/${storyUuid}`}
          </Button>
        </p>
        <p>
          <Button
            onClick={hide}
            variant="primary"
          >
            Close
          </Button>
        </p>
      </Modal.Body>
    </Modal>
  );
}
