export const SCROLLABLE_STEP_FORM_ELEMENT_ID = 'step_form_scrollable_element';

export function count(array) {
  let c = 0;
  for (let i = 0; i < array.length; i++) {
    if (typeof array[i] !== 'undefined') {
      c += 1;
    }
  }
  return c;
}
