import React from 'react';
import { Button } from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import { PremiumIpDisabledApprovedEdit } from '../../../utils/premiumIpDisabledApprovedEdit';
import { PremiumIpDisabledEdit } from '../../../utils/premiumIpDisabledEdit';

export function NextBtn(props) {
  const {
    user,
    obj,
    nextNotAllowed,
    story,
    previewAddStepAction,
    nextAction,
    storyUuid,
  } = props;

  const currentBranch = obj.current_branch && obj.current_branch;
  const steps = (currentBranch && currentBranch.steps) && currentBranch.steps;
  const currentStep = obj.current_step && obj.current_step;
  const stepTypeId = steps && steps[currentStep] && steps[currentStep].stepTypeId;
  const disabledAdd = (!user || (user.role !== 'admin' && !['owner', 'editor', 'co-author'].includes(obj.story.story_role))) || ['live', 'liveprompt'].includes(obj.story.group);
  const userRole = (user && user.role) && user.role;
  const book = (obj && obj.story && obj.story.book) && obj.story.book;

  if (
    currentBranch
      && (!steps
          || steps.length === 0
          || (!currentBranch.gotoBranchId && (currentStep === (steps.length - 1)))
      )
      && (stepTypeId !== 3)
      && !disabledAdd
  ) {
    const modeEdit = JSON.parse(localStorage.getItem(`modeEdit-${storyUuid}`));
    if (disabledAdd || modeEdit) {
      return null;
    }
    return (
      <Button
        block
        disabled={
                disabledAdd
                || modeEdit
                || obj.editMode
                || PremiumIpDisabledEdit(userRole, book, story?.group)
                || PremiumIpDisabledApprovedEdit(userRole, book)
            }
        onClick={previewAddStepAction}
        variant="primary"
        className="px-0"
      >
        +
      </Button>
    );
  }

  return (
    <Button
      block
      disabled={nextNotAllowed() || obj.editMode}
      onClick={() => nextAction()}
      variant="primary"
      className="px-0"
    >
      &#62;
    </Button>
  );
}
