import { EditorStore, InitEditorOptions } from '@dorian/creation-tools-ui';
import React, { useEffect, useRef } from 'react';
import {
  DefaultCharacterVisualProperties,
} from '../../../dorian-shared/types/character/DefaultCharacterVisualProperties';
import { noop } from '../../../helpers/noop';
import { getRuntimeEnv } from '../../../helpers/runtimeEnv';
import { RuntimeEnv } from '../../../helpers/RuntimeEnvEnum';
import { baseURL } from '../../api';
import { getBaseUrl, getEnvPrefix } from './getManifestUrlByRuntimeEnv';
import classNames from './Preview.module.scss';
import { useAvatarResolver } from './useAvatarResolver/useAvatarResolver';
import { useSizes } from './useSizes';

const letterboxViewportSettings = {
  viewportSize: {
    width: 1080,
    height: 1920,
  },
  viewportBgColor: 0x000000,
};

type GameplayRendererOptions = InitEditorOptions['gameplayRendererOptions'];

type CorrectedInitEditorOptions = Omit<InitEditorOptions, 'episodeUuid' | 'container' | 'engineUrl' | 'gameplayRendererOptions'> & {
  gameplayRendererOptions: Omit<GameplayRendererOptions, 'localAssetsPath' | 'avatarResolver'>
};

// TODO: fix CT, force to export enum
// eslint-disable-next-line no-shadow
export declare enum GameUIStyle {
  Default = 'default',
  Arcana = 'arcana'
}

interface PreviewProps {
  episodeUuid: string,
  store: EditorStore,
  bookStyle?: GameUIStyle,

  width?: number,
  onPreviewExit?: (returnCode: string, params?: {episodeUuid: string}) => void
  isBranchDetailsCacheDisabled?: boolean,
  editorProps?: Partial<CorrectedInitEditorOptions>
}

export function Preview(props: PreviewProps) {
  const {
    episodeUuid,
    store,
    bookStyle,

    isBranchDetailsCacheDisabled,
    width = 450,
    onPreviewExit = noop,
    editorProps,
  } = props;

  const { mobileBackgroundStyle, sizeConfig } = useSizes(width);
  const avatarResolver = useAvatarResolver();

  const containerRef = useRef(null);
  useEffect(() => {
    const engineToken = localStorage.getItem('token');
    if (engineToken) {
      localStorage.setItem('engineToken', engineToken);
    }

    (async () => {
      if (!containerRef.current) {
        return;
      }
      if (!avatarResolver) {
        return;
      }

      const runtimeEnv = getRuntimeEnv();
      const envLabel = runtimeEnv === RuntimeEnv.Prod ? 'prod' : 'staging';
      const assetsEnvironment = getEnvPrefix(envLabel);
      const assetsUrl = getBaseUrl(envLabel);

      const CT = await import('@dorian/creation-tools-ui');

      CT.initEditor({
        letterboxViewportSettings,
        currentUserCharacterProps: {} as DefaultCharacterVisualProperties,
        exitCallback: onPreviewExit,
        logAnalyticsEvent: noop,
        assetsUrl,
        assetsEnvironment,
        ...editorProps,
        gameplayRendererOptions: {
          mouseEventsEnabled: true,
          ...sizeConfig,
          ...editorProps?.gameplayRendererOptions,
          localAssetsPath: `${process.env.PUBLIC_URL}/`,
          avatarResolver,
          viewPortSize: sizeConfig,
          styles: {
            alias: bookStyle,
          },
        },
        auth: editorProps?.auth ?? { // probably not need if engine token in local storage
          type: 'deviceId',
          deviceId: '',
        },
        episodeUuid,
        container: containerRef.current,
        engineUrl: baseURL.slice(0, -1),
        externalStore: store,
        isReadOnly: true,
        isBranchDetailsCacheDisabled,
        isLetterboxForArcanaActive: true,
        isLetterboxForAllStoriesActive: false,
      });
    })();
    return () => {
      localStorage.removeItem('engineToken');
    };
  }, [
    avatarResolver,
    bookStyle,
    editorProps,
    episodeUuid,
    onPreviewExit,
    sizeConfig,
    store,
    isBranchDetailsCacheDisabled,
  ]);

  return (
    <div
      className={classNames.wrapper}
      style={mobileBackgroundStyle}
    >
      <div
        ref={containerRef}
        className={classNames.previewContainer}
        style={sizeConfig}
      />
    </div>
  );
}
