import React, { Component } from 'react';
import {
  Alert, Button, Col, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../../api';
import './PreviewUtilities.scss';

export class PreviewCreateChoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      lastEdit: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { obj } = this.props;
    const { lastEdit } = this.state;

    if (
      lastEdit
      && obj !== prevProps.obj
      && (obj.current_branch.id !== prevProps.obj.current_branch.id)
      && (obj.current_branch.id === lastEdit)
    ) {
      this.addStepData();
    }
  }

  addStepData = () => {
    const { obj, updateCharacter, onHide } = this.props;
    const { checked } = this.state;

    const value = {
      answers: [{ text: ' ' }, { text: ' ' }, { text: ' ' }],
      stepTypeId: '3',
    };

    api.post(`/v1/stories/${obj.story.id}/branches/${obj.current_branch.id}/steps`, value)
      .then(() => {
        this.setState({
          loading: false,
          lastEdit: null,
        }, () => {
          updateCharacter(obj.current_branch.id, obj.current_step, checked);
          onHide();
        });
      })
      .catch((error) => {
        this.errorAlert(error.response.data.error);
        this.setState({
          loading: false,
          lastEdit: null,
        });
      });
  };

  createData = () => {
    const { obj, setLastEdit, updateScene } = this.props;

    this.setState({
      loading: true,
      lastEdit: null,
    });
    const arr = { gotoBranchId: '-1', title: obj.current_branch.title };
    api.put(`/v1/stories/${obj.story.id}/branches/${obj.current_branch.id}`, arr)
      .then((res) => {
        updateScene();
        setLastEdit(res.data.branch.gotoBranchId);
        this.setState({
          lastEdit: res.data.branch.gotoBranchId,
        });
      })
      .catch((error) => {
        this.errorAlert(error.response.data.error);
        this.setState({
          loading: false,
          lastEdit: null,
        });
      });
  };

  errorAlert = (error) => {
    this.setState({
      error,
    });
    setTimeout(() => {
      this.setState({
        error: null,
      });
    }, 1500);
  };

  render() {
    const { show, onHide } = this.props;
    const { error, loading } = this.state;

    if (!show) {
      return null;
    }

    return (
      <div className="PreviewEditScene-box size-small">
        <div className="PreviewEditScene-wrap">
          <div className="PreviewEditScene-header">
            <h2>Create Choice</h2>
          </div>
          <div className="PreviewEditScene-content">

            <Row
              className={error === null ? 'd-none' : 'd-block pt-2'}
            >
              <Col md={12}>
                <Alert variant="danger">
                  {error}
                </Alert>
              </Col>
            </Row>
            {loading ? (
              <div
                className="text-center"
              >
                <Spinner
                  variant="primary"
                  animation="border"
                  className="justify-content-center"
                />
              </div>
            ) : (
              <p>Do you really want to create a new choice?</p>
            )}
          </div>
          <div className="PreviewEditScene-footer">
            <Button
              size="sm"
              type="reset"
              variant="secondary"
              disabled={loading}
              onClick={onHide}
            >
              Cancel
            </Button>

            <Button
              size="sm"
              type="submit"
              variant="primary"
              className="mx-1"
              disabled={loading}
              onClick={this.createData}
            >
              Create
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
