function parseInside(txt, left, right) {
  return left + txt + right;
}

export function markupToHTML(text) {
  if (!text) {
    return text;
  }

  // eslint-disable-next-line no-param-reassign
  text = text.replace(/_([^\s_][\s\S]*?)_/g, (wm, m) => (/\S$/.test(m) ? parseInside(m, '<i>', '</i>') : wm));
  // eslint-disable-next-line no-param-reassign
  text = text.replace(/\*([^\s*][\s\S]*?)\*/g, (wm, m) => (/\S$/.test(m) ? parseInside(m, '<strong>', '</strong>') : wm));
  // eslint-disable-next-line no-param-reassign
  text = text.replace(/<c=([^>]*)>([^\s*][\s\S]*?)<\/c>/g, (wm, m1, m2) => {
    const regexp = /(#([\da-fA-F]{3}){1,2}|(rgb|hsl)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|hsl)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\)|[A-Za-z]+)/g;
    const found = m1.match(regexp);
    if (found) {
      return /\S$/.test(m2) ? parseInside(m2, `<span style="color:${found[0]};">`, '</span>') : wm;
    }
    return wm;
  });

  return text;
}
