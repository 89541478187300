import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { MemorySlotsBase, MemorySlotsValue } from 'components/pages/Book/MemoryBank/memoryBankTypes';
import { MemorySlotsTypesList } from 'components/pages/Book/MemoryBank/MemoryBankTypesList';
import { Character } from 'dorian-shared/types/character/Character';
import { MemorySlotCompareValueForm } from './MemorySlotCompareValueForm';
import { CheckOperator } from './StepTypeCheck';

export type MemorySlotFormValue = {
  name: string,
  value: string | number | null,
}

interface MemorySlotCompareFormProp {
  inputNamePrefix: string,
  variableInputValue: number,
  operatorInputValue: string,
  checkInputValue: MemorySlotsValue,
  currentSlot?: MemorySlotsBase,
  memoryBankSlots?: MemorySlotsBase[],
  stepIndex: number,
  characters: Character[],
  onChangeValue: (
    values: MemorySlotFormValue[],
    stepIndex: number,
  ) => void,
  isDisabled?: boolean,
}

export function MemorySlotCompareForm(props: MemorySlotCompareFormProp) {
  const {
    inputNamePrefix,
    variableInputValue,
    operatorInputValue,
    checkInputValue,
    currentSlot,
    memoryBankSlots,
    stepIndex,
    characters,
    onChangeValue,
    isDisabled,
  } = props;

  return (
    <Row
      xs={1}
      sm={2}
      lg={3}
    >
      <Form.Group as={Row}>
        <Col lg={4}>
          <Form.Label column>
            Whether
          </Form.Label>
        </Col>
        <Col>
          <Form.Control
            disabled={isDisabled}
            size="sm"
            as="select"
            name={`${inputNamePrefix}[variableId]`}
            value={variableInputValue}
            onChange={(event) => {
              const values: MemorySlotFormValue[] = [
                {
                  name: 'variableId',
                  value: Number(event.target.value),
                },
                {
                  name: 'value',
                  value: 0,
                },
              ];

              const isOperatorToChange = operatorInputValue !== CheckOperator.EQUAL.name
                && operatorInputValue !== CheckOperator.NOTEQUAL.name;
              if (isOperatorToChange) {
                values.push({
                  name: 'operator',
                  value: 0,
                });
              }

              onChangeValue(values, stepIndex);
            }}
          >
            <option />
            {memoryBankSlots?.map((slot) => (
              <option key={slot.id} value={slot.id}>
                {slot.name}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col lg={2}>
          <Form.Label column>
            is
          </Form.Label>
        </Col>
        <Col>
          <Form.Control
            disabled={isDisabled}
            size="sm"
            as="select"
            name={`${inputNamePrefix}[operator]`}
            value={operatorInputValue}
            onChange={(event) => onChangeValue([{
              name: 'operator',
              value: event.target.value,
            }], stepIndex)}
          >
            <option />
            {Object.keys(CheckOperator).map((operatorKey) => {
              const operator = CheckOperator[operatorKey];

              const isSlotANumber = currentSlot?.type === MemorySlotsTypesList.number;
              const isOnlyNumberOperator = operator.name !== CheckOperator.EQUAL.name
                && operator.name !== CheckOperator.NOTEQUAL.name;

              if (!isSlotANumber && isOnlyNumberOperator) {
                return null;
              }
              return (
                <option key={`option${operator.name}`} value={operator.name}>
                  {operator.title}
                </option>
              );
            })}
          </Form.Control>
        </Col>
      </Form.Group>
      <MemorySlotCompareValueForm
        onChangeValue={onChangeValue}
        stepIndex={stepIndex}
        valueInputValue={checkInputValue}
        characters={characters}
        memoryBankSlot={currentSlot}
        inputName={`${inputNamePrefix}[value]`}
        isDisabled={isDisabled}
      />
    </Row>
  );
}
