import { useCallback, useState } from 'react';

// eslint-disable-next-line no-shadow
export enum RequestState {
    None = 'None',
    Loading = 'Loading',
    Success = 'Success',
    Error = 'Error',
}

export function useAsyncOperationState() {
  const [requestState, setRequestState] = useState(RequestState.None);
  const setToLoading = useCallback(() => setRequestState(RequestState.Loading), []);
  const setToError = useCallback(() => setRequestState(RequestState.Error), []);
  const setToSuccess = useCallback(() => setRequestState(RequestState.Success), []);
  const isSuccess = requestState === RequestState.Success;
  const isError = requestState === RequestState.Error;
  const isLoading = requestState === RequestState.Loading;
  return [requestState, {
    isSuccess,
    isError,
    isLoading,
    setToLoading,
    setToError,
    setToSuccess,
  }] as const;
}

export function combineAsyncOperationStates(states: RequestState[]) {
  return states.reduce((accumulatedState, currentState) => {
    if (accumulatedState === RequestState.Error || currentState === RequestState.Error) {
      return RequestState.Error;
    }
    if (accumulatedState === RequestState.Loading || currentState === RequestState.Loading) {
      return RequestState.Loading;
    }
    if (accumulatedState === RequestState.None || currentState === RequestState.None) {
      return RequestState.None;
    }
    return RequestState.Success;
  });
}
