import ReactIsCapsLockActive from '@matsun/reactiscapslockactive';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, InputGroup, Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ImgEye from '../../../assets/images/eye-black.svg';
import { api } from '../../api';

export class LoginForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      validated: null,
      name: '',
      password: '',
      showPass: false,
      showErrorAlert: false,
      error: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location.hash !== location.hash) {
      this.setState({
        validated: null,
        name: '',
        password: '',
        showPass: false,
        showErrorAlert: false,
        error: null,
      });
    }
  }

  handleChangeName = (event) => {
    this.setState({ name: event.target.value });
  };

  handleChangePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  handleSubmit = (event) => {
    this.setState({ showLoading: true });
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    if (validated === false) {
      this.setState({ showLoading: false });
      event.stopPropagation();
    } else {
      const { name, password } = this.state;
      const login = name.trim().toLowerCase();
      const createTemplate = JSON.parse(localStorage.getItem('createTemplate'));
      if (createTemplate) {
        createTemplate.signIn = true;
      }

      api.post('/v1/login', { login, password, createTemplate })
        .catch((error) => {
          this.setState({
            showErrorAlert: true,
            showLoading: false,
            error: error?.response?.data?.error,
          });
        })
        .then((res) => {
          const { auth } = this.props;
          if (res && res.status === 200) {
            localStorage.setItem('createTemplate', JSON.stringify(createTemplate));
            this.setState({
              showErrorAlert: false,
              showLoading: false,
            });
            auth.login(res.data);
          }
        });
    }
    this.setState({ validated: true });
    event.stopPropagation();
  };

  render() {
    const { play, className } = this.props;
    const {
      validated, showErrorAlert, showLoading, error, name, password, showPass,
    } = this.state;

    return (
      <>
        <Alert show={showErrorAlert} variant="danger">
          ERROR:
          {' '}
          {error || 'invalid email or password'}
        </Alert>
        <Form
          noValidate
          validated={validated}
          onSubmit={this.handleSubmit}
          className={className}
        >
          <Form.Row>
            <Form.Group
              as={Col}
              md={12}
              className="mt-input"
              controlId="formUserNameLogin"
            >
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="text"
                name="name"
                onChange={this.handleChangeName}
                value={name}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid Email.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              as={Col}
              md={12}
              className="mt-input"
              controlId="formPassword"
            >
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <Form.Control
                  required
                  type={!showPass ? 'password' : 'text'}
                  name="password"
                  autoComplete="password"
                  onChange={this.handleChangePassword}
                  value={password}
                />
                <InputGroup.Prepend
                  className="passEyeBtn"
                  onClick={() => {
                    this.setState({
                      showPass: !showPass,
                    });
                  }}
                >
                  <img src={ImgEye} alt="" />
                </InputGroup.Prepend>
                <Form.Control.Feedback type="invalid">
                  Password is required
                </Form.Control.Feedback>
              </InputGroup>
              <ReactIsCapsLockActive>
                {(active) => (active ? <Form.Text className="text-danger">Warning: Caps Lock is on</Form.Text> : null)}
              </ReactIsCapsLockActive>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Col md={12} className="text-center">
              <Button
                variant="outline-primary"
                type="submit"
                className="mt-4 btnMainPage"
              >
                {showLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                Submit
              </Button>
            </Col>
          </Form.Row>

          {!play
            ? (
              <p className="text-center pt-2 mainPageLinks-nav">
                <Link
                  className="mr-2 mainPageLinks"
                  to="#lost-password"
                >
                  Lost Password?
                </Link>
                <Link
                  className="ml-2 mainPageLinks"
                  to="/write-with-us-app"
                >
                  Write with us
                </Link>
              </p>
            )
            : null}
        </Form>
      </>
    );
  }
}
