import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../../api';
import './PreviewUtilities.scss';

export class PreviewEditExpressions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: null,
      error: null,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;

    if (prevProps.show !== show && show) {
      this.setState({
        checked: this.checkCharacter(),
      });
    }
  }

  checkCharacter = () => {
    const { obj } = this.props;

    if (!obj.current_branch || !obj.current_branch.steps) return null;
    return obj.current_branch.steps[obj.current_step].expression;
  };

  errorAlert = (error) => {
    this.setState({
      error,
    });
    setTimeout(() => {
      this.setState({
        error: null,
      });
    }, 1500);
  };

  saveData = () => {
    const {
      obj, updateCharacter, onHide,
    } = this.props;
    const { checked } = this.state;

    this.setState({
      loading: true,
    });
    const value = { characterExpressionId: checked.id };
    api.put(`/v1/stories/${obj.story.id}/branches/${obj.current_branch.id}/steps/${obj.current_branch.steps[obj.current_step].id}`, value)
      .then(() => {
        this.setState({
          loading: false,
        }, () => {
          updateCharacter(obj.current_branch.id, obj.current_step, checked);
          onHide();
        });
      })
      .catch((error) => {
        this.errorAlert(error.response.data.error);
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const { show, obj, onHide } = this.props;
    const { error, loading, checked } = this.state;

    if (!show) {
      return null;
    }

    return (
      <div className="PreviewEditScene-box">
        <div className="PreviewEditScene-wrap">

          <div className="PreviewEditScene-header">
            <h2>Edit Expressions</h2>
          </div>

          <div className="PreviewEditScene-content">
            {loading && (
              <div className="text-center loadingSpinner">
                <Spinner
                  variant="primary"
                  animation="border"
                  className="justify-content-center"
                />
              </div>
            )}
            <Row className={error === null ? 'd-none' : 'd-block pt-2'}>
              <Col md={12}>
                <Alert variant="danger">
                  {error}
                </Alert>
              </Col>
            </Row>
            {obj.expressions.map((expression, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Row key={i} className="py-2 border-bottom d-flex align-items-center">
                <Col md={2} className="text-center">
                  <Form.Check
                    type="radio"
                    id={`checkbox_${i}`}
                    checked={(checked && checked.id === expression.id) || false}
                    onChange={() => this.setState({ checked: expression })}
                  />
                </Col>
                <Col md={10} className="text-left">
                  <label htmlFor={`checkbox_${i}`}>{expression.title}</label>
                </Col>
              </Row>
            ))}
          </div>

          <div className="PreviewEditScene-footer">
            <Button
              size="sm"
              type="reset"
              variant="secondary"
              onClick={onHide}
            >
              Cancel
            </Button>

            <Button
              size="sm"
              type="reset"
              variant="primary"
              onClick={() => this.saveData()}
              className="mx-1"
              disabled={(!!loading)}
            >
              {loading && (
                <Spinner
                  size="sm"
                  animation="border"
                />
              )}
              Save
            </Button>
          </div>

        </div>
      </div>
    );
  }
}
