import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../../api';
import './PreviewUtilities.scss';

export class PreviewNewEpisode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      checked: null,
    };
  }

  errorAlert = (error) => {
    this.setState({
      error,
    });
    setTimeout(() => {
      this.setState({
        error: null,
      });
    }, 1500);
  };

  createData = () => {
    const { obj, updateCharacter, onHide } = this.props;
    const { checked } = this.state;

    if (!checked) {
      return;
    }
    const arr = checked.id !== -1 ? { gotoBranchId: checked.id } : { gotoBranchId: '-1' };
    this.setState({
      loading: true,
    });
    api.put(`/v1/stories/${obj.story.id}/branches/${obj.current_branch.id}/answers/${obj.previewAddEpisode.id}`, arr)
      .then((res) => {
        updateCharacter(res.data.answer.gotoBranchId, 0);
        onHide();
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        this.errorAlert(error.response.data.error);
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const { obj, onHide } = this.props;
    const { error, loading, checked } = this.state;

    if (!obj.previewAddEpisode) {
      return null;
    }

    return (
      <div className="PreviewEditScene-box">
        <div className="PreviewEditScene-wrap">
          <div className="PreviewEditScene-header">
            <h2>Goto Node</h2>
          </div>
          <div className="PreviewEditScene-content">
            {loading && (
              <div className="text-center loadingSpinner">
                <Spinner
                  variant="primary"
                  animation="border"
                  className="justify-content-center"
                />
              </div>
            )}
            <Row className={error === null ? 'd-none' : 'd-block pt-2'}>
              <Col md={12}>
                <Alert variant="danger">
                  {error}
                </Alert>
              </Col>
            </Row>
            {obj.branches.map((branch, i) => {
              if (branch.current_branch.id === branch.id) {
                return null;
              }
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Row key={i} className="py-2 border-bottom d-flex align-items-center">
                  <Col md={1} className="text-center">
                    <Form.Check
                      type="checkbox"
                      checked={(checked && checked.id === branch.id) || false}
                      onChange={() => this.setState({ checked: branch })}
                    />
                  </Col>
                  <Col md={10} className="text-left">
                    {branch.title}
                  </Col>
                </Row>
              );
            })}

            <Row className="py-2 border-bottom d-flex align-items-center">
              <Col md={1} className="text-center">
                <Form.Check
                  type="checkbox"
                  checked={(checked && checked.id === -1) || false}
                  onChange={() => this.setState({ checked: { id: -1 } })}
                />
              </Col>
              <Col md={10} className="text-left">
                CREATE NEW NODE
              </Col>
            </Row>

          </div>
          <div className="PreviewEditScene-footer">
            <Button
              size="sm"
              type="reset"
              variant="secondary"
              disabled={loading}
              onClick={onHide}
            >
              Cancel
            </Button>

            <Button
              size="sm"
              type="submit"
              variant="primary"
              className="mx-1"
              disabled={loading}
              onClick={this.createData}
            >
              Create
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
