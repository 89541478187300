import React, { Fragment } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BranchesWidgetItem } from './BranchesWidgetItem';
import { BranchesWidgetContext } from './context';

export function BranchesWidgetList(props) {
  const { value, filteredData } = React.useContext(BranchesWidgetContext);

  return filteredData && filteredData.length > 0 && filteredData.map((obj, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <Fragment key={i}>
      {(props.type === 'Dropdown' ? (
        <Dropdown.Item eventKey={obj} active={(obj === value)}>
          <BranchesWidgetItem obj={obj} />
        </Dropdown.Item>
      ) : (
        <BranchesWidgetItem obj={obj} />
      ))}
    </Fragment>
  ));
}
