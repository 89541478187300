import {
  BRANCHES, FILTERED_DATA, LOADING, SEARCH, STORY, VALUE,
} from './types';

export function branchesWidgetReducer(state, action) {
  const { payload, type } = action;

  switch (type) {
    case VALUE:
      return {
        ...state,
        value: payload,
      };

    case FILTERED_DATA:
      return {
        ...state,
        filteredData: payload,
      };

    case SEARCH:
      return {
        ...state,
        search: payload,
      };

    case LOADING:
      return {
        ...state,
        loading: payload,
      };

    case STORY:
      return {
        ...state,
        story: payload,
      };

    case BRANCHES:
      return {
        ...state,
        branches: payload,
      };

    default:
      return state;
  }
}
