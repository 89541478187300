import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { Branch } from 'dorian-shared/types/branch/Branch';
import { AddStepGotoBranchSelect } from '../AddStepGotoBranchSelect';
import { SWITCH_INDEX_TYPE } from './StepTypeCheck';

type StepTypeCheckSwitchFormProps = {
  stepIndex: number,
  switchIndex: number,
  gotoBranchId: number,
  currentBranchId: number,
  branches: Branch[],
  handleChangeStepSwitch: (gotoBranchId: number, stepIndex: number, switchIndex: number) => void,
  isDisabled?: boolean,
}

export function StepTypeCheckSwitchForm(props: StepTypeCheckSwitchFormProps) {
  const {
    stepIndex, switchIndex, gotoBranchId, currentBranchId, handleChangeStepSwitch, branches,
    isDisabled,
  } = props;

  const valueLabel = switchIndex === SWITCH_INDEX_TYPE.TRUE ? 'true' : 'false';
  const gotoBranchName = `steps[${stepIndex}][switch][${switchIndex}]`;
  return (
    <Col>
      <Form.Label
        column
        md={10}
      >
        {'is '}
        <strong>{valueLabel}</strong>
        {' go to:'}
      </Form.Label>
      <AddStepGotoBranchSelect
        inputName={`${gotoBranchName}[gotoBranchId]`}
        gotoBranchId={gotoBranchId}
        isDisabled={isDisabled}
        currentBranchId={currentBranchId}
        branches={branches}
        onBranchChange={(selectedGotoBranchId) => {
          const gotoBranchIdValue = selectedGotoBranchId ? Number(selectedGotoBranchId) : 0;
          handleChangeStepSwitch(gotoBranchIdValue, stepIndex, switchIndex);
        }}
      />
      <input type="hidden" value={valueLabel} name={`${gotoBranchName}[value]`} />
    </Col>
  );
}
