import classNames from 'classnames/bind';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Container, Form, Row,
} from 'react-bootstrap';
import { api } from '../../../api';
import styles from '../../Characters/Characters.scss';
import { PreviewImg } from '../../Characters/PreviewImg';
import { StoryPreviewContext } from '../context';

const cs = classNames.bind(styles);

export class PreviewScenesForm extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    const { obj } = this.props;
    this.state = {
      backgrounds: [],
      validated: false,
      formError: null,
      imageSelected: null,
      title: obj ? obj.title : '',
    };
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 3000);
  };

  loadBackgrounds() {
    api.get('/v1/backgrounds')
      .then((res) => {
        const { user } = this.props;

        const images = user.role === 'admin' ? res.data.images
          : res.data.images.filter((bg) => {
            if (bg.policyId === 1) return true;
            return bg.policyId === 2 && bg.authorId === user.id;
          });
        this.setState({
          backgrounds: images,
        });
      });
  }

  addNewScene(id, title, backgroundId, isDefault) {
    const { obj, onClose, updateLocation } = this.props;

    const postData = {
      title,
      backgroundId: backgroundId || null,
      isDefault,
    };

    api.post(`/v1/books/${obj.story.bookId}/locations`, postData)
      .then(() => {
        updateLocation();
        onClose();
      })
      .catch((error) => {
        if (error) {
          this.errorAlert(error.response.data.error);
        }
      });
  }

  handleSubmit(event) {
    const form = event.currentTarget;
    const id = event.target.elements.id.value;
    const title = event.target.elements.Title.value;
    const backgroundId = event.target.elements.backgroundId.value;
    const isDefault = event.target.elements.isDefault.checked;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.addNewScene(id, title, backgroundId, isDefault);
    }
    this.setState({ validated: true });
    event.preventDefault();
    event.stopPropagation();
  }

  setTitle = (image) => {
    this.setState({ title: image && image.alias ? image.alias : '' });
  };

  imageClick(obj) {
    this.setState({ imageSelected: obj });
    this.setTitle(obj);
  }

  imageChanged(event) {
    const { backgrounds } = this.state;
    const img = backgrounds.find((el) => Number(el.id) === Number(event.target.value));
    this.setState({ imageSelected: img });
    this.setTitle(img);
  }

  bgImagesOptions() {
    const { backgrounds } = this.state;

    if (!backgrounds) {
      return [];
    }
    return backgrounds.map((object) => (
      <option value={object.id} key={object.id}>
        {object.alias}
      </option>
    ));
  }

  bgImagesGrid() {
    const { imageSelected, backgrounds } = this.state;

    if (!backgrounds) {
      return null;
    }

    return (
      <ul className={cs('thumbnails', 'image_selector')}>
        {backgrounds.map((object, i) => {
          const selected = imageSelected && imageSelected.id === object.id;
          return (
            // eslint-disable-next-line max-len
            // eslint-disable-next-line react/no-array-index-key,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
            <li key={i} onClick={() => this.imageClick(object)}>
              <PreviewImg
                style={{ height: '60px' }}
                character={{ image: object }}
                trigger="hover"
                className={cs('thumbnail', selected ? 'selected' : '')}
                alias={object.alias}
              />
            </li>
          );
        })}
      </ul>
    );
  }

  componentDidMount() {
    this.loadBackgrounds();
  }

  componentDidUpdate(prevProps) {
    const { obj } = this.props;
    const { backgrounds } = this.state;

    if (obj && prevProps.obj.backgroundId !== obj.backgroundId) {
      const img = backgrounds.find((el) => Number(el.id) === Number(obj.backgroundId));
      this.setState({ imageSelected: img });
      this.setTitle(img);
    }
  }

  render() {
    const { obj, onClose } = this.props;
    const {
      validated, title, imageSelected, formError,
    } = this.state;
    const { limits } = this.context;

    const id = '';
    const isDefault = obj && obj.locations && obj.locations.length === 0 ? true : null;
    const titleMaxLength = Number(limits.scene_title_max.value);

    return (
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => this.handleSubmit(e)}
      >
        <Container style={{ paddingTop: '7em' }}>
          <Row>
            <Col md={12} className={cs(formError === null ? 'd-none' : 'd-block')}>
              <Alert variant="danger">
                {formError}
              </Alert>
            </Col>

            <Col md={2} className="text-center">
              <Form.Check
                custom
                id="isDefault"
                name="isDefault"
                defaultChecked={isDefault}
                label=""
              />
              <input type="hidden" name="id" value={id} />
            </Col>
            <Col md={10}>
              <Form.Group controlId="Title">
                <Form.Control
                  required
                  size="sm"
                  type="text"
                  placeholder="Title"
                  name="Tile"
                  defaultValue={title}
                  onChange={(e) => {
                    this.setState({
                      title: e.target.value,
                    });
                  }}
                  className={title && title.length > titleMaxLength ? 'text-limit' : null}
                />
                <Form.Text className="char-limit-info-box">

                  {titleMaxLength - (title ? title.length : 0)}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  Please choose a Location title.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={12} className="text-center">
              <Form.Group controlId="backgroundId">
                <Form.Control
                  size="sm"
                  as="select"
                  name="backgroundId"
                  value={imageSelected ? imageSelected.id : ''}
                  onChange={(ev) => this.imageChanged(ev)}
                >
                  <option />
                  {this.bgImagesOptions()}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <div className="PreviewScenesForm-ImagesBox">
            {this.bgImagesGrid()}
          </div>
          <Row className="PreviewScenesForm-ActionBox">
            <Col md={12} className="text-right">
              <Button
                className="mr-1"
                size="sm"
                type="reset"
                variant="secondary"
                onClick={onClose}
              >
                Cancel
              </Button>

              <Button
                disabled={(title && title.length > titleMaxLength)}
                className="ml-1"
                size="sm"
                type="submit"
                variant="primary"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Container>

      </Form>
    );
  }
}

PreviewScenesForm.contextType = StoryPreviewContext;
