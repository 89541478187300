import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal, OverlayTrigger, Row, Table, Tooltip,
} from 'react-bootstrap';
import imgDelete from '../../../../assets/images/delete.svg';
import imgEditStory from '../../../../assets/images/edit-story.png';
import { doubleQuote } from '../../../../helpers/html';
import { api } from '../../../api';
import { ConfirmModal } from '../../../ui/ConfirmModal/ConfirmModal';
import { SelectAsyncPaginate } from '../../../ui/SelectAsyncPaginate/SelectAsyncPaginate';
import { getUserDisplayName } from '../../Users';
import '../Stories.css';
import { DeleteBookShare } from './DeleteBookShare';
import { DeleteFolderShare } from './DeleteFolderShare';
import { DeleteShare } from './DeleteShare';
import './index.scss';
import { role } from './role';

const maxUsers = 20;

export class Share extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      validated: null,
      addUser: false,
      editUser: null,
      modalDeleteUserShow: false,
      modalRole: false,
      serializeObj: [],
      obj: [],
      shareWithUsers: [],
      owner: null,
      formError: null,
      selectedUser: null,
    };
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  deleteUser = () => {
    const { obj, modalDeleteUserShow } = this.state;
    const { obj: book, type } = this.props;

    if (modalDeleteUserShow === true) {
      if (type === 'story') {
        return (
          <DeleteShare
            show={modalDeleteUserShow}
            onHide={this.modalDeleteUserClose}
            storyID={book.id}
            obj={obj}
            onError={this.errorAlert}
          />
        );
      }

      if (type === 'book') {
        return (
          <DeleteBookShare
            show={modalDeleteUserShow}
            onHide={this.modalDeleteUserClose}
            bookID={book.id}
            obj={obj}
            onError={this.errorAlert}
          />
        );
      }

      return (
        <DeleteFolderShare
          show={modalDeleteUserShow}
          onHide={this.modalDeleteUserClose}
          folderID={book.id}
          obj={obj}
          onError={this.errorAlert}
        />
      );
    }
    return null;
  };

  modalDeleteUserClose = () => {
    this.setState({
      modalDeleteUserShow: false,
      obj: [],
    });
    this.loadData();
  };

  componentDidUpdate(prevProps) {
    const { obj, show } = this.props;

    if (prevProps.obj.id !== obj.id) {
      this.loadData();
    }
    if (prevProps.show !== show && !show) {
      this.setState({
        validated: null,
        addUser: false,
        editUser: null,
        modalDeleteUserShow: false,
        modalRole: false,
        modalCoAuthor: false,
        serializeObj: [],
        obj: [],
        shareWithUsers: [],
        owner: null,
        selectedUser: null,
      });
    }
  }

  handleCloseModalRole = () => {
    const { modalRole } = this.state;

    this.setState({
      modalRole: !modalRole,
    });
  };

  handleCloseModalCoAuthor = () => {
    const { modalCoAuthor } = this.state;

    this.setState({
      modalCoAuthor: !modalCoAuthor,
    });
  };

  loadData() {
    const { obj, type } = this.props;

    if (obj.id) {
      let url = `/v1/folders/${obj.id}/share`;

      if (type === 'story') {
        url = `/v1/stories/${obj.id}/share`;
      }

      if (type === 'book') {
        url = `/v1/books/${obj.id}/share`;
      }

      api.get(url)
        .then((res) => {
          const shareWithUsers = res.data.users;
          const owner = shareWithUsers.find((user) => user[`${type}_role`] === 'owner');
          this.setState({
            shareWithUsers,
            owner: owner ?? null,
          });
        });
    }
  }

  addUserForm() {
    const { selectedUser, addUser } = this.state;
    const { type, disabled } = this.props;

    if (addUser !== true) {
      return null;
    }

    const value = selectedUser.id;
    const valueText = selectedUser.fullname;

    return (
      <tr className="table-row-user">
        <td className="table-data-user">
          {valueText}
          <Form.Control
            required
            readOnly
            type="hidden"
            name="user_id"
            defaultValue={value}
          />
        </td>
        <td>
          <Form.Control
            required
            size="sm"
            as="select"
            name={`${type}_role`}
            defaultValue=""
          >
            <option disabled value="">Select Role</option>
            {this.roleOptions(true)}
          </Form.Control>
        </td>
        <td className="text-center">
          <Form.Check
            custom
            as="input"
            id={selectedUser.id}
            name="analyticsAccess"
            defaultChecked
          />
        </td>
        <td className="text-right">
          <Button
            className="mr-1"
            size="sm"
            type="reset"
            variant="secondary"
            onClick={() => {
              this.setState({
                addUser: false,
                selectedUser: null,
              });
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={disabled}
            className="ml-1"
            size="sm"
            type="submit"
            variant="primary"
          >
            Save
          </Button>
        </td>
      </tr>
    );
  }

  roleOptions(addNew) {
    const { obj, user, type } = this.props;
    const { shareWithUsers } = this.state;

    let roles = role[`${type}`];
    roles = roles.filter((el) => el.hide !== true);
    const isSharedUserNumberExceeded = shareWithUsers.filter((shareWithUser) => shareWithUser.book_role !== 'exclusive-access').length >= maxUsers;
    if ((shareWithUsers && Array.isArray(shareWithUsers) && isSharedUserNumberExceeded) && addNew) {
      roles = roles.filter((el) => el.role === 'exclusive-access');
    }
    if (!obj.isPrompt || !obj.exclusiveAccess) {
      roles = roles.filter((el) => el.role !== 'exclusive-access');
    }
    if (obj.original === true && obj.sourceBookId && !obj.isPrompt) {
      roles = roles.filter((el) => el.role !== 'co-author');
    }
    if (user.role !== 'admin') {
      roles = roles.filter((el) => el.role !== 'owner');
    }
    return roles.map((object, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <option value={object.role} key={i}>{object.label}</option>
    ));
  }

  listUser() {
    const { user, type, disabled } = this.props;
    const {
      shareWithUsers, editUser, owner, addUser,
    } = this.state;

    const currentSharedUser = shareWithUsers.find((shareWithUser) => shareWithUser.id === user.id);

    return shareWithUsers.map((shareWithUser) => (
      <tr key={shareWithUser.id}>
        <td>
          {getUserDisplayName(shareWithUser)}
          {(editUser === shareWithUser.id) && (
          <Form.Control
            type="hidden"
            name="user_id"
            defaultValue={shareWithUser.id}
          />
          )}
        </td>
        <td>
          {editUser === shareWithUser.id ? (
            <Form.Control
              required
              size="sm"
              defaultValue={shareWithUser[`${type}_role`].replace('-pending', '')}
              as="select"
              name={`${type}_role`}
            >
              {this.roleOptions(false)}
            </Form.Control>
          )
            : type && role[`${type}`].find((e) => e.role === shareWithUser[`${type}_role`]).label}
        </td>
        {(user.role === 'admin' || owner.id === user.id)
            && (
            <td className="text-center">
              <Form.Check
                custom
                as="input"
                id={shareWithUser.id}
                name="analyticsAccess"
                disabled={editUser !== shareWithUser.id}
                defaultChecked={shareWithUser.analyticsAccess}
              />
            </td>
            )}
        <td className="text-right">
          {editUser === shareWithUser.id ? (
            <>
              <Button
                className="mr-1"
                size="sm"
                type="reset"
                variant="secondary"
                onClick={() => {
                  this.setState({
                    editUser: null,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                className="mr-1"
                size="sm"
                type="submit"
                variant="primary"
                disabled={disabled}
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <OverlayTrigger
                overlay={(
                  <Tooltip id="tooltip-top">
                    Edit
                  </Tooltip>
                  )}
              >
                <Button
                  disabled={
                      (shareWithUser[`${type}_role`] === 'owner')
                      || addUser
                      || editUser
                      || !owner
                      || !user
                      || (user.role !== 'admin' && owner.id !== user.id)
                      || disabled
                    }
                  variant="secondary"
                  size="sm"
                  className="mx-1"
                  onClick={() => this.setState({ editUser: shareWithUser.id })}
                >
                  <img src={imgEditStory} className="btnImg" alt="Edit" />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                overlay={(
                  <Tooltip id="tooltip-top">
                    Delete
                  </Tooltip>
                  )}
              >
                <Button
                  disabled={(shareWithUser[`${type}_role`] === 'owner') || addUser || editUser || !owner || !user || (user.role !== 'admin' && !['owner'].includes(currentSharedUser[`${type}_role`]) && !(['editor', 'co-author', 'viewer'].includes(currentSharedUser[`${type}_role`]) && user.id === shareWithUser.id)) || disabled}
                  onClick={() => {
                    this.setState({
                      modalDeleteUserShow: true,
                      obj: shareWithUser,
                    });
                  }}
                  variant="secondary"
                  size="sm"
                  className="mx-1"
                >
                  <img src={imgDelete} className="btnImg" alt="Delete" />
                </Button>
              </OverlayTrigger>
            </>
          )}
          {this.deleteUser()}
        </td>
      </tr>
    ));
  }

  handleSubmit(event) {
    const { type } = this.props;
    const { editUser, shareWithUsers } = this.state;

    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const shareWithUserParameters = serialize(form, { hash: true });

    if (validated === false) {
      this.errorAlert('Validation error');
      return;
    }
    this.setState({ validated });
    const newUserRole = shareWithUserParameters[`${type}_role`];

    switch (newUserRole) {
      case 'co-author': {
        const currentSharedUser = shareWithUsers.find((sharedUser) => sharedUser.id === editUser);
        const isNewUser = !currentSharedUser;
        const isRoleChanged = !currentSharedUser?.book_role.includes(newUserRole);
        shareWithUserParameters.analyticsAccess = shareWithUserParameters.analyticsAccess === 'on';
        if (isRoleChanged || isNewUser) {
          this.setState({
            modalCoAuthor: true,
            serializeObj: shareWithUserParameters,
          });
        } else {
          this.addUser(shareWithUserParameters, validated);
        }
        break;
      }
      case 'owner':
        shareWithUserParameters.analyticsAccess = true;
        this.setState({
          modalRole: true,
          serializeObj: shareWithUserParameters,
        });
        break;
      default:
        shareWithUserParameters.analyticsAccess = false;
        this.addUser(shareWithUserParameters, validated);
        break;
    }
  }

  addUser(value, validated) {
    const { obj, type } = this.props;

    if (validated === true) {
      let url = `/v1/folders/${obj.id}/share`;
      if (type === 'story') {
        url = `/v1/stories/${obj.id}/share`;
      }
      if (type === 'book') {
        url = `/v1/books/${obj.id}/share`;
      }
      api.post(url, value)
        .then(() => {
          this.setState({
            editUser: null,
            validated: null,
            addUser: false,
            shareWithUsers: [],
          });
          this.loadData();
        });
    }
  }

  handleSearch = async (searchString, page = 1) => {
    const { data } = await api.get('/v1/users', {
      params: {
        search: searchString,
        limit: 5,
        offset: 5 * (page - 1),
      },
    });
    return { options: data.users, totalCount: data.totalCount };
  };

  render() {
    const {
      obj, user, type, disabled,
    } = this.props;
    const {
      validated,
      shareWithUsers,
      modalCoAuthor,
      serializeObj,
      addUser: addUser1,
      formError,
      selectedUser,
      modalRole,
      editUser,
      owner,
    } = this.state;

    let title = 'Share Folder:';
    if (type === 'story') {
      title = 'Share Episode:';
    }
    if (type === 'book') {
      title = 'Share Story:';
    }

    const exclusiveAccess = obj.isPrompt && obj.exclusiveAccess;

    const maxUsersReached = shareWithUsers && Array.isArray(shareWithUsers) && shareWithUsers.filter((el) => el.book_role !== 'exclusive-access').length >= maxUsers;

    return (
      <>
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {title}
              <span>
                {doubleQuote}
                {obj.title}
                {doubleQuote}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
              <Alert variant="danger">
                {formError}
              </Alert>
            </Col>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => this.handleSubmit(e)}
            >
              <Table striped hover size="md">
                <thead>
                  <tr>
                    <th className="py-2 size-35">User</th>
                    <th className="py-2 size-25">Role</th>
                    {(user.role === 'admin' || owner?.id === user.id) && (
                      <th
                        className="py-2 size-10 text-center"
                        title="Can see story analytics"
                      >
                        Analytics
                      </th>
                    )}
                    <th className="mr-3 size-30 text-right">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.listUser()}
                </tbody>
                <tfoot>
                  {this.addUserForm()}
                </tfoot>
              </Table>
            </Form>
            <Row>
              <Col md={6} className="search-user-area">
                {addUser1
                  || editUser
                  || !owner
                  || !user
                  || disabled
                  || (user.role !== 'admin' && owner.id !== user.id)
                  || (maxUsersReached && !exclusiveAccess)
                  ? null
                  : (
                    <SelectAsyncPaginate
                      placeholder="Find user by name or email..."
                      showPerPage={5}
                      labelKey={(option) => `${option.fullname}`}
                      onSearch={this.handleSearch}
                      onSelect={(item) => this.setState({ selectedUser: item[0] })}
                    />
                  )}
                <Button
                  hidden={
                    addUser1
                    || editUser
                    || !owner
                    || !user
                    || disabled
                    || (user.role !== 'admin' && owner.id !== user.id)
                    || (maxUsersReached && !exclusiveAccess)
                  }
                  className="search-user-btn"
                  size="sm"
                  variant="secondary"
                  onClick={() => {
                    if (!selectedUser) return;
                    this.setState({
                      addUser: true,
                    });
                  }}
                >
                  Add User
                </Button>
              </Col>
              <Col md={6} />
            </Row>
          </Modal.Body>
        </Modal>

        {modalRole && (
        <ConfirmModal
          show
          className="modalRole"
          title="Change Lead role"
          onConfirm={() => {
            this.addUser(serializeObj, validated);
            this.handleCloseModalRole();
          }}
          onCancel={this.handleCloseModalRole}
        >
          <h5>Change Lead Author?</h5>
        </ConfirmModal>
        )}

        {modalCoAuthor && (
        <ConfirmModal
          show
          title="Assigning a Co-Author"
          onConfirm={() => {
            this.addUser(serializeObj, validated);
            this.handleCloseModalCoAuthor();
          }}
          onCancel={this.handleCloseModalCoAuthor}
        >
          <p>
            {/* eslint-disable-next-line max-len */}
            Since you are the Lead Author on this Story, should the Story generate revenue all money generated by this
            Story will go to your account.
            {/* eslint-disable-next-line max-len */}
            You are responsible for working out with your co-authors how money will be shared among you. Dorian will
            not be responsible in the event of any disputes between you and your co-authors.
          </p>
        </ConfirmModal>
        )}

      </>
    );
  }
}
