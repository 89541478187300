import React, { forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BranchesWidgetFilter } from './BranchesWidgetFilter';
import { BranchesWidgetList } from './BranchesWidgetList';
import { BranchesWidgetContext } from './context';
import { BranchesWidgetData } from './context/BranchesWidgetData';
import './style.scss';

const CustomToggle = forwardRef(function CustomToggle(props, ref) {
  const { onClick } = props;
  const { value } = React.useContext(BranchesWidgetContext);
  return (
    <a
      href="!#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {value ? value.title : 'Select Branch'}
      {' '}
      &#x25bc;
    </a>
  );
});

const CustomMenu = forwardRef(function CustomMenu(props, ref) {
  const {
    children,
    style,
    className,
    'aria-labelledby': labeledBy,
  } = props;
  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <div className="py-3 px-4">
        <BranchesWidgetFilter />
      </div>
      <ul className="list-unstyled">
        {children}
      </ul>
    </div>
  );
});

export function BranchesWidget(props) {
  const {
    isSelected,
    style,
    branchesList,
    onSelected,
    className,
    currentBranchId,
    storiesID,
    type,
  } = props;

  if (type === 'Dropdown') {
    return (
      <BranchesWidgetData
        storiesID={storiesID}
        isSelected={isSelected}
        onSelected={onSelected}
      >
        <Dropdown
          className={`branchesWidgetDropdown ${className}`}
          style={style}
        >
          <Dropdown.Toggle
            as={CustomToggle}
            id="dropdown-custom-components"
          />
          <Dropdown.Menu as={CustomMenu}>
            <BranchesWidgetList
              type="Dropdown"
            />
          </Dropdown.Menu>
        </Dropdown>
      </BranchesWidgetData>
    );
  }

  return (
    <BranchesWidgetData
      branchesList={branchesList}
      currentBranchId={currentBranchId}
      storiesID={storiesID}
      isSelected={isSelected}
      onSelected={onSelected}
    >
      <div
        className={`branchesWidget ${className}`}
        style={style}
      >
        <BranchesWidgetFilter />
        <BranchesWidgetList />
      </div>
    </BranchesWidgetData>
  );
}
