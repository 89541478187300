import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Avatar from '../../../../../assets/images/avatar.png';
import '../../News.scss';
import { NewsItemDropdown } from './NewsItemDropdown';

export function NewsItem(props) {
  const {
    type, loading, data, update, className,
  } = props;

  if (!data) {
    return null;
  }

  if (loading) {
    return (
      <Card className={`NewsItem flex-row flex-wrap card-horizontal ${className}`}>
        <div className="card-horizontal">
          <div className="img-square-wrapper" />
          <Card.Body className="NewsItemBody">
            <Card.Title className="NewsItemTitle" />
            <Card.Text className="NewsItemContent" />
            <Card.Text className="CardAvatar-box" />
          </Card.Body>
        </div>
      </Card>
    );
  }

  const imgUrl = (obj) => {
    if (!obj && data.owner?.image?.imageUrl) {
      return data.owner.image.imageUrl;
    }
    if (obj?.image?.imageUrl) {
      return obj.image.imageUrl;
    }
    return Avatar;
  };

  const propsCanvas = !data
      || !data.cover
      || !data.cover.character
      || data.cover.character.length < 0
      || !data.cover.character.properties
      || data.cover.character.properties.length < 0
    ? null : data.cover.character.properties;
  if (propsCanvas && data.cover.character.expression) {
    propsCanvas.expression = data.cover.character.expression;
  }

  const autor = () => {
    if (data?.owner?.fullname) {
      return (
        <i>
          {' '}
          -
          {data.owner.fullname}
        </i>
      );
    }

    if (data?.users?.length > 0) {
      const user = data.users.find((a) => a.story_role === 'owner');
      if (user.fullname) {
        return (
          <i>
            {' '}
            -
            {user.fullname}
          </i>
        );
      }
    }

    if (data?.originalBook?.users?.length > 0) {
      const user = data.originalBook.users.find((a) => a.story_role === 'owner');
      if (user.fullname) {
        return (
          <i>
            {' '}
            -
            {user.fullname}
          </i>
        );
      }
    }
    return '';
  };

  const original = data?.originalBook?.original ?? false;

  return (
    <Card className={`NewsItem flex-row flex-wrap card-horizontal ${className}`}>
      <div className="card-horizontal">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          className={`img-square-wrapper ${data.id && !data.cover ? 'link' : ''}`}
          to={!data.promptActiveContest && (
            type !== 'trending' && type !== 'prompts'
          ) ? `/book/${data.id}` : '#'}
        >
          {(data && data.imageAlt && data.imageAlt.imageUrl) && (
          <img
            src={data.imageAlt.imageUrl}
            className="card-img-left"
            alt=""
          />
          )}
          {(data && !data.imageAlt?.imageUrl && data.image?.imageUrl) && (
          <img
            src={data.image.imageUrl}
            className="card-img-left"
            alt=""
          />
          )}
        </Link>
        <Card.Body className="NewsItemBody">
          <Card.Title className="NewsItemTitle">
            <h5 className="h5">
              {data.id && !data.promptActiveContest && (type !== 'trending' && type !== 'prompts')
                ? (
                  <Link
                    className="books-list-link"
                    to={`/book/${data.id}`}
                  >
                    {data.title}
                  </Link>
                )
                : data.title}
            </h5>

            <NewsItemDropdown
              {...props}
            />

          </Card.Title>

          <Card.Title className="NewsItemAuthor">
            <h5 className="h5">
              <>{autor()}</>
            </h5>
          </Card.Title>

          <Card.Text className="NewsItemContent">
            {data.description || ''}
          </Card.Text>
          <Card.Text className="CardAvatar-box">
            {data?.owner?.fullname && (
              <span className="CardAvatar">
                <img alt={data.owner.fullname || ''} src={imgUrl()} className="avatar" />
                {data.owner.fullname || ''}
              </span>
            )}
            {(data.users?.length > 0)
                && data.users.map((user, i) => (
                  user.fullname
                  && user.story_role
                  && (user.story_role === 'owner' || user.story_role === 'co-author')
                ) && (
                // eslint-disable-next-line react/no-array-index-key
                <span className="CardAvatar" key={i}>
                  <img alt={user.fullname || ''} src={imgUrl(user)} className="avatar" />
                  {user.fullname || ''}
                </span>
                ))}
            {(type === 'trending' || (type === 'prompts' && data.promptActiveContest)) && (
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                update(data.id, original);
              }}
            >
              {type === 'trending' ? 'Create Story' : 'Enter Now'}
            </Button>
            )}
          </Card.Text>
        </Card.Body>
      </div>
    </Card>
  );
}
