import { CheckOperator, StepCheck } from 'components/pages/StoryBranches/BranchEdit/Steps/StepTypeCheck';
import { MemorySlotsBase } from './memoryBankTypes';

export function getCheckOperatorByName(operatorName: string) {
  const foundKey = Object.keys(CheckOperator).find((operatorKey) => {
    const operatorObject = CheckOperator[operatorKey];
    return operatorObject.name === operatorName;
  });

  if (foundKey) {
    return CheckOperator[foundKey];
  }

  return null;
}

export function getMemoryBankSlotDataById(
  memoryBank: MemorySlotsBase[] | null,
  checkData?: StepCheck,
) {
  if (!memoryBank || !checkData) {
    return null;
  }
  const slot = memoryBank.find(
    (memoryBankSlot) => memoryBankSlot.id === Number(checkData.variableId),
  );
  const operator = checkData ? getCheckOperatorByName(checkData.operator)?.short : '';

  if (!operator || !slot) {
    return null;
  }
  return {
    name: slot.name,
    value: checkData.value,
    operator,
  };
}
