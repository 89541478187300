import React from 'react';
import {
  Button, Modal, Spinner,
} from 'react-bootstrap';
import classNames from './ConfirmModal.module.scss';

export function ConfirmModal({
  children,
  isLoading,
  title,
  onConfirm,
  onCancel,
  ...otherProps
}) {
  return (
    <Modal
      {...otherProps}
    >
      <Modal.Header className={classNames.header}>
        <Modal.Title className={classNames.title}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={classNames.body}>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button
            type="button"
            variant="secondary"
            className={classNames.button}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="primary"
            className={classNames.button}
            onClick={onConfirm}
          >
            {isLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            )}
            Confirm
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
