import React, { Component } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { api } from '../../api';
import { ErrorAlert } from '../../ui/Errors/ErrorAlet';
import './DeleteBranch.scss';

export class DeleteBranch extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoading: false,
      errorMsg: null,
    };
  }

  deleteBranch(id, obj) {
    const {
      onHide, update, storyId, onDeleteSuccess,
    } = this.props;

    api.delete(`/v1/stories/${storyId}/branches/${id}`)
      .then(() => {
        onDeleteSuccess();
        if (obj && obj.length > 0) {
          obj.splice(0, 1);
          if (!obj || obj.length === 0) {
            this.setState({ showLoading: false });
            onHide();
            update();
          } else {
            this.deleteBranch(obj[0], obj);
          }
        } else {
          this.setState({ showLoading: false });
          onHide();
        }
      })
      .catch((error) => {
        this.errorAlert(error);
        this.setState({ showLoading: false });
        onHide();
        update();
      });
  }

  deleteBranchAction() {
    const { obj, branch } = this.props;

    this.setState({ showLoading: true });
    const a = branch && obj.length > 0 ? obj[0] : obj.id;
    const b = (branch && obj.length > 0) && obj;
    this.deleteBranch(a, b);
  }

  errorAlert = (error) => {
    this.setState({
      errorMsg: error,
    });
    setTimeout(() => {
      this.errorAlertClose();
    }, 3000);
  };

  errorAlertClose = () => {
    this.setState({
      errorMsg: null,
    });
  };

  render() {
    const {
      storyId, update, obj, branch, onHide, onDeleteSuccess, ...other
    } = this.props;
    const { errorMsg, showLoading } = this.state;

    const title = () => {
      if (branch && obj.length > 0) {
        const b = [];
        branch.forEach((a, i) => {
          if (obj.includes(a.id)) {
            b.push(
              // eslint-disable-next-line react/no-array-index-key
              <i key={i}>
                {a.title}
                <br />
              </i>,
            );
          }
        });
        return b;
      }
      if (!branch && obj.title) {
        return obj.title;
      }
      return null;
    };

    return (
      <>
        <ErrorAlert error={errorMsg} close={this.errorAlertClose} />

        <Modal
          obj={obj}
          branch={branch}
          onHide={onHide}
          {...other}
          size={branch && obj.length > 0 ? 'md' : 'sm'}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Delete
              {' '}
              {(branch && obj.length > 0) && obj.length}
              {' '}
              Node
              {(branch && obj.length > 0) && '(s)'}
              ?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-center" style={{ maxHeight: '50vh' }}>
              Do you really want to delete the Node
              {(branch && obj.length > 0) && '(s)'}
              ?
              <br />
              <span>{title()}</span>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="reset"
              variant="secondary"
              onClick={onHide}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={() => this.deleteBranchAction()}
              disabled={showLoading}
            >
              {showLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
