import React, { useState } from 'react';
import {
  Button, Modal, ModalBody, ModalFooter, ModalTitle, Spinner,
} from 'react-bootstrap';
import Cropper from 'react-cropper';
import { showToast } from '../../../ui/utils';
import './AvatarImageUploader.scss';
import { calculateMaxSideByAspectRatio, convertCanvasToFileWithCropper } from './utils';

export type AvatarImageUploaderProps = {
  imageSrc: string;
  onSave: (imageSrc: File) => void
  onCancel: () => void,
  isLoading: boolean,
}

const acceptFileTypes = '.jpg,.jpeg,.png,.webp';

export function AvatarImageUploader(props: AvatarImageUploaderProps) {
  const {
    imageSrc, onSave, onCancel, isLoading,
  } = props;

  const [cropper, setCropper] = useState<Cropper>();

  const handleAvatarImageSave = () => {
    if (!cropper) {
      showToast({
        textMessage: 'Error: Cropper initialisation error.',
      });
      return;
    }
    const { aspectRatio } = cropper.getImageData();
    const { maxWidth, maxHeight } = calculateMaxSideByAspectRatio(aspectRatio);

    convertCanvasToFileWithCropper(
      cropper,
      {
        maxWidth,
        maxHeight,
        imageSmoothingQuality: 'high',
        imageSmoothingEnabled: true,
      },
      'newAvatar.jpg',
      'image/jpeg',
    ).then((file) => {
      if (file && file instanceof File) {
        onSave(file);
      }
    }).catch((error) => {
      showToast({
        textMessage: error.message,
      });
    });
  };

  const onChangeFile = (event: { target: HTMLInputElement}) => {
    if (!event.target.files) {
      showToast({
        textMessage: 'File not selected',
      });
      return;
    }

    const file = event.target.files[0];
    if (!file) {
      showToast({
        textMessage: 'File not selected',
      });
      return;
    }
    if (!file.type.includes('image/')) {
      showToast({
        textMessage: 'File type not supported',
      });
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileImageSrc = fileReader.result?.toString() ?? '';
      cropper?.replace(fileImageSrc);
    };
    fileReader.readAsDataURL(file);
  };

  return (
    <Modal
      show
      size="lg"
      onHide={onCancel}
    >
      <ModalTitle>
        <div className="text-center mt-3">
          Avatar image
        </div>
      </ModalTitle>
      <ModalBody>
        <div className="avatarImageCropContainer">
          <Cropper
            className="avatarImageCropper"
            style={{ height: '100%', width: '100%' }}
            zoomTo={0}
            zoomable={false}
            zoomOnWheel={false}
            dragMode="crop"
            aspectRatio={1}
            minContainerHeight={600}
            minContainerWidth={600}
            minCropBoxHeight={100}
            minCanvasHeight={300}
            autoCropArea={1}
            restore={false}
            center={false}
            toggleDragModeOnDblclick={false}
            initialAspectRatio={1}
            src={imageSrc}
            viewMode={1}
            background
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance: Cropper) => setCropper(instance)}
          />
        </div>
        <div className="text-center mt-3">
          <input
            type="file"
            accept={acceptFileTypes}
            id="uploadFile"
            onChange={onChangeFile}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={onCancel}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleAvatarImageSave}
          disabled={isLoading}
        >
          {isLoading && (
            <Spinner
              size="sm"
              variant="secondary"
              animation="border"
            />
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
