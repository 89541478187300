import React, { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { MemorySlotsBase, MemorySlotsValue } from 'components/pages/Book/MemoryBank/memoryBankTypes';
import { MemorySlotsTypesList } from 'components/pages/Book/MemoryBank/MemoryBankTypesList';
import { Character } from 'dorian-shared/types/character/Character';
import { MemorySlotFormValue } from './MemorySlotCompareForm';
import { MemorySlotCompareValueForm } from './MemorySlotCompareValueForm';

export type MemoryRememberAction = {
  variable: string,
  variableId: number,
  type: string,
  value: MemorySlotsValue,
}

export const RememberActionType: Record<string, { name: string, title: string}> = {
  SET: {
    name: 'set',
    title: 'is now',
  },
  INCREASE: {
    name: 'increase',
    title: 'went up by',
  },
  DECREASE: {
    name: 'decrease',
    title: 'went down by',
  },
};

type StepTypeRememberProps = {
  stepIndex: number,
  memoryBankSlots: MemorySlotsBase[],
  handleChangeStepAction: (
    values: MemorySlotFormValue[],
    stepIndex: number,
  ) => void,
  action: MemoryRememberAction,
  addStepAction: (
    stepIndex: number,
    variableId: number,
    variable: string,
    type: string,
    value: MemorySlotsValue,
  ) => void,
  characters: Character[],
  isDisabled?: boolean,
}

export function StepTypeRemember(props: StepTypeRememberProps) {
  const {
    stepIndex, memoryBankSlots, handleChangeStepAction, action, addStepAction, characters,
    isDisabled,
  } = props;

  const variableInputValue = action && action.variableId ? action.variableId : 0;
  const typeInputValue = action && action.type ? action.type : '';
  const valueInputValue = action && action.value ? action.value : '';

  const isRememberStepPossible = memoryBankSlots && memoryBankSlots.length > 0;
  const memoryBankSlot = memoryBankSlots.find((slot) => slot.id === Number(variableInputValue));

  useEffect(() => {
    if (!isRememberStepPossible) {
      return;
    }

    // prevent re-adding action object if already exists
    if (action && action.variableId && action.type) {
      return;
    }

    const variable = memoryBankSlots[0];
    const type = RememberActionType.SET.name;
    const value = '';
    addStepAction(stepIndex, variable.id, variable.name, type, value);
  }, [action, addStepAction, isRememberStepPossible, memoryBankSlots, stepIndex]);
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      { isRememberStepPossible
        ? (
          <Col>
            <Row
              xs={1}
              sm={1}
              lg={3}
            >
              <Form.Group
                as={Col}
                className="mb-0 px-1 d-block"
              >
                <Row>
                  <Col md={4} lg={3}>
                    <Form.Label column>
                      That
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      disabled={isDisabled}
                      size="sm"
                      as="select"
                      name={`steps[${stepIndex}][action][variableId]`}
                      value={variableInputValue}
                      onChange={(event) => handleChangeStepAction([{
                        name: 'variableId',
                        value: event.target.value,
                      }], stepIndex)}
                    >
                      {memoryBankSlots.map((slot) => (
                        <option key={slot.id} value={slot.id}>
                          {slot.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                as={Col}
                className="mb-0 px-1 d-block"
              >
                <Form.Control
                  disabled={isDisabled}
                  size="sm"
                  as="select"
                  name={`steps[${stepIndex}][action][type]`}
                  value={typeInputValue}
                  onChange={(event) => handleChangeStepAction([{
                    name: 'type',
                    value: event.target.value,
                  }], stepIndex)}
                >
                  {Object.keys(RememberActionType).map((actionTypeKey) => {
                    const inputAction = RememberActionType[actionTypeKey];

                    // prevent showing increase/decrease when slot is different than number
                    if (
                      memoryBankSlot?.type !== MemorySlotsTypesList.number
                      && inputAction.name !== RememberActionType.SET.name
                    ) {
                      return null;
                    }
                    return (
                      <option key={inputAction.name} value={inputAction.name}>
                        {RememberActionType[actionTypeKey].title}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Col className="mb-0 px-1 d-block">
                <MemorySlotCompareValueForm
                  onChangeValue={handleChangeStepAction}
                  stepIndex={stepIndex}
                  valueInputValue={valueInputValue}
                  characters={characters}
                  memoryBankSlot={memoryBankSlot}
                  inputName={`steps[${stepIndex}][action][value]`}
                  isDisabled={isDisabled}
                />
              </Col>
            </Row>
          </Col>
        )
        : <Row>Memory bank is empty.</Row>}
    </>
  );
}
