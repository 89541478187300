import serialize from 'form-serialize';
import JoditEditor from 'jodit-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal,
} from 'react-bootstrap';
import { api } from '../../api';

export class EditArticle extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      validated: false,
      formError: null,
      // eslint-disable-next-line react/destructuring-assignment
      data: this.props.obj || {
        title: '',
        body: '',
        order: '',
      },
    };

    this.contentRef = React.createRef();
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.obj.id) {
      this.loadData();
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true, empty: true });
    if (validated === false) {
      event.stopPropagation();
    } else {
      this.addArticle(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  loadData() {
    this.setState({
      loading: true,
    });
    // eslint-disable-next-line react/destructuring-assignment
    api.get(`v1/blog/posts/${this.props.obj.id}`)
      .then((res) => {
        this.setState({
          loading: false,
          data: res.data.post,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  addArticle(value, validated) {
    if (validated === true) {
      // eslint-disable-next-line react/destructuring-assignment
      if (this.props.obj.id) {
        // eslint-disable-next-line react/destructuring-assignment
        api.put(`/v1/blog/posts/${this.props.obj.id}`, value)
          .then(() => {
            // eslint-disable-next-line react/destructuring-assignment
            this.props.update();
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
          });
      } else {
        api.post('/v1/blog/posts', value)
          .then(() => {
            // eslint-disable-next-line react/destructuring-assignment
            this.props.update();
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
          });
      }
    }
  }

  render() {
    const { validated } = this.state;
    // eslint-disable-next-line react/destructuring-assignment
    const title = this.props.obj.id ? 'Edit Article' : 'Create Article';
    const config = {
      readonly: false,
      disablePlugins: 'table,tablekeyboardnavigation,tooltip,symbols,sticky,stat,search,resizer,media,limit,justify,iframe',
      buttons: ',,,,,,|,,ul,ol,,,,,font,fontsize,,paragraph,align,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr',
    };
    const { update, onHide, ...props } = this.props;
    const { formError, loading, data } = this.state;

    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
      >

        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >

          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
              <Alert variant="danger">
                {formError}
              </Alert>
            </Col>

            {
              loading ? null
                : (
                  <>
                    <Form.Group as={Col} sm={12} xs={12}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Title"
                        value={data.title || ''}
                        onChange={(e) => {
                          const { newData } = this.state;
                          newData.title = e.target.value;
                          this.setState({
                            data,
                          });
                        }}
                        name="title"
                      />
                    </Form.Group>

                    <Form.Group as={Col} sm={12} xs={12}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="order"
                        value={data.order || ''}
                        onChange={(e) => {
                          const { newData } = this.state;
                          newData.order = e.target.value;
                          this.setState({
                            data,
                          });
                        }}
                        name="order"
                      />
                    </Form.Group>

                    <Form.Group as={Col} sm={12} xs={12}>
                      <JoditEditor
                        ref={this.contentRef}
                        value={data.body || ''}
                        name="body"
                        config={config}
                        tabIndex={0}
                      />
                    </Form.Group>
                  </>
                )
              }
          </Modal.Body>

          <Modal.Footer>
            <Button
              type="reset"
              variant="secondary"
              onClick={() => onHide()}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="primary"
              disabled={loading}
            >
              Save
            </Button>

          </Modal.Footer>

        </Form>
      </Modal>
    );
  }
}

EditArticle.propTypes = {
  onHide: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};
