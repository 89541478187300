import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import imgAnalyticHeart from '../../../assets/images/favorite-24px.svg';
import imgCurrencyUser from '../../../assets/images/Friend.svg';
import IconHeart from '../../../assets/images/icon-heart-glow.svg';
import imgTraitAnimal6 from '../../../assets/images/traitanimals/icon-romance-portrait-512.png';
import imgTraitAnimal1 from '../../../assets/images/traitanimals/traitportraitambition512.png';
import imgTraitAnimal3 from '../../../assets/images/traitanimals/traitportraitcreativity512.png';
import imgTraitAnimal4 from '../../../assets/images/traitanimals/traitportraitcuriosity512.png';
import imgTraitAnimal8 from '../../../assets/images/traitanimals/traitportraithumor512.png';
import imgTraitAnimal2 from '../../../assets/images/traitanimals/traitportraitindependence512.png';
import imgTraitAnimal5 from '../../../assets/images/traitanimals/traitportraitkindness512.png';
import imgTraitAnimal7 from '../../../assets/images/traitanimals/traitportraitloyalty512.png';
import { markupToHTML } from '../../../helpers/markupToHTML';
import './Preview.scss';

const traitImgs = {
  ambition: imgTraitAnimal1,
  independence: imgTraitAnimal2,
  creativity: imgTraitAnimal3,
  curiosity: imgTraitAnimal4,
  kindness: imgTraitAnimal5,
  romance: imgTraitAnimal6,
  loyalty: imgTraitAnimal7,
  humor: imgTraitAnimal8,
};

export function numberForUser(num) {
  const v = ' kMGTPE';
  let cnt = 0;
  let tmp = num;
  while (tmp >= 1000.0) {
    tmp /= 1000.0;
    cnt += 1;
  }
  if (tmp > 100) {
    tmp = Math.round(tmp);
  } else {
    tmp = Number.parseFloat(tmp).toFixed(1).toString().replace('.0', '');
  }
  return `${tmp}${v[cnt]}`;
}

export class TextPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  checkBonusContent(answer) {
    return answer.modifiers && answer.modifiers.find((e) => e.value === 'bonus_content');
  }

  componentDidUpdate(prevProps) {
    const { btnNumber, index } = this.props;

    if (prevProps.btnNumber !== btnNumber && btnNumber === index) {
      window.addEventListener('keydown', this.keyPress, { passive: false });
    } else {
      window.removeEventListener('keydown', this.keyPress);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyPress);
  }

  keyPress = (e) => {
    if (e.keyCode === 39) {
      this.nextStep();
    }
  };

  nextStep() {
    const {
      arr, addNewEpisode, answerGotoAction, index, disabled,
    } = this.props;

    if (!arr.gotoBranchId && !disabled) {
      addNewEpisode({ index, id: arr.id });
    }
    answerGotoAction(arr.gotoBranchId);
  }

  answerImage = (obj) => {
    if (obj?.length > 0 && !window.$traits) {
      return (
        <div className="answerPlayImageBox">
          <img
            className={obj[0].requireType.value}
            src={traitImgs[obj[0].requireType.value]}
            alt=""
          />
        </div>
      );
    }
    return null;
  };

  btnVariant = (obj) => {
    if (this.checkBonusContent(obj)) {
      return 'goldBorder';
    }
    if (obj?.requires?.length > 0) {
      switch (obj.requires[0].requireType.value) {
        case 'loyalty':
          return 'info';
        case 'creativity':
          return 'yellow';
        case 'ambition':
          return 'primary';
        case 'curiosity':
          return 'success';
        case 'humor':
          return 'warning';
        case 'independence':
          return 'purple';
        case 'kindness':
          return 'secondary';
        default:
          return 'success';
      }
    }
    return 'success';
  };

  analyticChoicePoints = () => {
    const { analyticsData, showAnalytics } = this.props;

    if (showAnalytics && analyticsData === 'percentage') {
      const { analytics } = this.props;
      const analyticsValue = analytics?.percent_users ?? 0;
      return (
        <div
          title="choice points"
          className="analytic_answer_box"
          style={{ backgroundImage: `url(${imgCurrencyUser})`, whiteSpace: 'nowrap' }}
        >
          {`${numberForUser(analyticsValue).trim()}%`}
        </div>
      );
    }
    return null;
  };

  analyticHeartPrice = () => {
    const { analyticsData, showAnalytics } = this.props;

    if (showAnalytics && analyticsData === 'counts') {
      const { analytics } = this.props;
      const analyticsValue = analytics && analytics.hearts !== undefined ? analytics.hearts : 0;
      return (
        <div
          className="analytic_answer_box"
          style={{ backgroundImage: `url(${imgAnalyticHeart})` }}
        >
          {analyticsValue}
        </div>
      );
    }
    return null;
  };

  analyticAnswerleft = () => {
    const { analytics, analyticsData, showAnalytics } = this.props;

    if (!showAnalytics || analyticsData !== 'counts') {
      return null;
    }

    const analyticsValue = analytics?.users ?? 0;

    return (
      <div
        className="analytic_answer_box"
        style={{ backgroundImage: `url(${imgCurrencyUser})` }}
      >
        {numberForUser(analyticsValue)}
      </div>
    );
  };

  analyticAnswerHearts = () => {
    const { analytics, analyticsData, showAnalytics } = this.props;

    if (!showAnalytics || analyticsData !== 'percentage') {
      return null;
    }

    const analyticsValue = analytics?.hearts ?? 0;
    return (
      <div
        className="analytic_answer_box"
        style={{ backgroundImage: `url(${imgAnalyticHeart})` }}
      >
        <span>{numberForUser(analyticsValue)}</span>
      </div>
    );
  };

  render() {
    const {
      arr,
      text,
      analyticsData,
      index,
      btnNumber,
      step,
      showAnalytics,
      type,
    } = this.props;

    if (type === 'answ' && step.stepTypeId === 3) {
      const { analytics } = this.props;
      return (
        <Button
          block
          onClick={() => this.nextStep()}
          variant={this.btnVariant(arr)}
          className={
            `mt-1 mb-1 ${
              arr && arr.answerType && arr.answerType.value
                ? `answerType_${arr.answerType.value}`
                : ''
            } ${
              btnNumber === index
                ? 'active'
                : ''
            } ${
              arr?.requires?.length > 0 && !window.$traits
                ? 'haveAnswerImage'
                : 'passiveChoiceBtn'
            } ${
              showAnalytics && analyticsData && analyticsData !== 'hide'
                ? 'showAnswerAnalytics'
                : ''
            }`
          }
        >
          {this.answerImage(arr.requires)}
          <span
            className={
              `${
                !window.$traits
                  ? 'analytic_answer_content'
                  : 'analytic_answer_content_not_img'
              } ${
                arr?.requires?.length > 0 && !window.$traits
                  ? 'haveAnswerImage'
                  : ''
              } ${
                showAnalytics && analytics
                  ? 'haveAnswerAnalytics'
                  : ''
              }`
            }
          >
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: markupToHTML(text) }} />
            {arr.answerPrice && arr.answerPrice > 0 ? (
              <i className="answerPrice">
                <i><img src={IconHeart} alt="heart" /></i>
                <i>{arr.answerPrice}</i>
              </i>
            ) : null}
          </span>
          <div className="analyticAnswerInfoBoxs">
            {this.analyticChoicePoints()}
            {this.analyticAnswerleft()}
            {this.analyticHeartPrice()}
            {this.analyticAnswerHearts()}
          </div>
        </Button>
      );
    }

    if (text) {
      return (
        <span
          className="textBox"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: markupToHTML(text) }}
        />
      );
    }

    return null;
  }
}
