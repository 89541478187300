import React, { ChangeEvent } from 'react';
import {
  Form,
} from 'react-bootstrap';
import { MemorySlotsTypes } from './memoryBankTypes';

export const MemorySlotsTypesList: MemorySlotsTypes = {
  string: 'string',
  number: 'number',
  boolean: 'boolean',
  character: 'character',
};

const memorySlotsTypesLabels: Record<string, string> = {
  string: 'Text',
  number: 'Number',
  boolean: 'Yes/No',
  character: 'Role',
};

type MemoryBankTypesListProps = {
  value: string,
  slotIndex: number,
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void,
}

export function MemoryBankTypesList(props: MemoryBankTypesListProps) {
  const {
    value,
    slotIndex,
    handleChange,
  } = props;
  return (
    <Form.Control
      as="select"
      required
      placeholder="type"
      name={`memoryBankSlots[${slotIndex}].type`}
      value={value ?? 'string'}
      onChange={handleChange}
    >
      {Object.keys(MemorySlotsTypesList).map((variableType) => (
        <option value={variableType} key={variableType}>
          {memorySlotsTypesLabels[variableType]}
        </option>
      ))}
    </Form.Control>
  );
}
