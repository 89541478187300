import { isEqual } from 'lodash-es';
import { Event } from '../../../dorian-shared/types/event/Event';
import { api } from '../../api';
import { showToast } from '../../ui/utils';
import { emptyPrize } from './components/EventForm/DetailedPrizes/DetailedPrizes';
import { DetailedPrize } from './components/EventForm/DetailedPrizes/DetailedPrizeType';

function createPrizeFormData(prize: DetailedPrize) {
  const prizeFormData = new FormData();
  prizeFormData.append('label', prize.label);
  if (prize.image) {
    prizeFormData.append('image', prize.image.file, prize.image.fileName);
  }
  return prizeFormData;
}

export async function updatePrizes(
  prizes: DetailedPrize[],
  eventId: number,
) {
  return Promise.all(prizes.map((prize) => {
    const prizeFormData = createPrizeFormData(prize);

    return api.put<{ event: Event }>(`/v1/events/${eventId}/prizes/${prize.id}`, prizeFormData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }));
}

export async function createPrizes(
  newDetailedEventPrizes: DetailedPrize[],
  eventId: number,
) {
  return Promise.all(newDetailedEventPrizes.map((prize) => {
    const prizeFormData = createPrizeFormData(prize);

    return api.post<{ event: Event }>(`/v1/events/${eventId}/prizes`, prizeFormData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }));
}

export function deletePrizes(prizeIdsToDelete: number[], eventId: number) {
  return Promise
    .all(
      prizeIdsToDelete.map((prizeId) => api.delete(`/v1/events/${eventId}/prizes/${prizeId}`)),
    )
    .catch(() => showToast({
      textMessage: 'There was an issue removing prize, please try again later or contact support.',
      timeout: 20000,
    }));
}

export function syncWithServerDetailedEventPrizes(
  newDetailedEventPrizes: DetailedPrize[],
  oldDetailedEventPrizes: DetailedPrize[],
  eventId: number,
) {
  const prizeIdsToDelete = oldDetailedEventPrizes
    .filter((oldPrize) => !newDetailedEventPrizes.find((newPrize) => newPrize.id === oldPrize.id))
    .map((oldPrize) => oldPrize.id)
    .filter(Boolean);

  const prizesToCreate = newDetailedEventPrizes
    .filter((newPrize) => !newPrize.id)
    .filter((newPrize) => !isEqual(newPrize, emptyPrize));

  const prizesToUpdate = newDetailedEventPrizes
    .filter((newPrize) => {
      const hasId = newPrize.id;
      const prevPrizeValue = oldDetailedEventPrizes.find((oldPrize) => oldPrize.id === newPrize.id);
      if (!prevPrizeValue) {
        return false;
      }
      const hasDifferentLabel = prevPrizeValue.label !== newPrize.label;
      const hasDifferentImageUrl = prevPrizeValue.imageUrl !== newPrize.imageUrl;
      return hasId && (hasDifferentLabel || hasDifferentImageUrl);
    });

  return Promise.all([
    // filter(boolean) doesn't work well with TS
    deletePrizes(prizeIdsToDelete as number[], eventId),
    updatePrizes(prizesToUpdate, eventId),
    createPrizes(prizesToCreate, eventId),
  ]);
}
