import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Spinner,
} from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { api } from '../../api';
import { checkExternal } from '../../pages/Home/PrivacyPolicy';
import { InputWriting } from './inputWriting';

export class SignUpForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      validated: null,
      username: '',
      email: '',
      code: '',
      firstName: '',
      lastName: '',
      receive_email: false,
      terms: false,
      social_media: '',
      showErrorAlert: '',
      loading: false,
      yourWriting: [],
      redirect: false,
    };
    const { location, auth } = this.props;
    this.user = auth && auth.getUser();

    this.app = (
      location
      && location.pathname
      && location.pathname === '/write-with-us-app'
    ) && 'app';
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location.hash !== location.hash) {
      this.setState({
        validated: null,
        username: '',
        email: '',
        code: '',
        firstName: '',
        lastName: '',
        receive_email: false,
        terms: false,
        social_media: '',
        showErrorAlert: '',
        loading: false,
        redirect: false,
      });
    }
  }

  checkForRegisteredEmail = () => {
    if (this.registeredEmailTimer) {
      clearTimeout(this.registeredEmailTimer);
    }

    try {
      if (localStorage.getItem('deviceId')) {
        api.post('/v1/login', { deviceSecret: localStorage.getItem('deviceId') })
          .catch(() => {
            // TODO: report to sentry
          })
          .then((res) => {
            if (res && res.status === 200 && res.data.user.email_verified) {
              const { auth } = this.props;
              auth.login(res.data);
            } else {
              this.registeredEmailTimer = setTimeout(
                this.checkForRegisteredEmail.bind(this),
                3000,
              );
            }
          });
      }
    } catch (err) {
      // TODO: report to sentry
    }
  };

  handleChangeName = (event) => {
    this.setState({ username: event.target.value, showErrorAlert: '' });
  };

  handleChangeFirstName = (event) => {
    this.setState({ firstName: event.target.value, showErrorAlert: '' });
  };

  handleChangeLastName = (event) => {
    this.setState({ lastName: event.target.value, showErrorAlert: '' });
  };

  handleChangeEmail = (event) => {
    localStorage.setItem('signUpEmail', event.target.value);
    this.setState({
      email: event.target.value,
      showErrorAlert: '',
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    if (validated === false) {
      event.stopPropagation();
    } else {
      this.setState({ loading: true });
      const {
        username,
        email,
        code,
        firstName,
        lastName,
        receive_email: receiveEmail,
        social_media: socialMedia,
        yourWriting,
      } = this.state;

      const stringifiedYourWriting = JSON.stringify(yourWriting);
      const approved = this.app;
      const formattedEmail = email.trim().toLowerCase();
      const createTemplate = JSON.parse(localStorage.getItem('createTemplate'));
      if (createTemplate) {
        createTemplate.writeWithUs = true;
      }
      if (this.user && !this.user.email) { // this doesnt exist yet
        api.post('v1/registerEmail', {
          email: formattedEmail,
          firstName,
          lastName,
          userId: this.user.id,
        }).then((res) => {
          this.setState({
            showErrorAlert: '',
            loading: false,
          });
          if (res && res.status === 200) {
            this.checkForRegisteredEmail();
          }
        }).catch((error) => {
          this.setState({
            showErrorAlert: error.response && error.response.data && error.response.data.error
              ? error.response.data.error
              : (
                <>
                  This email is already in use. If you’ve already signed up click
                  {' '}
                  <Link
                    to="/#sign-in"
                  >
                    here
                  </Link>
                  {' '}
                  to log in, or
                  <Link
                    to="/#lost-password"
                  >
                    here
                  </Link>
                  {' '}
                  to reset your password.
                  <br />
                  <br />
                  If you think this is in error, contact
                  {' '}
                  <a href="mailto:support@dorian.live">support@dorian.live</a>
                </>
              ),
            loading: false,
          });
        });
      } else {
        api.post('/v1/signup3', {
          username,
          email: formattedEmail,
          code,
          firstName,
          lastName,
          receive_email: receiveEmail,
          social_media: socialMedia,
          yourWriting: stringifiedYourWriting,
          approved,
          createTemplate,
        })
          .then((res) => {
            this.setState({
              showErrorAlert: '',
              loading: false,
            });
            if (res && res.status === 201) {
              localStorage.setItem('createTemplate', JSON.stringify(createTemplate));
              const { update, type } = this.props;
              if (type === 'external') {
                update(true);
              } else {
                this.setState({
                  redirect: '/#congratulations',
                });
              }
            }
          })
          .catch((error) => {
            this.setState({
              showErrorAlert: error.response && error.response.data && error.response.data.error
                ? error.response.data.error
                : (
                  <>
                    This email is already in use. If you’ve already signed up click
                    {' '}
                    <Link
                      to="/#sign-in"
                    >
                      here
                    </Link>
                    {' '}
                    to log in, or
                    <Link
                      to="/#lost-password"
                    >
                      here
                    </Link>
                    {' '}
                    to reset your password.
                    <br />
                    <br />
                    If you think this is in error, contact
                    {' '}
                    <a href="mailto:support@dorian.live">support@dorian.live</a>
                  </>
                ),
              loading: false,
            });
          });
      }
    }
    this.setState({ validated: true });
    event.stopPropagation();
  };

  render() {
    const { type } = this.props;
    const {
      redirect, terms, loading, showErrorAlert, validated,
    } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <div>
        <Alert show={!!showErrorAlert} variant="danger">{showErrorAlert}</Alert>

        <Form
          noValidate
          validated={validated}
          onSubmit={this.handleSubmit}
        >

          <Form.Row>
            {type !== 'tab' && type !== 'external'
              ? (
                <Form.Group
                  as={Col}
                  md={type !== 'tab' && type !== 'external' ? 6 : 12}
                  controlId="formUserNameSignUp"
                  className="mt-input"
                >
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="username"
                    placeholder="User Name"
                    onChange={this.handleChangeName}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid User Name.
                  </Form.Control.Feedback>
                </Form.Group>
              )
              : null}

            <Form.Group
              as={Col}
              md={type !== 'tab' && type !== 'external' ? 3 : 6}
              controlId="formFirstName"
              className="mt-input"
            >
              <Form.Label>First Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="firstName"
                onChange={this.handleChangeFirstName}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid First Name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              as={Col}
              md={type !== 'tab' && type !== 'external' ? 3 : 6}
              controlId="formLastName"
              className="mt-input"
            >
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                required
                type="text"
                name="lastName"
                onChange={this.handleChangeLastName}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid Last Name.
              </Form.Control.Feedback>
            </Form.Group>

          </Form.Row>

          <Form.Row>
            <Form.Group
              as={Col}
              md={type !== 'tab' && type !== 'external' ? 6 : 12}
              controlId="formEmail"
              className="mt-input"
            >
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                required
                type="email"
                name="email"
                onChange={this.handleChangeEmail}
                pattern="\S+@\S+\.\S{2,}"
              />
              <Form.Text className="text-muted">
                NOTE: You will receive login code by email
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                Please provide a valid Email Address.
              </Form.Control.Feedback>
            </Form.Group>

          </Form.Row>
          <Form.Row className="d-flex align-items-center">

            {
                !this.app
                && (
                <>
                  <Form.Group
                    as={Col}
                    md={type !== 'tab' && type !== 'external' ? 6 : 12}
                    controlId="form_social_media"
                    className="mt-input"
                  >
                    <Form.Label>
                      Social Media Handle
                      <small>(optional)</small>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="social_media"
                      onChange={
                              (e) => {
                                this.setState({
                                  social_media: e.target.value,
                                  showErrorAlert: '',
                                });
                              }
                            }
                    />
                  </Form.Group>

                  <InputWriting
                    update={(e) => {
                      this.setState({
                        yourWriting: e,
                      });
                    }}
                  />
                </>
                )
            }

            <Col md={type !== 'tab' && type !== 'external' ? 6 : 12}>
              <Form.Check
                custom
                id="check_terms"
                type="checkbox"
                name="terms"
                className="mt-3 mb-3"
                checked={terms}
                onChange={
                    () => {
                      this.setState({
                        terms: !terms,
                        showErrorAlert: '',
                      });
                    }
                  }
              >
                <Form.Check.Input
                  required
                  type="checkbox"
                />
                <Form.Check.Label>
                  I agree to Dorian&apos;s
                  {' '}
                  <Link
                    to={checkExternal(type, 'privacy-policy')}
                    className="mainPageLinks"
                  >
                    Privacy Policy
                  </Link>
                </Form.Check.Label>
                <Form.Control.Feedback type="invalid">
                  this field is required.
                </Form.Control.Feedback>
              </Form.Check>
            </Col>
          </Form.Row>

          <Form.Row className="mt2-4 d-flex align-items-center text-center">
            <Col md={type !== 'tab' && type !== 'external' ? 6 : 12}>
              <Button
                variant="outline-primary"
                type="submit"
                className="btnMainPage"
              >
                {
                    loading
                    && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    )
                }
                Register for Writer Access
              </Button>
            </Col>
            {
                (type !== 'external' || this.app)
                && (
                <Col md={type !== 'tab' && type !== 'external' ? 6 : 12}>
                  <p className="text-center pt-3 pb-5 mainPageLinks-nav">
                    <Link
                      className="mr-2 mainPageLinks"
                      to="/#lost-password"
                    >
                      Lost Password?
                    </Link>
                    <Link
                      className="ml-2 mainPageLinks"
                      to="/#sign-in"
                    >
                      Sign in
                    </Link>
                  </p>
                </Col>
                )
            }

          </Form.Row>
        </Form>
      </div>
    );
  }
}
