import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import imgAvatar from '../../../assets/images/avatar.png';
import { api } from '../../api';
import { AvatarImage } from '../Profile/AvatarImage/AvatarImage';
// eslint-disable-next-line import/no-cycle
import { getUserName } from '.';

export class AuthorProfile extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      user: {
        firstName: '',
        lastName: '',
        about: '',
        stories: [],
      },
      validated: false,
      uploadFormLoading: false,
    };
    this.btnSaveForm = React.createRef();
  }

  componentDidMount() {
    this.loadData();
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true });

    if (validated === false) {
      event.stopPropagation();
    } else {
      this.saveUser(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  loadData() {
    this.setState({
      loading: true,
    });

    const { user } = this.props;
    api.get(`/v1/users/${user}/profile`)
      .then((res) => {
        this.setState({
          user: res.data.user,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  saveUser(value, validated) {
    const { update, user } = this.props;
    if (validated === true) {
      api.put(`/v1/users/${user}`, value).then(() => update());
    }
  }

  render() {
    const { update, ...other } = this.props;
    const {
      validated, user, loading, uploadFormLoading,
    } = this.state;

    const listStories = () => {
      const books = user.books ? user.books : [];
      return (
        books.length > 0
          ? books.map((obj, i) => (
            <Alert
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              variant="secondary"
              className="p-0 my-1"
            >
              <Alert.Link
                href={`/book/${obj.id}`}
                className="px-3 py-1 d-block"
              >
                {obj.title}
              </Alert.Link>
            </Alert>
          ))
          : null
      );
    };

    return (
      <Modal
        {...other}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Modal.Header className="modalHeaderAuthor" closeButton>
          <Modal.Title>
            {user ? getUserName(user) : 'Profile'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          onMouseDown={(e) => e.stopPropagation()}
        >
          {!loading
            ? (
              <>
                <Row>
                  <Col className="text-center imgAvatar-col">
                    <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
                      <AvatarImage
                        imgURL={user.image?.imageUrl || imgAvatar}
                        userId={user.id}
                        onUpdate={() => this.loadData()}
                        isActionsVisible
                        rounded
                      />
                      <Spinner
                        variant="primary"
                        animation="border"
                        className={uploadFormLoading !== false ? 'loadingSpinner justify-content-center' : 'd-none '}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="pt-2">
                      {!loading
                        ? (
                          <>
                            <h4 className="text-center">Earnings</h4>
                            <p>
                              <b>
                                Estimated Hearts Earned:
                                {user.hearts ? user.hearts.estimatedHeartsEarned : 0}
                                {' '}
                                <span role="img" aria-label="heart">❤️</span>
                              </b>
                            </p>
                            <p>
                              <b>
                                Hearts Earned:
                                {user.hearts ? user.hearts.heartsPayable : 0}
                                {' '}
                                <span role="img" aria-label="heart">❤️</span>
                              </b>
                            </p>
                            <p>
                              <b>
                                Hearts Pending Payment:
                                {user.hearts ? user.hearts.heartsPending : 0}
                                {' '}
                                <span role="img" aria-label="heart">❤️</span>
                              </b>
                            </p>
                            <p>
                              <b>
                                Hearts Paid Out - Lifetime:
                                {user.hearts ? user.hearts.heartsPaid : 0}
                                {' '}
                                <span role="img" aria-label="heart">❤️</span>
                                ️
                              </b>
                            </p>

                            <p>
                              Total Released Episodes:
                              {user.releasedEpisodes ? user.releasedEpisodes : 0}
                            </p>
                            <p>
                              New Episodes In The Last 30 Days:
                              {user.newReleasedEpisodes ? user.newReleasedEpisodes : 0}
                            </p>

                            <p>
                              Single Player Reads:
                              {user.stats && user.stats.total_reads ? user.stats.total_reads : 0}
                            </p>
                            <p>
                              Livestream Reads:
                              <i>coming soon</i>
                            </p>
                            <p>
                              High completion rate episodes in last 30 days:
                              {user.stats?.day30_complete70_read100 ?? 0}
                            </p>
                          </>
                        )
                        : (
                          <div className="text-center">
                            <Spinner
                              variant="primary"
                              animation="border"
                              className="justify-content-center"
                            />
                          </div>
                        )}
                    </div>
                  </Col>
                </Row>

                <Row className="pt-4">
                  <Col>
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={(e) => this.handleSubmit(e)}
                    >
                      <Form.Row>
                        <Form.Group as={Col} controlId="firstName" className="my-0 pt-0">
                          <Form.Control
                            required
                            type="text"
                            defaultValue={user.firstName}
                            name="firstName"
                            placeholder="First Name"
                            onMouseDown={(e) => e.stopPropagation()}
                            onBlur={() => this.btnSaveForm.current.click()}
                          />
                        </Form.Group>
                        <Form.Group as={Col} controlId="lastName" className="my-0 pt-0">
                          <Form.Control
                            required
                            type="text"
                            defaultValue={user.lastName}
                            name="lastName"
                            placeholder="Last Name"
                            onMouseDown={(e) => e.stopPropagation()}
                            onBlur={() => this.btnSaveForm.current.click()}
                          />
                        </Form.Group>

                      </Form.Row>

                      <h6 className="text-center">{user.username || ''}</h6>

                      <h4 className="text-center mt-4">About the Author</h4>

                      <Form.Group controlId="about">
                        <Form.Control
                          as="textarea"
                          defaultValue={user.about}
                          name="about"
                          onMouseDown={(e) => e.stopPropagation()}
                          rows="10"
                          placeholder="About the Author"
                          onBlur={() => this.btnSaveForm.current.click()}
                        />
                      </Form.Group>

                      <Button
                        className="d-none"
                        type="submit"
                        ref={this.btnSaveForm}
                        variant="primary"
                      >
                        Save
                      </Button>
                    </Form>
                  </Col>
                  <Col>
                    <h4 className="text-center">Stories</h4>
                    <div className="pt-2 listChaptersBox">
                      {listStories()}
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <div className="text-center">
                <Spinner
                  variant="primary"
                  animation="border"
                  className="justify-content-center"
                />
              </div>
            )}

        </Modal.Body>
      </Modal>
    );
  }
}
