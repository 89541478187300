import classNames from 'classnames/bind';
import queryString from 'query-string';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Container, Navbar, Row,
} from 'react-bootstrap';
import YouTube from 'react-youtube';
import ImgFront01 from '../../../../assets/images/img-front-002.png';
import ImgFront02 from '../../../../assets/images/img-front-003.png';
import { api } from '../../../api';
import { PageHeader } from '../../../ui/PageHeader';
import { SignUpForm } from '../../../ui/SignUpForm';
import styles from '../Home.scss';
import { Reset } from '../reset';

const cs = classNames.bind(styles);

export class SignUpPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: '',
      checkToken: '',
      checkTokenType: '',
      // eslint-disable-next-line react/no-unused-state
      showLogin: false,
      // eslint-disable-next-line react/no-unused-state
      showSignUp: false,
      // eslint-disable-next-line react/no-unused-state
      showLostPassword: false,
      signUpBox: false,
    };
  }

  componentDidMount() {
    this.checkToken();
  }

  checkToken = () => {
    const { location } = this.props;

    const values = queryString.parse(location.search);
    if (location.pathname === '/verify') {
      if (values.token) {
        const val = { token: values.token };
        this.setState({
          data: '',
          checkToken: 'Please wait',
          checkTokenType: 'warning',
        });
        api.post('v1/signup/verification', val)
          .then((res) => {
            this.setState({
              checkToken: res.data.message,
              checkTokenType: 'success',
            }, () => {
              setTimeout(() => {
                window.location.assign('/');
              }, 5000);
            });
          })
          .catch((error) => {
            this.setState({
              checkToken: error?.response?.data?.error || error.message,
              checkTokenType: 'danger',
            }, () => {
              setTimeout(() => {
                window.location.assign('/');
              }, 5000);
            });
          });
      }
    }
  };

  render() {
    const { location } = this.props;

    const {
      signUpBox,
      checkTokenType,
      checkToken: checkToken1,
      data,
    } = this.state;

    const classes = cs('HomePage');
    return (
      <div className={classes}>
        <PageHeader />

        <div className="homeSliderBox" />

        <Navbar
          collapseOnSelect
          expand="lg"
          bg="secondary"
          variant="dark"
        />

        <Container>
          {location.pathname === '/reset'
            ? (
              <Reset
                {...this.props}
              />
            )
            : (
              <>
                <div>
                  <p>&nbsp;</p>
                  <Alert show={!!data} variant="success" className="my-5">
                    {data}
                  </Alert>

                  <Alert
                    show={!!checkToken1}
                    variant={checkTokenType}
                    className="my-5 emailVerifiedAlert"
                  >
                    {checkToken1}
                  </Alert>

                </div>

                <div className="signUpPageContent">
                  <div className="signUpPageContent-box-1">
                    <Row className="justify-content-center align-items-stretch">
                      <Col sm={4} className="d-none d-md-block">
                        <img src={ImgFront01} className="ImgFront" alt="" />
                      </Col>
                      <Col sm={8} className="text-center">
                        <YouTube
                          className="videoYouTube"
                          videoId="Tmw--lpcTKo"
                        />
                        <p>
                          Produced by our writers while creating
                          <br />
                          from home during quarantine
                        </p>
                        <Button
                          variant="primary"
                          onClick={() => {
                            this.setState({
                              signUpBox: !signUpBox,
                            });
                          }}
                        >
                          Sign up to join in!
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className={`signUpPageContent-box-2 ${signUpBox ? 'active' : ''}`}>
                    <Row className="justify-content-center align-items-stretch">
                      <Col sm={4} className="d-none d-md-block" />
                      <Col sm={4} className="d-none d-md-block text-right" style={{ backgroundColor: '#fff' }}>
                        <img src={ImgFront02} className="ImgFront" alt="" />
                      </Col>
                      <Col sm={4} style={{ backgroundColor: '#fff' }}>
                        <div className="py-5">
                          <SignUpForm
                            {...this.props}
                            type="tab"
                            LoginModal={(val) => {
                              this.setState({
                                // eslint-disable-next-line react/no-unused-state
                                showLogin: val,
                              });
                            }}
                            SignUpModal={(val) => {
                              this.setState({
                                // eslint-disable-next-line react/no-unused-state
                                showSignUp: val,
                              });
                            }}
                            LostPassword={(val) => {
                              this.setState({
                                // eslint-disable-next-line react/no-unused-state
                                showLostPassword: val,
                              });
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

              </>
            )}
        </Container>
      </div>
    );
  }
}
