import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../../api';
import './PreviewUtilities.scss';

export class PreviewChangeStepType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: null,
      loading: false,
      error: null,
      ending: false,
      result: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { show, obj } = this.props;

    if (prevProps.show !== show && show) {
      this.checkChoice();
      this.setState({
        checked: obj.current_branch.steps[obj.current_step].stepTypeId,
      });
    }
  }

  errorAlert = (error) => {
    this.setState({
      error,
    });
    setTimeout(() => {
      this.setState({
        error: null,
      });
    }, 1500);
  };

  saveData = () => {
    const {
      obj, updateCharacter, onHide,
    } = this.props;
    const { checked } = this.state;

    this.setState({
      loading: true,
    });
    const value = { stepTypeId: checked };

    api.put(`/v1/stories/${obj.story.id}/branches/${obj.current_branch.id}/steps/${obj.current_branch.steps[obj.current_step].id}`, value)
      .then(() => {
        this.setState({
          loading: false,
        }, () => {
          updateCharacter(obj.current_branch.id, obj.current_step, checked);
          onHide();
        });
      })
      .catch((error) => {
        this.errorAlert(error.response.data.error);
        this.setState({
          loading: false,
        });
      });
  };

  checkChoice() {
    const { obj } = this.props;

    if (!obj.current_branch || !obj.current_branch.steps || obj.current_branch.steps.length < 1) {
      return;
    }
    const { steps } = obj.current_branch;
    this.setState(() => ({
      ending: steps.find((a) => a && (Number(a.stepTypeId) === 5)) !== undefined,
      result: steps.find((a) => a && (Number(a.stepTypeId) === 6)) !== undefined,
    }));
  }

  render() {
    const { show, obj, onHide } = this.props;
    const {
      error, result, loading, ending, checked,
    } = this.state;

    if (!show) {
      return null;
    }

    const { current_branch: currentBranch } = obj;
    const { steps } = currentBranch;

    let types = obj.stepTypes;

    if (Number(checked) !== 7) {
      types = types.filter((el) => el.id !== 7);
    }
    if (Number(checked) !== 6) {
      types = types.filter((el) => el.id !== 6);
    }

    return (
      <div className="PreviewEditScene-box">
        <div className="PreviewEditScene-wrap">

          <div className="PreviewEditScene-header">
            <h2>Change Step Type</h2>
          </div>

          <div className="PreviewEditScene-content">
            {loading && (
            <div className="text-center loadingSpinner">
              <Spinner
                variant="primary"
                animation="border"
                className="justify-content-center"
              />
            </div>
            )}
            <Row className={error === null ? 'd-none' : 'd-block pt-2'}>
              <Col md={12}>
                <Alert variant="danger">
                  {error}
                </Alert>
              </Col>
            </Row>
            {types.map((type, i) => {
              const dis = steps && (
                (steps.length > 0 && type.id === 3)
                || (steps.length > 1 && result && type.id !== 5)
                || (steps.length > 1 && ending && type.id !== 5 && type.id !== 6)
                || (steps.length > 1 && !ending && !result && (type.id === 5 || type.id === 6))
              );

              if (type.id === 3) {
                return null;
              }

              return (
              // eslint-disable-next-line react/no-array-index-key
                <Row key={i} className="py-2 border-bottom d-flex align-items-center">
                  <Col xs={2} className="text-center">
                    <Form.Check
                      disabled={dis}
                      type="radio"
                      value={type.id}
                      id={`checkbox_${i}`}
                      checked={(checked && Number(checked) === Number(type.id)) || false}
                      onChange={() => {
                        this.setState({
                          checked: type.id,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={10} className={`text-left ${dis && 'disabled'}`}>
                    <label htmlFor={`checkbox_${i}`}>{type.title}</label>
                  </Col>
                </Row>
              );
            })}
          </div>

          <div className="PreviewEditScene-footer">
            <Button
              size="sm"
              type="reset"
              variant="secondary"
              onClick={onHide}
            >
              Cancel
            </Button>

            <Button
              size="sm"
              type="reset"
              variant="primary"
              onClick={() => {
                this.saveData();
              }}
              className="mx-1"
              disabled={(!!loading)}
            >
              {loading && (
                <Spinner
                  size="sm"
                  animation="border"
                />
              )}
              Save
            </Button>
          </div>

        </div>
      </div>
    );
  }
}
