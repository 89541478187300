import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PageWrapper } from '../../ui/PageWrapper';
import { BackgroundsList } from './BackgroundsList';
import { UploadImage } from './UploadImage';

export class BackgroundsPage extends Component {
  constructor(props) {
    super(props);
    const { history, auth } = this.props;

    this.state = {
      uploadImage: false,
    };

    this.user = auth.getUser();

    this.pageLayout = {
      header: {
        title: 'Backgrounds',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Upload Image',
            action: () => this.initUpload(),
            variant: 'primary',
            disabled: false,
          },
        ],
      },
    };
  }

  initUpload = () => {
    this.setState({ uploadImage: true });
  };

  cancelUpload = () => {
    this.setState({ uploadImage: false });
  };

  onUpload = () => {
    this.setState({ uploadImage: false });
    this.BackgroundsListEl.loadImages();
  };

  render() {
    const { uploadImage } = this.state;

    return (
      <>
        <PageWrapper {...this.props} page={this.pageLayout}>
          <BackgroundsList
            user={this.user}
            ref={(BackgroundsListEl) => { this.BackgroundsListEl = BackgroundsListEl; }}
          />
        </PageWrapper>
        <UploadImage
          show={uploadImage}
          onHide={this.cancelUpload}
          update={this.onUpload}
          user={this.user}
          type="background"
        />
      </>
    );
  }
}

BackgroundsPage.propTypes = {
  auth: PropTypes.shape({
    getUser: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    length: PropTypes.number.isRequired,
  }).isRequired,
};
