import React, { Component } from 'react';
import { SelectableGroupContext } from './Context';

export class DeselectAll extends Component {
  constructor(...args) {
    super(...args);
    this.root = null;
  }

  componentDidMount() {
    this.root.addEventListener('mousedown', (evt) => evt.stopPropagation());
  }

  getRootRef = (ref) => {
    this.root = ref;
  };

  render() {
    const {
      component, children, className, ...rest
    } = this.props;
    const { selectable } = this.context;

    const ButtonComponent = component || 'div';

    return (
      <ButtonComponent
        ref={this.getRootRef}
        className={`selectable-select-all ${className}`}
        onClick={selectable.clearSelection}
        {...rest}
      >
        {children}
      </ButtonComponent>
    );
  }
}

DeselectAll.contextType = SelectableGroupContext;
