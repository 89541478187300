import React, { Component } from 'react';
import FrontImg001 from '../../../assets/images/01_neutral_reducedheight.png';
import FrontImg001rightBox from '../../../assets/images/01_reducedheight.png';
import FrontImg002rightBox from '../../../assets/images/03_champ_opensmile.png';
import FrontImg002 from '../../../assets/images/03_champ_smile.png';

export class HomeLeftImages extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      img001: false,
      // eslint-disable-next-line react/no-unused-state
      img002: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        img001: true,
        // eslint-disable-next-line react/no-unused-state
        img002: true,
      });
    }, 500);
  }

  render() {
    const { rightBox } = this.props;

    return (
      <div className="mainPage-left-box">
        <div
          className={`frontImgBox FrontImg001 ${rightBox === 2 || rightBox === 3 || rightBox === 4 || rightBox === 7 ? 'active' : ''}`}
        >
          <img src={rightBox > 1 ? FrontImg001rightBox : FrontImg001} alt="" />
        </div>
        <div
          className={`frontImgBox FrontImg002 ${rightBox === 2 || rightBox === 3 || rightBox === 4 || rightBox === 7 ? 'active' : ''}`}
        >
          <img src={rightBox > 1 ? FrontImg002rightBox : FrontImg002} alt="" />
        </div>
      </div>
    );
  }
}
