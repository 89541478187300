import {
  ANSWERS_TYPE,
  BRANCHES,
  CHOICE_PROPS, LIMITS,
  LOADING,
  MODIFIERE_TYPES,
  SHOW_DELETE_ANSWER,
  SHOW_EDIT_CHOICE,
  STORY,
} from './types';

export function storyPreviewReducer(state, action) {
  const { payload, type } = action;

  switch (type) {
    case LIMITS:
      return {
        ...state,
        limits: payload,
      };

    case SHOW_DELETE_ANSWER:
      return {
        ...state,
        showDeleteAnswer: payload,
      };

    case MODIFIERE_TYPES:
      return {
        ...state,
        modifiersTypes: payload,
      };

    case ANSWERS_TYPE:
      return {
        ...state,
        answersTypes: payload,
      };

    case CHOICE_PROPS:
      return {
        ...state,
        choiceProps: payload,
      };

    case BRANCHES:
      return {
        ...state,
        branches: payload,
      };

    case LOADING:
      return {
        ...state,
        loading: payload,
      };

    case SHOW_EDIT_CHOICE:
      return {
        ...state,
        showEditChoice: payload,
      };

    case STORY:
      return {
        ...state,
        story: payload,
      };

    default:
      return state;
  }
}
