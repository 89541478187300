import React, { Component } from 'react';
import { noop } from '../../../../helpers/noop';
import { SelectableGroupContext } from './Context';
import { SelectBox } from './Selectbox';
import {
  castTouchToMouseEvent, detectMouseButton, doObjectsCollide, getBoundsForNode, isNodeInRoot,
} from './utils';

export class SelectableGroup extends Component {
  defaultContainerStyle = {
    position: 'relative',
  };

  constructor(...args) {
    super(...args);
    this.state = {
      selectionMode: false,
    };
    const { ignoreList } = this.props;
    this.mouseDownStarted = false;
    this.mouseMoveStarted = false;
    this.mouseMoved = false;
    this.mouseUpStarted = false;
    this.selectionStarted = false;
    this.deselectionStarted = false;
    this.clickedItem = null;
    this.mouseDownData = {
      selectboxY: 0,
      selectboxX: 0,
      target: null,
    };

    this.registry = new Set();
    this.selectedItems = new Set();
    this.selectingItems = new Set();
    this.ignoreCheckCache = new Map();

    this.ignoreList = ignoreList.concat(['.selectable-select-all', '.selectable-deselect-all']);
    this.ignoreListNodes = [];

    this.selectbox = null;
    this.selectableGroup = null;
    this.scrollContainer = null;

    this.maxScrollTop = 0;
    this.maxScrollLeft = 0;
    this.scrollBounds = null;
  }

  componentDidMount() {
    const { deselectOnEsc, scrollContainer } = this.props;

    if (scrollContainer) {
      this.scrollContainer = document.querySelector(scrollContainer);
    } else {
      this.scrollContainer = this.selectableGroup;
    }

    this.selectableGroup.addEventListener('mousedown', this.mouseDown, { passive: false });
    this.selectableGroup.addEventListener('touchstart', this.mouseDown, { passive: false });

    if (deselectOnEsc) {
      document.addEventListener('keydown', this.keyListener, { passive: false });
      document.addEventListener('keyup', this.keyListener, { passive: false });
    }
  }

  componentWillUnmount() {
    const { deselectOnEsc } = this.props;

    this.selectableGroup.removeEventListener('mousedown', this.mouseDown);
    this.selectableGroup.removeEventListener('touchstart', this.mouseDown);

    if (deselectOnEsc) {
      document.removeEventListener('keydown', this.keyListener);
      document.removeEventListener('keyup', this.keyListener);
    }

    this.removeTempEventListeners();
  }

  removeTempEventListeners() {
    document.removeEventListener('mousemove', this.updateSelectBox);
    document.removeEventListener('touchmove', this.updateSelectBox);
    document.removeEventListener('mouseup', this.mouseUp);
    document.removeEventListener('touchend', this.mouseUp);
  }

  updateRootBounds() {
    this.scrollBounds = this.scrollContainer.getBoundingClientRect();
    this.maxScrollTop = this.scrollContainer.scrollHeight - this.scrollContainer.clientHeight;
    this.maxScrollLeft = this.scrollContainer.scrollWidth - this.scrollContainer.clientWidth;
  }

  updateRegistry = () => {
    const containerScroll = {
      scrollTop: this.scrollContainer.scrollTop,
      scrollLeft: this.scrollContainer.scrollLeft,
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const selectableItem of this.registry.values()) {
      selectableItem.registerSelectable(containerScroll);
    }
  };

  registerSelectable = (selectableItem) => {
    this.registry.add(selectableItem);
    if (selectableItem.state.isSelected) {
      this.selectedItems.add(selectableItem);
    }
  };

  unregisterSelectable = (selectableItem) => {
    const { onSelectionFinish } = this.props;

    this.registry.delete(selectableItem);

    // eslint-disable-next-line max-len
    const isHandled = this.selectedItems.has(selectableItem) || this.selectingItems.has(selectableItem);

    this.selectedItems.delete(selectableItem);
    this.selectingItems.delete(selectableItem);

    if (isHandled) {
      // eslint-disable-next-line max-len
      // Notify third-party dev that component did unmount and handled item probably should be deleted
      onSelectionFinish([...this.selectedItems]);
    }
  };

  toggleSelectionMode() {
    const {
      selectedItems,
      state: { selectionMode },
    } = this;

    if (selectedItems.size && !selectionMode) {
      this.setState({ selectionMode: true });
    }
    if (!selectedItems.size && selectionMode) {
      this.setState({ selectionMode: false });
    }
  }

  updateContainerScroll = (evt) => {
    const { scrollTop, scrollLeft } = this.scrollContainer;

    this.checkScrollTop(evt.clientY, scrollTop);
    this.checkScrollBottom(evt.clientY, scrollTop);
    this.checkScrollLeft(evt.clientX, scrollLeft);
    this.checkScrollRight(evt.clientX, scrollLeft);
  };

  getScrollStep = (offset) => {
    const { minimumSpeedFactor, scrollSpeed } = this.props;

    return Math.max(offset, minimumSpeedFactor) * scrollSpeed;
  };

  checkScrollTop = (clientY, currentTop) => {
    const offset = this.scrollBounds.top - clientY;

    if (offset > 0 || clientY < 0) {
      this.scrollContainer.scrollTop = currentTop - this.getScrollStep(offset);
    }
  };

  checkScrollBottom = (clientY, currentTop) => {
    const offset = clientY - this.scrollBounds.bottom;

    if (offset > 0 || clientY > window.innerHeight) {
      const newTop = currentTop + this.getScrollStep(offset);
      this.scrollContainer.scrollTop = Math.min(newTop, this.maxScrollTop);
    }
  };

  checkScrollLeft = (clientX, currentLeft) => {
    const offset = this.scrollBounds.left - clientX;

    if (offset > 0 || clientX < 0) {
      this.scrollContainer.scrollLeft = currentLeft - this.getScrollStep(offset);
    }
  };

  checkScrollRight = (clientX, currentLeft) => {
    const offset = clientX - this.scrollBounds.right;

    if (offset > 0 || clientX > window.innerWidth) {
      const newLeft = currentLeft + this.getScrollStep(offset);
      this.scrollContainer.scrollLeft = Math.min(newLeft, this.maxScrollLeft);
    }
  };

  updateSelectBox = (event) => {
    const { duringSelection } = this.props;

    const evt = castTouchToMouseEvent(event);
    this.updateContainerScroll(evt);

    if (this.mouseMoveStarted) {
      return;
    }
    this.mouseMoveStarted = true;
    this.mouseMoved = true;

    const { mouseDownData } = this;
    const { clientX, clientY } = evt;
    const { scrollLeft, scrollTop } = this.scrollContainer;

    const pointY = clientY - this.scrollBounds.top + scrollTop;
    const selectboxY = Math.min(pointY, mouseDownData.selectboxY);

    const pointX = clientX - this.scrollBounds.left + scrollLeft;
    const selectboxX = Math.min(pointX, mouseDownData.selectboxX);

    this.selectbox.setState(
      {
        x: selectboxX,
        y: selectboxY,
        isSelecting:
          Math.abs(pointX - mouseDownData.selectboxX) > 10
          || Math.abs(pointY - mouseDownData.selectboxY) > 10,
        width: Math.abs(pointX - mouseDownData.selectboxX),
        height: Math.abs(pointY - mouseDownData.selectboxY),
      },
      () => {
        this.updateSelecting();
        duringSelection([...this.selectingItems]);
        this.mouseMoveStarted = false;
      },
    );
  };

  updateSelecting = () => {
    const selectboxNode = this.selectbox.getRef();
    if (!selectboxNode) {
      return;
    }

    const selectboxBounds = getBoundsForNode(selectboxNode);

    this.selectItems({
      ...selectboxBounds,
      offsetWidth: selectboxBounds.offsetWidth || 1,
      offsetHeight: selectboxBounds.offsetHeight || 1,
    });
  };

  selectItems = (selectboxBounds, options) => {
    const { tolerance, enableDeselect, mixedDeselect } = this.props;

    // eslint-disable-next-line no-param-reassign
    selectboxBounds.top += this.scrollContainer.scrollTop;
    // eslint-disable-next-line no-param-reassign
    selectboxBounds.left += this.scrollContainer.scrollLeft;

    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.registry.values()) {
      this.processItem({
        item,
        selectboxBounds,
        tolerance,
        mixedDeselect,
        enableDeselect,
        isFromClick: options && options.isFromClick,
      });
    }
  };

  processItem(options) {
    const { delta } = this.props;

    const {
      item, tolerance, selectboxBounds, enableDeselect, mixedDeselect, isFromClick,
    } = options;

    if (this.isInIgnoreList(item.node)) {
      return null;
    }

    const isCollided = doObjectsCollide(selectboxBounds, item.bounds, tolerance, delta);
    const { isSelecting, isSelected } = item.state;

    if (isFromClick && isCollided) {
      /* console.log("+++", item); */
      if (isSelected) {
        this.selectedItems.delete(item);
      } else {
        this.selectedItems.add(item);
      }

      item.setState({ isSelected: !isSelected });

      this.clickedItem = item;

      return null;
    }

    if (!isFromClick && isCollided) {
      if (isSelected && enableDeselect && (!this.selectionStarted || mixedDeselect)) {
        item.setState({ isSelected: false });
        item.deselected = true;

        this.deselectionStarted = true;

        return this.selectedItems.delete(item);
      }

      const canSelect = mixedDeselect ? !item.deselected : !this.deselectionStarted;

      if (!isSelecting && !isSelected && canSelect) {
        item.setState({ isSelecting: true });

        this.selectionStarted = true;
        this.selectingItems.add(item);

        return { updateSelecting: true };
      }
    }

    if (!isFromClick && !isCollided && isSelecting) {
      if (this.selectingItems.has(item)) {
        item.setState({ isSelecting: false });

        this.selectingItems.delete(item);

        return { updateSelecting: true };
      }
    }

    return null;
  }

  clearSelection = () => {
    const { onSelectionClear, onSelectionFinish } = this.props;

    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.selectedItems.values()) {
      item.setState({ isSelected: false });
      this.selectedItems.delete(item);
    }

    this.setState({ selectionMode: false });

    onSelectionFinish([...this.selectedItems]);
    onSelectionClear();
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  selectByIds = (ids) => {
    const { onSelectionFinish } = this.props;

    this.updateWhiteListNodes();
    this.registry.forEach((item) => {
      if (!this.isInIgnoreList(item.node)
        && !item.state.isSelected
        && item.id && ids.includes(item.id)) {
        item.setState({ isSelected: true });
        this.selectedItems.add(item);
      }
    });

    this.setState({ selectionMode: true });

    onSelectionFinish([...this.selectedItems]);
  };

  selectAll = () => {
    const { onSelectionFinish } = this.props;

    this.updateWhiteListNodes();

    // eslint-disable-next-line no-restricted-syntax
    for (const item of this.registry.values()) {
      if (!this.isInIgnoreList(item.node) && !item.state.isSelected) {
        item.setState({ isSelected: true });
        this.selectedItems.add(item);
      }
    }

    this.setState({ selectionMode: true });

    onSelectionFinish([...this.selectedItems]);
  };

  contextValue = {
    selectable: {
      register: this.registerSelectable,
      unregister: this.unregisterSelectable,
      selectAll: this.selectAll,
      clearSelection: this.clearSelection,
      getScrolledContainer: () => this.scrollContainer,
    },
  };

  updateWhiteListNodes() {
    this.ignoreListNodes = Array.from(document.querySelectorAll(this.ignoreList.join(', ')));
  }

  isInIgnoreList(target) {
    if (!target) {
      return false;
    }

    if (this.ignoreCheckCache.get(target) !== undefined) {
      return this.ignoreCheckCache.get(target);
    }

    const shouldBeIgnored = this.ignoreListNodes.some(
      (ignoredNode) => target === ignoredNode || ignoredNode.contains(target),
    );

    this.ignoreCheckCache.set(target, shouldBeIgnored);

    return shouldBeIgnored;
  }

  mouseDown = (e) => {
    const {
      allowShiftClick,
      resetOnStart,
      allowCtrlClick,
      globalMouse,
      disabled,
    } = this.props;

    const isNotLeftButtonClick = !e.type.includes('touch')
      && !detectMouseButton(e, 1, {
        allowShiftClick,
        allowCtrlClick,
      });
    if (this.mouseDownStarted || disabled || isNotLeftButtonClick) {
      return;
    }

    this.updateWhiteListNodes();

    if (this.isInIgnoreList(e.target)) {
      this.mouseDownStarted = false;
      return;
    }

    if (resetOnStart) {
      this.clearSelection();
    }
    this.mouseDownStarted = true;
    this.mouseUpStarted = false;
    const evt = castTouchToMouseEvent(e);

    if (!globalMouse && !isNodeInRoot(evt.target, this.selectableGroup)) {
      const offsetData = getBoundsForNode(this.selectableGroup);
      const collides = doObjectsCollide(
        {
          top: offsetData.top,
          left: offsetData.left,
          width: 0,
          height: 0,
          offsetHeight: offsetData.offsetHeight,
          offsetWidth: offsetData.offsetWidth,
        },
        {
          top: evt.pageY,
          left: evt.pageX,
          width: 0,
          height: 0,
          offsetWidth: 0,
          offsetHeight: 0,
        },
      );

      if (!collides) {
        return;
      }
    }

    this.updateRootBounds();
    this.updateRegistry();

    this.mouseDownData = {
      target: evt.target,
      selectboxY: evt.clientY - this.scrollBounds.top + this.scrollContainer.scrollTop,
      selectboxX: evt.clientX - this.scrollBounds.left + this.scrollContainer.scrollLeft,
    };

    evt.preventDefault();

    document.addEventListener('mousemove', this.updateSelectBox, { passive: false });
    document.addEventListener('touchmove', this.updateSelectBox, { passive: false });
    document.addEventListener('mouseup', this.mouseUp, { passive: false });
    document.addEventListener('touchend', this.mouseUp, { passive: false });
  };

  preventEvent(target, type) {
    const preventHandler = (evt) => {
      target.removeEventListener(type, preventHandler, true);
      evt.preventDefault();
      evt.stopPropagation();
    };
    target.addEventListener(type, preventHandler, true, { passive: false });
  }

  mouseUp = (event) => {
    const { onSelectionFinish } = this.props;

    if (this.mouseUpStarted) {
      return;
    }

    this.mouseUpStarted = true;
    this.mouseDownStarted = false;
    this.removeTempEventListeners();

    if (!this.mouseDownData) {
      return;
    }

    const evt = castTouchToMouseEvent(event);
    const { pageX, pageY } = evt;

    if (!this.mouseMoved && isNodeInRoot(evt.target, this.selectableGroup)) {
      this.handleClick(evt, pageY, pageX);
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.selectingItems.values()) {
        item.setState({ isSelected: true, isSelecting: false });
      }
      this.selectedItems = new Set([...this.selectedItems, ...this.selectingItems]);
      this.selectingItems.clear();

      if (evt.which === 1 && this.mouseDownData.target === evt.target) {
        this.preventEvent(evt.target, 'click');
      }

      this.selectbox.setState({
        isSelecting: false,
        width: 0,
        height: 0,
      });

      onSelectionFinish([...this.selectedItems]);
    }

    this.toggleSelectionMode();
    this.cleanUp();
    this.mouseMoved = false;
  };

  handleClick(evt, top, left) {
    const {
      selectOnClick,
      clickClassName,
      allowClickWithoutSelected,
      onSelectionFinish,
    } = this.props;

    if (!selectOnClick) {
      return;
    }

    const classNames = evt.target.classList || [];
    const isMouseUpOnClickElement = Array.from(classNames).indexOf(clickClassName) > -1;

    if (
      allowClickWithoutSelected
      || this.selectedItems.size
      || isMouseUpOnClickElement
      || evt.ctrlKey
    ) {
      this.selectItems(
        {
          top,
          left,
          width: 0,
          height: 0,
          offsetWidth: 0,
          offsetHeight: 0,
        },
        { isFromClick: true },
      );

      onSelectionFinish([...this.selectedItems], this.clickedItem);

      if (evt.which === 1) {
        this.preventEvent(evt.target, 'click');
      }
      if (evt.which === 2 || evt.which === 3) {
        this.preventEvent(evt.target, 'contextmenu');
      }
    }
  }

  keyListener = (evt) => {
    if (evt.keyCode === 27) {
      // escape
      this.clearSelection();
    }
  };

  cleanUp() {
    const { mixedDeselect } = this.props;

    this.deselectionStarted = false;
    this.selectionStarted = false;

    if (mixedDeselect) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.registry.values()) {
        item.deselected = false;
      }
    }
  }

  getGroupRef = (ref) => {
    this.selectableGroup = ref;
  };

  getSelectboxRef = (ref) => {
    this.selectbox = ref;
  };

  render() {
    const { selectionMode } = this.state;
    const {
      component,
      className,
      style,
      selectionModeClass,
      fixedPosition,
      selectboxClassName,
      children,
    } = this.props;

    const GroupComponent = component || 'div';

    return (
      <SelectableGroupContext.Provider value={this.contextValue}>
        <GroupComponent
          ref={this.getGroupRef}
          style={({ ...this.defaultContainerStyle, ...style })}
          className={`${className} ${selectionMode ? selectionModeClass : ''}`}
        >
          <SelectBox
            ref={this.getSelectboxRef}
            className={selectboxClassName}
            fixedPosition={fixedPosition}
          />
          {children}
        </GroupComponent>
      </SelectableGroupContext.Provider>
    );
  }
}

SelectableGroup.defaultProps = {
  clickClassName: '',
  tolerance: 0,
  globalMouse: false,
  ignoreList: [],
  scrollSpeed: 0.25,
  minimumSpeedFactor: 60,
  duringSelection: noop,
  onSelectionFinish: noop,
  onSelectionClear: noop,
  allowClickWithoutSelected: true,
  selectionModeClass: 'in-selection-mode',
  resetOnStart: false,
  disabled: false,
  deselectOnEsc: true,
  fixedPosition: false,
  delta: 1,
  allowShiftClick: false,
  allowCtrlClick: false,
  selectOnClick: true,
};
