import React, { PureComponent } from 'react';
// eslint-disable-next-line import/no-cycle
import { AddAnswer } from './AddAnswer';
import './Preview.scss';
// eslint-disable-next-line import/no-cycle
import { StepTextPreview } from './stepText';

export class StepPreview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      btnNumber: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { step } = this.props;

    if (step.answers !== prevProps.step.answers && prevProps.step.answers.length > 0) {
      this.setState({
        btnNumber: null,
      });
    }
  }

  keyPress = (e) => {
    const { step } = this.props;
    const { btnNumber } = this.state;
    const number = Number(btnNumber);

    const stepLength = step.answers.length;

    if (stepLength > 0) {
      if (e.keyCode === 38) {
        this.setState({
          btnNumber: number - 1 >= 0 ? number - 1 : stepLength - 1,
        });
      }

      if (e.keyCode === 40) {
        this.setState({
          btnNumber: number >= stepLength - 1 || btnNumber === null ? 0 : number + 1,
        });
      }
    }
  };

  componentDidMount() {
    window.addEventListener('keydown', this.keyPress, { passive: false });
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyPress);
  }

  renderCharacterName(character) {
    const { playername } = this.props;

    if (!character || !character.name) {
      return null;
    }

    if (character.name.match(/<playername>/gi)) {
      return (<b style={{ color: 'blue' }}>{playername}</b>);
    }
    if (character.name.match(/<playernamefemale>/gi)) {
      return (<b style={{ color: 'blue' }}>{playername}</b>);
    }
    return character.name;
  }

  render() {
    const { step, analytic, hideUi } = this.props;
    const { btnNumber } = this.state;

    let mainBoxClass;

    const { character } = step;
    // let expression = step.expression;

    let nameBoxClass = 'd-none';

    if (step.stepTypeId === 1) {
      mainBoxClass = 'dialogue';
      nameBoxClass = 'dialogue_name';
    }

    if (step.stepTypeId === 3) {
      mainBoxClass = 'choice';
      nameBoxClass = 'choice_name';
    }

    if (step.stepTypeId === 8) {
      mainBoxClass = 'texting';
      nameBoxClass = 'texting_name';
    }

    if (step.stepTypeId === 9) {
      mainBoxClass = 'thinking';
      nameBoxClass = 'thinking_name';
    }

    if (step.stepTypeId === 10) {
      mainBoxClass = 'reaction';
      nameBoxClass = 'reaction_name';
    }

    return (
      <div className={mainBoxClass}>
        <StepTextPreview
          nameBoxClass={nameBoxClass}
          name={this.renderCharacterName(character)}
          {...this.props}
        />
        <div>
          {step?.answers?.length > 0 && step.answers.map((obj, i) => (
            <StepTextPreview
              {...this.props}
              type="answ"
              arr={obj}
                // eslint-disable-next-line react/no-array-index-key
              key={`${obj.id}_${i}`}
              btnNumber={btnNumber}
              index={i}
              analytics={analytic?.answers?.[i] || null}
            />
          ))}
          <AddAnswer
            {...this.props}
            hideUi={hideUi}
          />
        </div>
      </div>
    );
  }
}
