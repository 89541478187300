import cs from 'classnames';
import React, { useState } from 'react';
import {
  Button, Image, Spinner,
} from 'react-bootstrap';
import imgDelete from '../../../../assets/images/delete.svg';
import imgEdit from '../../../../assets/images/edit-branches.png';
import { useShowModal } from '../../../../hooks/useShowModal';
import { api } from '../../../api';
import { AvatarImageUploader } from '../AvatarImageUploader/AvatarImageUploader';
import classNames from './AvatarImage.module.scss';

export type AvatarImage = {
    imgURL: string;
    userId: number,
    onUpdate: (imgURL:string | null) => void,
    isActionsVisible: boolean,
    rounded: boolean,
}

export function AvatarImage(props: AvatarImage) {
  const {
    imgURL,
    onUpdate,
    isActionsVisible = false,
    rounded = false,
  } = props;

  const { isModalShown, showModal, hideModal } = useShowModal();
  const [isLoading, setIsLoading] = useState(false);

  const handleAvatarImageSave = (file: File) => {
    const { userId } = props;
    const formData = new FormData();
    formData.append('image', file);
    formData.append('user_id', userId.toString());
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    setIsLoading(true);
    api.post('/v1/user/profile/avatar', formData, config)
      .then((response) => {
        hideModal();
        onUpdate(response.data.image);
      }).finally(() => setIsLoading(false));
  };

  const handleAvatarImageDelete = () => {
    const userFromStorage = localStorage.getItem('user');
    if (!userFromStorage) {
      return;
    }
    const userStorage = JSON.parse(userFromStorage);
    if (!userStorage.image) {
      return;
    }
    setIsLoading(true);
    api.delete('/v1/user/profile/avatar')
      .then(() => {
        onUpdate(null);
      }).finally(() => setIsLoading(false));
  };

  return (
    <>
      <div
        className={cs(
          classNames.imageAvatarWrapper,
          rounded ? classNames.rounded : '',
        )}
      >
        <Image
          className={cs(
            classNames.imgAvatar,
          )}
          src={imgURL}
          fluid
        />
        {isActionsVisible && (
          <div className={classNames.buttons}>
            <Button
              onClick={showModal}
              className={classNames.imageButton}
            >
              <Image
                src={imgEdit}
                alt="Edit"
                fluid
              />
            </Button>
            <Button
              onClick={handleAvatarImageDelete}
              className={classNames.imageButton}
              active={false}
            >
              <Image
                src={imgDelete}
                alt="Delete"
                fluid
              />
            </Button>
          </div>
        )}
        { isModalShown && (
        <AvatarImageUploader
          imageSrc={imgURL}
          onSave={handleAvatarImageSave}
          onCancel={hideModal}
          isLoading={isLoading}
        />
        )}

      </div>
      {isLoading && (
        <div className={classNames.spinnerWrapper}>
          <Spinner
            variant="primary"
            animation="border"
            className={classNames.spinner}
          />
        </div>
      )}
    </>
  );
}
