import async from 'async';
import queryString from 'query-string';
import React, { Component } from 'react';
import {
  Alert, Badge, Button, ButtonGroup, Card, Col, Form, FormControl, InputGroup, Modal,
} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { BookPush } from '../Book/BookPush';
import { Loading } from '../Book/Loading';
import { Share } from '../Stories/Share';
import { AddBook } from './AddBook';
import { BookGroups } from './BookGroups';
import { DeleteBook } from './DeleteBook';

const SortableItem = SortableElement(({
  object, i, showIndex, ...props
}) => (
  <BookGroups
    {...props}
    key={i}
    obj={object}
  />
));

const SortableList = SortableContainer((
  {
    items,
    disabledRow,
    deleteBook,
    editBook,
    dragBook,
    groupHover,
    loading,
    ...props
  },
) => (
  <div>
    {
          items.map((value, index) => (
            <SortableItem
              /* eslint-disable-next-line react/no-array-index-key */
              key={`item-${index}`}
              index={index}
              object={value}
              disabledRow={disabledRow}
              disabled={disabledRow}
              deleteBook={deleteBook}
              editBook={editBook}
              dragBook={dragBook}
              groupHover={groupHover}
              loading={loading}
              i={index}
              {...props}
            />
          ))
        }
  </div>
));

export class Books extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      disabledRow: true,
      editBook: null,
      deleteBook: null,
      groups: [],
      limits: [],
      groupHover: null,
      dragBook: [],
      editTitle: false,
      editShare: {},
      editShareActive: false,
      formError: null,
      search: '',
      checkToken: '',
      checkTokenType: '',
      unreadCount: 0,
      openBookPush: false,
      getInviteLinkModal: false,
      // eslint-disable-next-line react/no-unused-state
      remaining: 0,
      isCloning: false,
    };

    const { auth } = this.props;

    this.user = auth.getUser();
    this.emailVerifie = !!(this.user.email && !this.user.email_verified);
  }

  componentDidMount() {
    this.loadData();
    this.checkToken();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location !== prevProps.location) {
      this.loadData();
      this.checkToken();
    }
  }

  checkToken = () => {
    const { location } = this.props;

    const values = queryString.parse(location.search);
    if (location.pathname === '/verify') {
      if (values.token) {
        const val = { token: values.token };
        this.emailVerifie = false;
        this.setState({
          checkToken: 'Please wait',
          checkTokenType: 'warning',
        });
        api.post('v1/signup/verification', val)
          .then((res) => {
            const { user } = this;
            user.email_verified = true;
            localStorage.setItem('user', JSON.stringify(user));
            this.setState({
              checkToken: res.data.message,
              checkTokenType: 'success',
            }, () => {
              setTimeout(() => {
                window.location.assign('/');
              }, 5000);
            });
          })
          .catch((error) => {
            this.setState({
              checkToken: error.response && error.response.data && error.response.data.error
                ? error.response.data.error
                : error.message,
              checkTokenType: 'danger',
            }, () => {
              setTimeout(() => {
                window.location.assign('/');
              }, 5000);
            });
          });
      }
    }
  };

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  // eslint-disable-next-line react/sort-comp
  loadData() {
    this.setState({
      loading: true,
    });
    async.parallel({
      groups: (callback) => {
        api.get('/v1/groups/books')
          .then((res) => {
            callback(null, res.data.groups);
          }).catch((error) => {
            callback(error, null);
          });
      },
      limits: (callback) => {
        api.get('/v1/settings')
          .then((res) => {
            callback(null, res.data.settings.limits);
          }).catch((error) => {
            callback(error, null);
          });
      },
      unreadCount: (callback) => {
        api.get('/v1/blog/countposts')
          .then((res) => {
            callback(null, res.data.unreadCount);
          }).catch((error) => {
            callback(error, null);
          });
      },
      invites: (callback) => {
        api.get('/v1/invites')
          .then((res) => {
            callback(null, res.data.invites);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (error, response) => {
      try {
        if (error) {
          if (error && error.response && error.response.data && error.response.data.error) {
            this.errorAlert(error.response.data.error);
          } else if (error && error.message) {
            this.errorAlert(error.message);
          }
          this.setState({
            loading: false,
          });
        } else {
          const {
            groups,
            limits,
            unreadCount,
            invites,
          } = response;

          let pending = 0;
          invites.forEach((obj) => {
            if (obj.granted === false) {
              pending += 1;
            }
          });
          this.setState({
            limits,
            groups,
            unreadCount,
            // eslint-disable-next-line react/no-unused-state
            pendingFanCount: pending,
            loading: false,
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    });
  }

  initAddBook = () => {
    this.setState({
      editBook: [],
    });
  };

  editBook = (obj) => {
    this.setState({
      editBook: obj,
    });
  };

  articleBadge = () => {
    const { unreadCount } = this.state;

    return (
      <>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Writer's Corner
        <span className="boxBadge">
          {unreadCount || unreadCount > 0
            ? (
              <Badge pill variant="warning">
                {unreadCount || 0}
              </Badge>
            )
            : null}
        </span>
      </>
    );
  };

  pendingPlayersBadge = () => (
    <>
      Author Signups
    </>
  );

  deleteBook = (obj) => {
    this.setState({
      deleteBook: obj,
    });
  };

  dragBook = (action, obj) => {
    const { groupHover: groupHover1 } = this.state;
    const { location } = this.props;

    this.setState({
      dragBook: action === 'start' ? obj : [],
    });

    if (action === 'stop' && obj.groupId !== groupHover1 && groupHover1 !== null) {
      const value = { groupId: groupHover1 };
      const values = queryString.parse(location.search);

      if (values && values.story_list && values.story_list !== 'all') {
        value.story_list = values.story_list;
      }

      api.put(`/v1/books/${obj.id}`, value)
        .then(() => {
          this.loadData();
        })
        .catch((error) => {
          this.errorAlert(error.response.data.error);
        });
    }
  };

  groupHover = (obj, id) => {
    this.setState({
      groupHover: id,
    });
  };

  actionShare = (obj) => {
    this.setState({
      editShare: obj,
      editShareActive: true,
    });
  };

  closeShare = () => {
    this.setState({
      editShare: {},
      editShareActive: false,
    });
    this.loadData();
  };

  booksShare() {
    const {
      editShareActive,
      editShare,
    } = this.state;

    return (
      <Share
        type="book"
        obj={editShare}
        show={editShareActive}
        onHide={this.closeShare}
        user={this.user}
      />
    );
  }

  clone(obj) {
    this.setState({ isCloning: true });
    const value = { users: [this.user.id], clone: true };
    api.post(`/v1/books/${obj.id}/push`, value)
      .then(() => {
        this.setState({ isCloning: false });
        this.loadData();
      })
      .catch(() => {
        this.setState({ isCloning: false });
      });
  }

  cloneBook = (val) => {
    this.clone(val);
  };

  render() {
    const {
      search,
      limits,
      copyText,
      disabledRow,
      checkTokenType,
      openBookPush,
      checkToken,
      loading,
      dragBook,
      deleteBook: deleteBookState,
      editTitle,
      isCloning,
      groups,
      getInviteLinkModal,
      formError,
      editBook,
    } = this.state;

    const {
      match,
      location,
    } = this.props;

    const page = {
      header: {
        title: 'Stories',
        type: 'stories',
        settings: this.user.role === 'admin' ? 'admin' : null,
      },
      sidebar: {
        nav: [
          {
            title: 'Home',
            action: '',
            href: '/home',
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Create Story',
            action: () => this.initAddBook(),
            variant: 'primary',
            disabled: loading /* || this.user.role !== "admin" */,
            activeWizardStep: 2,
          },
          {
            title: 'Search',
            action: '',
            href: '/search',
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Invite Writers',
            action: () => {
              this.setState({
                getInviteLinkModal: true,
              });
            },
            variant: 'success',
          },
          {
            title: this.pendingPlayersBadge(),
            variant: 'secondary',
            href: '/fan',
          },
          {
            title: this.articleBadge(),
            action: '',
            href: '/articles',
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Story Activity',
            action: '',
            href: '/activity',
            variant: 'secondary',
            disabled: this.user.role !== 'admin',
            NotAdminPermissions: this.user.role !== 'admin',
          },
          {
            title: 'Episode Creation',
            action: '',
            href: '/creation',
            variant: 'secondary',
            disabled: this.user.role !== 'admin',
            NotAdminPermissions: this.user.role !== 'admin',
          },
          {
            title: 'Story Performance',
            action: '',
            href: '/performance',
            variant: 'secondary',
          },
          {
            title: 'Documents',
            action: '',
            href: '/documents',
            variant: 'secondary',
          },

        ],
      },
    };

    const addBook = () => {
      if (editBook) {
        return (
          <AddBook
            show
            limits={limits}
            user={this.user}
            onHide={() => {
              this.setState({
                editBook: null,
              });
            }}
            book={editBook}
            items={groups}
            update={() => {
              this.setState({
                editBook: null,
              }, () => {
                this.loadData();
              });
            }}
          />
        );
      }
      return null;
    };

    const deleteBook = () => {
      if (deleteBookState) {
        return (
          <DeleteBook
            show
            onHide={() => {
              this.setState({
                deleteBook: null,
              });
            }}
            obj={deleteBookState}
            update={() => {
              this.setState({
                deleteBook: null,
              });
              this.loadData();
            }}
          />
        );
      }
      return null;
    };

    const linkUrl = document.location.origin.replace('www.', '');

    const handleCopy = () => {
      const copyTextEl = document.getElementById('linkShare');
      copyTextEl.select();
      copyTextEl.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.setState({
        copyText: copyTextEl.value,
      }, () => {
        setTimeout(() => {
          this.setState({
            copyText: null,
          });
        }, 1000);
      });
    };

    const path = match && match.path
      ? match.path
      : '/books';
    const values = queryString.parse(location.search);

    return (
      <>
        <PageWrapper
          {...this.props}
          page={page}
          emailVerifie={this.emailVerifie}
        >
          <Alert
            show={!!checkToken}
            variant={checkTokenType}
            className="my-5 emailVerifiedAlert"
          >
            {checkToken}
          </Alert>

          <Loading show={isCloning} />

          <Card>
            <Card.Body>

              <Row className={!formError ? 'd-none' : 'd-block'}>
                <Col md={12}>
                  <Alert variant="danger">
                    {formError}
                  </Alert>
                </Col>
              </Row>

              <Form
                className="my-3"
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Form.Control
                  type="text"
                  placeholder="Story Title"
                  value={search}
                  onChange={(e) => {
                    const val = e.target.value;
                    this.setState(() => ({
                      search: val,
                      // eslint-disable-next-line react/no-unused-state
                      offset: 0,
                    }));
                  }}
                />
              </Form>

              <div className="text-right mt-3">
                <ButtonGroup size="sm">
                  <Button
                    variant={
                      !values
                      || !values.story_list
                      || (
                        values
                        && values.story_list
                        && values.story_list === 'all'
                      )
                        ? 'primary' : 'secondary'
}
                    as={Link}
                    to={`${path}?story_list=all`}
                  >
                    All
                  </Button>
                  <Button
                    variant={
                      values
                      && values.story_list
                      && values.story_list === 'written'
                        ? 'primary' : 'secondary'
}
                    to={`${path}?story_list=written`}
                    as={Link}
                  >
                    Written Stories
                  </Button>
                  <Button
                    variant={
                      values
                      && values.story_list
                      && values.story_list === 'prompts'
                        ? 'primary' : 'secondary'
}
                    to={`${path}?story_list=prompts`}
                    as={Link}
                  >
                    Prompts Stories
                  </Button>
                </ButtonGroup>
              </div>

              <SortableList
                {...this.props}
                currentItem={this}
                onSortEnd={this.onSortEnd}
                helperClass="sortItem"
                distance={1}
                lockAxis="y"
                disabledRow={editTitle || disabledRow}
                items={groups}
                deleteBook={this.deleteBook}
                editBook={this.editBook}
                dragBookItem={dragBook}
                dragBook={this.dragBook}
                groupHover={this.groupHover}
                loading={loading}
                share={this.actionShare}
                search={search}
                limits={limits}
                editTitle={editTitle}
                cloneBook={(val) => this.cloneBook(val)}
                openBookPush={(val) => {
                  this.setState({
                    openBookPush: val,
                  });
                }}
                editTitleAction={(val) => {
                  this.setState({
                    editTitle: val,
                  });
                }}
              />
            </Card.Body>
          </Card>
        </PageWrapper>

        {this.booksShare()}
        {addBook()}
        {deleteBook()}

        <BookPush
          {...this.props}
          show={openBookPush}
          onHide={
            () => {
              this.setState({
                openBookPush: false,
              });
            }
          }
          update={
            () => {
              this.setState({
                openBookPush: false,
              }, () => {
                this.loadData();
              });
            }
          }
        />

        <Modal
          show={getInviteLinkModal}
          onHide={() => {
            this.setState({
              getInviteLinkModal: false,
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Invite Writers</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {copyText
            && (
            <Alert variant="success">
              copy to clipboard
            </Alert>
            )}

            <InputGroup className="mb-3">
              <FormControl
                id="linkShare"
                aria-label="Share"
                defaultValue={`${linkUrl}/invite?token=${btoa(this.user.id)}`}
              />
              <InputGroup.Append>
                <Button
                  variant="dark"
                  onClick={handleCopy}
                >
                  Copy
                </Button>
              </InputGroup.Append>
            </InputGroup>

          </Modal.Body>
        </Modal>

      </>
    );
  }
}
