import React, {
  useEffect, useRef, useState,
} from 'react';
import { api } from '../../api';
import { LoginModalForm } from '../../ui/LoginModalForm';
import { Preview } from '../../ui/Preview/Preview';
import classNames from './PlayPreview.module.scss';

export function PlayPreview(props) {
  const { match, auth, location } = props;
  const [story, setStory] = useState(null);
  const userRef = useRef(auth.getUser());

  useEffect(() => {
    api.get(`/v1/preview/${match.params.uuid}`)
      .then((res) => {
        setStory(res.data.story);
      });
  }, [match.params.uuid]);

  if (!story) {
    return null;
  }

  const isLoginModalVisible = !userRef.current && !story.public;
  const bookStyle = story.book && story.book.bookStyle;
  const bookStyleAlias = bookStyle && bookStyle.alias === 'arcana_style' ? 'arcana' : undefined;

  return (
    <div className={classNames.wrapper}>
      {isLoginModalVisible ? (
        <LoginModalForm
          location={location}
          auth={auth}
          play
          show
          className="loginOnPreview"
        />
      ) : (
        <Preview
          episodeId={story.id}
          episodeUuid={match.params.uuid}
          bookStyle={bookStyleAlias}
        />
      )}
    </div>
  );
}
