import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { api } from '../../../api';
import { StoryPreviewContext } from '../context';
import './style.scss';

export function AnswerDeleting(props) {
  const { loadData } = props;
  const {
    storyId,
    showDeleteAnswer,
    setShowDeleteAnswer,
    choiceProps,
  } = React.useContext(StoryPreviewContext);

  const [loading, setLoading] = React.useState(false);

  if (!showDeleteAnswer) {
    return null;
  }

  const handleSubmit = () => {
    setLoading(true);
    const answers = [];
    choiceProps.step.answers.forEach((obj, i) => {
      if (i !== choiceProps.index) {
        answers.push(obj);
      }
    });

    api.put(`/v1/stories/${storyId}/branches/${choiceProps.branchId}`, {
      steps: [{
        answers,
        characterExpressionId: choiceProps.step.characterExpressionId,
        characterId: choiceProps.step.characterId,
        stepTypeId: choiceProps.step.stepTypeId,
        text: choiceProps.step.text,
      }],
    })
      .then(() => {
        loadData();
        setLoading(false);
        setShowDeleteAnswer(false, null);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Modal.Dialog
      className="AnswerEditingModal"
    >
      <Modal.Header
        closeButton
        onHide={() => {
          setShowDeleteAnswer(false, null);
        }}
      >
        <Modal.Title>Delete Answer?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure you want to delete this answer?</p>
        {
            loading && (
            <div
              style={{
                position: 'absolute',
                zIndex: 999,
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                background: 'rgba(0,0,0,.2)',
              }}
            >
              <div style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%,-50%)',
              }}
              >
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
            )
          }
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => {
            setShowDeleteAnswer(false, null);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="outline-secondary"
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal.Dialog>
  );
}
