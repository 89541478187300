/* eslint-disable max-len,react/no-unescaped-entities */
import React, { Component } from 'react';
import { checkExternal } from './index';

export class TermsOfUseContent extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { type } = this.props;

    return (
      <div className="privacy-policy-content">
        <h6>Last Updated January 5, 2020</h6>
        <p>
          Please read these Terms of Service (the "
          <strong>Terms</strong>
          ") and our Privacy Policy (
          <a href={checkExternal(type, 'privacy-policy')}>
            https://dorian.live/privacy-policy
          </a>
          ) ("
          <strong>Privacy Policy</strong>
          ") carefully
          because they govern your use of the website located at www.Dorian.Live (the "
          <strong>Site</strong>
          ") and
          platform accessible via the Site and corresponding mobile application ("
          <strong>App</strong>
          ") offered by
          Dorian Inc. ("
          <strong>Dorian,</strong>
          " "
          <strong>we,</strong>
          " "
          <strong>us,</strong>
          " or "
          <strong>our</strong>
          ").
          To make these Terms easier to read, the Site, our services and App are collectively called the
          "
          <strong>Services.</strong>
          "
        </p>
        <p>
          These terms apply to all users of the Services, whether such users create or access content on the Services
          (individually "
          <strong>User</strong>
          " or "
          <strong>you</strong>
          "). All Users can create and view content on our
          Services for free, but we also permit some Users to monetize their content. If you decide to use the Services
          to monetize your User Content (as defined below), you must also agree separately to Dorian’s Independent
          Writer Agreement that is available to eligible Users.
        </p>
        <p>
          <strong>
            IMPORTANT NOTICE REGARDING ARBITRATION FOR U.S. CUSTOMERS: WHEN YOU AGREE TO THESE TERMS YOU ARE
            AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND DORIAN THROUGH BINDING, INDIVIDUAL
            ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 18 "DISPUTE RESOLUTION" BELOW FOR DETAILS
            REGARDING ARBITRATION.
          </strong>
        </p>
        <ol>
          <li>
            <p>
              <strong>Agreement to Terms.</strong>
              {' '}
              By using our Services, you agree to be bound by these Terms. If you
              don’t agree to be bound by these Terms, do not use the Services.
            </p>
          </li>
          <li>
            <p>
              <strong>Privacy Policy. </strong>
              Please review our Privacy Policy, which also governs your use of the
              Services, for information on how we collect, use and share your information.
            </p>
          </li>
          <li>
            <p>
              <strong>Changes to these Terms or the Services. </strong>
              We may update the Terms from time to time in our
              sole discretion. If we do, we’ll let you know by posting the updated Terms on the Site, to the App and/or
              may also send other communications. It’s important that you review the Terms whenever we update them or
              you use the Services. If you continue to use the Services after we have posted updated Terms, it means
              that you accept and agree to the changes. If you don’t agree to be bound by the changes, you may not use
              the Services anymore. Because our Services are evolving over time we may change or discontinue all or any
              part of the Services, at any time and without notice, at our sole discretion.
            </p>
          </li>
          <li>
            <strong>Who May Use the Services?</strong>
            <p>
              You must be at least 13 years old to use the Services. If you are between the ages of 13 and 18 (or
              between 13 and the age of legal majority in your country of residence), you need to get your parent’s or
              guardian’s permission, and your parent or legal guardian must read and agree to these Terms on your
              behalf. Parents and guardians must supervise your use of our Services, and are responsible for the acts of
              their children using our Services.
            </p>
            <p>
              It’s important that you provide us with accurate, complete and current account information and keep this
              information up to date. If you don’t, we might have to suspend or terminate your account. To protect your
              account, keep the account details and password confidential, and notify us right away of any unauthorized
              use. You’re responsible for all activities that occur under your account.
            </p>
          </li>
          <li>
            <p>
              <strong>Feedback. </strong>
              We appreciate feedback, comments, ideas, proposals and suggestions for
              improvements to the Services ("
              <strong>Feedback</strong>
              "). If you choose to submit Feedback, you agree
              that we are free to use it without any restriction or compensation to you.
            </p>
          </li>
          <li>
            <p>
              <strong>Virtual Entitlements. </strong>
              Within our Services, we may offer the ability to obtain or purchase
              digital items such as coins or hearts (collectively, "
              <strong>Virtual Entitlements</strong>
              ") which you
              can use to access certain content within the Services. Your use of Virtual Entitlements is governed by
              these Terms and the App Store Agreement(s) (described in Section 9 below). Provided you comply with these
              Terms and the App Store Agreement(s), we grant you a personal, non-exclusive, non-transferable,
              non-sublicensable limited right and license to use any Virtual Entitlements you gain access to, whether
              purchased or otherwise obtained, solely in connection with your personal use of the Services and for no
              other purpose. We may revoke your license to Virtual Entitlements at any time consistent with these Terms
              without notice or liability to you. Virtual Entitlements are not money, may not be sold, traded,
              transferred, or exchanged for cash, and have no value outside of the Services. By purchasing Virtual
              Entitlements, you hereby waive any statutory right you may have to withdraw from these Terms, and you
              agree that you will not be eligible to receive a refund (or any alternative remedy) in relation to such
              Virtual Entitlements, unless the Virtual Entitlements are defective, unavailable, or do not perform in
              accordance with the specifications we provide.
            </p>
          </li>
          <li>
            <p>
              <strong>One-Time Payments and Subscriptions.</strong>
              {' '}
              Dorian requires payment of a fee for use of the
              Services (or certain portions thereof) and you agree to pay such fees. You have the option of making a
              one-time payment ("
              <strong>One-Time Payment</strong>
              ") or purchasing a subscription
              ("
              <strong>Subscription</strong>
              ") for such use.
            </p>
            <ol style={{ listStyle: 'lower-alpha' }}>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>General.</span>
                  {' '}
                  Whether you make a One-Time Payment or
                  purchase a Subscription (each, a "
                  <strong>Transaction</strong>
                  "), you expressly authorize us (or our
                  third-party payment processor) to charge you for such Transaction. We may ask you to supply additional
                  information relevant to your Transaction, including your credit card number, the expiration date of your
                  credit card and your email and postal addresses for billing and notification (such information,
                  "
                  <strong>Payment Information</strong>
                  "). You represent and warrant that you have the legal right to use
                  all payment method(s) represented by any such Payment Information. When you initiate a Transaction, you
                  authorize us to provide your Payment Information to third parties so we can complete your Transaction
                  and to charge your payment method for the type of Transaction you have selected (plus any applicable
                  taxes and other charges). You may need to provide additional information to verify your identity before
                  completing your Transaction (such information is included within the definition of Payment Information).
                  By initiating a Transaction, you agree to the pricing, payment and billing policies applicable to such
                  fees and charges, as posted or otherwise communicated to you. All payments for Transactions are
                  non-refundable and non-transferable except as expressly provided in these Terms. All fees and applicable
                  taxes, if any, are payable in United States dollars.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Subscriptions.</span>
                  {' '}
                  If you purchase a Subscription,
                  you will be charged the monthly Subscription fee, plus any applicable taxes, and other charges
                  ("
                  <strong>Subscription Fee</strong>
                  "), at the beginning of your Subscription and each year thereafter,
                  at the then-current Subscription Fee. BY PURCHASING A SUBSCRIPTION, YOU AUTHORIZE DORIAN TO INITIATE
                  RECURRING NON-REFUNDABLE PAYMENTS AS SET FORTH BELOW. If you purchase a Subscription, we (or our
                  third-party payment processor) will automatically charge you each month on the anniversary of the date
                  on which you commenced your Subscription, using the Payment Information you have provided until you
                  cancel your Subscription. By agreeing to these Terms and electing to purchase a Subscription, you
                  acknowledge that your Subscription has recurring payment features and you accept responsibility for all
                  recurring payment obligations prior to cancellation of your Subscription by you or Dorian. Your
                  Subscription continues until cancelled by you or we terminate your access to or use of the Services or
                  Subscription in accordance with these Terms.
                </p>
              </li>
              <li>
                <p>
                  <span
                    style={{ textDecoration: 'underline' }}
                  >
                    Cancelling One-Time Payment or Subscription.
                  </span>
                  {' '}
                  "You may
                  cancel a Subscription for a full refund within fourteen (14) calendar days of your initial purchase, or
                  such other period as provided under our payment providers’ refund policies. AFTER THAT, YOUR PURCHASE IS
                  FINAL AND YOU WILL NOT BE ABLE TO CANCEL THE PURCHASE AND/OR RECEIVE A REFUND OF YOUR ONE-TIME PAYMENT
                  OR SUBSCRIPTION FEE AT ANY TIME. But if something unexpected happens in the course of completing a
                  Transaction, we reserve the right to cancel your Transaction for any reason; if we cancel your
                  Transaction, we’ll refund any payment you have already remitted to us for such Transaction. Without
                  limiting the foregoing, you may cancel your Subscription at any time, but please note that such
                  cancellation will be effective at the end of the then-current Subscription period. EXCEPT AS SET FORTH
                  ABOVE WITH RESPECT TO YOUR INITIAL SUBSCRIPTION PURCHASE, YOU WILL NOT RECEIVE A REFUND OF ANY PORTION
                  OF THE SUBSCRIPTION FEE PAID FOR THE THEN CURRENT SUBSCRIPTION PERIOD AT THE TIME OF CANCELLATION. To
                  cancel, you can send an email to support@dorian.live or use the self-service tools available via the
                  Service. You will be responsible for all Subscription Fees (plus any applicable taxes and other charges)
                  incurred for the then-current Subscription period. If you cancel, your right to use the Services will
                  continue until the end of your then current Subscription period and will then terminate without further
                  charges.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p><strong>Your Content. </strong></p>
            <ol style={{ listStyle: 'lower-alpha' }}>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Posting Content.</span>
                  {' '}
                  Our Services may allow you to
                  store or share content such as text (in posts or communications with others), files, documents,
                  graphics, images, music, software, audio and video. Anything (other than Feedback) that you post or
                  otherwise make available through the Services is referred to as "
                  <strong>User Content.</strong>
                  " Dorian
                  does not claim any ownership rights in any User Content and nothing in these Terms will be deemed to
                  restrict any rights that you may have to your User Content. You retain all ownership rights in and to
                  your User Content.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Permissions to Your User Content.</span>
                  {' '}
                  You hereby
                  grant Dorian a worldwide, nonexclusive, sublicensable, royalty-free, fully paid-up, transferable,
                  irrevocable and perpetual right and license to use, post, publish, share, store, reproduce, alter,
                  modify and create derivative works based upon your User Content solely in order to enable us to operate
                  the Services, and to permit our Users to use, access, and download your User Content.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Your Responsibility for User Content.</span>
                  {' '}
                  You are
                  solely responsible for all your User Content. You represent and warrant that you have (and will have)
                  all rights that are necessary to grant us the license rights in your User Content under these Terms. You
                  represent and warrant that neither your User Content, nor your use and provision of your User Content to
                  be made available through the Services, nor any use of your User Content by Dorian on or through the
                  Services will infringe, misappropriate or violate a third party’s intellectual property rights, or
                  rights of publicity or privacy, or result in the violation of any applicable law or regulation.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Removal of User Content.</span>
                  {' '}
                  You can remove your
                  User Content by specifically deleting it. You should know that in certain instances, some of your User
                  Content (such as posts or comments you make) may not be completely removed and copies of your User
                  Content may continue to exist on the Services. To the maximum extent permitted by law, we are not
                  responsible or liable for the removal or deletion of (or the failure to remove or delete) any of your
                  User Content.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Dorian’s Intellectual Property.</span>
                  {' '}
                  We may make
                  available through the Services content that is subject to intellectual property rights. We retain all
                  rights to that content.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p><strong>Rights and Terms for Apps.</strong></p>
            <ol style={{ listStyle: 'lower-alpha' }}>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>App License.</span>
                  {' '}
                  If you comply with these Terms,
                  Dorian grants to you a limited non-exclusive, non-transferable license, with no right to sublicense, to
                  download and install the App on your personal computers, mobile handsets, tablets, wearable devices,
                  and/or other devices and to run the App solely for your own personal non-commercial purposes. Except as
                  expressly permitted in these Terms, you may not: (i) copy, modify or create derivative works based on
                  the App; (ii) distribute, transfer, sublicense, lease, lend or rent the App to any third party; (iii)
                  reverse engineer, decompile or disassemble the App (unless applicable law permits, despite this
                  limitation); or (iv) make the functionality of the App available to multiple users through any means.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Additional Information: Apple App Store.</span>
                  {' '}
                  This
                  Section 9(b) applies to any App that you acquire from the Apple App Store or use on an iOS device. Apple
                  has no obligation to furnish any maintenance and support Services with respect to the App. In the event
                  of any failure of the App to conform to any applicable warranty, you may notify Apple, and Apple will
                  refund the App purchase price to you (if applicable) and, to the maximum extent permitted by applicable
                  law, Apple will have no other warranty obligation whatsoever with respect to the App. Apple is not
                  responsible for addressing any claims by you or any third party relating to the App or your possession
                  and use of it, including, but not limited to: (i) product liability claims; (ii) any claim that the App
                  fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under
                  consumer protection or similar legislation. Apple is not responsible for the investigation, defense,
                  settlement and discharge of any third-party claim that your possession and use of the App infringe that
                  third party’s intellectual property rights. Apple and its subsidiaries, are third-party beneficiaries of
                  these Terms, and upon your acceptance of the Terms, Apple will have the right (and will be deemed to
                  have accepted the right) to enforce these Terms against you as a third-party beneficiary thereof. You
                  represent and warrant that (i) you are not located in a country that is subject to a U.S. Government
                  embargo, or that has been designated by the U.S. Government as a terrorist-supporting country; and (ii)
                  you are not listed on any U.S. Government list of prohibited or restricted parties. You must also comply
                  with any applicable third-party terms of service when using the App.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>General Prohibitions and Dorian’s Enforcement Rights. </strong>
              You agree not to do any of the
              following:
            </p>
            <ol style={{ listStyle: 'lower-alpha' }}>
              <li>
                <p>
                  {' '}
                  Post, upload, publish, submit or transmit any User Content that: (i) infringes, misappropriates or
                  violates a third party’s patent, copyright, trademark, trade secret, moral rights or other intellectual
                  property rights, or rights of publicity or privacy; (ii) violates, or encourages any conduct that would
                  violate, any applicable law or regulation or would give rise to civil liability; (iii) is fraudulent,
                  false, misleading or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive; (v)
                  promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group;
                  (vi) is violent or threatening or promotes violence or actions that are threatening to any person or
                  entity; or (vii) promotes illegal or harmful activities or substances;
                </p>
              </li>
              <li>
                <p>
                  {' '}
                  Use, display, mirror or frame the Services or any individual element within the Services, Dorian’s
                  name, any Dorian trademark, logo or other proprietary information, or the layout and design of any page
                  or form contained on a page, without Dorian’s express written consent;
                </p>
              </li>
              <li>
                <p>
                  {' '}
                  Access, tamper with, or use non-public areas of the Services, Dorian’s computer systems, or the
                  technical delivery systems of Dorian’s providers;
                </p>
              </li>
              <li>
                <p>
                  {' '}
                  Attempt to probe, scan or test the vulnerability of any Dorian system or network or breach any
                  security or authentication measures;
                </p>
              </li>
              <li>
                <p>
                  {' '}
                  Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological
                  measure implemented by Dorian or any of Dorian’s providers or any other third party (including another
                  user) to protect the Services;
                </p>
              </li>
              <li>
                <p>
                  {' '}
                  Attempt to access or search the Services or download content from the Services using any engine,
                  software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or
                  the like) other than the software and/or search agents provided by Dorian or other generally available
                  third-party web browsers;
                </p>
              </li>
              <li>
                <p>
                  {' '}
                  Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam,
                  chain letters or other form of solicitation;
                </p>
              </li>
              <li>
                <p>
                  {' '}
                  Use any meta tags or other hidden text or metadata utilizing a Dorian trademark, logo URL or
                  product name without Dorian’s express written consent;
                </p>
              </li>
              <li>
                <p>
                  {' '}
                  Use the Services, or any portion thereof, for any commercial purpose or for the benefit of any
                  third party or in any manner not permitted by these Terms;
                </p>
              </li>
              <li>
                <p>
                  {' '}
                  Forge any TCP/IP packet header or any part of the header information in any email or newsgroup
                  posting, or in any way use the Services to send altered, deceptive or false source-identifying
                  information;
                </p>
              </li>
              <li>
                <p>
                  {' '}
                  Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to
                  provide the Services;
                </p>
              </li>
              <li>
                <p>
                  {' '}
                  Interfere with, or attempt to interfere with, the access of any user, host or network, including,
                  without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;
                </p>
              </li>
              <li>
                <p>
                  {' '}
                  Collect or store any personally identifiable information from the Services from other users of the
                  Services without their express permission;
                </p>
              </li>
              <li><p> Impersonate or misrepresent your affiliation with any person or entity;</p></li>
              <li><p> Violate any applicable law or regulation; or</p></li>
              <li><p> Encourage or enable any other individual to do any of the foregoing.</p></li>
            </ol>
            <p>
              Dorian is not obligated to monitor access to or use of the Services or to review or edit any content.
              However, we have the right to do so for the purpose of operating the Services, to ensure compliance with
              these Terms and to comply with applicable law or other legal requirements. We reserve the right, but are
              not obligated, to remove or disable access to any content, including User Content, at any time and without
              notice, including, but not limited to, if we, at our sole discretion, consider it objectionable or in
              violation of these Terms. We have the right to investigate violations of these Terms or conduct that
              affects the Services. We may also consult and cooperate with law enforcement authorities to prosecute
              users who violate the law.
            </p>
          </li>
          <li>
            <p>
              <strong>DMCA/Copyright Policy. </strong>
              Dorian respects copyright law and expects its users to do the
              same. It is Dorian’s policy to terminate in appropriate circumstances account holders who repeatedly
              infringe or are believed to be repeatedly infringing the rights of copyright holders. Please see Dorian’s
              Copyright Policy at
              {' '}
              <a
                href={checkExternal(type, 'copyright-policy')}
              >
                https://dorian.live/copyright-policy
              </a>
              , for
              further
              information.
            </p>
          </li>
          <li>
            <p>
              <strong>Links to Third-Party Websites or Resources.</strong>
              {' '}
              The Services (including the App) may allow
              you to access third-party websites or other resources. We provide access only as a convenience and are not
              responsible for the content, products or Services on or available from those resources or links displayed on
              such websites. You acknowledge sole responsibility for and assume all risk arising from, your use of any
              third-party resources.
            </p>
          </li>
          <li>
            <p>
              <strong>Termination.</strong>
              {' '}
              We may suspend or terminate your access to and use of the Services,
              including suspending access to or terminating your account, at our sole discretion, at any time and without
              notice to you. You may cancel your account at any time via the Site or App, or by sending us an email at
              support@dorian.live. Upon any termination, discontinuation or cancellation of the Services or your account, the
              following Sections will survive: 6, 7(a), 7(b), 7(c) (only for payments due and owing to Dorian prior to the
              termination), 8(b), 8(c), 8(e), 9, 13, 14, 15, 16, 17, 18 and 19.
            </p>
          </li>
          <li>
            <p><strong>Warranty Disclaimers.</strong></p>
            {' '}
            THE SERVICES ARE PROVIDED "
            <strong>AS IS,</strong>
            " WITHOUT
            WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT, AND ANY WARRANTIES
            ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We make no warranty that the Services will meet your
            requirements or be available on an uninterrupted, secure, or error-free basis. We make no warranty regarding
            the quality, accuracy, timeliness, truthfulness, completeness or reliability of any information or content
            on the Services.
          </li>
          <li>
            <p>
              <strong>Indemnity.</strong>
              {' '}
              You will indemnify and hold Dorian and its officers, directors, employees
              and agents, harmless from and against any claims, disputes, demands, liabilities, damages, losses, and costs
              and expenses, including, without limitation, reasonable legal and accounting fees arising out of or in any
              way connected with (a) your access to or use of the Services, (b) your User Content, or (c) your violation
              of these Terms.
            </p>
          </li>
          <li>
            <p><strong>Limitation of Liability.</strong></p>
            <ol style={{ listStyle: 'lower-alpha' }}>
              <li>
                <p>
                  {' '}
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER DORIAN NOR ITS SERVICE PROVIDERS INVOLVED IN
                  CREATING, PRODUCING, OR DELIVERING THE SERVICES WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR
                  CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS
                  OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE
                  COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE
                  OF OR INABILITY TO USE THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
                  PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT DORIAN OR ITS SERVICE PROVIDERS HAVE
                  BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO
                  HAVE FAILED OF ITS ESSENTIAL PURPOSE.
                </p>
              </li>
              <li>
                <p>
                  {' '}
                  TO THE MAXIMUM EXTENT PERMITTED BY THE LAW OF THE APPLICABLE JURISDICTION, IN NO EVENT WILL
                  DORIAN’S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR
                  INABILITY TO USE THE SERVICES EXCEED THE AMOUNTS YOU HAVE PAID OR ARE PAYABLE BY YOU TO DORIAN FOR USE
                  OF THE SERVICES OR ONE HUNDRED DOLLARS ($100), IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO DORIAN, AS
                  APPLICABLE.
                </p>
              </li>
              <li>
                <p>
                  {' '}
                  THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF
                  THE BARGAIN BETWEEN DORIAN AND YOU.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>Governing Law and Forum Choice.</strong>
              {' '}
              These Terms and any action related thereto will be
              governed by the Federal Arbitration Act, federal arbitration law, and the laws of the State of California,
              without regard to its conflict of laws provisions. Except as otherwise expressly set forth in Section 18
              "
              <strong>Dispute Resolution,</strong>
              " the exclusive jurisdiction for all Disputes (defined below) that you
              and Dorian are not required to arbitrate will be the state and federal courts located in Los Angeles County,
              CA, and you and Dorian each waive any objection to jurisdiction and venue in such courts.
            </p>
          </li>
          <li>
            <p><strong>Dispute Resolution. </strong></p>
            <ol style={{ listStyle: 'lower-alpha' }}>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Mandatory Arbitration of Disputes. </span>
                  {' '}
                  We each
                  agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach,
                  termination, enforcement, interpretation or validity thereof or the use of the Services (collectively, "
                  <strong>Disputes</strong>
                  ")
                  will be resolved solely by binding, individual arbitration and not in a class, representative or
                  consolidated action or proceeding. You and Dorian agree that the U.S. Federal Arbitration Act governs
                  the interpretation and enforcement of these Terms, and that you and Dorian are each waiving the right to
                  a trial by jury or to participate in a class action. This arbitration provision shall survive
                  termination of these Terms.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Exceptions. </span>
                  {' '}
                  As limited exceptions to Section
                  18(a) above: (i) we both may seek to resolve a Dispute in small claims court if it qualifies; and (ii)
                  we each retain the right to seek injunctive or other equitable relief from a court to prevent (or
                  enjoin) the infringement or misappropriation of our intellectual property rights.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Conducting Arbitration and Arbitration Rules. </span>
                  The
                  arbitration will be conducted by the American Arbitration Association ("
                  <strong>AAA</strong>
                  ") under its
                  Consumer Arbitration Rules (the "
                  <strong>AAA Rules</strong>
                  ") then in effect, except as modified by
                  these Terms. The AAA Rules are available at www.adr.org or by calling 1-800-778-7879. A party who wishes
                  to start arbitration must submit a written Demand for Arbitration to AAA and give notice to the other
                  party as specified in the AAA Rules. The AAA provides a form Demand for Arbitration at www.adr.org.
                </p>
                <p>
                  Any arbitration hearings will take place in the county (or parish) where you live, unless we both
                  agree to a different location. The parties agree that the arbitrator shall have exclusive authority to
                  decide all issues relating to the interpretation, applicability, enforceability and scope of this
                  arbitration agreement.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Arbitration Costs. </span>
                  Payment of all filing,
                  administration and arbitrator fees will be governed by the AAA Rules, and we won’t seek to recover the
                  administration and arbitrator fees we are responsible for paying, unless the arbitrator finds your
                  Dispute frivolous. If we prevail in arbitration, we’ll pay all of our attorneys’ fees and costs and
                  won’t seek to recover them from you. If you prevail in arbitration you will be entitled to an award of
                  attorneys’ fees and expenses to the extent provided under applicable law.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Injunctive and Declaratory Relief. </span>
                  Except as
                  provided in Section 18(b) above, the arbitrator shall determine all issues of liability on the merits of
                  any claim asserted by either party and may award declaratory or injunctive relief only in favor of the
                  individual party seeking relief and only to the extent necessary to provide relief warranted by that
                  party’s individual claim. To the extent that you or we prevail on a claim and seek public injunctive
                  relief (that is, injunctive relief that has the primary purpose and effect of prohibiting unlawful acts
                  that threaten future injury to the public), the entitlement to and extent of such relief must be
                  litigated in a civil court of competent jurisdiction and not in arbitration. The parties agree that
                  litigation of any issues of public injunctive relief shall be stayed pending the outcome of the merits
                  of any individual claims in arbitration.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Class Action Waiver. </span>
                  YOU AND DORIAN AGREE THAT
                  EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF
                  OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, if the parties’ Dispute is
                  resolved through arbitration, the arbitrator may not consolidate another person’s claims with your
                  claims, and may not otherwise preside over any form of a representative or class proceeding. If this
                  specific provision is found to be unenforceable, then the entirety of this Dispute Resolution section
                  shall be null and void.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Severability.</span>
                  {' '}
                  With the exception of any of the
                  provisions in Section 18(f) of these Terms ("
                  <strong>Class Action Waiver</strong>
                  "), if an arbitrator or
                  court of competent jurisdiction decides that any part of these Terms is invalid or unenforceable, the
                  other parts of these Terms will still apply.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <strong>General Terms.</strong>
            <ol style={{ listStyle: 'lower-alpha' }}>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Reservation of Rights. </span>
                  Dorian and its licensors
                  exclusively own all right, title and interest in and to the Services, including all associated
                  intellectual property rights. You acknowledge that the Services are protected by copyright, trademark,
                  and other laws of the United States and foreign countries. You agree not to remove, alter or obscure any
                  copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying
                  the Services.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Entire Agreement. </span>
                  These Terms constitute the
                  entire and exclusive understanding and agreement between Dorian and you regarding the Services, and
                  these Terms supersede and replace all prior oral or written understandings or agreements between Dorian
                  and you regarding the Services. If any provision of these Terms is held invalid or unenforceable by an
                  arbitrator or a court of competent jurisdiction, that provision will be enforced to the maximum extent
                  permissible and the other provisions of these Terms will remain in full force and effect. You may not
                  assign or transfer these Terms, by operation of law or otherwise, without Dorian’s prior written
                  consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null.
                  Dorian may freely assign or transfer these Terms without restriction. Subject to the foregoing, these
                  Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Notices. </span>
                  Any notices or other communications
                  provided by Dorian under these Terms will be given: (i) via email; or (ii) by posting to the Services.
                  For notices made by email, the date of receipt will be deemed the date on which such notice is
                  transmitted.
                </p>
              </li>
              <li>
                <p>
                  <span style={{ textDecoration: 'underline' }}>Waiver of Rights. </span>
                  Dorian’s failure to enforce
                  any right or provision of these Terms will not be considered a waiver of such right or provision. The
                  waiver of any such right or provision will be effective only if in writing and signed by a duly
                  authorized representative of Dorian. Except as expressly set forth in these Terms, the exercise by
                  either party of any of its remedies under these Terms will be without prejudice to its other remedies
                  under these Terms or otherwise.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>Contact Information.</strong>
              {' '}
              If you have any questions about these Terms or the Services, please
              contact us at:
            </p>
            <p>
              Dorian Inc.
              <br />
              340 S LEMON AVE #5571
              <br />
              WALNUT , CA 91789
              <br />
              United States
              <br />
              Phone: 4159263905
              <br />
              support@dorian.live
            </p>
          </li>
        </ol>

      </div>
    );
  }
}
