import arrayMove from 'array-move';
import async from 'async';
import classNames from 'classnames/bind';
import serialize from 'form-serialize';
import { isEqual } from 'lodash-es';
import React, { Component } from 'react';
import {
  Button, Col, Form, ListGroup, Modal, Row, Spinner,
} from 'react-bootstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { MemorySlotsTypesList } from 'components/pages/Book/MemoryBank/MemoryBankTypesList';
import { MEMORY_BANK_STEPS_TYPES } from 'components/pages/Book/MemoryBankModal';
import { showToast } from 'components/ui/utils';
import { noop } from '../../../../helpers/noop';
import { api } from '../../../api';
import { ErrorAlert } from '../../../ui/Errors/ErrorAlet';
// eslint-disable-next-line import/no-cycle
import { PremiumIpDisabledApprovedEdit } from '../../../utils/premiumIpDisabledApprovedEdit';
import { PremiumIpDisabledEdit } from '../../../utils/premiumIpDisabledEdit';
import { CustomSelect } from '../CustomSelect';
import { AddBranchHead } from './AddBranchHead';
import { AddStep } from './AddStep';
import { BranchModalPrevNext } from './BranchModalPrevNext';
import { ContextProvider } from './context/ContextProvider';
import { clearStepData, getStepData, prepareForCopy } from './Steps/CopyPastStep';
import styles from './Steps/Steps.scss';
import { StepsForm } from './Steps/StepsForm';
import { SWITCH_INDEX_TYPE } from './Steps/StepTypeCheck';
import { SCROLLABLE_STEP_FORM_ELEMENT_ID, count } from './Steps/utils';

const cs = classNames.bind(styles);

const SortableItemSteps = SortableElement(({ object, i, ...props }) => (
  <StepsForm
    step={object}
    index={i}
    {...props}
  />
));

const SortableListSteps = SortableContainer(({ items, disabledSortable, ...props }) => (
  <ListGroup as="ol" start="0" variant="flush" className="my-1 ml-4 mr-1 branchesList">
    {items.map((value, index) => (
      <SortableItemSteps
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        disabled={!!disabledSortable}
        index={index}
        object={value}
        i={index}
        {...props}
      />
    ))}
  </ListGroup>
));

// eslint-disable-next-line no-return-assign,no-void,no-cond-assign,no-param-reassign
const replacer = (k, v) => (Array.isArray(v) && !(v = v.filter((e) => e)).length ? void 0 : v);

export class AddBranch extends Component {
  constructor(...args) {
    super(...args);
    const { data } = this.props;

    this.state = {
      validated: false,
      locations: [],
      locationId: '',
      steps: [],
      stepConst: [],
      stepsType: [],
      characters: [],
      characterExpression: [],
      tagsTypes: [],
      modifiersTypes: [],
      requiresTypes: [],
      traitsTypes: [],
      // eslint-disable-next-line react/no-unused-state
      addStep: false,
      // eslint-disable-next-line react/no-unused-state
      analytics: null,
      loading: false,
      errorMsg: null,
      saveLoading: false,
      activeSteps: null,
      addLoading: false,
      choice: false,
      isCheckStep: false,
      gotoBranchId: data ? data.gotoBranchId : '',
      modalPrev: false,
      modalNext: false,
      defaultValueTitle: data ? data.title : 'NewNode',
      defaultValueDescription: data ? data.description || '' : 'Description',
      answerType: [],
      statsInfo: {},
      currStatsInfo: {},
      ending: false,
      result: false,
      disabledSortable: false,
      editHeader: false,
      SelectStepsCopy: false,
      copyStep: [],
      allCopyStepChecked: false,
      deleteStep: [],
      SelectStepsDelete: false,
      allDeleteStepChecked: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { edit, addBranchEdit, data } = this.props;
    const {
      gotoBranchId, steps, copyStep, editHeader, stepConst,
    } = this.state;

    if (steps !== prevState.steps) {
      steps.forEach((step, i) => {
        // eslint-disable-next-line no-param-reassign
        step.ind = i;
      });
    }
    const copyStepFromStorage = JSON.parse(localStorage.getItem('copyStep'));
    if (JSON.stringify(copyStepFromStorage) !== JSON.stringify(copyStep)) {
      this.setState({
        copyStep: copyStepFromStorage,
      });
    }
    if (
      JSON.stringify(prevState.steps) !== JSON.stringify(steps)
      || prevState.editHeader !== editHeader
      || Number(prevState.gotoBranchId) !== Number(gotoBranchId)
    ) {
      const isStepsEqual = isEqual(steps, stepConst);

      const newEdit = !isStepsEqual
        || editHeader
        || Number(gotoBranchId) !== Number(data.gotoBranchId);
      if (newEdit !== addBranchEdit) {
        edit(newEdit);
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.stepTypes !== state.stepsType) {
      return { stepsType: props.stepTypes };
    }
    return null;
  }

  defStepType() {
    const { result, ending } = this.state;

    if (result) {
      return 5;
    } if (ending) {
      return 5;
    }
    return 1;
  }

  disabledSortableAction = (val) => {
    this.setState({
      disabledSortable: val,
    });
  };

  errorAlert = (error) => {
    this.setState({
      errorMsg: error,
    });
    setTimeout(() => {
      this.errorAlertClose();
    }, 3000);
  };

  errorAlertClose = () => {
    this.setState({
      errorMsg: null,
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { steps } = this.state;

    this.setState({
      steps: arrayMove(steps, oldIndex, newIndex),
      activeSteps: `step-${newIndex}`,
    });
  };

  updateSteps = (index, value) => {
    const { steps } = this.state;

    const newSteps = steps.slice();
    newSteps[index] = value;
    this.setState({
      steps: newSteps,
    }, () => {
      this.checkChoice();
      this.updateStats();
    });
  };

  handleChangeSteps = (e, index, name) => {
    const { steps } = this.state;
    const stepsCopy = steps.slice();
    const obj = {
      [name]: e,
    };
    stepsCopy[index] = { ...stepsCopy[index], ...obj };

    if (Number(stepsCopy[index].stepTypeId) !== MEMORY_BANK_STEPS_TYPES.REMEMBER) {
      this.removeStepAction(stepsCopy[index]);
    }

    if (Number(stepsCopy[index].stepTypeId) !== MEMORY_BANK_STEPS_TYPES.CHECK) {
      this.removeStepCheck(stepsCopy[index]);
    }

    this.setState({
      steps: stepsCopy,
    }, this.checkChoice);
  };

  addStepObject = (stepIndex, objectName, newObject) => {
    const { steps } = this.state;
    const stepCopy = { ...steps[stepIndex] };

    stepCopy[objectName] = newObject;
    this.updateSteps(stepIndex, stepCopy);
  };

  removeStepObject = (step, objectNames) => {
    if (!objectNames) {
      return;
    }

    for (let i = 0; i < objectNames.length; i++) {
      const objectName = objectNames[i];
      if (step[objectName]) {
        // eslint-disable-next-line no-param-reassign
        delete step[objectName];
      }
    }
  };

  handleChangeStepObject = (objectName, values, stepIndex) => {
    const { steps } = this.state;
    const stepCopy = { ...steps[stepIndex] };

    let tempObj = {};
    if (stepCopy[objectName]) {
      tempObj = { ...stepCopy[objectName] };
    }

    values.forEach((valueObj) => {
      tempObj[valueObj.name] = valueObj.value;
    });
    stepCopy[objectName] = tempObj;
    this.updateSteps(stepIndex, stepCopy);
  };

  addStepAction = (stepIndex, variableId, variable, type, value) => {
    const actionObj = {
      variableId, variable, type, value,
    };
    this.addStepObject(stepIndex, 'action', actionObj);
  };

  removeStepAction = (step) => {
    this.removeStepObject(step, ['action']);
  };

  handleChangeStepAction = (values, stepIndex) => {
    this.handleChangeStepObject('action', values, stepIndex);
  };

  addStepCheck = (stepIndex, variableId, operatorName, value) => {
    const checkObj = {
      variableId: Number(variableId), operator: operatorName, value,
    };
    this.addStepObject(stepIndex, 'check', checkObj);
  };

  removeStepCheck = (step) => {
    this.removeStepObject(step, ['check', 'switch']);
  };

  handleChangeStepCheck = (values, stepIndex) => {
    this.handleChangeStepObject('check', values, stepIndex);
  };

  handleChangeStepSwitch = (gotoBranchId, stepIndex, switchIndex) => {
    const { steps } = this.state;
    const stepCopy = { ...steps[stepIndex] };

    let switchValues = [];
    if (stepCopy.switch) {
      switchValues = [...stepCopy.switch];
    }

    if (!switchValues[SWITCH_INDEX_TYPE.TRUE]) {
      switchValues[SWITCH_INDEX_TYPE.TRUE] = {
        value: true,
        gotoBranchId: 0,
      };
    }
    if (!switchValues[SWITCH_INDEX_TYPE.FALSE]) {
      switchValues[SWITCH_INDEX_TYPE.FALSE] = {
        value: false,
        gotoBranchId: 0,
      };
    }

    switchValues[switchIndex].gotoBranchId = gotoBranchId;
    stepCopy.switch = switchValues;
    this.updateSteps(stepIndex, stepCopy);
  };

  addStep = (index, obj, replace) => {
    const { steps } = this.state;
    const newSteps = steps.slice();
    const { data } = this.props;

    const newStep = obj || {
      id: '',
      stepTypeId: this.defStepType(),
      text: null,
      characterId: null,
      characterRelationship: null,
      characterExpressionId: null,
      branchId: data.id,
      answers: [],
      data: [],
    };
    if (index !== undefined) {
      if (replace) {
        newSteps.splice(index, 1, newStep);
      } else {
        newSteps.splice(index + 1, 0, newStep);
      }
    } else {
      newSteps.push(newStep);
    }
    this.setState({
      activeSteps: `step-${index !== undefined ? index + 1 : newSteps.length - 1}`,
      steps: newSteps,
    }, this.checkChoice);
  };

  deleteStep = (i) => {
    const newSteps = [];
    const { steps } = this.state;
    steps.forEach((a, index) => {
      if (!i.includes(index)) {
        newSteps.push(a);
      }
    });
    this.setState({
      steps: newSteps,
      deleteStep: [],
      SelectStepsDelete: false,
      allDeleteStepChecked: false,
    }, this.checkChoice);
  };

  activeSteps = (arg) => {
    this.setState({
      activeSteps: arg,
    });
  };

  updateStats = () => {
    const { steps, statsInfo, currStatsInfo } = this.state;
    const newStatsInfo = { ...statsInfo };
    steps.forEach((el) => {
      if (Number(el.stepTypeId) === 3) {
        el.answers.forEach((ans) => {
          ans.requires.forEach((req) => {
            newStatsInfo[Number(req.requireTypeId)] += 1;
          });
        });
      }
    });
    if (JSON.stringify(newStatsInfo) !== JSON.stringify(currStatsInfo)) {
      this.setState({
        currStatsInfo: newStatsInfo,
      });
    }
  };

  initEditBranch = (val) => {
    const { modalNext, modalPrev } = this.state;

    if (val === 'prev') {
      this.setState({
        modalPrev: !modalPrev,
      });
    }
    if (val === 'next') {
      this.setState({
        modalNext: !modalNext,
      });
    }
  };

  actionEditBranch = (val) => {
    const { onHide, actionEditBranch } = this.props;

    onHide();
    setTimeout(() => {
      actionEditBranch(val);
    }, 50);
  };

  componentDidMount() {
    const { data } = this.props;

    if (data !== null && data.id) {
      this.loadData();
    }

    if (data === null || !data.id) {
      this.addStories(data || {}, true);
    }
  }

  reLoadCharacters = () => {
    const { storyId } = this.props;

    api.get(`/v1/stories/${storyId}/characters`)
      .then((res) => {
        this.setState({
          characters: res.data.characters,
        });
      })
      .catch((error) => {
        this.errorAlert(error);
      });
  };

  loadData() {
    const { story, storyId, data } = this.props;
    this.setState({
      loading: true,
    });

    async.parallel({
      locations: (callback) => {
        api.get(`/v1/books/${story.book.id}/locations`)
          .then((res) => {
            callback(null, res.data.locations);
          }).catch((error) => {
            callback(error, null);
          });
      },
      characters: (callback) => {
        api.get(`/v1/stories/${storyId}/characters`)
          .then((res) => {
            callback(null, res.data.characters);
          }).catch((error) => {
            callback(error, null);
          });
      },
      expressions: (callback) => {
        api.get('/v1/characters/expressions')
          .then((res) => {
            callback(null, res.data.expressions);
          }).catch((error) => {
            callback(error, null);
          });
      },
      tags: (callback) => {
        api.get('/v1/answers/tags/types')
          .then((res) => {
            callback(null, res.data.types);
          }).catch((error) => {
            callback(error, null);
          });
      },
      modifiers: (callback) => {
        api.get('/v1/answers/modifiers/types')
          .then((res) => {
            callback(null, res.data.types);
          }).catch((error) => {
            callback(error, null);
          });
      },
      stats: (callback) => {
        api.get('/v1/stats/types')
          .then((res) => {
            callback(null, res.data.types);
          }).catch((error) => {
            callback(error, null);
          });
      },
      answerType: (callback) => {
        api.get('/v1/answers/types')
          .then((res) => {
            callback(null, res.data.types);
          }).catch((error) => {
            callback(error, null);
          });
      },
      stepsData: (callback) => {
        if (data.id) {
          api.get(`/v1/stories/${storyId}/branches/${data.id}`)
            .then((res) => {
              callback(null, res.data.branch);
            }).catch((error) => {
              callback(error, null);
            });
        } else {
          callback(null, null);
        }
      },
      storyInfo: (callback) => {
        api.get(`/v1/stories/${storyId}/info?statonly=1`)
          .then((res) => {
            callback(null, res.data.info);
          }).catch((error) => {
            callback(error, null);
          });
      },
      memoryBankSlots: (callback) => {
        api.get(`/v1/books/${story.book.id}/variables/`)
          .then((res) => {
            callback(null, res.data.variables);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (err) {
          this.errorAlert(err);
        } else {
          const reqs = []; const
            traits = [];
          for (let i = 0; i < res.stats.length; i++) {
            const item = res.stats[i];
            if (item.trait === true) traits.push(item);
            if (item.require === true) reqs.push(item);
          }
          const statsInfo = { ...res.storyInfo.stats };
          res.stepsData.steps.forEach((el) => {
            if (el.stepTypeId === 3) {
              el.answers.forEach((ans) => {
                ans.requires.forEach((req) => {
                  statsInfo[req.requireTypeId] -= 1;
                });
              });
            }
          });

          const step = res.stepsData && res.stepsData.steps != null ? res.stepsData.steps : [];
          this.setState({
            requiresTypes: reqs,
            traitsTypes: traits,
            characters: res.characters,
            locations: res.locations,
            characterExpression: res.expressions,
            tagsTypes: res.tags,
            modifiersTypes: res.modifiers,
            steps: step,
            stepConst: step,
            locationId: res.stepsData && res.stepsData.locationId !== null ? Number(res.stepsData.locationId) : '',
            // eslint-disable-next-line react/no-unused-state
            analytics: res.stepsData && res.stepsData.analytics ? res.stepsData.analytics : null,
            // eslint-disable-next-line react/no-unused-state
            addStep: false,
            loading: false,
            data: res.stepsData,
            answerType: res.answerType,
            statsInfo,
            currStatsInfo: res.storyInfo.stats,
            memoryBankSlots: res.memoryBankSlots,
          }, this.checkChoice);
        }
      } catch (e) {
        this.errorAlert(e);
      }
    });
  }

  updateLocation = () => {
    const { story } = this.props;

    api.get(`/v1/books/${story.book.id}/locations`)
      .then((res) => {
        this.setState({
          locations: res.data.locations,
        });
      });
  };

  addStories(arr, validated) {
    const {
      lastLocation,
      update,
      data,
      storyId,
      onError,
      onLocationSelected,
      onBranchUpdate,
      onBranchCreate,
    } = this.props;

    // eslint-disable-next-line no-param-reassign
    arr = JSON.parse(JSON.stringify(arr, replacer, 2));

    if (arr && arr.steps && arr.steps.length > 0
        && parseInt(arr.steps[0].stepTypeId, 10) !== 3
        && (arr.steps[0].answers || (arr.steps[0].answers
        && arr.steps[0].answers.length > 0))
    ) {
      // eslint-disable-next-line no-param-reassign
      delete arr.steps[0].answers;
    }

    if (validated === true) {
      if (data !== null && data.id) {
        onLocationSelected(arr.locationId || null);
        this.setState({
          saveLoading: true,
        });
        api.put(`/v1/stories/${storyId}/branches/${data.id}`, arr)
          .then(() => {
            onBranchUpdate(data.id);
            this.setState({
              saveLoading: false,
            });
          })
          .catch((error) => {
            this.errorAlert(error);
            this.setState({
              saveLoading: false,
            });
          });
      } else {
        this.setState({
          addLoading: true,
        });
        if (!arr.locationId && lastLocation) {
          // eslint-disable-next-line no-param-reassign
          arr.locationId = lastLocation;
        }
        api.post(`/v1/stories/${storyId}/branches`, arr)
          .then((res) => {
            localStorage.setItem(`lastEdit-${storyId}`, res.data.branch.id);
            onBranchCreate({ id: res.data.branch.id, arr });
          })
          .catch((error) => {
            this.setState({
              addLoading: false,
            });
            if ((data === null || !data.id) && onError) {
              onError(error);
              update();
            } else {
              this.errorAlert(error);
            }
          });
      }
    }
  }

  updateLive(arr, validated) {
    // eslint-disable-next-line no-param-reassign
    arr = JSON.parse(JSON.stringify(arr, replacer, 2));

    if (validated === true) {
      this.setState({
        saveLoading: true,
      });
      const { update, storyId, data } = this.props;
      const putUrl = `/v1/stories/${storyId}/branches/${data.id}/live`;
      api.put(putUrl, arr)
        .then(() => {
          update();
          this.setState({
            saveLoading: false,
          });
        })
        .catch((error) => {
          this.errorAlert(error);
          this.setState({
            saveLoading: false,
          });
        });
    }
  }

  formSlotValidate = (slotValue, slotId) => {
    const { memoryBankSlots } = this.state;
    const foundSlot = memoryBankSlots.find((slot) => slot.id === Number(slotId));
    if (!foundSlot) {
      return false;
    }

    if (foundSlot.type === MemorySlotsTypesList.number) {
      const isNumbersOnly = /^-?\d+$/.test(slotValue);
      return isNumbersOnly;
    }

    return true;
  };

  formActionValidate = (stepObj) => {
    const { action } = stepObj;

    if (!action.value) {
      return false;
    }
    const isSlotValid = this.formSlotValidate(action.value, action.variableId);
    return isSlotValid;
  };

  formSwitchValidate = (stepObj) => {
    if (stepObj.switch.length !== 2) {
      return false;
    }

    let isValid = true;
    stepObj.switch.forEach((switchObj) => {
      if (!switchObj.gotoBranchId) {
        isValid = false;
      }
    });

    return isValid;
  };

  formCheckValidate = (stepObj) => {
    if (!stepObj.check.variableId || !stepObj.check.operator) {
      return false;
    }

    const isSlotValid = this.formSlotValidate(
      stepObj.check.value,
      stepObj.check.variableId,
    );
    return isSlotValid;
  };

  formAnswerRequirementsValidate = (answersList) => {
    const { user } = this.props;
    let isSlotValid = true;
    let numOfRequirements = 0;
    const numOfAnswers = answersList.length;

    answersList.forEach((answer) => {
      if (answer.requirement && answer.requirement.check) {
        numOfRequirements += 1;

        // check if comparing value is ok
        const isAnswerSlotValid = this.formSlotValidate(
          answer.requirement.check.value,
          answer.requirement.check.variableId,
        );

        if (!isAnswerSlotValid) {
          isSlotValid = false;
        }
      }
    });

    if (!isSlotValid) {
      showToast({
        textMessage: 'Requirements parameters are invalid',
      });
    }

    const isAdmin = user && user.role === 'admin';
    // prevent having all answers with requirements (one needs to be always visible);
    const isAnswerWithoutRequirement = !(numOfRequirements === numOfAnswers) || isAdmin;
    if (!isAnswerWithoutRequirement) {
      showToast({
        textMessage: 'No Free Answer without Requirements',
      });
    }

    return isSlotValid && isAnswerWithoutRequirement;
  };

  formValidated = (val) => {
    const { limits } = this.props;
    const { steps } = this.state;

    if (val.title && val.title.length > Number(limits.branch_title_max.value)) {
      return false;
    }
    if (val.description && val.description.length > Number(limits.branch_description_max.value)) {
      return false;
    }
    if (val.steps && val.steps.length > 0) {
      let newSteps = true;

      steps.forEach((obj) => {
        if (obj?.text?.length > Number(limits[`step_${obj?.stepTypeId}_text_max`]?.value)) {
          newSteps = false;
        }
        if (obj.answers && obj.answers.length > 0) {
          obj.answers.forEach((ans) => {
            if (ans.text && ans.text.length > Number(limits.answer_text_max.value)) {
              newSteps = false;
            }
            if (ans.data && ans.data.length > 0) {
              ans.data.forEach((a) => {
                if (a.key && a.key.length > Number(limits.data_key_max.value)) {
                  newSteps = false;
                }
                if (a.value && a.value.length > Number(limits.data_value_max.value)) {
                  newSteps = false;
                }
              });
            }
          });

          if (!this.formAnswerRequirementsValidate(obj.answers)) {
            newSteps = false;
          }
        }
        if (obj.data && obj.data.length > 0) {
          obj.data.forEach((ans) => {
            if (ans.key && ans.key.length > Number(limits.data_key_max.value)) {
              newSteps = false;
            }
            if (ans.value && ans.value.length > Number(limits.data_value_max.value)) {
              newSteps = false;
            }
          });
        }
        if (obj.action && !this.formActionValidate(obj)) {
          newSteps = false;
        }
        if (obj.switch && !this.formSwitchValidate(obj)) {
          newSteps = false;
        }
        if (obj.check && !this.formCheckValidate(obj)) {
          newSteps = false;
        }
      });
      return newSteps;
    }
    return true;
  };

  handleSubmit(event) {
    const { restrictedEdit } = this.props;

    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const value = serialize(form, { hash: true, disabled: false });
    if (validated === false || !this.formValidated(value)) {
      event.stopPropagation();
      return;
    }

    if (restrictedEdit) {
      this.updateLive(value, validated);
    } else {
      this.addStories(value, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  checkChoice() {
    this.setState((state) => ({
      choice: state.steps.find((a) => a && (Number(a.stepTypeId) === 3)) !== undefined,
      isCheckStep: state.steps.find((a) => a
        && (Number(a.stepTypeId) === MEMORY_BANK_STEPS_TYPES.CHECK)) !== undefined,
      ending: state.steps.find((a) => a && (Number(a.stepTypeId) === 5)) !== undefined,
      result: state.steps.find((a) => a && (Number(a.stepTypeId) === 6)) !== undefined,
    }));
  }

  pasteStep = () => {
    const { result, steps, ending } = this.state;

    const data = getStepData();
    if (data.length < 1) {
      return;
    }
    data.forEach((a) => {
      const obj = prepareForCopy(a);

      if ((Number(obj.stepTypeId) === 3) && (steps.length > 0)) {
        this.errorAlert({ response: { data: { error: 'Choice step should be the only one in the node' } } });
        return;
      }
      if ((Number(obj.stepTypeId) === 5 || Number(obj.stepTypeId) === 6) && (!ending && !result)) {
        this.errorAlert({ response: { data: { error: 'Outro/ending node must only have Ending steps' } } });
        return;
      }
      if ((Number(obj.stepTypeId) !== 5 && Number(obj.stepTypeId) !== 6) && (ending || result)) {
        this.errorAlert({ response: { data: { error: 'Outro/ending node must only have Ending steps' } } });
        return;
      }
      setTimeout(() => {
        this.addStep(undefined, obj, false);
      }, 100);
    });
  };

  render() {
    const {
      user,
      disabled,
      show,
      limits,
      restrictedEdit,
      modeEdit,
      onHide,
      data: dataFromProps,
      storyId,
      branchesGroup,
      branch,
      story,
    } = this.props;
    const {
      validated,
      gotoBranchId,
      editHeader,
      stepsType,
      copyStep,
      data,
      saveLoading,
      activeSteps,
      characters,
      currStatsInfo,
      errorMsg,
      deleteStep,
      tagsTypes,
      modalPrev,
      locations,
      ending,
      result,
      SelectStepsDelete,
      traitsTypes,
      locationId,
      modalNext,
      characterExpression,
      requiresTypes,
      modifiersTypes,
      addLoading,
      loading,
      disabledSortable,
      defaultValueDescription,
      choice,
      isCheckStep,
      steps,
      SelectStepsCopy,
      allCopyStepChecked,
      answerType,
      defaultValueTitle,
      allDeleteStepChecked,
      memoryBankSlots,
    } = this.state;

    if (addLoading === true) {
      return null;
    }

    return (
      <>
        <ErrorAlert error={errorMsg} close={this.errorAlertClose} />

        <Modal
          show={show}
          onHide={onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          className={cs('modalBig', 'modal-dialog', modalNext === true ? 'modalNext-active' : '', modalPrev ? 'modalPrev-active' : '')}
          centered
          backdrop="static"
          keyboard={false}
        >

          <Spinner
            animation="border"
            variant="primary"
            className={loading !== false ? 'loadingSpinner justify-content-center' : 'd-none '}
          />

          <Modal.Header closeButton className="mb-0 smf-header">

            <BranchModalPrevNext
              {...this.props}
              storyId={storyId}
              data={data}
              type="prev"
              characterExpression={characterExpression}
              characters={characters}
              actionEditBranch={this.actionEditBranch}
              initEditBranch={this.initEditBranch}
            />

          </Modal.Header>

          <Modal.Body className="p-0">

            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => {
                if (!disabled || restrictedEdit) {
                  this.handleSubmit(e);
                }
              }}
              className={cs('sf-modal', loading !== false ? 'd-none' : null)}
            >

              <div className="sf-container">
                <AddBranchHead
                  user={user}
                  title={defaultValueTitle}
                  disabled={!!(saveLoading || disabled)}
                  description={defaultValueDescription}
                  storyId={storyId}
                  story={story}
                  locationId={locationId}
                  locations={locations}
                  limits={limits}
                  activeStepsFunc={this.activeSteps}
                  editHeader={editHeader}
                  updateLocation={this.updateLocation}
                  update={(e) => {
                    this.setState({
                      editHeader: e,
                    });
                  }}
                  restrictedEdit={restrictedEdit}
                />

                <div
                  id={SCROLLABLE_STEP_FORM_ELEMENT_ID}
                  className="sf-content"
                >

                  {SelectStepsCopy && (
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div
                      onClick={(e) => {
                        this.setState({
                          allCopyStepChecked: !allCopyStepChecked,
                        });
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      style={{
                        width: '15em',
                        marginLeft: '1.55em',
                      }}
                    >
                      <Form.Check
                        custom
                        autoFocus
                        as="input"
                        checked={allCopyStepChecked}
                        onMouseOver={(e) => {
                          this.disabledSortableAction(e);
                        }}
                        onChange={
                          (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }
                        }
                        type="checkbox"
                        id="allCopyStep"
                        name="allCopyStep"
                        label="Select All Steps to Copy"
                        style={{ margin: '.25em' }}
                      />
                    </div>
                  )}

                  {SelectStepsDelete && (
                  // eslint-disable-next-line max-len
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div
                      onClick={(e) => {
                        const all = !allDeleteStepChecked;
                        const newDeleteStep = [];
                        if (all) {
                          steps.map((a, i) => newDeleteStep.push(i));
                        }
                        this.setState({
                          deleteStep: newDeleteStep,
                          allDeleteStepChecked: all,
                        });
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      style={{
                        width: '15em',
                        marginLeft: '1.55em',
                      }}
                    >
                      <Form.Check
                        custom
                        autoFocus
                        as="input"
                        checked={allDeleteStepChecked}
                        onMouseOver={(e) => {
                          this.disabledSortableAction(e);
                        }}
                        onChange={
                          (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }
                        }
                        type="checkbox"
                        id="allDeleteStep"
                        name="allDeleteStep"
                        label="Select All Steps to Delete"
                        style={{ margin: '.25em' }}
                      />
                    </div>
                  )}
                  { !loading && (
                    <ContextProvider
                      branches={branch}
                      currentBranchId={dataFromProps.id}
                      characterExpression={characterExpression}
                      tagsTypes={tagsTypes}
                      modifiersTypes={modifiersTypes}
                      characters={characters}
                      stepsType={stepsType}
                      requiresTypes={requiresTypes}
                      traitsTypes={traitsTypes}
                      answerType={answerType}
                      currStatsInfo={currStatsInfo}
                      storyRecommendations={story.recommendations}
                      memoryBankSlots={memoryBankSlots}
                    >
                      <SortableListSteps
                        user={user}
                        items={steps}
                        limits={limits}
                        disabledSortable={disabledSortable || restrictedEdit}
                        disabledSortableAction={this.disabledSortableAction}
                        onSortEnd={this.onSortEnd}
                        helperClass="sort-item"
                        errorAlert={this.errorAlert}
                        story={story}
                        totalSteps={count(steps)}
                        deleteStep={this.deleteStep}
                        addStep={this.addStep}
                        activeSteps={activeSteps}
                        activeStepsFunc={this.activeSteps}
                        handleChangeSteps={this.handleChangeSteps}
                        updateStep={this.updateSteps}
                        isIntro={dataFromProps && dataFromProps.title === 'intro'}
                        ending={ending}
                        result={result}
                        defStepType={this.defStepType()}
                        updateCharacters={(val) => {
                          this.reLoadCharacters(val);
                        }}
                        copyStep={copyStep}
                        updateCopyStep={(selectedSteps) => {
                          this.setState({
                            copyStep: selectedSteps,
                            allCopyStepChecked: steps.length === selectedSteps.length,
                          });
                        }}
                        updateDeleteStep={(stepIndex, isCheck) => {
                          const newStepIndexesToDelete = isCheck
                            ? [...deleteStep, stepIndex]
                            : deleteStep.filter(
                              (stepIndexToDelete) => stepIndexToDelete !== stepIndex,
                            );
                          this.setState({
                            deleteStep: newStepIndexesToDelete,
                            allDeleteStepChecked: steps.length === newStepIndexesToDelete.length,
                          });
                        }}
                        deleteStepVal={deleteStep}
                        SelectStepsCopy={SelectStepsCopy}
                        SelectStepsDelete={SelectStepsDelete}
                        restrictedEdit={restrictedEdit}
                        allCopyStepChecked={allCopyStepChecked}
                        allDeleteStepChecked={allDeleteStepChecked}
                        branches={branch}
                        addStepAction={this.addStepAction}
                        removeStepAction={this.removeStepAction}
                        handleChangeStepAction={this.handleChangeStepAction}
                        addStepCheck={this.addStepCheck}
                        removeStepCheck={this.removeStepCheck}
                        handleChangeStepCheck={this.handleChangeStepCheck}
                        handleChangeStepSwitch={this.handleChangeStepSwitch}
                      />
                    </ContextProvider>
                  )}

                  <AddStep
                    steps={steps}
                    restrictedEdit={restrictedEdit}
                    choice={choice}
                    addStep={this.addStep}
                    pasteStep={this.pasteStep}
                    copyStep={copyStep}
                    SelectStepsCopy={SelectStepsCopy}
                    updateCopyStep={(val) => {
                      this.setState({
                        copyStep: val,
                      });
                    }}
                    updateDeleteStep={(val) => {
                      this.setState({
                        deleteStep: val,
                      });
                    }}
                    handleAddSteps={() => {
                      clearStepData();
                      this.setState({
                        SelectStepsCopy: !SelectStepsCopy,
                        SelectStepsDelete: false,
                        disabledSortable: false,
                        allCopyStepChecked: false,
                        allDeleteStepChecked: false,
                        deleteStep: [],
                        copyStep: [],
                      });
                    }}
                    SelectStepsDelete={SelectStepsDelete}
                    deleteStep={deleteStep}
                    deleteStepFunc={this.deleteStep}
                    handleDelete={() => {
                      localStorage.setItem('copyStep', JSON.stringify([]));
                      this.setState({
                        SelectStepsCopy: false,
                        SelectStepsDelete: !SelectStepsDelete,
                        disabledSortable: false,
                        allCopyStepChecked: false,
                        allDeleteStepChecked: false,
                        deleteStep: [],
                        copyStep: [],
                      });
                    }}
                    isCheckStep={isCheckStep}
                  />
                </div>

                <div className="sf-footer">
                  <Form.Row className="w-100">
                    <Form.Group as={Col} md="9" className="mb-0 pb-0" controlId="gotoBranchId">
                      <Row>
                        <Form.Label column>Goto node</Form.Label>
                        <Col md={9}>
                          <CustomSelect
                            value={gotoBranchId || ''}
                            disabled={choice || ending || result || restrictedEdit || isCheckStep}
                            name="gotoBranchId"
                            args={[
                              { id: null, title: '' },
                              { id: -1, title: '[Create New Node]' },
                              ...branch,
                            ]}
                            data={dataFromProps.id}
                            onFocus={noop}
                            onChange={(e) => {
                              this.setState({
                                gotoBranchId: e,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group as={Col} className="mb-0 pb-0 text-right">
                      <Button
                        className="mx-1"
                        size="sm"
                        variant="secondary"
                        onClick={() => onHide()}
                      >
                        Cancel
                      </Button>

                      <Button
                        size="sm"
                        type="submit"
                        variant="primary"
                        disabled={
                          !!((
                            saveLoading
                              || disabled
                          )
                            && !restrictedEdit
                          )
                          || !modeEdit
                          || PremiumIpDisabledEdit(user.role, story.book, branchesGroup)
                          || PremiumIpDisabledApprovedEdit(user.role, story.book)
                        }
                      >
                        {saveLoading && (
                          <Spinner
                            variant="primary"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        {restrictedEdit ? 'Update Live' : 'Save'}
                      </Button>
                    </Form.Group>
                  </Form.Row>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer className="smf-footer">
            <BranchModalPrevNext
              {...this.props}
              storyId={storyId}
              data={data}
              type="next"
              characterExpression={characterExpression}
              characters={characters}
              actionEditBranch={this.actionEditBranch}
              initEditBranch={this.initEditBranch}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
