import React from 'react';
import { Branch } from 'dorian-shared/types/branch/Branch';
import { noop } from 'helpers/noop';
import { CustomSelect } from '../CustomSelect';

type AddStepGotoBranchSelectProps = {
  inputName: string,
  gotoBranchId: number,
  currentBranchId: number,
  branches: Branch[],
  isDisabled?: boolean,
  onBranchChange: (branchId: number | string | null) => void,
}

export function AddStepGotoBranchSelect(props: AddStepGotoBranchSelectProps) {
  const {
    gotoBranchId, isDisabled, branches, currentBranchId, onBranchChange, inputName,
  } = props;

  return (
    <CustomSelect
      value={gotoBranchId || 0}
      disabled={isDisabled}
      name={inputName}
      args={[
        { id: null, title: '' },
        ...branches,
      ]}
      data={currentBranchId}
      onFocus={noop}
      onChange={onBranchChange}
    />
  );
}
