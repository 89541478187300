import React, { Component } from 'react';
import { Alert, Tab, Tabs } from 'react-bootstrap';
import { Cropper } from 'react-cropper';
import { Auth } from '../../Auth/Auth';
import { calculateMaxSideByAspectRatio, convertCanvasToBlob } from '../Profile/AvatarImageUploader/utils';
import { BookCoverGenerate } from './BookCoverGenerate';
import './BookCoverUploader.scss';

const auth = new Auth();
const maxImageHeight = 2000;
const maxImageWidth = 1405;
const imageAspectRatio = maxImageWidth / maxImageHeight;

export class BookCoverUploader extends Component {
  constructor(props, context) {
    super(props, context);
    const { portrait } = this.props;

    this.state = {
      imageSrc: null,
      fileError: null,
      // eslint-disable-next-line react/no-unused-state
      imageHeight: null,
      // eslint-disable-next-line react/no-unused-state
      imageWidth: null,
      cropper: null,
      aspectRatio: portrait ? imageAspectRatio : 1 / imageAspectRatio,
    };
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.btnSaveForm = React.createRef();
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.btnSaveImageForm = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { portrait } = this.props;

    if (portrait !== prevProps.portrait) {
      const aspectRatio = portrait ? imageAspectRatio : 1 / imageAspectRatio;
      this.setState({ aspectRatio });
    }
  }

  onChangeFile = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return false;
    }
    if (file.type.indexOf('image') === -1) {
      this.setState({ fileError: 'File not supported' });
      return false;
    }

    const imageSrc = URL.createObjectURL(file);
    const image = await this.loadImg(imageSrc);
    this.setState({
      fileError: null,
      imageSrc,
      // eslint-disable-next-line react/no-unused-state
      imageHeight: image.height,
      // eslint-disable-next-line react/no-unused-state
      imageWidth: image.width,
    });
    return true;
  };

  onGenerateDone = (file, width, height) => {
    this.setState({
      fileError: null,
      imageSrc: file ? URL.createObjectURL(file) : null,
      // eslint-disable-next-line react/no-unused-state
      imageHeight: height,
      // eslint-disable-next-line react/no-unused-state
      imageWidth: width,
    });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  onImageLoaded = (image) => {
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.imageRef = image;
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  onCropComplete = (crop, percentCrop) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ crop, percentCrop });
    const { onComplete } = this.props;
    onComplete();
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  onCropChange = (crop, percentCrop) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ crop, percentCrop });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  doCropImg = async () => {
    const { cropper } = this.state;
    const { onDone } = this.props;

    if (cropper === null
      || !cropper.getCroppedCanvas()
    ) {
      this.setState({ fileError: 'Error: Can\'t get crop data' });
      return;
    }
    const { aspectRatio } = cropper.getImageData();

    const { maxWidth, maxHeight } = calculateMaxSideByAspectRatio(
      aspectRatio,
      maxImageWidth,
      maxImageHeight,
    );

    const canvas = await cropper.getCroppedCanvas(
      {
        maxWidth,
        maxHeight,
        imageSmoothingQuality: 'high',
        imageSmoothingEnabled: true,
      },
    );

    let blob = new Blob();
    try {
      blob = await convertCanvasToBlob(canvas, 'image/jpeg');
    } catch (error) {
      this.errorAlert(error);
      return;
    }
    blob.name = 'cover.jpg';
    blob.lastModifiedDate = new Date();
    onDone(blob);
  };

  cropReadyHandler = () => {
    const { onComplete } = this.props;
    onComplete();
  };

  loadImg(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }

  render() {
    const { imageSrc, aspectRatio, fileError } = this.state;
    const { book } = this.props;
    return (
      <div className="cropContainer">
        {imageSrc
          ? (
            <div className="cropper-source">
              <div className="cropper-source-area">
                <Cropper
                  style={{ maxHeight: '100%', maxWidth: '100%' }}
                  zoomTo={0}
                  dragMode="crop"
                  aspectRatio={aspectRatio}
                  minContainerHeight={250}
                  minContainerWidth={250}
                  minCropBoxHeight={250}
                  minCanvasHeight={250}
                  autoCropArea={1}
                  restore={false}
                  guides
                  center={false}
                  highlight
                  toggleDragModeOnDblclick={false}
                  initialAspectRatio={aspectRatio}
                  preview=".img-preview"
                  src={imageSrc}
                  viewMode={1}
                  background
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={(instance) => { this.setState({ cropper: instance }); }}
                  ready={this.cropReadyHandler}
                />
              </div>
            </div>
          )
          : (
            <div>
              <Tabs defaultActiveKey="upload" id="tabs">
                <Tab eventKey="upload" title="Upload">
                  <p><b>file size cannot be larger than 1MB</b></p>
                  {
                    fileError
                    && (
                    <Alert
                      variant="danger"
                      style={{ margin: '1em 0', fontSize: '1em' }}
                    >
                      {fileError}
                    </Alert>
                    )
                  }
                  <input type="file" onChange={this.onChangeFile} accept="image/*" />
                </Tab>
                <Tab eventKey="Generate" title="Generate">
                  <BookCoverGenerate
                    book={book}
                    onDone={this.onGenerateDone}
                    user={auth.getUser()}
                  />
                </Tab>
              </Tabs>
            </div>
          )}
      </div>
    );
  }
}
