import React from 'react';
import { Form } from 'react-bootstrap';
import { BranchesWidgetContext } from './context';

export function BranchesWidgetFilter() {
  const { search } = React.useContext(BranchesWidgetContext);
  const BranchesWidgetFunc = React.useContext(BranchesWidgetContext);

  return (
    <Form>
      <Form.Control
        type="text"
        value={search}
        placeholder="Type to filter..."
        onChange={(e) => {
          BranchesWidgetFunc.setSearch(e.target.value);
        }}
      />
    </Form>
  );
}
