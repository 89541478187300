import React, { useReducer } from 'react';
import { api } from '../../../api';
import { storyPreviewReducer } from './reducer';
import {
  ANSWERS_TYPE,
  BRANCHES,
  CHOICE_PROPS,
  LIMITS,
  MODIFIERE_TYPES,
  SHOW_DELETE_ANSWER,
  SHOW_EDIT_CHOICE,
  STORY,
} from './types';
import { StoryPreviewContext } from './index';

export function StoryPreviewData(props) {
  const { children } = props;
  const initialState = {
    showEditChoice: false,
    loading: false,
    limits: [],
    story: {},
    branches: {},
    choiceProps: null,
    answersTypes: null,
    modifiersTypes: null,
    showDeleteAnswer: false,
  };

  const [state, dispatch] = useReducer(storyPreviewReducer, initialState);

  const setLimits = () => {
    api.get('/v1/settings')
      .then((res) => {
        dispatch({ type: LIMITS, payload: res.data.settings.limits });
        return res;
      });
  };

  const setStory = (data) => {
    dispatch({ type: STORY, payload: data });
  };

  const setBranches = (data) => {
    dispatch({ type: BRANCHES, payload: data });
  };

  const setShowEditChoice = (data) => {
    dispatch({ type: SHOW_EDIT_CHOICE, payload: data });
  };

  const setShowEditChoiceProps = (data) => {
    dispatch({ type: CHOICE_PROPS, payload: data });
  };

  const setShowDeleteAnswer = (val, data) => {
    dispatch({ type: SHOW_DELETE_ANSWER, payload: val });
    dispatch({ type: CHOICE_PROPS, payload: data });
  };

  const setModifiersTypes = () => api.get('/v1/answers/modifiers/types')
    .then((res) => {
      dispatch({ type: MODIFIERE_TYPES, payload: res.data.types });
      return res;
    });

  const getAnswersTypes = async () => api.get('/v1/answers/types')
    .then((res) => {
      dispatch({ type: ANSWERS_TYPE, payload: res.data.types });
      return res;
    });

  React.useEffect(() => {
    getAnswersTypes().then();
  }, []);

  return (
    <StoryPreviewContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        limits: state.limits,
        branches: state.branches,
        storyId: state?.story?.id,
        showEditChoice: state.showEditChoice,
        choiceProps: state.choiceProps,
        answersTypes: state.answersTypes,
        modifiersTypes: state.modifiersTypes,
        showDeleteAnswer: state.showDeleteAnswer,

        setLimits,
        setStory,
        setBranches,
        setModifiersTypes,
        setShowEditChoice,
        setShowEditChoiceProps,
        setShowDeleteAnswer,
      }}
    >
      {children}
    </StoryPreviewContext.Provider>
  );
}
