import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LoginForm } from '../LoginForm';

export function LoginModalForm(props) {
  const { show, play, LoginModal } = props;
  return (
    <>
      {!play && (
        <Button
          className="mx-2"
          variant="primary"
          onClick={() => LoginModal(true)}
        >
          Log in
        </Button>
      )}

      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
      >
        <Modal.Header closeButton={!play}>
          <Modal.Title>Log in</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoginForm {...props} />
        </Modal.Body>
      </Modal>
    </>
  );
}
