import async from 'async';
import { omit } from 'lodash-es';
import React, { Component } from 'react';
import {
  Alert, Button, Form, Modal, Spinner,
} from 'react-bootstrap';
import { ReactComponent as Logo } from '../../../assets/images/dorian-white.svg';
import { api } from '../../api';
import { Auth } from '../../Auth/Auth';
import { AvatarCanvasWithCustomCharacterSupport } from '../../ui/AvatarTool/AvatarCanvasWithCustomCharacterSupport';
import { WizardFinalModal } from '../../ui/Wizard/WizardFinalModal';
import { convertAssetsImageToURL } from '../../utils/urlUtils';
import { fetchIsStoryAnalyticsVisible } from '../StoryBranches/BranchAnalytics/utils';
import { AnalyticsData } from './AnalyticsData';
import { AnswerDeleting } from './AnswerDeleting';
import { ChoiceEditing } from './ChoiceEditing';
import { StoryPreviewContext } from './context';
import './Preview.scss';
// eslint-disable-next-line import/no-cycle
import { PreviewUtilities } from './PreviewUtilities';
// eslint-disable-next-line import/no-cycle
import { NextBtn } from './PreviewUtilities/NextBtn';
import { PreviewAddStep } from './PreviewUtilities/PreviewAddStep';
import { PreviewNewEpisode } from './PreviewUtilities/PreviewNewEpisode';
// eslint-disable-next-line import/no-cycle
import { StepPreview } from './step';
// eslint-disable-next-line import/no-cycle
import { numberForUser } from './textPreview';

const auth = new Auth();

function getCustomCharacterImageURL(characterProperties, expression) {
  const expressionName = expression?.value;
  const characterExpressions = characterProperties.expressions;

  if (characterExpressions && expressionName in characterExpressions) {
    const expressionValue = characterExpressions[expressionName];
    return convertAssetsImageToURL(expressionValue.imageURL);
  }
  return convertAssetsImageToURL(characterProperties.image);
}

export class Preview extends Component {
  constructor(props, context) {
    super(props, context);
    this.user = auth.getUser();
    const storedPlayerName = localStorage.getItem('playername');
    let playername;
    if (this.user) {
      if (this.user.fullname) {
        playername = this.user.fullname;
      } else if (this.user.username) {
        playername = this.user.username;
      } else if (storedPlayerName) {
        playername = storedPlayerName;
      } else {
        playername = 'Player Name';
      }
    }
    this.state = {
      errorMsg: null,
      story: {},
      branches: [],
      loading: false,
      current_location: undefined,
      current_branch: undefined,
      current_analytic: null,
      current_step: 0,
      wordCount: 0,
      history: [],
      editMode: false,
      showChoices: false,
      lastChoices: [],
      playername,
      showPlayerForm: !playername,
      wizardStep: null,
      WizardFinalModal: false,
      storyCharactersProps: {},
      /* eslint-disable react/no-unused-state */
      characters: {},
      expressions: {},
      locations: [],
      previewAddEpisode: false,
      isAnalyticsVisible: false,
      analyticsData: localStorage.getItem('AnalyticsData'),
      previewAddStep: false,
      loadCurrBranch: false,
    };
  }

  static clearState = (storyUuid) => {
    localStorage.removeItem(`previewState-${storyUuid}`);
  };

  errorAlert = (error) => {
    this.setState({
      errorMsg: error.toString(),
    });
    setTimeout(() => {
      this.errorAlertClose();
    }, 3000);
  };

  walk3 = (links, choices, node) => {
    if (!links[node]) {
      return [];
    }
    let tm = [];
    if (choices.length > 0) {
      links[node].forEach((link) => {
        if (choices.includes(link)) {
          tm.push(link);
        } else {
          const zzz = this.walk3(links, choices, link);
          tm = zzz.concat(tm);
        }
      });
    }
    return tm;
  };

  findChoices = (data, end) => new Promise((resolve) => {
    const choices = [];
    const links = {};
    data.forEach((node) => {
      if (node.type === 'choice') {
        choices.push(node.id);
      }
      if (node.gotoBranchId) {
        if (!links[node.gotoBranchId]) {
          links[node.gotoBranchId] = [node.id];
        } else {
          links[node.gotoBranchId].push(node.id);
        }
      } else {
        node.links.forEach((link) => {
          if (!links[link]) {
            links[link] = [node.id];
          } else {
            links[link].push(node.id);
          }
        });
      }
    });

    const tm = this.walk3(links, choices, end);
    const uni = tm.filter((v, i, a) => a.indexOf(v) === i);
    const res = uni.map((x) => data.find((el) => el.id === x));
    resolve(res);
  });

  errorAlertClose = () => {
    this.setState({
      errorMsg: null,
    });
  };

  saveState = () => {
    const { lastEdit, storyUuid } = this.props;
    const {
      current_branch: currentBranch,
      current_step: currentStep,
      history,
      wordCount,
    } = this.state;

    if (currentBranch) {
      localStorage.setItem(`previewState-${storyUuid}`, JSON.stringify({
        lastEdit,
        current_branch: currentBranch.id,
        current_step: currentStep,
        history,
        wordCount,
      }));
    }
  };

  getState = () => {
    const { lastEdit, storyUuid } = this.props;

    let data = localStorage.getItem(`previewState-${storyUuid}`);
    if (!data) {
      return null;
    }
    data = JSON.parse(data);
    // WTF?
    if (Number(data.lastEdit) !== Number(lastEdit)) {
      Preview.clearState(storyUuid);
      return null;
    }
    return data;
  };

  WizardFinalModal = () => {
    const { WizardFinalModal: isWizardFinalModalShown } = this.state;

    if (isWizardFinalModalShown) {
      return (<WizardFinalModal {...this.props} />);
    }
    return null;
  };

  nextAction = () => {
    const { appWizard } = this.props;
    const {
      current_branch: currentBranch,
      current_step: currentStep,
      wizardStep,
      editMode,
      wordCount,
    } = this.state;

    if (wizardStep === 11) {
      this.setState({
        WizardFinalModal: true,
      });
      return;
    }

    if (this.nextNotAllowed() || editMode || (appWizard && appWizard === 'step2')) {
      return;
    }

    const { history } = this.state;
    const stepWC = currentBranch && currentBranch.steps[currentStep]
      ? currentBranch.steps[currentStep].wordCount
      : 0;

    history.push({
      branch_id: currentBranch.id,
      step: currentStep,
      wordCount: wordCount - stepWC,
    });

    if (currentStep + 1 < currentBranch.steps.length) {
      this.setState((state) => ({
        current_step: state.current_step + 1,
        wordCount: state.wordCount + currentBranch.steps[state.current_step + 1].wordCount,
      }));
    } else {
      this.loadCurrBranch(currentBranch.gotoBranchId);
    }

    this.setState({
      history,
    });
  };

  answerGotoAction = (branchId) => {
    const {
      history,
      current_branch: currentBranch,
      current_step: currentStep,
      wordCount,
    } = this.state;
    if (!branchId) {
      return;
    }

    const stepWC = currentBranch && currentBranch.steps[currentStep]
      ? currentBranch.steps[currentStep].wordCount
      : 0;

    history.push({
      branch_id: currentBranch.id,
      step: currentStep,
      wordCount: wordCount - stepWC,
    });

    this.loadCurrBranch(branchId);

    this.setState({ history });
  };

  resetAction = () => {
    const { storyUuid } = this.props;

    Preview.clearState(storyUuid);
    this.loadData();
  };

  prevAction = () => {
    const { history } = this.state;
    const el = history.pop();
    this.setState({ history });
    if (el) {
      this.loadCurrBranch(el.branch_id, el.step, el.wordCount);
    }
  };

  keyPress = (e) => {
    const { editMode } = this.state;

    if (Number(e.keyCode) === 39 && !editMode) {
      this.nextAction();
    }
    if (e.keyCode === 37 && !editMode) {
      this.prevAction();
    }
  };

  showChoices = () => {
    this.setState({ showChoices: true });
  };

  componentDidMount() {
    const { branches, story } = this.state;
    const { setBranches, setStory } = this.context;

    setStory(story);
    setBranches(branches);
    const wizardStep = JSON.parse(localStorage.getItem('wizardStep'));
    if (wizardStep === 11) {
      this.setState({
        wizardStep,
      }, () => {
        localStorage.setItem('wizardStep', wizardStep);
      });
    }
    window.addEventListener('keydown', this.keyPress, false);
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { lastEdit, storyUuid } = this.props;
    const {
      current_step: currentStep,
      current_branch: currentBranch,
      analyticsData,
      branches,
      story,
    } = this.state;
    const { setBranches, setStory } = this.context;

    if (prevState.story !== story) {
      setStory(story);
      if (story) {
        fetchIsStoryAnalyticsVisible(this.user, story.book.id, story.group)
          .then((isStoryAnalyticsVisible) => {
            this.setState({ isAnalyticsVisible: isStoryAnalyticsVisible });
          });
      }
    }

    if (prevState.branches !== branches) {
      setBranches(branches);
    }

    const wizardStep = JSON.parse(localStorage.getItem('wizardStep'));
    if (localStorage.getItem('AnalyticsData') !== analyticsData) {
      this.setState({
        analyticsData: localStorage.getItem('AnalyticsData'),
      });
    }
    if (wizardStep === 11 && prevState.wizardStep !== wizardStep) {
      this.setState({
        wizardStep,
      }, () => {
        localStorage.setItem('wizardStep', wizardStep);
      });
    }

    if (lastEdit !== prevProps.lastEdit) {
      this.resetAction();
    }
    if (storyUuid !== prevProps.storyUuid) {
      this.loadData();
    }
    if (currentStep !== prevState.current_step || currentBranch !== prevState.current_branch) {
      this.saveState();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.updateStorage);
  }

  loadCharacters = () => {
    const { storyCharactersProps, story } = this.state;

    if (!this.user || !(this.user.role === 'admin' || ['owner', 'editor', 'co-author'].includes(story.story_role))) {
      return;
    }

    async.parallel({
      characters: (callback) => {
        api.get(`/v1/stories/${story.id}/characters`)
          .then((res) => {
            callback(null, res.data.characters);
          }).catch((error) => {
            callback(error, null);
          });
      },
      expressions: (callback) => {
        api.get('/v1/characters/expressions')
          .then((res) => {
            callback(null, res.data.expressions);
          }).catch((error) => {
            callback(error, null);
          });
      },
      originalBook: (callback) => {
        if ((story.book.sourceBookId || story.book.isPrompt) && story.book.originalBookId) {
          api.get(`/v1/books/${story.book.originalBookId}`)
            .then((res) => {
              callback(null, res.data.book);
            }).catch((error) => {
              callback(error, null);
            });
        } else {
          callback(null, story.book);
        }
      },
    }, (err, res) => {
      try {
        if (err) {
          this.errorAlert(err);
        } else {
          const storyChars = storyCharactersProps;
          Object.keys(storyChars).forEach((key) => {
            if (res.originalBook?.uuid && 'imageUrl' in storyChars[key].data) {
              storyChars[key].data.imageUrl = storyChars[key].data.imageUrl.replace(
                story.book.uuid,
                res.originalBook.uuid,
              );
            }
          });

          this.setState({
            // eslint-disable-next-line react/no-unused-state
            characters: res.characters,
            // eslint-disable-next-line react/no-unused-state
            expressions: res.expressions,
            storyCharactersProps: storyChars,
          });
        }
      } catch (e) {
        this.errorAlert(e);
      }
    });
  };

  loadData = () => {
    this.setState({ loading: true });

    async.parallel({
      story: (callback) => {
        const { storyUuid } = this.props;

        api.get(`/v1/preview/${storyUuid}`)
          .then((res) => {
            callback(null, res.data.story);
          }).catch((error) => {
            callback(error, null);
          });
      },
      branches: (callback) => {
        const { storyUuid } = this.props;

        api.get(`/v1/preview/${storyUuid}/branches`)
          .then((res) => {
            callback(null, res.data.branches);
          }).catch((error) => {
            callback(error, null);
          });
      },
      data: (callback) => {
        const { storyUuid } = this.props;

        api.get(`/v1/preview/${storyUuid}/data`)
          .then((res) => {
            callback(null, res.data);
          }).catch((error) => {
            callback(error, null);
          });
      },
      stepTypes: (callback) => {
        if (this.user) {
          api.get('/v1/steps/types')
            .then((res) => {
              callback(null, res.data.types);
            }).catch((error) => {
              callback(error, null);
            });
        } else {
          callback(null, null);
        }
      },
    }, (err, res) => {
      try {
        if (err) {
          this.errorAlert(err);
        } else {
          this.setState({
            story: res.story,
            loading: false,
            wordCount: 0,
            branches: res.branches,
            history: [],
            // eslint-disable-next-line react/no-unused-state
            storyData: res.data,
            storyCharactersProps: res.data.characters,
            // eslint-disable-next-line react/no-unused-state
            locations: res.data.locations,
            // eslint-disable-next-line react/no-unused-state
            stepTypes: res.stepTypes,
          });

          if (this.user) {
            const { setLimits } = this.context;

            setLimits();
          }
          this.loadCharacters();

          const intro = res.branches.find((el) => el.title === 'intro');

          const savedState = this.getState();
          if (savedState) {
            this.setState({ history: savedState.history });
            this.loadCurrBranch(
              savedState.current_branch,
              savedState.current_step,
              savedState.wordCount,
              true,
            );
          } else {
            const { lastEdit } = this.props;

            const initBranch = lastEdit || intro.id;
            this.loadCurrBranch(initBranch);
          }
        }
      } catch (e) {
        this.errorAlert(e);
      }
    });
  };

  loadBranchAnalytic(title) {
    const { story } = this.state;

    if (!title) {
      return;
    }
    api.get(`/v1/analytics/stories/${story.uuid}/${story.revision}/${title}`)
      .then((res) => {
        this.setState(() => ({
          current_analytic: res.data.analytic,
        }));
      });
  }

  loadCurrBranch(id, step, wordCount, restore) {
    const { storyUuid } = this.props;
    const {
      branches,
      wordCount: wordCountFromState,
      loadCurrBranch: isLoadCurrBranch,
    } = this.state;

    if (!id && isLoadCurrBranch) {
      return;
    }
    this.setState({
      loadCurrBranch: true,
    });
    api.get(`/v1/preview/${storyUuid}/branches/${id}`)
      .then((res) => {
        let location;
        if (res.data.branch.location) {
          location = res.data.branch.location;
        }
        let loadStep = Number.isInteger(step) ? step : 0;
        if (res.data.branch.steps && res.data.branch.steps.length < loadStep) {
          loadStep = 0;
        }
        let wc = Number.isInteger(wordCount) ? wordCount : wordCountFromState;
        if (!restore) {
          if (res.data.branch.steps && res.data.branch.steps.length > 0) {
            wc += res.data.branch.steps[loadStep].wordCount;
          }
        }
        this.setState(() => ({
          current_analytic: null,
          current_branch: res.data.branch,
          current_step: loadStep,
          current_location: location,
          wordCount: wc,
        }));
        this.loadBranchAnalytic(res.data.branch.title);
        this.findChoices(branches, res.data.branch.id).then((lastChoices) => {
          this.setState({ lastChoices });
        });
        this.setState({
          loadCurrBranch: false,
        });
      })
      .catch(() => {
        this.setState({
          loadCurrBranch: false,
        });
        const intro = branches.find((el) => el.title === 'intro');
        this.loadCurrBranch(intro.id);
      });
  }

  choicesAction = (id) => {
    const { storyUuid } = this.props;

    Preview.clearState(storyUuid);
    this.setState({
      showChoices: false,
      history: [],
    }, () => {
      this.loadCurrBranch(id, 0, 0);
    });
  };

  nextNotAllowed = () => {
    const {
      current_branch: currentBranch,
      current_step: currentStep,
      wizardStep,
    } = this.state;

    if (wizardStep === 11) {
      return false;
    }
    return (
      !currentBranch
        || Number.isNaN(currentStep)
        || currentBranch.steps.length === 0
        || currentBranch.steps[currentStep].stepTypeId === 3)
      || (!currentBranch.gotoBranchId && (currentStep === (currentBranch.steps.length - 1))
      );
  };

  renderCurrentBranchBtn() {
    const { setLastEdit } = this.props;
    const { current_branch: currentBranch } = this.state;

    if (!currentBranch) {
      return null;
    }
    return (
      <Button
        block
        disabled={!(this.user && this.user.role === 'admin')}
        onClick={() => {
          if (setLastEdit && this.user) {
            setLastEdit(currentBranch.id);
          }
        }}
      >
        {currentBranch.title}
      </Button>
    );
  }

  renderPrevBtn() {
    const { history, editMode } = this.state;

    return (
      <Button
        block
        disabled={(history.length === 0) || editMode}
        onClick={() => this.prevAction()}
        variant="primary"
        className="px-0"
      >
        &#60;
      </Button>
    );
  }

  renderCharacterImg = (storyCharacterType) => {
    const {
      storyCharactersProps,
      current_branch: currentBranch,
      current_step: currentStep,
      story,
    } = this.state;

    if (!story.book || !story.book.id || !story.book.uuid) {
      return null;
    }

    if (!currentBranch || Number.isNaN(currentStep)) {
      return null;
    }

    const step = currentBranch.steps[currentStep];
    if (!step) {
      return null;
    }

    const { character, expression } = step;
    if (!character) return null;

    const storyCharacterProps = storyCharactersProps[character.id];
    if (!storyCharacterProps?.data?.imageUrl) return null;

    if (character.type !== 'custom' && (storyCharacterProps.data.properties && !this.isEmpty(storyCharacterProps.data.properties))) return null;

    const imageUrl = encodeURI(
      getCustomCharacterImageURL(storyCharacterProps.character.properties, expression),
    );

    if (storyCharacterType === 'user') {
      return character.isPlayer ? imageUrl : null;
    }

    if (storyCharacterType === 'other') {
      return !character.isPlayer ? imageUrl : null;
    }

    return null;
  };

  isEmpty = (obj) => Object.keys(obj).length === 0;

  getCharacterProps = (val) => {
    const {
      storyCharactersProps,
      current_branch: currentBranch,
      current_step: currentStep,
    } = this.state;

    if (!currentBranch || Number.isNaN(currentStep)) {
      return null;
    }

    const step = currentBranch.steps[currentStep];
    if (!step) {
      return null;
    }

    const { character } = step;
    if (!character) return null;
    const storyCharacterProps = storyCharactersProps[character.id];
    if (!storyCharacterProps?.data?.properties
        || this.isEmpty(storyCharacterProps.data.properties)
    ) return null;

    const properties = { ...storyCharacterProps.data.properties };

    const { expression } = step;
    if (expression && expression.value) {
      properties.expression = expression.value;
    }

    if (val === 'user') {
      return character.isPlayer ? properties : null;
    }

    if (val === 'other') {
      return !character.isPlayer ? properties : null;
    }

    return null;
  };

  renderCurrentState(userProps) {
    const { hideUi } = this.props;
    const {
      analyticsData,
      current_branch: currentBranch,
      current_analytic: currentAnalytic,
      playername,
      current_step: currentStep,
      story,
      isAnalyticsVisible,
    } = this.state;

    if (!currentBranch || Number.isNaN(currentStep)) {
      return null;
    }

    const step = currentBranch.steps[currentStep];
    if (!step) {
      return null;
    }
    const stepAnalytic = (
      currentAnalytic?.steps
      && currentAnalytic?.steps[currentStep]
    ) || null;

    return (
      <StepPreview
        {...this.props}
        user_props={userProps}
        step={step}
        auth={auth}
        hideUi={hideUi}
        playername={playername}
        story={story}
        branchId={currentBranch.id}
        answerGotoAction={this.answerGotoAction}
        showAnalytics={isAnalyticsVisible}
        analytic={stepAnalytic}
        analyticsData={analyticsData}
        addNewEpisode={(e) => {
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            previewAddEpisode: e,
          });
        }}
        editMode={(e) => {
          this.setState({
            editMode: e,
          });
        }}
      />
    );
  }

  handleSubmit(event) {
    const { playername } = this.state;
    event.preventDefault();
    this.setState({ showPlayerForm: false });
    localStorage.setItem('playername', playername);
    event.stopPropagation();
  }

  renderPlayerForm = () => {
    const { playername, showPlayerForm } = this.state;

    if (!(showPlayerForm && !this.user)) {
      return null;
    }

    return (
      <div className="previewPlayerModal">
        <Modal.Dialog
          centered
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header
            onHide={() => this.setState({ showPlayerForm: false })}
          >
            <Modal.Title>Player name</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form
              onSubmit={(e) => {
                this.handleSubmit(e);
              }}
            >
              <Form.Group controlId="name">
                <Form.Control
                  placeholder="Player Name"
                  name="text"
                  required
                  value={playername || ''}
                  onChange={(e) => {
                    this.setState({
                      playername: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide player name.
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                variant="outline-secondary"
                block
                type="submit"
              >
                OK
              </Button>
            </Form>

          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  };

  renderChoices = () => {
    const { showChoices, lastChoices } = this.state;

    if (!showChoices) {
      return null;
    }

    return (
      <div className="previewChoicesModal">
        <Modal.Dialog
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header
            closeButton
            onHide={() => this.setState({ showChoices: false })}
          >
            <Modal.Title>Previous Choice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {lastChoices.map((object, i) => (
              <Button
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                block
                variant="secondary"
                className="my-1"
                onClick={() => this.choicesAction(object.id)}
              >
                {object.title}
              </Button>
            ))}
          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  };

  renderAnalytic = () => {
    const { hideUi } = this.props;
    const {
      current_analytic: currentAnalytic,
      current_step: currentStep,
      isAnalyticsVisible,
      analyticsData,
    } = this.state;

    if (hideUi) {
      return null;
    }

    if (!isAnalyticsVisible || !currentAnalytic) {
      return null;
    }

    const stepAnalytic = (currentAnalytic.steps && currentAnalytic.steps[currentStep]) || null;

    if (!stepAnalytic || !stepAnalytic.dropoff) {
      return null;
    }

    if (analyticsData === 'hide') {
      return null;
    }

    return (
      <div className="dropoff">
        <div className="dropofftext">
          {analyticsData === 'counts' && stepAnalytic.dropoff_users !== undefined ? (
            <>
              <div>User</div>
              <div>Drop Off</div>
            </>
          ) : (
            <>
              <div>Drop off</div>
              <div>Rate</div>
            </>
          )}
        </div>
        <div style={{ whiteSpace: 'nowrap' }}>
          {analyticsData === 'counts' && stepAnalytic.dropoff_users !== undefined
            ? <span className="dropoffvalue">{numberForUser(stepAnalytic.dropoff_users)}</span>
            : (
              <>
                <span className="dropoffvalue">{stepAnalytic.dropoff}</span>
                <span className="dropoffpercent">%</span>
              </>
            )}
        </div>
      </div>
    );
  };

  is_texting = () => {
    const {
      current_branch: currentBranch,
      current_step: currentStep,
    } = this.state;

    if (!currentBranch || Number.isNaN(currentStep)) {
      return null;
    }
    const step = currentBranch.steps[currentStep];
    if (!step) {
      return null;
    }
    return step.stepTypeId === 8;
  };

  render() {
    const { hideUi } = this.props;
    const {
      errorMsg,
      loading,
      analyticsData,
      current_analytic: currentAnalytic,
      current_location: currentLocation,
      current_step: currentStep,
      lastChoices,
      story,
      isAnalyticsVisible,
      previewAddStep,
    } = this.state;

    let pageTitle;

    if (isAnalyticsVisible) {
      const stepAnalytic = (
        currentAnalytic
        && currentAnalytic.steps
        && currentAnalytic.steps[currentStep]
      ) || null;
      let users = 0;
      if (stepAnalytic && stepAnalytic.users) {
        users = stepAnalytic.users;
      }
      pageTitle = !analyticsData || analyticsData === 'hide' ? story.title : `users: ${users}`;
    } else {
      pageTitle = story && story.title ? `${story.title}` : 'Loading...';
    }

    const backgroundImage = currentLocation?.image?.imageUrl
      ? currentLocation.image.imageUrl
      : null;
    const backgroundImageURL = backgroundImage ? encodeURI(backgroundImage) : null;

    const user = this.renderCharacterImg('user');
    const other = this.renderCharacterImg('other');

    const userProps = this.getCharacterProps('user');
    const otherProps = this.getCharacterProps('other');

    const isCustomCharacter = userProps?.type === 'custom' || otherProps?.type === 'custom';

    const screenStyle = {};
    if (backgroundImageURL) {
      screenStyle.backgroundImage = `url("${backgroundImageURL}")`;
      screenStyle.backgroundSize = 'cover';
      screenStyle.backgroundRepeat = 'no-repeat';
      screenStyle.backgroundPosition = 'center';
    }

    return (
      <>
        <div
          className={`emulatorScreen ${hideUi ? 'hideUi' : ''}`}
          style={screenStyle}
        >
          <div style={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
          >

            <ChoiceEditing
              loadData={this.loadData}
            />

            <AnswerDeleting
              loadData={this.loadData}
            />

            {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}

            <Spinner
              animation="border"
              variant="primary"
              className={loading !== false ? 'loadingSpinner justify-content-center' : 'd-none '}
            />

            {!hideUi && (
            <div className="header">
              <div className="row mx-0">

                <div className="col-3 px-1">
                  <Button
                    onClick={() => this.resetAction()}
                    variant="outline-secondary"
                  >
                    Reset
                  </Button>
                </div>
                <div className="col-6 px-0 headerText">
                  {pageTitle}
                </div>
                <div className="col-3 px-1">
                  <Button
                    onClick={() => this.showChoices()}
                    variant="outline-secondary"
                    disabled={!lastChoices || (lastChoices.length === 0)}
                    className="mr-3 btnPreviousChoices"
                  >
                    Previous Choice
                  </Button>
                </div>

              </div>
            </div>
            )}

            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className="screen"
              onClick={() => this.nextAction()}
            >
              <div
                className={`characterImage-user ${user && !this.is_texting() ? 'active' : ''}`}
                style={user ? { backgroundImage: `url(${user})` } : {}}
              />
              <div
                className={`characterImage ${other && !this.is_texting() ? 'active' : ''}`}
                style={other ? { backgroundImage: `url(${other})` } : {}}
              />
              <div
                className={`characterImage ${otherProps && !this.is_texting() ? 'active' : ''}`}
              >
                {!isCustomCharacter && (
                  <AvatarCanvasWithCustomCharacterSupport
                    properties={otherProps}
                  />
                )}

              </div>
              <div
                className={`characterImage-user ${userProps && !this.is_texting() ? 'active' : ''}`}
              >
                {!isCustomCharacter && (
                  <AvatarCanvasWithCustomCharacterSupport
                    properties={userProps}
                  />
                )}

              </div>
              {this.renderCurrentState(userProps)}
              {this.renderAnalytic()}
            </div>

            {!hideUi && (
            <div className="navi">
              {isAnalyticsVisible && (
                <AnalyticsData
                  update={() => {
                    this.setState({
                      analyticsData: localStorage.getItem('AnalyticsData'),
                    });
                  }}
                />
              )}
              {isAnalyticsVisible && currentAnalytic && (
                <div className="navi_current_step">
                  Step #
                  {currentStep}
                </div>
              )}
              <div className="row mx-0">
                <div className="col-2 px-0">
                  {this.renderPrevBtn()}
                </div>
                <div className="col-8 px-1">
                  {this.renderCurrentBranchBtn()}
                </div>
                <div className="col-2 px-0">
                  <NextBtn
                    {...this.props}
                    user={this.user}
                    obj={this.state}
                    story={story}
                    nextNotAllowed={this.nextNotAllowed}
                    nextAction={this.nextAction}
                    previewAddStepAction={() => {
                      this.setState({ previewAddStep: !previewAddStep });
                    }}
                  />
                </div>
              </div>
            </div>
            )}

            <PreviewNewEpisode
              {...this.props}
              obj={this.state}
              onHide={() => {
                this.setState({
                  // eslint-disable-next-line react/no-unused-state
                  previewAddEpisode: false,
                });
              }}
              updateCharacter={(id, step) => {
                this.loadCurrBranch(id, step);
                this.loadData();
              }}
            />

            <PreviewUtilities
              {...this.props}
              hideUi={hideUi}
              user={this.user}
              obj={this.state}
              story={story}
              updateLocation={() => {
                this.loadData();
              }}
              loadCharacters={this.loadCharacters}
              updateCharacter={(id, step) => {
                this.loadCurrBranch(id, step);
                this.loadData();
              }}
            />

            <PreviewAddStep
              {...omit(this.props, ['user'])}
              user={this.user}
              obj={this.state}
              onHide={
              () => {
                this.setState({
                  previewAddStep: false,
                });
              }
            }
              updateStep={(id, step) => {
                this.loadCurrBranch(id, step);
                this.loadData();
              }}
            />

            <div className="prevLogoBox">
              <Logo />
              <b>Dorian</b>
            </div>
          </div>
        </div>

        {this.renderChoices()}
        {this.renderPlayerForm()}
        {this.WizardFinalModal()}
      </>
    );
  }
}

Preview.contextType = StoryPreviewContext;
