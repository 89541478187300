import axios from 'axios';
import { baseURL } from '../api';

let assetsImageURL;

const requestAssetsImageURL = () => {
  axios.get(`${baseURL}v1/avatar/config`).then(
    (response) => {
      assetsImageURL = response.data.imageUrl;
    },
  );
};

export const getAssetsImageURL = () => assetsImageURL;

requestAssetsImageURL();
