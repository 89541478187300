import moment from 'moment';
import React, { Component } from 'react';
import {
  Card, Col, OverlayTrigger, Row, Spinner, Tooltip,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';
import Moment from 'react-moment';
import CheckIconG from '../../../assets/images/check-g.svg';
import CheckIcon from '../../../assets/images/check.svg';
import MailG from '../../../assets/images/mail-g.svg';
import MailR from '../../../assets/images/mail-r.svg';
import Mail from '../../../assets/images/mail.svg';
import { api } from '../../api';
import { Auth } from '../../Auth/Auth';
import { DebouncedSearch } from '../../ui/DebouncedSearch/DebouncedSearch';
import { ExportAsyncCSV } from '../../ui/ExportAsyncCSV/ExportAsyncCSV';
import { PageWrapper } from '../../ui/PageWrapper';
// eslint-disable-next-line import/no-cycle
import { Actions } from './Actions';
// eslint-disable-next-line import/no-cycle
import { AddUser } from './AddUser';
// eslint-disable-next-line import/no-cycle
import { AuthorProfile } from './AuthorProfile';
import './Users.css';

const auth = new Auth();

export function getUserDisplayName(obj) {
  const user = auth.getUser();
  const name = obj.fullname ? obj.fullname : obj.username;
  if (user && user.role === 'admin') {
    return obj.email ? `${name} (${obj.email})` : name;
  }
  return obj.fullname && obj.username ? `${name} (${obj.username})` : name;
}

export function getUserName(obj) {
  const name = obj && obj.fullname ? obj.fullname : obj.username;
  return obj && obj.fullname && obj.username ? `${name} (${obj.username})` : name;
}

export function getUserFullName(obj) {
  if (!obj) {
    return '';
  }
  return obj.fullname ? obj.fullname : obj.username;
}

const isVerifiedFormatter = (cell) => (
  <>
    {cell}
    <div className={`${cell === true ? 'checked' : ''} circleBox`} />
  </>
);

const dateFormatter = (cell) => (
  <Moment
    element="span"
    format="YYYY-MM-DD hh:mm A"
    date={cell}
  />
);

const monetizedFormatter = (cell) => (
  <>
    {cell}
    <div className={`${cell === true ? 'checked' : ''} circleBox`} />
  </>
);

const latestActivityFormatter = (cell) => (cell ? (
  <Moment
    element="span"
    format="YYYY-MM-DD hh:mm A"
    date={cell}
  />
) : 'none');

const userHeaders = [{ label: 'ID', key: 'id' }, { label: 'Name', key: 'name' }, {
  label: 'Email',
  key: 'email',
}, { label: 'Monetized', key: 'monetized' }, { label: 'Verified', key: 'isVerified' }, {
  label: 'Social Media Handle',
  key: 'social_media',
}, { label: 'Activity', key: 'latestActivity' }, { label: 'Date', key: 'createdAt' }];

export class Users extends Component {
  constructor(props) {
    super(props);
    // TODO: Create observer class for convenience
    this.abortControllers = new Map();

    this.state = {
      users: [],
      editUserData: null,
      listUsersActive: false,
      addUserActive: false,
      showUserProfile: false,
      userProfileData: null,
      loading: false,
      loadingCSV: false,
      searchTerm: '',
    };

    const { history } = this.props;
    this.page = {
      header: {
        title: 'Users', settings: 'admin',
      },
      sidebar: {
        nav: [{
          title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
          href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
          action: history.length > 1 && document.referrer !== window.location.href ? () => {
            window.history.back();
          } : null,
          variant: 'secondary',
          disabled: false,
        }, {
          title: 'Add New User', action: () => this.initAddUser(null), variant: 'primary', disabled: false,
        }],
      },
    };
  }

  componentDidMount() {
    this.abortControllers.set('loadingCSV', new AbortController());
    this.abortControllers.set('loading', new AbortController());
    this.setState({
      listUsersActive: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      searchTerm,
    } = this.state;
    if (prevState.searchTerm !== searchTerm) {
      this.loadUsersList();
    }
  }

  componentWillUnmount() {
    this.abortControllers.forEach((abortController) => abortController.abort());
  }

  loadUsersList = () => {
    const {
      searchTerm,
      loading,
    } = this.state;

    if (!searchTerm) {
      this.setState({
        users: [],
        listUsersActive: true,
        addUserActive: false,
      });
      return;
    }

    if (loading) {
      this.abortControllers.get('loading').abort();
      this.abortControllers.set('loading', new AbortController());
    }

    const config = {
      params: {
        search: searchTerm,
      },
      signal: this.abortControllers.get('loading').signal,
    };
    this.setState({ loading: true });
    api.get('/v1/users', config)
      .then((res) => {
        for (let i = 0; i < res.data.users.length; i++) {
          res.data.users[i].name = getUserName(res.data.users[i]);
        }

        this.setState({
          users: res.data.users,
          listUsersActive: true,
          addUserActive: false,
        });
      })
      .finally(() => this.setState({
        loading: false,
      }));
  };

  initAddUser = (obj) => {
    let editUserData = null;
    if (obj || obj !== null) {
      editUserData = obj;
    }
    this.setState({
      editUserData, addUserActive: true,
    });
  };

  cancelAddUser = () => {
    this.setState({
      editUserData: null, addUserActive: false,
    });
  };

  showUserProfile = (obj) => {
    this.setState({ showUserProfile: true, userProfileData: obj });
  };

  modalAuthorProfileClose = () => {
    this.setState({ showUserProfile: false, userProfileData: null });
  };

  nameFormatter = (cell, row) => (
    <>
      <OverlayTrigger
        overlay={(
          <Tooltip id="tooltip-top">
            {row.receive_email ? 'receive updates' : 'not receive updates'}
          </Tooltip>
        )}
      >
        <img src={row.receive_email ? CheckIconG : CheckIcon} className="UserMailIcon" alt="" />
      </OverlayTrigger>
      {cell}
    </>
  );

  emailFormatter = (cell, row) => {
    let mailIcon = Mail; let
      verifiedMail = 'does not require verified mail';
    if (row.email_verified) {
      mailIcon = MailG;
      verifiedMail = 'verified mail';
    }

    if (cell && !row.email_verified) {
      mailIcon = MailR;
      verifiedMail = 'not verified mail';
    }

    return (
      <>
        <OverlayTrigger
          overlay={(
            <Tooltip id="tooltip-top">
              {verifiedMail}
            </Tooltip>
          )}
        >
          <img src={mailIcon} className="UserMailIcon" alt="" />
        </OverlayTrigger>
        {cell}
      </>
    );
  };

  actionsFormatter = (cell, row) => {
    const action = this.initAddUser;
    const showProfile = this.showUserProfile;
    const updateUsers = () => {
      this.loadUsersList();
    };
    return (
      <Actions
        obj={row}
        actionEditUsers={action}
        actionUserProfile={showProfile}
        update={updateUsers}
      />
    );
  };

  prepareCSVData = async () => {
    const { searchTerm, loadingCSV } = this.state;

    if (loadingCSV) {
      this.abortControllers.get('loadingCSV').abort();
      this.abortControllers.set('loadingCSV', new AbortController());
    }

    this.setState({ loadingCSV: true });
    const config = {
      params: {
        search: searchTerm,
      },
      signal: this.abortControllers.get('loadingCSV').signal,
    };
    const response = await api.get('/v1/users', config);
    this.setState({ loadingCSV: false });
    const { users } = response.data;
    if (users) {
      const data = users.map((user) => {
        const userName = getUserName(user);
        return { ...user, ...{ name: userName } };
      });
      const fileName = `Users-${moment()}.csv`;
      return {
        data,
        fileName,
        headers: userHeaders,
      };
    }
    return null;
  };

  render() {
    const {
      users,
      loading,
      loadingCSV,
      addUserActive,
      editUserData,
      showUserProfile,
      userProfileData,
      listUsersActive,
      searchTerm,
    } = this.state;

    const columns = [{
      dataField: 'id',
      text: 'ID',
      headerStyle: () => ({ width: '7%' }),
      sort: true,
    }, {
      dataField: 'name', text: 'Name', sort: true, formatter: this.nameFormatter,
    }, {
      dataField: 'email', text: 'Email', sort: true, formatter: this.emailFormatter,
    }, {
      dataField: 'monetized',
      text: 'Monetized',
      headerStyle: () => ({ width: '7%' }),
      formatter: monetizedFormatter,
    }, {
      dataField: 'isVerified',
      text: 'Verified',
      headerStyle: () => ({ width: '7%' }),
      formatter: isVerifiedFormatter,
    }, {
      dataField: 'social_media', text: 'Social Media Handle',
    }, {
      dataField: 'latestActivity',
      sort: true,
      text: 'Activity',
      type: 'date',
      formatter: latestActivityFormatter,
    }, {
      dataField: 'createdAt',
      sort: true,
      text: 'Date',
      formatter: dateFormatter,
    }, {
      dataField: 'actions', isDummyField: true, text: '', formatter: this.actionsFormatter,
    }];

    const defaultSorted = [{
      dataField: 'name', order: 'asc',
    }];

    const expandRow = {
      renderer: (row) => {
        const yourWriting = row.yourWriting && JSON.parse(row.yourWriting);
        return (
          <div>
            <Row>
              {row.id && (
              <Col>
                <p>
                  <b>ID:</b>
                  {' '}
                  {row.id}
                </p>
              </Col>
              )}
              {row.role && (
              <Col>
                <p>
                  <b>Role:</b>
                  {' '}
                  {row.role}
                </p>
              </Col>
              )}
              {row.device && (
              <Col>
                <p>
                  <b>Device:</b>
                  {' '}
                  {row.device}
                </p>
              </Col>
              )}
              {row.country && (
              <Col>
                <p>
                  <b>Country:</b>
                  {' '}
                  {row.country}
                </p>
              </Col>
              )}
            </Row>

            {yourWriting && (
            <p>
              <b>Where can we see your writing:</b>
              {yourWriting.map((e, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <i key={i}>
                  {' '}
                  {e}
                  ,
                  {' '}
                </i>
              ))}
            </p>
            )}

          </div>
        );
      },
      showExpandColumn: true,
    };

    const paginationOptions = {
      custom: true,
      withFirstAndLast: true,
      hidePageListOnlyOnePage: false,
      paginationSize: 5,
      showTotal: true,
      totalSize: users.length,
      sizePerPageList: [{
        text: '10', value: 10,
      }, {
        text: '25', value: 25,
      }, {
        text: '50', value: 50,
      }, {
        text: '100', value: 100,
      }, {
        text: '250', value: 250,
      }, {
        text: '500', value: 500,
      }, {
        text: '1000', value: 1000,
      }, {
        text: 'All', value: users.length,
      }],
    };

    return (
      <>
        <PageWrapper
          {...this.props}
          page={this.page}
        >
          <Card className={listUsersActive ? 'd-block' : 'd-none'}>
            <Card.Body>
              <Card.Title>
                <Row>
                  <Col>
                    List of Users
                    {loading && (
                    <Spinner
                      className="users-loading-spinner"
                      variant="primary"
                      animation="border"
                    />
                    )}
                  </Col>
                  <Col className="text-right">
                    <ExportAsyncCSV
                      asyncExportMethod={this.prepareCSVData}
                      disabled={loadingCSV}
                    >
                      { loadingCSV && (
                      <Spinner
                        title="Loading data for export to CSV"
                        className="mr-2"
                        animation="border"
                        size="sm"
                      />
                      )}
                      { loadingCSV ? 'Downloading...' : 'Download CSV' }
                    </ExportAsyncCSV>
                  </Col>
                </Row>
              </Card.Title>
              <DebouncedSearch
                initialValue={searchTerm}
                onSearchSubmit={(newSearchTerm) => this.setState({
                  searchTerm: newSearchTerm,
                })}
              />
              { users.length > 0
                ? (
                  <PaginationProvider
                    pagination={paginationFactory(paginationOptions)}
                    keyField="id"
                    columns={columns}
                    data={users}
                  >
                    {
                            ({
                              paginationProps,
                              paginationTableProps,
                            }) => (
                              <>
                                <div className="mt-4 d-flex justify-content-center">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <PaginationTotalStandalone
                                  {...paginationProps}
                                />
                                <BootstrapTable
                                  hover
                                  bootstrap4
                                  keyField="id"
                                  columns={columns}
                                  expandRow={expandRow}
                                  data={users}
                                  defaultSorted={defaultSorted}
                                  {...paginationTableProps}
                                />
                                <PaginationTotalStandalone
                                  {...paginationProps}
                                />
                                <div className="d-flex justify-content-center">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </>
                            )
                          }
                  </PaginationProvider>
                )
                : (
                  <Row>
                    <Col className="text-center mt-4">
                      <h5>Enter a string to search by name or email address</h5>
                    </Col>
                  </Row>
                )}

            </Card.Body>
          </Card>
        </PageWrapper>

        {showUserProfile ? (
          <AuthorProfile
            show={showUserProfile}
            onHide={this.modalAuthorProfileClose}
            user={userProfileData ? userProfileData.id : null}
            update={this.loadUsersList}
          />
        ) : null}

        {addUserActive ? (
          <AddUser
            data={editUserData}
            show={addUserActive}
            onHide={this.cancelAddUser}
            update={this.loadUsersList}
          />
        ) : null}
      </>
    );
  }
}
