import React from 'react';
import { CardMemorySlotData } from 'components/pages/Book/MemoryBank/memoryBankTypes';

type BranchNodeCardCheckInfoProps = {
  memorySlotData: CardMemorySlotData,
}

export function BranchNodeCardCheckInfo(props: BranchNodeCardCheckInfoProps) {
  const { memorySlotData } = props;

  return (
    <div className="check_card_info">
      {memorySlotData && (
      <>
        <div>
          {`${memorySlotData.name} ${memorySlotData.operator} ${memorySlotData.value}`}
        </div>
        <div className="switch_info">
          <div className="switch_true">true</div>
          <div className="switch_false">false</div>
        </div>
      </>
      )}
    </div>
  );
}
