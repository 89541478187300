import React from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { Preview } from '../../../ui/Preview/Preview';
import './Preview.scss';

export class DetachablePreview extends React.PureComponent {
  constructor(props) {
    super(props);
    const { displayAnalyticsOption } = props;
    this.externalWindow = null;
    this.state = {
      portal: false,
      displayAnalyticsOption,
    };
  }

  closeWindow = () => {
    this.setState({ portal: false });
    if (this.externalWindow) this.externalWindow.close();
    this.externalWindow = null;
  };

  onExternalClose = () => {
    const { portal } = this.state;

    if (portal && this.externalWindow) {
      this.setState({ portal: false });
      this.externalWindow = null;
    }
  };

  openWindow = () => {
    const { storyUuid } = this.props;
    this.externalWindow = window.open(`/play/${storyUuid}`, '_blank');

    this.externalWindow.document.title = document.title;

    // NOTE: Different browser and also different versions of same browser
    // have strange behaviour for this events.
    // Dirty fix.
    this.externalWindow.addEventListener('beforeunload', this.onExternalClose, { capture: true });
    this.externalWindow.addEventListener('close', this.onExternalClose, { capture: true });
    this.externalWindow.addEventListener('unload', this.onExternalClose, { capture: true });

    this.externalWindow.addEventListener('load', () => {
      const { portal } = this.state;

      if (!portal) {
        this.setState({ portal: true });
      }
    });
  };

  beforeUnload = () => {
    this.closeWindow();
  };

  componentDidUpdate(prevProps, prevState) {
    const { update, show, displayAnalyticsOption } = this.props;
    const { portal } = this.state;

    if (prevState.portal !== portal) {
      update(!!(show && !portal));
    }

    if (prevProps.show !== show) {
      this.closeWindow();
      update(!!(show && !portal));
      if (show) {
        window.addEventListener('beforeunload', this.beforeUnload, { passive: true });
      } else {
        window.removeEventListener('beforeunload', this.beforeUnload);
      }
    }

    if (prevProps.displayAnalyticsOption !== displayAnalyticsOption) {
      this.setState({ displayAnalyticsOption });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnload);
    this.closeWindow();
  }

  renderPanel() {
    const { hideDetach, onClose } = this.props;

    return (
      <div className="navi">
        <Row>
          {!hideDetach
          && (
          <Col>
            <Button
              block
              onClick={this.openWindow}
              variant="primary"
              size="sm"
            >
              Detach
            </Button>
          </Col>
          )}
          <Col>
            <Button
              disabled={!onClose}
              block
              onClick={onClose}
              variant="secondary"
              size="sm"
            >
              Close
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  handleAnalyticOptionChanged(event) {
    this.setState({ displayAnalyticsOption: event.target.value });
  }

  render() {
    const {
      show,
      storyUuid,
      bookStyle,
      store,
      onPreviewExit,
      isBranchDetailsCacheDisabled,
      showAnalyticsPanel,
    } = this.props;

    const {
      portal,
      displayAnalyticsOption,
    } = this.state;

    if (portal || !show || !storyUuid) {
      return (
        <div className="previewDock" />
      );
    }

    store.displayAnalytics = displayAnalyticsOption;

    return (
      <div
        className="previewDock"
      >
        {this.renderPanel()}
        { showAnalyticsPanel && (
        <div className="analyticsPanelInPreview">
          <div className="analyticsPanelInPreviewTitle">
            Performance:
          </div>
          <Form.Check
            custom
            type="radio"
            inline
            label="Hide"
            id="Hide"
            value="hide"
            checked={displayAnalyticsOption === 'hide'}
            onChange={(event) => this.handleAnalyticOptionChanged(event)}
          />
          <Form.Check
            custom
            type="radio"
            inline
            label="as count"
            value="count"
            checked={displayAnalyticsOption === 'count'}
            onChange={(event) => this.handleAnalyticOptionChanged(event)}
            id="count"
          />
          <Form.Check
            custom
            type="radio"
            inline
            label="as percent"
            value="percentage"
            checked={displayAnalyticsOption === 'percentage'}
            onChange={(event) => this.handleAnalyticOptionChanged(event)}
            id="percentage"
          />
        </div>
        )}
        <Preview
          episodeUuid={storyUuid}
          bookStyle={bookStyle}
          // TODO: make width flexible on GR side.
          // 75% of width of previewDock which is 30em, where font-size is 16px
          width={345}
          store={store}
          onPreviewExit={onPreviewExit}
          isBranchDetailsCacheDisabled={isBranchDetailsCacheDisabled}
        />
      </div>
    );
  }
}
