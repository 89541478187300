import { useFormik } from 'formik';
import React, { ChangeEvent, useState } from 'react';
import {
  Button, Form, Modal,
} from 'react-bootstrap';
import * as yup from 'yup';
import { api } from 'components/api';
import { showToast } from 'components/ui/utils';
import { Character } from 'dorian-shared/types/character/Character';
import { submitMemorySlots } from '../MemoryBankModal';
import classes from './MemoryBank.module.scss';
import { MemoryBankSlotsForm } from './MemoryBankSlotsForm';
import { MemorySlotsConverted } from './memoryBankTypes';
import { MemorySlotsTypesList } from './MemoryBankTypesList';

type MemoryBankFormProps = {
    memoryBankSlots: MemorySlotsConverted[],
    onHide: () => void,
    bookId: number,
}

const schema = yup.object({
  memoryBankSlots: yup.array().of(
    yup.object({
      name: yup.string().min(2).max(19).required()
        .matches(/^(?:host\.)?([_A-Za-z][_A-Za-z0-9]{2,19})$/),
      value: yup.string()
        .when('type', (type) => {
          switch (type) {
            case MemorySlotsTypesList.boolean:
              return yup.boolean().required();
            case MemorySlotsTypesList.string:
              return yup.string().required().min(2).max(50);
            case MemorySlotsTypesList.number:
              return yup.number().required();
            case MemorySlotsTypesList.character:
              return yup.number().required();
            default:
              return yup.string().required();
          }
        }),
      type: yup.string(),
    }),
  ),
});

const fetchCharacters = async (bookId: number): Promise<Character[] | null> => {
  const characters = await api.get(`/v1/books/${bookId}/characters`);
  return characters?.data?.characters;
};

export function MemoryBankForm(props: MemoryBankFormProps) {
  const { memoryBankSlots, onHide, bookId } = props;
  const [characters, setCharacters] = useState<Character[] | null>(null);

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      memoryBankSlots,
    },
    onSubmit: (sendValues) => {
      submitMemorySlots(bookId, sendValues.memoryBankSlots);
      onHide();
    },
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
  } = formik;

  const handleChangeSlot = (event: ChangeEvent<HTMLInputElement>, slotIndex: number) => {
    const newMemoryBankSlots = [
      ...values.memoryBankSlots,
    ];

    newMemoryBankSlots[slotIndex].value = '';
    newMemoryBankSlots[slotIndex].defaultValue = '';

    setFieldValue('memoryBankSlots', newMemoryBankSlots);

    formik.handleChange(event);
  };

  const handleRemoveSlot = (slotIndex: number) => {
    const newMemoryBankSlots = [
      ...values.memoryBankSlots,
    ];

    if (newMemoryBankSlots[slotIndex].isNew) {
      delete newMemoryBankSlots[slotIndex];
    } else {
      newMemoryBankSlots[slotIndex].isRemoving = true;
    }

    setFieldValue('memoryBankSlots', newMemoryBankSlots);
  };
  const handleAddMemoryBankSlot = () => {
    if (!values) {
      return;
    }
    const nextIndex = values.memoryBankSlots.length;
    const newSlot: MemorySlotsConverted = {
      type: MemorySlotsTypesList.string,
      defaultValue: '',
      value: '',
      id: -1,
      index: nextIndex,
      name: '',
      isNew: true,
      isRemoving: false,
    };

    const newMemoryBankSlots = [
      ...values.memoryBankSlots,
      newSlot,
    ];
    setFieldValue('memoryBankSlots', newMemoryBankSlots);
  };

  const getCharacters = async () => {
    if (!characters) {
      try {
        const charactersData = await fetchCharacters(bookId);
        setCharacters(charactersData);
      } catch (e) {
        showToast({
          textMessage: 'Can\'t load characters',
        });
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>
          Memory Bank
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        className={classes.memoryBankFormWrapper}
      >
        <Form.Row
          className={classes.memoryBankTopPanel}
        >
          <Form.Label>
            Memory slots:
          </Form.Label>
          <Button
            size="sm"
            variant="secondary"
            onClick={handleAddMemoryBankSlot}
            className={classes.memoryBankAddSlotButton}
          >
            + Add Slot
          </Button>
        </Form.Row>

        {values.memoryBankSlots.map((memoryBankSlot, slotIndex) => {
          if (memoryBankSlot.isRemoving) {
            return null;
          }
          const errorsForSlot = errors.memoryBankSlots ? errors.memoryBankSlots[slotIndex] : {};
          return (
            <MemoryBankSlotsForm
              key={memoryBankSlot.index}
              slotIndex={slotIndex}
              memoryBankSlot={memoryBankSlot}
              handleChange={handleChangeSlot}
              handleRemove={handleRemoveSlot}
              errors={errorsForSlot}
              characters={characters}
              getCharacters={getCharacters}
            />
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          type="reset"
          variant="secondary"
          onClick={onHide}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          type="submit"
          variant="primary"
        >
          Save
        </Button>
      </Modal.Footer>
    </Form>
  );
}
