// based on source/www/js/server/ServerConfig.js

const envPrefixByEnvLabel = {
  local: 'local',
  cpt: 'cpt',
  unstable1: 'staging',
  unstable2: 'staging',
  staging: 'staging',
  prod: 'prod',
};

export function getEnvPrefix(envLabel: keyof typeof envPrefixByEnvLabel) {
  return envPrefixByEnvLabel[envLabel];
}

const assetsUrlByEnvLabel = {
  local: 'http://localhost',
  cpt: 'https://dorian-client-assets.s3.us-east-2.amazonaws.com',
  unstable1: 'https://dorian-client-assets.s3.us-east-2.amazonaws.com',
  unstable2: 'https://dorian-client-assets.s3.us-east-2.amazonaws.com',
  staging: 'https://dorian-client-assets.s3.us-east-2.amazonaws.com',
  prod: 'https://dorian-client-assets.s3.us-east-2.amazonaws.com',
};

export function getBaseUrl(envLabel: keyof typeof assetsUrlByEnvLabel) {
  return assetsUrlByEnvLabel[envLabel];
}
