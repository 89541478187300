import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { stringOrNumberValuePattern } from 'components/pages/Book/MemoryBank/MemoryBankSlotsForm';
import { MemorySlotsBase, MemorySlotsValue } from 'components/pages/Book/MemoryBank/memoryBankTypes';
import { MemorySlotsTypesList } from 'components/pages/Book/MemoryBank/MemoryBankTypesList';
import { Character } from 'dorian-shared/types/character/Character';
import { MemorySlotFormValue } from './MemorySlotCompareForm';
import { MemorySlotCompareValueFormList } from './MemorySlotCompareValueFormList';

type MemorySlotCompareValueFormProps = {
  valueInputValue: MemorySlotsValue,
  stepIndex: number,
  onChangeValue: (
    values: MemorySlotFormValue[],
    stepIndex: number,
  ) => void,
  characters: Character[],
  memoryBankSlot?: MemorySlotsBase,
  inputName: string,
  isDisabled?: boolean,
}

export function MemorySlotCompareValueForm(props: MemorySlotCompareValueFormProps) {
  const {
    valueInputValue, stepIndex, onChangeValue, memoryBankSlot, characters, inputName,
    isDisabled,
  } = props;

  return (
    <Form.Group as={Row}>
      <Col>
        {(memoryBankSlot?.type === MemorySlotsTypesList.string
        || memoryBankSlot?.type === MemorySlotsTypesList.number
        || !memoryBankSlot)
        && (
          <Form.Control
            disabled={isDisabled}
            size="sm"
            type="text"
            placeholder="value"
            name={inputName}
            pattern={stringOrNumberValuePattern}
            value={valueInputValue}
            onChange={(event) => onChangeValue([{
              name: 'value',
              value: event.target.value,
            }], stepIndex)}
            required
          />
        )}
        {memoryBankSlot?.type === MemorySlotsTypesList.boolean
        && (
          <MemorySlotCompareValueFormList
            stepIndex={stepIndex}
            onChangeValue={onChangeValue}
            valueInputValue={valueInputValue}
            inputName={inputName}
          >
            <option value="" disabled>[select]</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </MemorySlotCompareValueFormList>
        )}
        {memoryBankSlot?.type === MemorySlotsTypesList.character
        && (
          <MemorySlotCompareValueFormList
            stepIndex={stepIndex}
            onChangeValue={onChangeValue}
            valueInputValue={valueInputValue}
            inputName={inputName}
          >
            <option value="" disabled>[select]</option>
            {characters.map((character) => (
              <option value={character.id} key={character.id}>
                {character.name}
              </option>
            ))}
          </MemorySlotCompareValueFormList>
        )}
      </Col>
    </Form.Group>
  );
}
