import classNames from 'classnames/bind';
import serialize from 'form-serialize';
import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import styles from '../StoryBranches.scss';

const cs = classNames.bind(styles);

export class EditName extends Component {
  constructor(...args) {
    super(...args);
    const { branchNode } = this.props;

    this.state = {
      defaultValueTitle: branchNode.title,
      validated: false,
    };
  }

  handleSubmit = (event) => {
    const { handleSubmit } = this.props;
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const value = serialize(form, { hash: true, disabled: false });
    if (validated === true) {
      handleSubmit(value);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  };

  render() {
    const {
      disabled, refFormControl, limits, brancheNameForm,
    } = this.props;
    const { validated, defaultValueTitle } = this.state;

    const limit = limits
      && limits.branch_title_max
      && limits.branch_title_max.value
      ? Number(limits.branch_title_max.value) : 0;

    return (
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => this.handleSubmit(e)}
      >

        <Form.Group
          controlId="BranchTitle"
          className="mb-0"
        >
          <Form.Control
            isInvalid={validated}
            autoFocus
            size="sm"
            required
            type="text"
            placeholder="Node Title"
            value={defaultValueTitle}
            pattern="^([A-Za-z]|[0-9]|_|-)+$"
            name="title"
            ref={refFormControl}
            onBlur={() => {
              brancheNameForm.current.click();
            }}
            onMouseDown={(e) => e.stopPropagation()}
            onChange={(e) => {
              const re = new RegExp(e.target.pattern);
              this.setState({
                defaultValueTitle: e.target.value,
                validated: !re.test(e.target.value),
              });
            }}
            className={cs('editNameInp', defaultValueTitle && defaultValueTitle.length > limit ? 'text-limit' : null)}
          />

          <Form.Text className="char-limit-info-box">
            {limit
              ? limit - (defaultValueTitle ? defaultValueTitle.length : 0)
              : 0}
          </Form.Text>
          <Form.Control.Feedback
            type="invalid"
            className={defaultValueTitle && defaultValueTitle.length > limit ? 'd-block' : 'd-none'}
          >
            Node Title is too long
          </Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Please choose a Node Title.
          </Form.Control.Feedback>
        </Form.Group>

        <Button
          disabled={disabled || (defaultValueTitle && defaultValueTitle.length > limit)}
          className="d-none"
          type="submit"
          ref={brancheNameForm}
          variant="primary"
        >
          Save
        </Button>

        <div className="editNameBg" />
      </Form>
    );
  }
}
