import React, { Component } from 'react';
import { api } from '../../api';
import { LoginModalForm } from '../../ui/LoginModalForm';
import { StoryPreviewData } from './context/data';
import { Preview } from './Preview';
import './Preview.scss';

export class StoryPreview extends Component {
  constructor(props) {
    super(props);
    const { match, auth } = this.props;

    this.state = {
      emSize: 10,
      story: [],
      loading: true,
      lastEdit: localStorage.getItem(`lastEdit-${match.params.uuid}`) !== null ? JSON.parse(localStorage.getItem(`lastEdit-${match.params.uuid}`)) : null,
    };
    this.user = auth.getUser();
  }

  updateDimensions = () => {
    const newHeight = window.innerHeight;
    const emSize = 10 * (newHeight / 976);
    this.setState({ emSize });
  };

  updateStorage(e) {
    const { match } = this.props;

    if (e.key === `lastEdit-${match.params.uuid}`) {
      this.setState({
        lastEdit: localStorage.getItem(`lastEdit-${match.params.uuid}`) !== null ? JSON.parse(localStorage.getItem(`lastEdit-${match.params.uuid}`)) : null,
      });
    }
  }

  componentDidMount() {
    this.loadStory();
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
    window.addEventListener('storage', this.updateStorage.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
    window.removeEventListener('storage', this.updateStorage.bind(this));
  }

  setLastEdit = (branchId) => {
    const { match } = this.props;
    localStorage.setItem(`lastEdit-${match.params.uuid}`, branchId);
  };

  loadStory() {
    const { match } = this.props;

    api.get(`/v1/preview/${match.params.uuid}`)
      .then((res) => {
        this.setState({
          loading: false,
          story: res.data.story,
        });
      })
      .catch((error) => {
        this.errorAlert(error);
      });
  }

  render() {
    const { match } = this.props;
    const {
      lastEdit, loading, story, emSize,
    } = this.state;

    if (loading) {
      return null;
    }
    return (
      <StoryPreviewData>
        <div className="emulatorWindow" style={{ fontSize: `${emSize}px` }}>
          <Preview
            storyUuid={match.params.uuid}
            setLastEdit={this.setLastEdit}
            lastEdit={lastEdit}
          />
        </div>
        {(!this.user && !story.public) && (
          <LoginModalForm
            {...this.props}
            className="loginOnPreview"
            play
            show
          />
        )}
      </StoryPreviewData>
    );
  }
}
