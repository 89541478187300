import React, { useEffect, useState } from 'react';
import {
  Modal, Spinner,
} from 'react-bootstrap';
import { api } from 'components/api';
import { showToast } from 'components/ui/utils';
import { MemoryBankForm } from './MemoryBank/MemoryBankForm';
import {
  MemorySlotsBase,
  MemorySlotsConverted,
  MemorySlotsToSend,
} from './MemoryBank/memoryBankTypes';

export const MEMORY_BANK_STEPS_TYPES = {
  REMEMBER: 12,
  CHECK: 11,
};

type MemoryBankModalProps = {
  bookId: number,
  onHide: () => void,
}

const updateMemorySlotsState = (slot: MemorySlotsConverted, bookId: number | string) => {
  const slotToSend: MemorySlotsToSend = {
    defaultValue: slot.value,
    type: slot.type,
    name: slot.name,
    bookId: slot.bookId,
  };

  // remove
  if (slot.isRemoving) {
    // don't send data if it's a new slot that is not yet id DB
    if (slot.isNew) {
      return Promise.resolve(true);
    }

    return api.delete(`/v1/books/variables/${slot.id}`);
  }

  // update
  if (!slot.isNew && slot.id) {
    return api.put(`/v1/books/variables/${slot.id}`, slotToSend);
  }

  // create
  return api.post(`/v1/books/${bookId}/variables/`, slotToSend);
};

export const submitMemorySlots = async (
  bookId: number,
  memorySlotsConverted: MemorySlotsConverted[],
) => {
  memorySlotsConverted.forEach((slot) => {
    try {
      updateMemorySlotsState(slot, bookId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({
        textMessage: 'Can\'t update slots',
      });
    }
  });
};

export const fetchMemorySlotsData = async (bookId: number) => {
  let existingSlots: MemorySlotsBase[] = [];
  try {
    const result = await api.get(`/v1/books/${bookId}/variables/`);

    if (result.data) {
      const { success, variables } = result.data;

      if (success) {
        existingSlots = variables;
      }
    }
  } catch (error) {
    showToast({
      textMessage: 'Can\'t get slots data',
    });
  }

  return existingSlots;
};

const convertMemorySlotsData = (slots?: MemorySlotsBase[]): MemorySlotsConverted[] => {
  if (!slots) {
    return [];
  }

  return slots.map((slot, index) => ({
    ...slot, index, value: slot.defaultValue, isNew: false,
  }));
};

export function MemoryBankModal(props: MemoryBankModalProps) {
  const {
    onHide,
    bookId,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [memoryBankSlots, setMemoryBankSlots] = useState<MemorySlotsConverted[]>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const slotsData = await fetchMemorySlotsData(bookId);
        const slotsConverted = convertMemorySlotsData(slotsData);
        setMemoryBankSlots(slotsConverted);
      } catch (e) {
        showToast({
          textMessage: 'Can\'t load memory bank slots',
        });
      }
      setLoading(false);
    })();
  }, [bookId]);

  return (
    <Modal
      show
      onHide={onHide}
      size="lg"
      centered
      backdrop="static"
    >
      {loading
      && (
        <Spinner
          variant="primary"
          animation="border"
          className="spinner"
        />
      )}
      {memoryBankSlots
      && (
        <MemoryBankForm
          memoryBankSlots={memoryBankSlots}
          onHide={onHide}
          bookId={bookId}
        />
      )}
    </Modal>
  );
}
